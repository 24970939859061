import { useContext, useEffect, useState } from 'react';
import { Menu, Select, Dropdown } from 'antd';
import VilisoLogo from "../IMAGES/vilisoLogoTransparent.png";
import { IDS_AUSrvrIssueReqParamsNotSent, IDS_Ammonia, IDS_BarelySensed, IDS_ColdAndChilly, IDS_Comfortable, IDS_DTAddUser, IDS_DTDeviceProfile, IDS_DTDeviceRawData, IDS_DTFetchingDevcInfo, IDS_DTRemoveUser, IDS_Dry, IDS_Dust, IDS_EasilyRecognized, IDS_Good, IDS_Hot, IDS_Humid, IDS_Humidity, IDS_LPG, IDS_LoginServerIssue, IDS_MRI, IDS_Methane, IDS_Moderate, IDS_Odour, IDS_Ozone, IDS_Poor, IDS_PoorAqi, IDS_Pungent, IDS_RangeLowSevere, IDS_RangeVeryPoor, IDS_RefreshData, IDS_RegistNetworkError, IDS_SMOKE, IDS_Satisfactory, IDS_Severe, IDS_SevereAqi, IDS_Sticky, IDS_Strong, IDS_TVOC, IDS_Temperature, IDS_VRI, IDS_VeryPoorAqi, IDS_Warm, IDS_Weak, IDS_airQualityIndex, IDS_carbonDioxide, IDS_carbonMonoxide, IDS_currentAirQualityIndex, IDS_hydrogenSulphide, IDS_nitrogenDioxide, IDS_sulphurDioxide, IDS_Formaldehyde, IDS_Oxygen, IDS_NitricOxide, IDS_Chlorine, IDS_MethylMercaptan, IDS_Noise, IDS_LightIntensity, IDS_UV, IDS_Radon, IDS_AirPressure, IDS_WindSpeed, IDS_WindDirection, IDS_DaylightVisibility, IDS_Rainfall, IDS_Low, IDS_ExtremeLow, IDS_LightRain, IDS_LowNoiseLevel, IDS_MediumLightIntensity, IDS_Normal, IDS_GoodVisibility, IDS_CalmBreeze, IDS_VeryLow, IDS_ModerateRain, IDS_MediumNoiseLevel, IDS_ModerateVisibility, IDS_ModerateBreeze, IDS_HeavyRain, IDS_FairVisibility, IDS_High, IDS_VeryHeavyRain, IDS_StrongGale, IDS_VeryHigh, IDS_TorrentialRain, IDS_HighNoiseLevel, IDS_HighLightIntensity, IDS_Storm, IDS_Extreme, IDS_ExtremeHigh, IDS_RangeLowVeryPoor, IDS_AddToilet, IDS_PoorVisibility, IDS_NoRain, IDS_GentleBreeze, IDS_SatisfactoryInSingleDeviceData, IDS_EasilyRecognizedInSingleDeviceData, IDS_PungentInSingleDeviceData 
} from '../../VcLanguage';
import {ACTIVE_STATUS_MINIMUM_TIME, PVG_GENERAL_USER, NO_DEVC_FOUND_FOR_LOGGED_IN_USER,
    CO, O3, LPG, SMOKE, CH4, CO2, HUM, NO2, VOC, PM10, PM25, TEMP, PM1, NH3, H2S, SO2, NH3OD, ALERT_BASED,
    LOW_SEVERE_L, LOW_SEVERE_U, GOOD_L, GOOD_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U,
    HIGH_SEVERE_L, HIGH_SEVERE_U, LOW_SEVERE, GOOD, SATISFACTORY, MODERATE, POOR, V_POOR, HIGH_SEVERE, DEVICE_TYPE_PFC, VRI, MRI, CLIENT_TYPE, LOC, TTS, LAS, SOA, WLIP, VOL, AWL, AWC, SLIP, AQI, CAQI, SATISFACTORY_L, SATISFACTORY_U, PROD_DEVC_OWNER_USER_ID, WLI, HCHO, O2, PM100, NO, CL, CH3SH, NOISE, LUX, UV, RADON, AP, WS, WD, DLV, RNFL, NO_CHART_PARAMS, LOW_V_POOR, LOW_V_POOR_L, LOW_V_POOR_U,
    TCWL
} from '../../VcConstants';
import axios from 'axios';
import { getAPIHostURL, getDeviceActiveStatusRefreshTime, getDeviceActiveStatusTimeOut } from '../../ClientConfig';
import { getNestedChildren } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import '../CSS/TreeView.css';
import { HiDotsVertical } from "react-icons/hi";
import { TbDeviceDesktop } from "react-icons/tb";
import { TbDeviceDesktopPlus } from "react-icons/tb";
import enc from 'crypto-js/enc-utf8';
import aes from 'crypto-js/aes';
import { useDispatch, useSelector } from 'react-redux';
import { appendDevices, clearDeviceList, appendGlobalSearchNode } from './Store/Slices/deviceList';
import { appendTreeNodes, clearTreeNodeList } from './Store/Slices/treeNodeList';
import {  addToOpenToiletNodes, clearOpenToiletNodes, clearSearchOrClickedToiletNode, setSearchedOrClickedToiletNode, toggleToiletNodeInOpenToiletNodes 
} from './Store/Slices/treeAndSearchSlice';
import { setTreeDefinitionFlag } from "./Store/Slices/variables";
import { NavLink, useNavigate } from 'react-router-dom';
import useInterval from './useInterval';

const VcDeviceTree = ({ setTreeOnMobile }) => {

    const navigate = useNavigate();

    // Reading Store's Slices' State Variables:
    const dispatch = useDispatch();
    const { searchedDeviceToiletNode } = useSelector(s => s.variables)
    const fetchReduxToiletNode = useSelector((state) => state?.treeNodeList?.nodeList)
    const { openToiletNodesID, searchedOrClickedToiletNodeID } = useSelector((state) => state?.treeAndSearchSlice)
    const fetchReduxDeviceList = useSelector((state) => state?.deviceList?.devices)
    const searchedNodeBoolean = useSelector(state => state.deviceList.globalSearchNode)
    const treeDefinitionFlag = useSelector(state => state.variables.treeDefinitionFlag)

    const context = useContext(AppRelevantDataContext)
    const t = context.t;
    
    const selectedNodeID = context.selectedNodeInfo.nodeID;
    const selectedNodeTitle = context.selectedNodeInfo.nodeTitle;
    const contextLoggedInUserID = context.loggedInUserInfo.userID;
    const quickTrackParam = context.quickTrackParamInfo.quickTrackParam;
    const currentViewingLanguage = context.language.languageToViewIn;
    let urlPathName = window.location.pathname;
    const timeoutDuration = 1000;

    const [ state, setState ] = useState({
        deviceInfoToPopulateTree: [],
        toiletDevices: [],
        independentDevices: [],
        toiletNodeInfo: [],
        arrAirQualityRanges: [],
        encryptedPrivileges: '',
        LoggedInUserID: '',
        selectedTreeNodeID: '',
        languageToViewIn: '',
        quickTrackParam: '',
        errors: '',
        objPrivilege: {},
        isPageRefreshed: false,
        arrDeviceModelInfo: [],
        timeoutForScrollEnd: null,
        scrollStatus: '',
        getInfoOfVisibleDevices: [],
        arrQuickTrackParams: [],
        noDeviceRegistered: [],
  })

    useInterval(() => {
        getInfoOfVisibleDevicesInTree();
    }, getDeviceActiveStatusRefreshTime())


    // Initial Render on Component on-Mount:
    useEffect(() => {
        let intervalId;

        // Component mounted for the first time. Check User Privilege.
        checkUserPrivilegesAndCreateTreeNodes();

        // When Device's ID or Toilet's ID is `nodeID` in Context instead of the Logged Info User ID (Eg: Page Refresh from Device or Toilet Dashboard) 
        // Else, Mount the Tree for Initial (Eg: User Login)
            setTimeout(() => {
                renderToDeviceViewport(selectedNodeID)

            },  getDeviceActiveStatusTimeOut());
            // Set interval for after every 5mins
            // intervalId = setInterval(() => {
            //     getInfoOfVisibleDevicesInTree();
            // }, getDeviceActiveStatusRefreshTime());

        // Cleanup
        return () => {
            clearInterval(intervalId);

            {window.innerWidth > 570 &&
                dispatch(clearDeviceList())
            }
            
            {window.innerWidth > 570 &&
            dispatch(clearTreeNodeList())
            }

            dispatch(clearSearchOrClickedToiletNode())
            dispatch(clearOpenToiletNodes())
            dispatch(setTreeDefinitionFlag(false))

            if (state.timeoutForScrollEnd) {
                clearTimeout(state.timeoutForScrollEnd);
            }
        };
    }, []);


    useEffect(() => {

        // Called when Device or Toilet Searched from Global Search 
        if (selectedNodeID && searchedNodeBoolean) {
            setTimeout(() => {
                renderToDeviceViewport(selectedNodeID);
            }, timeoutDuration); // Delay to ensure element is rendered
        }

        // Called when New Toilet Node or Device is Added, Edited or Removed User's Tree Definition Feature Respectively 
        if (urlPathName == "/device/addNode") {
            if(searchedNodeBoolean) {
                setTimeout(() => {
                    renderToDeviceViewport(selectedNodeID);
                }, timeoutDuration); // Delay to ensure element is rendered

                return; // No further processing required
            }

            let shouldUpdate = false;

            if(state.toiletNodeInfo != null && state.toiletNodeInfo.length > 0) {
                state.toiletNodeInfo.forEach((node) => {
                    if(node.hasDevices) {
                        if (node.title != selectedNodeTitle) {
                            shouldUpdate = true;

                            return; // Exit the loop early since we only need to check for one mismatch, no further processing required
                        }
                    }
                })
            } else {
                shouldUpdate = true; // For User Having No Toilet Nodes
            }
    
            if (shouldUpdate) {
                updateStateDataBasedOnActiveUser();

                dispatch(setTreeDefinitionFlag(false))
                dispatch(toggleToiletNodeInOpenToiletNodes(selectedNodeID));
                dispatch(setSearchedOrClickedToiletNode(selectedNodeID));
            }
            
            setTimeout(() => {
                renderToDeviceViewport(selectedNodeID);
            }, timeoutDuration); // Delay to ensure element is rendered
        }

        // Called when Device Searched from Global Search Feature
        if (selectedNodeID) {
            const allDevices = [...state.toiletDevices, ...state.independentDevices];
            
            const device = allDevices.find(device => device.DeviceID === selectedNodeID);
            if (device) {
                context.onSelectedDevice(device.DeviceID, device.DeviceName, false, false, true, context.selectedNodeInfo.deviceType, device.parentID, 
                device.SelectedNodeDeviceType, device.nodePath);
            }
        }

        // To Make Selection Background-Color to Selected Node in Tree
        if(searchedOrClickedToiletNodeID != null) {
            // Called on Selection of Toilet-Node to Render the `Selection Background Color` for that Particular Selected Toilet-Node Respectively
            let allToiletIDs = []
            try {
                allToiletIDs = state.toiletNodeInfo.filter(toilet => toilet.hasDevices === true).map(hasDevcsToilet => hasDevcsToilet.id)
            } catch (error) {
                console.log("allToiletIDs cannot be obtained", error);
            }
            
            // Set all the Toilet Nodes Selection Color to 'white' (default-color for unselected node)
            allToiletIDs.forEach(currentId => {
                let singleToilet  = document.getElementById(currentId)
                if(singleToilet) {
                    let subtitleElement = singleToilet.querySelector('.ant-menu-submenu-title');
                    if(subtitleElement) {
                        subtitleElement.style.background = '#fcfeff'
                    }
                }
            })

            // Set Selected Toilet-Node Background-Color to `cyan`
            let currentToilet = null;
            if(selectedNodeID == contextLoggedInUserID) {
                currentToilet  = document.getElementById(searchedOrClickedToiletNodeID)
            } else {
                currentToilet  = document.getElementById(selectedNodeID)
            }
            if(currentToilet) {
                let subtitleElement = currentToilet.querySelector('.ant-menu-submenu-title')
                if(subtitleElement) {
                    subtitleElement.style.background = '#def1ff'
                }
            }
        }
    }, [selectedNodeID, selectedNodeTitle, treeDefinitionFlag, searchedOrClickedToiletNodeID])


    useEffect(() => {

        if((state.objPrivilege != null && state.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && state.objPrivilege[PVG_GENERAL_USER] == true) || state.isPageRefreshed) {
            updateStateDataBasedOnActiveUser()
        }

        if(state.deviceInfoToPopulateTree) {
            // Timeout for the first time to fetch Device Data in Device Tree
            const timeoutId = setTimeout(() => {
                getInfoOfVisibleDevicesInTree();
            }, getDeviceActiveStatusTimeOut());

            // Cleanup
            return () => {
                clearTimeout(timeoutId);
            };
        }

    }, [state.objPrivilege, state.isPageRefreshed, state.deviceInfoToPopulateTree])


    useEffect(() => {

        // Called to Render the First Available Entity (Device OR Toilet-Node) on Initial Mount of Device Tree
        if((selectedNodeID == contextLoggedInUserID) || (selectedNodeID != null && selectedNodeID.length <= 0)) {
            if((state.independentDevices != null && state.independentDevices.length > 0) ||
                (state.toiletNodeInfo != null && state.toiletNodeInfo.length > 0)
            ) {
                let firstToiletWithDevices = state.toiletNodeInfo?.find(toilet => toilet?.hasDevices === true)
                const firstIndependentDeviceOrNode = firstToiletWithDevices || state.independentDevices[0];

                let toiletNodeIdArr = []
                try {
                    toiletNodeIdArr = state.toiletNodeInfo.filter(toilet => toilet.hasDevices === true).map(hasDevcsToilet => hasDevcsToilet.id)
                    toiletNodeIdArr.forEach(id => {
                        dispatch(addToOpenToiletNodes(id));
                    })
                } catch {
                    console.log('Problem in opening all toilet nodes by default');
                }

                
                if(firstIndependentDeviceOrNode != undefined) {
                    dispatch(setSearchedOrClickedToiletNode(firstIndependentDeviceOrNode?.id))
                    if(firstIndependentDeviceOrNode.hasOwnProperty("hasDevices")) {
                        context.onSelectedDevice(firstIndependentDeviceOrNode?.id, firstIndependentDeviceOrNode?.title, false, true, false, firstIndependentDeviceOrNode?.deviceType, firstIndependentDeviceOrNode?.ParentNode, firstIndependentDeviceOrNode?.SelectedNodeDeviceType, firstIndependentDeviceOrNode?.path)
                        context.onSelectedNodeContainsChildNode(true)
                    } else {
                        context.onSelectedDevice(firstIndependentDeviceOrNode?.DeviceID, firstIndependentDeviceOrNode?.DeviceName, false, false, true, context.selectedNodeInfo.deviceType, firstIndependentDeviceOrNode?.parentID, firstIndependentDeviceOrNode?.SelectedNodeDeviceType, firstIndependentDeviceOrNode?.nodePath)
                    }
                } 
            }
        } 
        
        // Called to set Toilet-Associated-Device's Path when Re-Directed from All Device Dashboard React Table
        if(selectedNodeID) {
            state.toiletDevices.map((device) => {
                if(device.DeviceID == selectedNodeID) {
                    context.onSelectedDevice(device.DeviceID, device.DeviceName, false, false, true, context.selectedNodeInfo.deviceType, device.parentID, device.SelectedNodeDeviceType, device.nodePath)
                }
            })
        }

        // Called to Make all the Toilet-Nodes Collapsed Active (i.e, all toilet nodes will be in opened state in tree) when Device Tree is Mounted Initially 
        // And Set the Toilet ID of the first Toilet in the Device Tree for it to be treated with the Selection Color 
        if((fetchReduxToiletNode != null && fetchReduxToiletNode.length > 0) && 
            (openToiletNodesID != null && openToiletNodesID.length <= 0) &&
            (searchedOrClickedToiletNodeID != null && searchedOrClickedToiletNodeID.length <= 0)) {
                let toiletNodeIdArr = []
                try {
                    toiletNodeIdArr = state.toiletNodeInfo.filter(toilet => toilet.hasDevices === true).map(hasDevcsToilet => hasDevcsToilet.id)
                    toiletNodeIdArr.forEach(id => {
                        dispatch(addToOpenToiletNodes(id));
                    })
                } catch {
                    console.log('Problem in opening all toilet nodes by default');
                }
                
                if(toiletNodeIdArr[0] != undefined && context.selectedNodeInfo.isDevc == false) {
                    dispatch(setSearchedOrClickedToiletNode(toiletNodeIdArr[0]))
                } 
        }
        
    }, [state.independentDevices, state.toiletNodeInfo, state.toiletDevices]);

    
    useEffect(() => {
        getInfoOfVisibleDevicesInTree();
    }, [quickTrackParam, currentViewingLanguage])

    
    const handleScrollUponTree = () => {
        // if there is already a timeout in process cancel it
        if (state.timeoutForScrollEnd) { 
            clearTimeout(state.timeoutForScrollEnd);
        }

        // Setting a New Timeout & Fetching Data of Visible Viewport Devices at Scroll-End
        const newTimeout = setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                timeoutForScrollEnd: null,
                scrollStatus: 'ScrollStopped'
            }))

            getInfoOfVisibleDevicesInTree();
        }, 2000);

        setState(prevState => ({
            ...prevState,
            timeoutForScrollEnd: newTimeout
        }))

        if (state.scrollStatus != 'Scrolling') {
            setState(prevState => ({
                ...prevState,
                scrollStatus: 'Scrolling'
            }))
        }
    };

    const checkUserPrivilegesAndCreateTreeNodes = () => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  

        const encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
        const LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        const selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        const languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;
        const quickTrackParam = appRelevantDataContextValue.quickTrackParamInfo.quickTrackParam;

        axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
            .then(response => {
                if(response.data.code == 'SUCCESS') {
                    if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                        let errors = `Unable to get encryption key.`;
                        setState(prevState => ({
                            ...prevState,
                            errors: errors
                        }))

                    } else {
                        const PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

                        let bytes = aes.decrypt(encryptedPrivileges.toString(), PrivilegeEncKey);

                        let strPrivilege = bytes.toString(enc);

                        try {
                            const objPrivilege = JSON.parse(strPrivilege);

                            setState(prevState => ({
                                ...prevState,
                                objPrivilege: objPrivilege,
                                encryptedPrivileges: encryptedPrivileges,
                                LoggedInUserID: LoggedInUserID,
                                selectedTreeNodeID: selectedTreeNodeID,
                                languageToViewIn: languageToViewIn,
                                quickTrackParam: quickTrackParam
                            }))

                            getAirQualityRangesAndModelInfo(objPrivilege);

                        } catch(e) {
                            console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                        }
                    }
                } else {
                    let errors;
                    if(response.data.code == 'SQL_ERROR') {
                        errors = t(IDS_LoginServerIssue);
                    } else {
                        console.log("Should not reach here");
                        errors = t(IDS_LoginServerIssue);
                    }
                    setState(prevState => ({
                        ...prevState,
                        errors: errors
                    }));
                }
            })
            .catch(err => {
                console.log("Network error");
                console.log(err);

                let errors;
                if (axios.isCancel(err)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    errors = t(IDS_RegistNetworkError);
                }
                setState(prevState => ({
                    ...prevState,
                    errors: errors
                }));
            }) 
    }

    const getAirQualityRangesAndModelInfo = (objPrivilege) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        if(objPrivilege != null && objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && objPrivilege[PVG_GENERAL_USER] != true) {
            axios.post(`${getAPIHostURL()}/wclient/getAirQualityRangeAndModelInfo`)
                .then(response => {
                    if(response.data.code = "SUCCESS") {
                        if(response.data["AirQualityRanges"] == null || response.data["AirQualityRanges"].length <=0 ||
                            response.data["DeviceModelInfo"] == null || response.data["DeviceModelInfo"].length <=0
                        ) {
                            let errors = t(IDS_LoginServerIssue);
                            setState(prevState => ({
                                ...prevState,
                                errors: errors
                            }))
                        } else {
                            const arrAirQualityRangesResponse = response.data["AirQualityRanges"];
                            const arrDeviceModelInfo = response.data["DeviceModelInfo"];

                            setState(prevState => ({
                                ...prevState,
                                arrAirQualityRanges: arrAirQualityRangesResponse,
                                arrDeviceModelInfo: arrDeviceModelInfo,
                                isPageRefreshed: true
                            }))
                        }
                    } else {
                        let errors;
                        if (response.data.code == 'SQL_ERROR') {
                            errors = t(IDS_LoginServerIssue);   // Tell the user that Server is experiencing errors
                        } else {
                            console.log('Should not reach here');
                            errors = t(IDS_LoginServerIssue);
                        }
                        setState(prevState => ({
                            ...prevState,
                            errors: errors
                        }))
                    }
                })
                .catch( error => {
                    let errors;
                    console.log(error);
                    if (axios.isCancel(error)) {
                        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                    } else {
                        errors = t(IDS_RegistNetworkError); // Tell the user that there are network issues
                    }
                    setState(prevState => ({
                        ...prevState,
                        errors: errors
                    }))
                }); 
        }
    }

    const renderUserAssociatedWithNoDevices = () => {

        let noDeviceRegisteredStructure = {};
        let noDeviceRegisteredArr = []
            
        noDeviceRegisteredStructure = {
            id: NO_DEVC_FOUND_FOR_LOGGED_IN_USER, 
            title: "No Devices Registered", 
            SelectedNodeDeviceType: "",
            deviceType: [],
            isRoot: false,
            hasDevc: false,
            isDevc: true,
            path: "",
            parentID: null,
        };

        noDeviceRegisteredArr.push(noDeviceRegisteredStructure)

        context.onSelectedDevice(NO_DEVC_FOUND_FOR_LOGGED_IN_USER, "No Devices Registered", false, false, true, [], null, "", "");

        setState(prevState => ({
            ...prevState,
            noDeviceRegistered: noDeviceRegisteredArr 
        }))
    }

    const updateStateDataBasedOnActiveUser = (inModifiedState = null, bHasNodeChanged=false) => {

        // Incase a General user (a user without Devies) Logged in he/she will see a Node with DefaultDeviceID ['NO_DEVC_FOUND_FOR_LOGGED_IN_USER']
        // In this case no need to bring a data from server as DeviceID is not valid and we have to show him a HTML page with message.
        if(state.objPrivilege != null && state.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) &&  state.objPrivilege[PVG_GENERAL_USER] == true) {
            renderUserAssociatedWithNoDevices()

            return; // No further processing required
        }

        let inAppRelevantDataContextValue = context;
        let t = inAppRelevantDataContextValue.t;

        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;
        let selectedTreeNodeParentID = inAppRelevantDataContextValue.selectedNodeInfo.parentID;
        let selectedTreeNodeTitle = inAppRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        let isRootSelected = inAppRelevantDataContextValue.selectedNodeInfo.isRoot; 
        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
        let userFirstName = inAppRelevantDataContextValue.loggedInUserInfo.userFirstName;
        let userLastName = inAppRelevantDataContextValue.loggedInUserInfo.userLastName;
        let appNotifyOnSelectedDevice = inAppRelevantDataContextValue.onSelectedDevice;
        let appNotifyOnSelectedRoot = inAppRelevantDataContextValue.onSelectedRoot;
        let languageToViewIn = inAppRelevantDataContextValue.language.languageToViewIn;
        let trackedDeviceInfoArr = inAppRelevantDataContextValue.devicesToTrack.DevicesInfo;
        let ownerOfTrackedDevices =  inAppRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
        let hasAssociatedTreeNodeIds =  inAppRelevantDataContextValue.devicesToTrack.hasAssociatedTreeNodeIds;
        let userIsLoggedInAndRenderedTreeForFirstTime = inAppRelevantDataContextValue.loggedInUserInfo.userIsLoggedInAndRenderedTree;

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.selectedTreeNodeID = selectedTreeNodeID;
        modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
        modifiedState.isRootSelected = isRootSelected;
        modifiedState.LoggedInUserID = loggedInUserID;
        modifiedState.UserFirstName = userFirstName;
        modifiedState.UserLastName = userLastName;
        modifiedState.appNotifyOnSelectedDevice = appNotifyOnSelectedDevice;
        modifiedState.appNotifyOnSelectedRoot = appNotifyOnSelectedRoot;
        modifiedState.languageToViewIn = languageToViewIn;
        modifiedState.errors = t(IDS_DTFetchingDevcInfo); // Indicate that devices data is being retrieved
        modifiedState.deviceTree = []; // Set the device tree to empty initially
        modifiedState.arrDeviceListForSearchBox = [];
        modifiedState.retreivedTreeInfo = [];

        let strLoggedInOrOwnerUserIDToBringDevcData;

        if(ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else if(hasAssociatedTreeNodeIds) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        }
        else {
            strLoggedInOrOwnerUserIDToBringDevcData = modifiedState.LoggedInUserID;
        }

        const jsonParams = {
            LoggedInUserID : strLoggedInOrOwnerUserIDToBringDevcData,
            ClientType : CLIENT_TYPE
        }

        axios.post(`${getAPIHostURL()}/wclient/getLoggedInUserDeviceDetails`, jsonParams)
        .then(response => {
            if(response.data.code = "SUCCESS") {
                let receivedDevicesInfo = response.data.retreivedDevicesInfo;
                modifiedState.retreivedTreeInfo = response.data.retreivedTreeInfo; 
                modifiedState.isUsrAssignedOnNodeWhichHasDevcs = response.data.isUsrAssignedOnNodeWhichHasDevcs;

                let deviceInfoToPopulateTree;

                // For User Having Privilege Excluding GENERAL_USER && Associated with No Devices
                if(receivedDevicesInfo != null && receivedDevicesInfo.length <= 0 &&
                    modifiedState.retreivedTreeInfo != null && modifiedState.retreivedTreeInfo.length <= 0 &&
                    trackedDeviceInfoArr != null && trackedDeviceInfoArr.length <= 0) {
                        renderUserAssociatedWithNoDevices();
                        
                        return; // No further processing required
                }

                // If user wishes to Track Devices of DeviceOwner, then he/she will choose to "Track Devices" and "View Data",
                // on clicking "View Data", Devices page will get open in new tab of browser and
                // AppContext will get updated for values of "devicesToTrack" and "selectedNodeInfo".
                // If AppContext's "devicesToTrack.DevicesInfo" value has got updated then we populate Device Tree 
                // according to Device ID and Device Name which has got updated in AppContext for respective DeviceOwner,
                // else Device Tree will get populated according to devices visible for loggedin User.
                if((trackedDeviceInfoArr != null && trackedDeviceInfoArr.length >= 1) && (ownerOfTrackedDevices != null && 
                    (ownerOfTrackedDevices.length <= 0 || ownerOfTrackedDevices == PROD_DEVC_OWNER_USER_ID))) {
                        deviceInfoToPopulateTree = trackedDeviceInfoArr;
                } else {
                    deviceInfoToPopulateTree = receivedDevicesInfo;
                }

                let toiletDevices = [];
                let independentDevices = [];
                let trackDeviceArr = [];
                let toiletNodeInfo = [];
                let arrModelIDOfRecevicedDevices = [];

                let deviceStructure = {};

                modifiedState.retreivedTreeInfo.map((node) => {
                    receivedDevicesInfo.filter((device) => device.TreeParentID == node.id).map((device) => {
                        deviceStructure = {
                            title: device.DeviceName, // Pass each device name to the child node 'title'
                            id: device.DeviceID, // Device ID is unique in the backend
                            key: device.DeviceID, 
                            isDropDownVisible: false,
                            isRoot: false,
                            isSelected: device.DeviceID == selectedTreeNodeID ? true : false,
                            modelID: device.ModelID,
                            isDevice: true,
                            expanded: false,
                            showOnlineStatus: true,
                            SelectedNodeDeviceType: device.SelectedNodeDeviceType,
                            parentID: device?.TreeParentID ?? null,
                            nodePath: node.path,
                        };

                        toiletDevices.push({...device, ...deviceStructure})
                    });
                })

                receivedDevicesInfo.filter((device) => device.TreeParentID === null).map((device) => {
                    deviceStructure = {
                        title: device.DeviceName,
                        id: device.DeviceID,
                        key: device.DeviceID,
                        isDropDownVisible: false,
                        isRoot: false,
                        isSelected: device.DeviceID === selectedTreeNodeID,
                        modelID: device.ModelID,
                        isDevice: true,
                        expanded: false,
                        showOnlineStatus: true,
                        SelectedNodeDeviceType: device.SelectedNodeDeviceType,
                        parentID: device?.TreeParentID ?? null
                    };

                    independentDevices.push({ ...device, ...deviceStructure });
                })

                if(context.devicesToTrack.DevicesInfo != null && context.devicesToTrack.DevicesInfo.length > 0) {
                    trackedDeviceInfoArr.map((device) => {
                        deviceStructure = {
                            title: device.DeviceName,
                            id: device.DeviceID,
                            key: device.DeviceID,
                            isDropDownVisible: false,
                            isRoot: false,
                            isSelected: device.DeviceID === selectedTreeNodeID,
                            modelID: device.ModelID,
                            isDevice: true,
                            expanded: false,
                            showOnlineStatus: true,
                            SelectedNodeDeviceType: device.SelectedNodeDeviceType,
                            parentID: device?.TreeParentID ?? null,
                        };

                        trackDeviceArr.push({ ...device, ...deviceStructure });
                    })
                }

                modifiedState.retreivedTreeInfo.map((singleTreeNodeInfo) => {
                    let objHierarchicalEachNodeInfo = {};
                    let objNodeDisplayData = {};
                    let strDisplayName = "";
                    let bHasDevices = false;
                    let arrDeviceType = [];
                    let SelectedNodeDeviceType = "";
                    
                    try {
                        objNodeDisplayData = JSON.parse(singleTreeNodeInfo["objNodeInfo"]);
                        strDisplayName = objNodeDisplayData.hasOwnProperty("DisplayName") ? objNodeDisplayData["DisplayName"] : "";
                        bHasDevices = objNodeDisplayData.hasOwnProperty("HasDevices") ? objNodeDisplayData["HasDevices"] : false;
                        arrDeviceType = objNodeDisplayData.hasOwnProperty("DeviceType") ? objNodeDisplayData["DeviceType"] : [];
                        SelectedNodeDeviceType = objNodeDisplayData.hasOwnProperty("SelectedNodeDeviceType") ? objNodeDisplayData["SelectedNodeDeviceType"] : "";

                    } catch(e) {
                        console.log(e);
                        console.log("Invalid Json. Error while parsing Node display Data.");
                        objNodeDisplayData = {};
                        strDisplayName = "";
                        bHasDevices = false;
                        arrDeviceType = [];
                        SelectedNodeDeviceType = "";
                    }

                    let singleObj = {
                        title: strDisplayName,
                        key: singleTreeNodeInfo.id, // Just for the heck of it
                        isRoot: false,
                        isSelected: singleTreeNodeInfo.id == selectedTreeNodeID ? true : false,
                        expanded: false,
                        hasDevices: bHasDevices,
                        showOnlineStatus: false,
                        deviceType: arrDeviceType,
                        isDevice: false,
                        SelectedNodeDeviceType: SelectedNodeDeviceType,
                    }

                    objHierarchicalEachNodeInfo = {...singleTreeNodeInfo, ...singleObj};

                    toiletNodeInfo.push(objHierarchicalEachNodeInfo)
                })

                for(let i=0; i<deviceInfoToPopulateTree.length; i++) {
                    if(!arrModelIDOfRecevicedDevices.includes(deviceInfoToPopulateTree[i]["ModelID"])) {
                        arrModelIDOfRecevicedDevices.push(deviceInfoToPopulateTree[i]["ModelID"]);
                    }
                }

                // Retrieve all MeasuredParam based on ModelID of the Devices.
                let arrQuickTrackParams = getMeasuredParamForReceivedDevc(arrModelIDOfRecevicedDevices);

                // Note: this will need only at First render where quickTrackParam might be empty.
                // If Quickparam list does not contains NH3OD then make the first param as default track param.
                if(quickTrackParam == null || quickTrackParam.length <=0) {
                    if(arrQuickTrackParams != null && arrQuickTrackParams.length > 0 &&
                        !arrQuickTrackParams.includes(NH3OD)
                    ) {
                        if(arrQuickTrackParams.includes(VRI)){
                            inAppRelevantDataContextValue.onChangeQuickTrackParam(VRI);
                        } else if(arrQuickTrackParams.includes(PM1)){
                            inAppRelevantDataContextValue.onChangeQuickTrackParam(PM1);
                        } else if(arrQuickTrackParams.includes(PM10)){
                            inAppRelevantDataContextValue.onChangeQuickTrackParam(PM10);
                        } else {
                            inAppRelevantDataContextValue.onChangeQuickTrackParam(arrQuickTrackParams[0]);
                        }
                    } else {
                        if(arrQuickTrackParams.includes(NH3OD)) {
                            inAppRelevantDataContextValue.onChangeQuickTrackParam(NH3OD);
                        }                    
                    }
                }
                
                let receivedEmailID = response.data.retreivedEmailID;
                let noOfDevices = deviceInfoToPopulateTree.length;
                let arrChildDevices = []; // Declare child array
                let oSingleDeviceStructure = {}; // Create JSON obj for child array
                modifiedState.deviceTree = [];
                let arrHierarchicalNodeInfo = [];
                let arrNodeHavingDevcAsChildren = [];

                for(let i=0; i<modifiedState.retreivedTreeInfo.length; i++) {
                    let singleTreeNodeInfo = modifiedState.retreivedTreeInfo[i];
                    let objHierarchicalEachNodeInfo = {};
                    let objNodeDisplayData = {};
                    let strDisplayName = "";
                    let bHasDevices = false;
                    let arrDeviceType = [];
                    let SelectedNodeDeviceType = "";
                    
                    try {
                        objNodeDisplayData = JSON.parse(singleTreeNodeInfo["objNodeInfo"]);
                        strDisplayName = objNodeDisplayData.hasOwnProperty("DisplayName") ? objNodeDisplayData["DisplayName"] : "";
                        bHasDevices = objNodeDisplayData.hasOwnProperty("HasDevices") ? objNodeDisplayData["HasDevices"] : false;
                        arrDeviceType = objNodeDisplayData.hasOwnProperty("DeviceType") ? objNodeDisplayData["DeviceType"] : [];
                        SelectedNodeDeviceType = objNodeDisplayData.hasOwnProperty("SelectedNodeDeviceType") ? objNodeDisplayData["SelectedNodeDeviceType"] : "";

                    } catch(e) {
                        console.log(e);
                        console.log("Invalid Json. Error while parsing Node display Data.");
                        objNodeDisplayData = {};
                        strDisplayName = "";
                        bHasDevices = false;
                        arrDeviceType = [];
                        SelectedNodeDeviceType = "";
                    }

                    let singleObj = {
                        title: strDisplayName,
                        key: singleTreeNodeInfo.id, // Just for the heck of it
                        isRoot: false,
                        isSelected: singleTreeNodeInfo.id == selectedTreeNodeID ? true : false,
                        expanded: false,
                        hasDevices: bHasDevices,
                        showOnlineStatus: false,
                        deviceType: arrDeviceType,
                        isDevice: false,
                        SelectedNodeDeviceType: SelectedNodeDeviceType
                    }

                    objHierarchicalEachNodeInfo = {...singleTreeNodeInfo, ...singleObj};

                    // Fill the array named as [arrNodeHavingDevcAsChildren] in order to bring the Devices for particular ParentID.
                    // Condition 1:
                        // If there is only one assigned node for the LoggedInUser and same node has Devices and the DeviceTree is not rendered yet
                        // then we are bydefault selecting and expanding the assigned node.
                    // Condition 2:
                        // If the selected node has Devices and somehow page refresh is happens.
                        // then we are expanding and bringing the devices for the SelectedNodeId.
                    // Condition 3:
                        // If the selected node itself a Device from the Hierarchical tree structure.
                        // then we are explicitely brings all the devices for the parentNode of the selected device.
                    if((modifiedState.isUsrAssignedOnNodeWhichHasDevcs && bHasDevices && !userIsLoggedInAndRenderedTreeForFirstTime) || 
                        (singleTreeNodeInfo.id == selectedTreeNodeID && bHasDevices) ||
                        (selectedTreeNodeParentID != null && singleTreeNodeInfo.id == selectedTreeNodeParentID && bHasDevices && modifiedState.isPageRefreshed)
                    ) {
                        arrNodeHavingDevcAsChildren.push(objHierarchicalEachNodeInfo);
                    }

                    arrHierarchicalNodeInfo.push(objHierarchicalEachNodeInfo);
                }

                let arrNestedHierarchicalNodes = [];

                // Create a Hirerarchical tree structure from the array of tree node object.
                // Assigning Toilet Nodes to the Tree
                if(arrHierarchicalNodeInfo != null && arrHierarchicalNodeInfo.length > 0) {
                    // Creates Toilet Nodes essestial for Device Tree after User Login, else Renders a Single Device under "Tracked Device" as Root Node when 
                    // Redirected from "Search By Device ID" in Tracked Devices 
                    if((ownerOfTrackedDevices == strLoggedInOrOwnerUserIDToBringDevcData && hasAssociatedTreeNodeIds == true) || 
                        ((trackedDeviceInfoArr == null || trackedDeviceInfoArr.length <= 0) && ownerOfTrackedDevices == "" && hasAssociatedTreeNodeIds == false)) {
                            arrNestedHierarchicalNodes = getNestedChildren(arrHierarchicalNodeInfo, null);
                    }
                }

                if(arrNestedHierarchicalNodes != null && arrNestedHierarchicalNodes.length > 0) {
                    for(let i=0; i<arrNestedHierarchicalNodes.length; i++) {
                        arrChildDevices.push(arrNestedHierarchicalNodes[i]);
                        modifiedState.arrDeviceListForSearchBox.push({key: arrNestedHierarchicalNodes[i].key, value: arrNestedHierarchicalNodes[i].title});
                    }
                }

                for(let i=0; i < noOfDevices; i++) {
                    const singleValue =  deviceInfoToPopulateTree[i];

                    modifiedState.arrDeviceListForSearchBox.push({key: singleValue.DeviceID, value: singleValue.DeviceName});

                    oSingleDeviceStructure = {
                        title: singleValue.DeviceName, // Pass each device name to the child node 'title'
                        id: singleValue.DeviceID, // Device ID is unique in the backend
                        key: singleValue.DeviceID, // Just for the heck of it
                        isDropDownVisible: false,
                        isRoot: false,
                        isSelected: singleValue.DeviceID == selectedTreeNodeID ? true : false,
                        modelID: singleValue.ModelID,
                        isDevice: true,
                        expanded: false,
                        showOnlineStatus: true,
                        SelectedNodeDeviceType: singleValue.SelectedNodeDeviceType,
                        parentID: singleValue?.TreeParentID != null ? singleValue?.TreeParentID : null,
                    }; 

                    if(oSingleDeviceStructure.parentID === null) {
                        arrChildDevices.push(oSingleDeviceStructure); // Pushing all Independent Devices to the Tree     
                    }

                    if(selectedTreeNodeID === singleValue.DeviceID && selectedTreeNodeTitle !=  singleValue.DeviceName) {
                        modifiedState.appNotifyOnSelectedDevice(selectedTreeNodeID, singleValue.DeviceName, false); 
                    }
                }
                
                // Tracked Device Structure
                let singleOwnerDeviceDetails = {
                    // title: receivedEmailID, // Root node 'title'
                    // Root node 'title'. May change in future (Currently RootNodeTitle is same as LoggedInUserName).
                    // Only for Tracked Devices Feature root node 'title' will be "Tracked Devices" otherwise currently RootNodeTitle is same as LoggedInUserName.
                    title: ((trackedDeviceInfoArr != null && trackedDeviceInfoArr.length > 0) || (ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0)) ? "Tracked Devices" : 
                    (userLastName == null || userLastName.length <= 0 ) ? `${userFirstName}` :
                    `${userFirstName} ${userLastName}`,  
                    // To identify root node. May change in future (Currently RootNodeID is same as LoggedInUserID).
                    id: ((trackedDeviceInfoArr != null && trackedDeviceInfoArr.length > 0) || (ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0)) ? "trackedDevicesID" : receivedEmailID, 
                    expanded: true,
                    isRoot: true,
                    isSelected: (receivedEmailID == selectedTreeNodeID || selectedTreeNodeID == "trackedDevicesID") ? true : false,
                    children: arrChildDevices,
                    showOnlineStatus: false,
                    isDevice: false,
                }

                modifiedState.deviceTree = []; // Set the device tree to empty initially

                // Fill the information required for constructing the tree                
                modifiedState.deviceTree.push(singleOwnerDeviceDetails);
                modifiedState.arrDeviceListForSearchBox.push({key: singleOwnerDeviceDetails.key, value: singleOwnerDeviceDetails.title});
                modifiedState.errors = ''; // Success
                modifiedState.isPageRefreshed = false;

                setState(prevState => ({
                    ...prevState,
                    deviceInfoToPopulateTree: deviceInfoToPopulateTree,
                    toiletNodeInfo: toiletNodeInfo,
                    toiletDevices: toiletDevices,
                    independentDevices: independentDevices,
                    arrQuickTrackParams: arrQuickTrackParams,
                    trackDeviceArr: trackDeviceArr
                }))

                const toiletNodes = [];
                toiletNodeInfo?.map((toiletNode) => {
                    toiletNode.hasDevices && toiletNodes.push(toiletNode)
                })

                // Appending Tree Toilet-Nodes into the Redux-Store Slice's State Array Variable
                if(fetchReduxDeviceList != null && fetchReduxDeviceList?.length <= 0) {
                    if(toiletNodes != null && toiletNodes?.length > 0) {
                        dispatch(appendTreeNodes(toiletNodes))
                    }
                }

                // Appending Devices into the Redux-Store Slice's State Array Variable
                if(fetchReduxToiletNode != null && fetchReduxToiletNode?.length <= 0) {
                    if(deviceInfoToPopulateTree != null && deviceInfoToPopulateTree?.length > 0) {
                        dispatch(appendDevices(deviceInfoToPopulateTree))
                    }
                }

                // Appending Tree Toilet-Nodes && Devices into the Redux-Store Slice's State Array Variables when Performed Operations from User's Tree Defintion Feature
                if(urlPathName == "/device/addNode") {
                    dispatch(clearDeviceList())
                    dispatch(appendDevices(deviceInfoToPopulateTree))   
                    dispatch(clearTreeNodeList())
                    dispatch(appendTreeNodes(toiletNodes))
                }

                // When New Toilet Node or Device is Added, Edited, Removed from & into Device Tree from User's Tree Definition -->
                if(toiletNodeInfo?.length != fetchReduxToiletNode[0]?.length) {
                    dispatch(clearTreeNodeList())
                    dispatch(appendTreeNodes(toiletNodes))
                }
                if(deviceInfoToPopulateTree?.length != fetchReduxDeviceList[0]?.length) {
                    dispatch(clearDeviceList())
                    dispatch(appendDevices(deviceInfoToPopulateTree))
                }
                // <--

            } else {
                modifiedState.isPageRefreshed = false;

                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors = t(IDS_AUSrvrIssueReqParamsNotSent);  // Let the user know that the Required parameters were not sent to the Server
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors = t(IDS_LoginServerIssue); // Tell the user that Server is experiencing errors
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = t(IDS_LoginServerIssue);
                }
            }
        }) 
        .catch( error => {
            let errors;
            console.log(error); // Tell the user that there are network issues
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                errors = t(IDS_RegistNetworkError);
            }
            setState(prevState => ({
                ...prevState,
                errors: errors
            }))
        }); 
    }

    const getMeasuredParamForReceivedDevc = (arrModelIDOfRecevicedDevices) => {

        let measuredParamsForReceivedDevcs = [];
        for(let i=0; i<arrModelIDOfRecevicedDevices.length; i++) {

            let filteredModelInfoBasedOnModelID = state.arrDeviceModelInfo.filter((singleModelInfo) => singleModelInfo["ModelID"] == arrModelIDOfRecevicedDevices[i]);

            let strfilteredMeasuredParamBasedOnModelID = filteredModelInfoBasedOnModelID != null && filteredModelInfoBasedOnModelID.length > 0 ? filteredModelInfoBasedOnModelID[0]["MeasuredParams"] : null;

            let objfilteredMeasuredParamBasedOnModelID = {};

            try {
                objfilteredMeasuredParamBasedOnModelID = JSON.parse(strfilteredMeasuredParamBasedOnModelID);
                let arrMeasuredParamSeq = objfilteredMeasuredParamBasedOnModelID["Seq"];
                let arrMeasuredParamSeqWithoutAlertBased = [];

                for(let j = 0; j < arrMeasuredParamSeq.length; j++) {
                
                    let singleMeasuredParam = arrMeasuredParamSeq[j];

                    let bIsParamAlertBased = objfilteredMeasuredParamBasedOnModelID[singleMeasuredParam][ALERT_BASED] != null ? objfilteredMeasuredParamBasedOnModelID[singleMeasuredParam][ALERT_BASED] : false;

                    if(!bIsParamAlertBased) { // Filter out AlertBasedParam.
                        arrMeasuredParamSeqWithoutAlertBased.push(singleMeasuredParam);
                    }
                }

                measuredParamsForReceivedDevcs = [...new Set([...measuredParamsForReceivedDevcs,...arrMeasuredParamSeqWithoutAlertBased])];

                let RemoveParamsFromArr = [DEVICE_TYPE_PFC, LOC, TTS, LAS, SOA, WLIP, AWL, VOL, AWC, SLIP, TCWL];
                // let RemoveParamsFromArr = [DEVICE_TYPE_PFC, LOC, TTS, LAS, SO2, WLI, AWC, VOC, AWC, SLIP];
                measuredParamsForReceivedDevcs = measuredParamsForReceivedDevcs.filter(item => !RemoveParamsFromArr.includes(item));

            } catch(e) {
                console.log("Should not happen. The Device model info obtained from the server is in invalid JSON format.");
            }
        }
        return measuredParamsForReceivedDevcs;
    }

    const addAllChildToarrDeviceListForSearchBox = (childrens) =>{

        for(let i in childrens) {
            addAllChildToarrDeviceListForSearchBox(childrens[i].children);
        }
    }
    
    const getInfoOfVisibleDevicesInTree = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
    
        let treeNodeUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let quickTrackParam = appRelevantDataContextValue.quickTrackParamInfo.quickTrackParam;

        let childDeviceDivPos = []; 
        let VisibleTreeNodesIdArr = [];
        let activeStatusOfDeviceIdArr = [];

        let parrentTreeDivPos;
        let toiletDevicesDivElement;
        let parrentTreeViewDivPos;

        if(state.deviceInfoToPopulateTree != null && state.deviceInfoToPopulateTree.length > 0) {

            if(document.getElementById('treeView') == null) {
                return; // No need to process further.
            }

            // to get viewport boundary position of the parent tree div. 
            parrentTreeDivPos = document.getElementById('treeView')

            parrentTreeViewDivPos = parrentTreeDivPos.getBoundingClientRect();
            
            // to get div elements of all nodes in the tree including the root node (Note: ReactTree
            // is efficient, it creates only as many nodes as are required for displaying on the view - moreOrLess).
            toiletDevicesDivElement = document.querySelectorAll(".desktopViewLayout .renderDeviceName");

            // Compare the bounds of the node div element with the viewport bounds of the parent tree container
            // and get the list of NodeIds for the nodes which are visible (or partly visible) in the viewport.
            for(let i = 0; i< toiletDevicesDivElement.length; i++) {

                childDeviceDivPos[i] = toiletDevicesDivElement[i].getBoundingClientRect()
       
                if( childDeviceDivPos[i].bottom >= parrentTreeViewDivPos.top
                    && childDeviceDivPos[i].top <= parrentTreeViewDivPos.bottom ) {
    
                    VisibleTreeNodesIdArr.push(toiletDevicesDivElement[i].id);
                } else {
                    console.log("Tree node not in its parent div viewport bounds.")
                }
            }
        }
         else {
            // No Need to do anything as there is no Devices are avilable.
        }

        // Removing root node user id from array of visible tree node id (in case it is visible in viewport).
        let visibleNodeDeviceIdArr = VisibleTreeNodesIdArr.filter(removeTreeNodeUserID => removeTreeNodeUserID !== treeNodeUserID)

        // This is the case where loggedInUser is 'GeneralUer' or user have not devices in current timestamp.
        // so we are creating a node with id 'NO_DEVC_FOUND_FOR_LOGGED_IN_USER' which is a invalid deviceid 
        // hence their is no need to check a active status of this tree node.
        let arrAddtitonalTrackParam = [];

        // This is just to get ridoff the gray coloured band.
        // DeviceTree contains mix type of models some model does not contain Odour or else Dust.
        // So it shows gray band for those Devices.
        // so here we are prioritizing MeasuredParams and shows range and its bannd color based on Priority of the MeasuredParam.
        // Bydefault we are bringing value for selected quick track param, NH3OD, PM1, PM10, VRI (depending upon which is available at server).
        if(quickTrackParam == NH3OD) {
            arrAddtitonalTrackParam.push(PM1, PM10, VRI);
        } else if(quickTrackParam == VRI) {
            arrAddtitonalTrackParam.push(NH3OD, PM10, PM1);
        } else if(quickTrackParam == PM1) {
            arrAddtitonalTrackParam.push(NH3OD, PM10, VRI);
        } else if(quickTrackParam == PM10) {
            arrAddtitonalTrackParam.push(NH3OD, PM1, VRI);
        } else {
            arrAddtitonalTrackParam.push(NH3OD, PM10, VRI, PM1);
        }

        let jsonBody = {
            DeviceIDArr: visibleNodeDeviceIdArr,
            SelectedTrackParam: quickTrackParam,
            ArrAddtitonalTrackParam: arrAddtitonalTrackParam,
            LoggedInUserID: state.LoggedInUserID,
            ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME  // time in seconds - Minimum gap in log time 
                                                                 // after which device is considered inactive
        }
        
        if(visibleNodeDeviceIdArr != null && visibleNodeDeviceIdArr.length > 0) {
            axios.post(`${getAPIHostURL()}/wclient/getInfoOfVisibleDevicesInTree`, jsonBody)
                .then(response => {
                    if(response.data.code == 'SUCCESS') {
                        if(response.data.visibleDeviceActiveStatusArr == null || response.data.visibleDeviceActiveStatusArr.length <= 0) {
                            console.log("Should not reach here. Visible device active status missing.")
                        } else {
                            // Transfer active status information to the state (note: we have just
                            // assigned the array to the state)
                            activeStatusOfDeviceIdArr = response.data.visibleDeviceActiveStatusArr;
                            
                            for(let j=0; j<activeStatusOfDeviceIdArr.length; j++) {
                                let strParamNameToFindRangeAndBand = ""; 
                                let strParamValToFindRangeAndBand = null; 

                                // We will first try to find out range and band color for the selected Quick Track Param.
                                // If the value for the selected Quick Track Param is invalid, then we will check the value for NH3OD, VRI, PM1, PM10 respectively.
                                if(activeStatusOfDeviceIdArr[j]["DefaultTrackParamVal"] == null || activeStatusOfDeviceIdArr[j]["DefaultTrackParamVal"].length <=0) {
                                    if(arrAddtitonalTrackParam.includes(NH3OD) && activeStatusOfDeviceIdArr[j][NH3OD] != null && activeStatusOfDeviceIdArr[j][NH3OD].length > 0) {
                                        strParamNameToFindRangeAndBand = NH3OD; 
                                        strParamValToFindRangeAndBand = activeStatusOfDeviceIdArr[j][NH3OD];
                                    } else if(arrAddtitonalTrackParam.includes(VRI) && activeStatusOfDeviceIdArr[j][VRI] != null && activeStatusOfDeviceIdArr[j][VRI].length > 0) {
                                        strParamNameToFindRangeAndBand = VRI;
                                        strParamValToFindRangeAndBand = activeStatusOfDeviceIdArr[j][VRI];
                                    } else if(arrAddtitonalTrackParam.includes(PM1) && activeStatusOfDeviceIdArr[j][PM1] != null && activeStatusOfDeviceIdArr[j][PM1].length > 0) {
                                        strParamNameToFindRangeAndBand = PM1;
                                        strParamValToFindRangeAndBand = activeStatusOfDeviceIdArr[j][PM1];
                                    } else if(arrAddtitonalTrackParam.includes(PM10) && activeStatusOfDeviceIdArr[j][PM10] != null && activeStatusOfDeviceIdArr[j][PM10].length > 0) {
                                        } else if(arrAddtitonalTrackParam.includes(PM10) && activeStatusOfDeviceIdArr[j][PM10] != null && activeStatusOfDeviceIdArr[j][PM10].length > 0) {
                                        strParamNameToFindRangeAndBand = PM10;
                                        strParamValToFindRangeAndBand = activeStatusOfDeviceIdArr[j][PM10];
                                    } else {
                                        strParamNameToFindRangeAndBand = quickTrackParam;
                                        strParamValToFindRangeAndBand = activeStatusOfDeviceIdArr[j]["DefaultTrackParamVal"];
                                    }
                                } else {
                                    strParamNameToFindRangeAndBand = quickTrackParam;
                                    strParamValToFindRangeAndBand = activeStatusOfDeviceIdArr[j]["DefaultTrackParamVal"];
                                }

                                let objIncludeBgColorAndRange = getRangeNameAndStyleBasedOnValue(strParamValToFindRangeAndBand, strParamNameToFindRangeAndBand);

                                let singleVisibleDevcID = activeStatusOfDeviceIdArr[j]["DeviceID"];

                                // Get html element based on the unique id asscociated with the every div.
                                let htmlElementBasedOnVisibleDevcID = document.getElementById(singleVisibleDevcID);

                                // Based on the HTML element which is retrieved from unique id for the div,
                                // we are finding its topmost parent div and changes its borderLeft colour based on 
                                // Default Track Param and its Value.
                                let oneLevelAboveParentElement = htmlElementBasedOnVisibleDevcID != null ? htmlElementBasedOnVisibleDevcID.parentElement : null;

                                // Adding Band Color to Device Block
                                if(oneLevelAboveParentElement != null) {
                                    let bandColourToShow = objIncludeBgColorAndRange["RangeBgColor"];
                                    oneLevelAboveParentElement.style.borderLeft = `8px solid ${bandColourToShow}`;
                                    oneLevelAboveParentElement.style.borderTopLeftRadius = `5px`;
                                    oneLevelAboveParentElement.style.borderBottomLeftRadius = `5px`;
                                }

                                let displayParamNameToFindRangeAndBand = renderParamNameBasedOnType(strParamNameToFindRangeAndBand);
                                let strRangeName = objIncludeBgColorAndRange["RangeNameToShow"];
                                
                                // -------- INSERT DEVICE BLOCK PARAMERTER SUBTITLE ------------>
                                // Check if the subtitle elements already exist in the DOM
                                const existingSubTitles = document.querySelectorAll('.desktopViewLayout .deviceItem .deviceTitle #parameterSubTitle');

                                const existingDeviceItems = Array.from(existingSubTitles).map(subTitle => subTitle.closest('.deviceTitle'));
                                const singleMenuItem = document.querySelectorAll('.desktopViewLayout .deviceItem .deviceTitle');

                                // Case: Creating <span> Sub-Title Element in the DOM on First-Time Render 
                                singleMenuItem.forEach(item => {
                                    // Access the second child div element
                                    const secondChildDiv = item.children[1]; // Index 1 represents the second child

                                    let spanElement;
                                    let subTitleParameterClassName;

                                    // Fetches the className of <span> only if the <span> already exists in the DOM 
                                    // Check if the second child div exists and contains a span element
                                    if (secondChildDiv && secondChildDiv.querySelector('span')) {
                                        spanElement = secondChildDiv.querySelector('span'); // Get the span element

                                        // Access the class name of the span element
                                        subTitleParameterClassName = spanElement.className;
                                    }

                                    // ---- Check if the current item is already associated with a subtitle ----
                                    if((strRangeName != null && strRangeName.length > 0) || 
                                    (existingDeviceItems.includes(item) && strRangeName != null && strRangeName.length <= 0)) {

                                        // CASE 1: No <span> tag, so create one: 
                                        if (!existingDeviceItems.includes(item))  {
                                            const deviceItem = item.getAttribute('deviceitem');
                                            if (deviceItem === activeStatusOfDeviceIdArr[j].DeviceID) {

                                                // Creating a <div/> container
                                                const container = document.createElement('div');
                                                container.className = 'd-flex align-items-start';
                                                container.style.marginLeft = '17px';
                                                container.style.marginTop = '-25px';
                                    
                                                // Creating a span element for the subtitle
                                                const subTitle = document.createElement('span');
                                                subTitle.id = `parameterSubTitle`
                                                subTitle.className = `${displayParamNameToFindRangeAndBand}`
                                                subTitle.style.fontSize = '10px';
                                                subTitle.style.fontWeight = 'lighter';
                                                subTitle.style.marginTop = '0px';
                                    
                                                // Set inner HTML for the subtitle
                                                subTitle.innerHTML = `${displayParamNameToFindRangeAndBand}: ${strRangeName}`;
                                    
                                                // Append the span to the parent div
                                                container.appendChild(subTitle);
                                                item.appendChild(container);
                                            }
                                        }  
                                    }
                                });

                                // Case 2: To change the parameter-subtitle if <span> already exists by Switching Parameter from `Param At Glance` or Triggering `Refresh` Button:
                                if (strRangeName != null && strRangeName.length > 0) {
                                    singleMenuItem.forEach(item => {                           
                                        if (existingDeviceItems.includes(item)) {
                                            
                                            let deviceItemID = item.getAttribute('deviceItem')

                                            if(deviceItemID == activeStatusOfDeviceIdArr[j].DeviceID) {

                                                let secondChildDiv = item.children[1]; // Index 1 represents the second child
                                                let spanElement = secondChildDiv.querySelector('span');

                                                spanElement.innerHTML = `${displayParamNameToFindRangeAndBand}: ${strRangeName}`
                                                spanElement.className = `${displayParamNameToFindRangeAndBand}`;
                                            }
                                        }
                                    })
                                }
                                // <--------------------
                            }

                            setState(prevState => ({
                                ...prevState,
                                getInfoOfVisibleDevices: activeStatusOfDeviceIdArr
                            }));
                        }
                    } else {
                        // Note: Since this call happens at repeated interval, we are not showing an error
                        // on the UI. Also if there is an error on the LHS there will also be some error
                        // displayed on the UI on the right hand side. So we are just writing error to the console. 
                        if(response.data.code == 'REQ_PARAMS_MISSING') {
                            console.log("Request params missing while requesting visible device active status.")
                        } else if(response.data.code == 'SQL_ERROR') {
                            console.log("SQL Error while requesting visible device active status.")
                        } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUE') {
                            console.log("Should not reach here. Server experiencing issues while requesting visible device active status.")
                        } else {
                            console.log('Should not reach here. Unknown error while requesting visible device active status.');
                        }
                    }
                })
                .catch( error => {
                    console.log("Network error:");
                    console.log(error); // Just write the error to the console, as explained above.
                    if (axios.isCancel(error)) {
                        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                    }

                })
        }
    }

    const getRangeNameAndStyleBasedOnValue = (inParamValue, inParamNameType) => {

        let rangeToDisplay = "";   // If need to show Current Range in future.

        let filteredParamAirQualityRange = state.arrAirQualityRanges.filter((singleAirQualityInfo) => singleAirQualityInfo.MeasuredParam == inParamNameType);
        let strSingleParamAirQualityRange = filteredParamAirQualityRange != null && filteredParamAirQualityRange.length > 0 ? filteredParamAirQualityRange[0]["RangeValues"] : null;
        let singleParamAirQualityRange = {};

        try {
            singleParamAirQualityRange = JSON.parse(strSingleParamAirQualityRange);
        } catch(e) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // Return Default styles if stdAirQualityRange missing.
            return {RangeNameToShow: "", RangeBgColor: "lightGrey"};
            
        }
        
        if(singleParamAirQualityRange == null) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // Return Default styles if stdAirQualityRange missing.
            return {RangeNameToShow: "", RangeBgColor: "lightGrey"};
        }

        let singleParamAirQualityRangeLowSevereL = singleParamAirQualityRange[LOW_SEVERE_L];
        let singleParamAirQualityRangeLowSevereU = singleParamAirQualityRange[LOW_SEVERE_U];
        let singleParamAirQualityRangeLowVPoorL= singleParamAirQualityRange[LOW_V_POOR_L];
        let singleParamAirQualityRangeLowVPoorU = singleParamAirQualityRange[LOW_V_POOR_U];
        let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
        let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
        let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
        let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
        let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
        let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
        let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
        let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
        let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];


        if( inParamValue == null || inParamValue.length <= 0) {

            // If in future, we need to show Current Range for Parameteres in Single Device Data.
            rangeToDisplay = '';    
        } else {
            if( ( singleParamAirQualityRangeLowSevereL == null && inParamValue <= singleParamAirQualityRangeLowSevereU ) ||
                ( singleParamAirQualityRangeLowSevereU == null && inParamValue > singleParamAirQualityRangeLowSevereL ) ||
                ( inParamValue >= singleParamAirQualityRangeLowSevereL && inParamValue <= singleParamAirQualityRangeLowSevereU ) )
            {            
                rangeToDisplay = LOW_SEVERE; 

            } else if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                        ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                        ( inParamValue >= singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                rangeToDisplay = GOOD;
            } 
            else if(( singleParamAirQualityRangeLowVPoorL == null && inParamValue <= singleParamAirQualityRangeLowVPoorU ) ||
                        ( singleParamAirQualityRangeLowVPoorU == null && inParamValue > singleParamAirQualityRangeLowVPoorL ) ||
                        ( inParamValue > singleParamAirQualityRangeLowVPoorL && inParamValue <= singleParamAirQualityRangeLowVPoorU ) )
            {
                rangeToDisplay = LOW_V_POOR;

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                rangeToDisplay = SATISFACTORY;

            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                        ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                        ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                rangeToDisplay = MODERATE;

            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                        ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                        ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                rangeToDisplay = POOR;

            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                        ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                        ( inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                rangeToDisplay = V_POOR;

            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                        ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                        ( inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            { 
                rangeToDisplay = HIGH_SEVERE;

            }else if (inParamNameType == RNFL && inParamValue == 0) {
                rangeToDisplay = "NoRain";
            } else {
                rangeToDisplay = '';  // Default black/grey
            }
        }

        let selectedRangeNameAndColour = showRangeNameAndColor(rangeToDisplay, inParamNameType);

        return {RangeNameToShow: selectedRangeNameAndColour.retRangeName, RangeBgColor: selectedRangeNameAndColour.retRangeBandColor};
    }

    const showRangeNameAndColor = (inStdRangName, inParamNameType) => {
       
        let retRangeName = '';
        let retRangeBandColor = '';

        switch(inStdRangName) {

            case LOW_SEVERE:
                retRangeName = inParamNameType == TEMP ? t(IDS_ColdAndChilly) :
                                inParamNameType == HUM ? t(IDS_Dry) :
                                inParamNameType == NH3OD ? "" :
                                inParamNameType == O2 ? t(IDS_Low):
                                inParamNameType == AP ? t(IDS_ExtremeLow):
                                t(IDS_RangeLowSevere);
                retRangeBandColor = '#C00000';
                break;
            case GOOD:
                retRangeName =  inParamNameType == TEMP ? t(IDS_Comfortable) :
                                inParamNameType == HUM ? t(IDS_Comfortable) :
                                // inParamNameType == NH3OD ? t(IDS_BarelySensedInSingleDeviceData) :
                                inParamNameType == RNFL ? t(IDS_LightRain) :
                                inParamNameType == NOISE ? t(IDS_LowNoiseLevel) :
                                inParamNameType == LUX ? t(IDS_MediumLightIntensity) :
                                inParamNameType == O2 ? t(IDS_Normal) :
                                inParamNameType == DLV ? t(IDS_GoodVisibility) :
                                inParamNameType == WS ? t(IDS_CalmBreeze) :
                                inParamNameType == CL ? t(IDS_VeryLow) :
                                inParamNameType == CH3SH ? t(IDS_VeryLow) :
                                inParamNameType == UV ? t(IDS_Low) :
                                inParamNameType == AP ? t(IDS_Normal) :
                                inParamNameType == NH3OD ? t(IDS_BarelySensed) :
                                t(IDS_Good);
                retRangeBandColor = '#50C878';
                break;
            case SATISFACTORY:
                retRangeName =  inParamNameType == TEMP ? t(IDS_Comfortable) :
                                inParamNameType == HUM ? t(IDS_Comfortable) :
                                inParamNameType == NH3OD ? t(IDS_SatisfactoryInSingleDeviceData) :
                                inParamNameType == CL ? t(IDS_Low) :
                                inParamNameType == CH3SH ? t(IDS_Low) :
                                t(IDS_Satisfactory);
                retRangeBandColor = '#9acd32';
                break;
            case LOW_V_POOR:
                retRangeName =  inParamNameType == AP ? t(IDS_Low) :
                                t(IDS_RangeLowVeryPoor);
                retRangeBandColor = '#ff0000';
                break;
            case MODERATE:
                retRangeName =  inParamNameType == TEMP ? "" :
                                inParamNameType == HUM ? "" :
                                // inParamNameType == NH3OD ? t(IDS_WeakOdourInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_Weak) :
                                inParamNameType == RNFL ? t(IDS_ModerateRain) :
                                inParamNameType == NOISE ? t(IDS_MediumNoiseLevel) :
                                inParamNameType == DLV ? t(IDS_ModerateVisibility) :
                                inParamNameType == WS ? t(IDS_GentleBreeze) :
                                t(IDS_Moderate);
                retRangeBandColor = '#FFD700';
                break;
            case POOR:
                retRangeName =  inParamNameType == TEMP ? t(IDS_Warm):
                                inParamNameType == HUM ? t(IDS_Humid) :
                                // inParamNameType == NH3OD ? t(IDS_EasilyRecognizedInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_EasilyRecognizedInSingleDeviceData) :
                                inParamNameType == AQI || inParamNameType == CAQI ? t(IDS_PoorAqi) :
                                inParamNameType == RNFL ? t(IDS_HeavyRain) :
                                inParamNameType == DLV ? t(IDS_FairVisibility) :
                                inParamNameType == WS ? t(IDS_ModerateBreeze) :
                                inParamNameType == UV ? t(IDS_High) :
                                t(IDS_Poor);
                retRangeBandColor = 'orange';
                break;
            case V_POOR:
                retRangeName =  inParamNameType == TEMP ? "" :
                                inParamNameType == HUM ? "" :
                                // inParamNameType == NH3OD ? t(IDS_StrongOdourInSingleDeviceData) :
                                inParamNameType == NH3OD ? t(IDS_Strong) :
                                inParamNameType == AQI || inParamNameType == CAQI ? t(IDS_VeryPoorAqi) :

                                inParamNameType == RNFL ? t(IDS_VeryHeavyRain) :
                                inParamNameType == WS ? t(IDS_StrongGale) :
                                inParamNameType == CL ? t(IDS_Poor) :
                                inParamNameType == CH3SH ? t(IDS_Poor) :
                                inParamNameType == UV ? t(IDS_VeryHigh) :
                                inParamNameType == AP ? t(IDS_High) :
                                inParamNameType == DLV ? t(IDS_PoorVisibility) :
                                t(IDS_RangeVeryPoor);
                retRangeBandColor = 'red';
                break;
            case HIGH_SEVERE:
                retRangeName = inParamNameType == TEMP ? t(IDS_Hot) :
                               inParamNameType == HUM ? t(IDS_Sticky) :
                               inParamNameType == NH3OD ? t(IDS_PungentInSingleDeviceData) :
                               inParamNameType == AQI || inParamNameType == CAQI ? t(IDS_SevereAqi) :

                               inParamNameType == RNFL ? t(IDS_TorrentialRain) :
                               inParamNameType == NOISE ? t(IDS_HighNoiseLevel) :
                               inParamNameType == LUX ? t(IDS_HighLightIntensity) :
                               inParamNameType == O2 ? t(IDS_High) :
                               inParamNameType == WS ? t(IDS_Storm) :
                               inParamNameType == UV ? t(IDS_Extreme) :
                               inParamNameType == AP ? t(IDS_ExtremeHigh) :
                               t(IDS_Severe);
                retRangeBandColor = '#C00000';
                break;
            case "NoRain" :
                retRangeName = t(IDS_NoRain)
                retRangeBandColor = 'gray';
                break;
            default:
                console.log(`Unable to get RangeName. Unknown Range Type: ${inStdRangName, inParamNameType}`);
                retRangeName =  "";
                retRangeBandColor = 'lightGrey';
                break;
        }

        return {"retRangeName": retRangeName, "retRangeBandColor": retRangeBandColor}
    }

    const renderParamNameBasedOnType = (inParamNameType) => {

        switch(inParamNameType) {
            case NO2:
                return t(IDS_nitrogenDioxide);
            case O3:
                return t(IDS_Ozone);
            case SO2:
                return t(IDS_sulphurDioxide);
            case VOC:
                return t(IDS_TVOC);
            case CO:
                return t(IDS_carbonMonoxide);
            case NH3:
                return t(IDS_Ammonia);
            case CO2:
                return t(IDS_carbonDioxide);
            case H2S:
                return t(IDS_hydrogenSulphide);
            case CH4:
                return t(IDS_Methane);                
            case PM1:
                return t(IDS_Dust) + " (PM1)";
            case PM25:
                return t(IDS_Dust) + " (PM2.5)";;
            case PM10:
                return t(IDS_Dust) + " (PM10)";;
            case PM100:
                return t(IDS_Dust) + " (PM100)";;
            case TEMP:
                return t(IDS_Temperature);
            case HUM:
                return t(IDS_Humidity);
            case NH3OD:
                return t(IDS_Odour);   
            case SMOKE:
                return t(IDS_SMOKE);   
            case LPG:
                return t(IDS_LPG);
            case VRI:
                return t(IDS_VRI);
            case MRI:
                return t(IDS_MRI);     
            case AQI:
                return t(IDS_airQualityIndex);  
            case CAQI:
                return t(IDS_currentAirQualityIndex);   
            case HCHO:
                return t(IDS_Formaldehyde);
            case O2:
                return t(IDS_Oxygen);
            case NO:
                return t(IDS_NitricOxide);
            case CL:
                return t(IDS_Chlorine);
            case CH3SH:
                return t(IDS_MethylMercaptan);
            case NOISE:
                return t(IDS_Noise);
            case LUX:
                return t(IDS_LightIntensity);
            case UV:
                return t(IDS_UV);
            case RADON:
                return t(IDS_Radon);
            case AP:
                return t(IDS_AirPressure);
            case WS:
                return t(IDS_WindSpeed);
            case WD:
                return t(IDS_WindDirection);
            case DLV:
                return t(IDS_DaylightVisibility);
            case RNFL:
                return t(IDS_Rainfall);
                 
            default:
                console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (""); // Return empty tag
        }
    }

    const renderSelectedSingleDeviceData = (DeviceID, DeviceName, parentID, SelectedNodeDeviceType, nodePath) => {

        if(urlPathName === "/device/addNode") {
            const allDevices = [
                ...state.toiletNodeInfo.map(node => ({
                    id: node.id,
                    title: node.title,
                    deviceType: node.deviceType,
                    parentID: node.ParentNode,
                    selectedNodeDeviceType: node.SelectedNodeDeviceType,
                    nodePath: node.path,
                    isToiletNode: true
                })),
                ...state.toiletDevices.map(device => ({
                    id: device.DeviceID,
                    title: device.DeviceName,
                    deviceType: context.selectedNodeInfo.deviceType,
                    parentID: device.parentID,
                    selectedNodeDeviceType: device.SelectedNodeDeviceType,
                    nodePath: device.nodePath,
                    isToiletNode: false
                })),
                ...state.independentDevices.map(device => ({
                    id: device.DeviceID,
                    title: device.DeviceName,
                    deviceType: context.selectedNodeInfo.deviceType,
                    parentID: device.parentID,
                    selectedNodeDeviceType: device.SelectedNodeDeviceType,
                    nodePath: device.nodePath,
                    isToiletNode: false
                }))
            ];
    
            const matchedDevice = allDevices.find(device => device.id === selectedNodeID);
            if (matchedDevice) {
                context.onSelectedDevice( matchedDevice.id, matchedDevice.title, false, matchedDevice.isToiletNode, !matchedDevice.isToiletNode, matchedDevice.deviceType, matchedDevice.parentID, matchedDevice.selectedNodeDeviceType, matchedDevice.nodePath);
    
                if (matchedDevice.isToiletNode) {
                    context.onSelectedNodeContainsChildNode(true);
                }
    
                navigate('/device/dashboard');
            }
        }

        dispatch(setSearchedOrClickedToiletNode(''));
        dispatch(appendGlobalSearchNode(false))
        context.onSelectedDevice(DeviceID, DeviceName, false, false, true, context.selectedNodeInfo.deviceType, parentID, SelectedNodeDeviceType, nodePath)
    }

    const onRefreshClicked = () => {
        let refreshIconElement = document.getElementById('deviceTreeRefresh');
        if (refreshIconElement) {
            refreshIconElement?.classList.add('spinn')
            setTimeout(() => {
                refreshIconElement?.classList.remove('spinn')
            }, timeoutDuration);
        }
        getInfoOfVisibleDevicesInTree();
    }

    const renderAddUser = (id, title, parentID) => {
        context.onAddUser(id, title, parentID);
    }

    const onRemoveUser = (id, title, parentID) => {
        context.onRemoveUser(id, title, parentID);
    }

    const onGetRawData = (id, title, SelectedNodeDeviceType) => {
        context.onGetRawData(id, title, SelectedNodeDeviceType);
    }

    const onDeviceProfile = (id, title) => {
        context.onDeviceProfile(id, title);
    }

    const renderDropDownMenu = (device) => {
        const items = [
            {
                label: t(IDS_DTAddUser),
                key: '0',
                onClick: () => renderAddUser(device.id, device.title, device.parentID),
            },
            {
                label: t(IDS_DTRemoveUser),
                key: '1',
                onClick: () => onRemoveUser(device.id, device.title, device.parentID),
            },
            {
                label: t(IDS_DTDeviceRawData),
                key: '2',
                onClick: () => onGetRawData(device.id, device.title, device.SelectedNodeDeviceType),
            },
            {
                label: t(IDS_DTDeviceProfile),
                key: '3',
                onClick: () => onDeviceProfile(device.id, device.title),
            },
        ];


        return (
            <div className='3-dotDropdown'>
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                >
                    <div className="tvStyleForDropDownToggle"
                        style={{fontSize: "13px", marginRight: "0.5rem"}}
                        data-arr-id={device.id}>
                            <HiDotsVertical />
                    </div>
                    
                </Dropdown>
            </div>
        );
    }

    const renderDotBasedOnDeviceActiveStatus = (singleDeviceID) => {
        const device = state.getInfoOfVisibleDevices.find(statusDeviceId => statusDeviceId.DeviceID === singleDeviceID);
    
        if (device && device.DeviceStatus === "true") {
            return "#00ff00"; // Return green if device status is true
        } else {
            return "white"; // Return white otherwise
        }
    };

    const paramOptionsList = () => {
        let filteredParams = state.arrQuickTrackParams && state.arrQuickTrackParams.filter(param => {
            return !NO_CHART_PARAMS.includes(param);
        })
        const options = filteredParams.map(param => ({
            label: <span>{renderParamNameBasedOnType(param)}</span>,
            value: param
        }));
    
        return options;
    };
    
    const setToiletId = (toiletID, toiletTitle, toiletPath) => {

        if(urlPathName === "/device/addNode") {
            const allDevices = [
                ...state.toiletNodeInfo.map(node => ({
                    id: node.id,
                    title: node.title,
                    deviceType: node.deviceType,
                    parentID: node.ParentNode,
                    selectedNodeDeviceType: node.SelectedNodeDeviceType,
                    nodePath: node.path,
                    isToiletNode: true
                })),
                ...state.toiletDevices.map(device => ({
                    id: device.DeviceID,
                    title: device.DeviceName,
                    deviceType: context.selectedNodeInfo.deviceType,
                    parentID: device.parentID,
                    selectedNodeDeviceType: device.SelectedNodeDeviceType,
                    nodePath: device.nodePath,
                    isToiletNode: false
                })),
                ...state.independentDevices.map(device => ({
                    id: device.DeviceID,
                    title: device.DeviceName,
                    deviceType: context.selectedNodeInfo.deviceType,
                    parentID: device.parentID,
                    selectedNodeDeviceType: device.SelectedNodeDeviceType,
                    nodePath: device.nodePath,
                    isToiletNode: false
                }))
            ];
    
            const matchedDevice = allDevices.find(device => device.id === selectedNodeID);
            if (matchedDevice) {
                context.onSelectedDevice( matchedDevice.id, matchedDevice.title, false, matchedDevice.isToiletNode, !matchedDevice.isToiletNode, matchedDevice.deviceType, matchedDevice.parentID, matchedDevice.selectedNodeDeviceType, matchedDevice.nodePath);
    
                if (matchedDevice.isToiletNode) {
                    context.onSelectedNodeContainsChildNode(true);
                }
    
                navigate('/device/dashboard');
            }
        }

        dispatch(setSearchedOrClickedToiletNode(toiletID));
        dispatch(toggleToiletNodeInOpenToiletNodes(toiletID));
        
        context.onSelectedDevice(toiletID, toiletTitle, false, true, false, context.selectedNodeInfo.deviceType, context.selectedNodeInfo.parentID, context.selectedNodeInfo.SelectedNodeDeviceType, toiletPath)
        context.onSelectedNodeContainsChildNode(true)
    };

    const renderToDeviceViewport = (selectedNodeID) => {

        const deviceElement = document.querySelector(`li[id="${selectedNodeID}"]`) || document.querySelector(`div[deviceitemid="${selectedNodeID}"]`);
        if (deviceElement) {
            deviceElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }

        dispatch(appendGlobalSearchNode(false))
    };

    const renderToiletNodeDevices = () => {
        return (
            state.toiletNodeInfo?.map((toilet) => {
                if(toilet.hasDevices) {
                    return (
                        <Menu.SubMenu
                            id={toilet?.id}
                            key={toilet?.key}
                            className='mySelector'
                            title={
                                <div className="d-flex align-items-center">
                                    <div style={{fontSize: "1.3rem", color: "gray"}}>
                                        <TbDeviceDesktop /> 
                                    </div>
                                    <span style={{marginLeft: "8px"}}>
                                        {toilet.title.length > 28 ? toilet.title.substring(0, 28) + '..' : toilet.title}
                                    </span>
                                </div>
                            }
                            style={{ color: "black",  marginBottom: "2px", backgroundColor: "#f5f7f8" }}
                            onTitleClick={() => setToiletId(toilet.id, toilet.title, toilet.path)}
                        >  
                            {state.toiletDevices.filter((device) => device.TreeParentID === toilet.id).map((device) => {
                                return (
                                    <div 
                                        className='d-flex align-items-center deviceBlock' 
                                        deviceitemid={device.DeviceID}
                                        style={ selectedNodeID === device.DeviceID ? {backgroundColor: "#def1ff", borderBottom: "1px solid lightgray", borderBottomLeftRadius: "5px"} : {margin: "3px 0px", borderBottom: "1px solid lightgray", borderBottomLeftRadius: "5px"} }
                                    >
                                        {/* Rendering Device Onine/Offline Status  */}
                                        <div 
                                            id="deviceStatus" 
                                            style={{borderRadius: "50%", border: "1px solid lightgrey", backgroundColor: renderDotBasedOnDeviceActiveStatus(device.DeviceID), height: "0.5rem", width:"0.6rem", marginLeft: "0.6rem" }}>
                                        </div> 

                                        <Menu.Item
                                            className='deviceItem'
                                            id={device?.id}
                                            style={{ padding: "7px", fontSize: "12px" }}
                                            onClick={() => renderSelectedSingleDeviceData(device.DeviceID, device.DeviceName, device.parentID, device.SelectedNodeDeviceType, device.nodePath)}
                                            key={device?.key}
                                        >
                                            <div style={{ marginTop: "5px" }} className='deviceTitle' deviceitem={device.id}>
                                                <div id={device.DeviceID} className='d-flex align-items-start renderDeviceName' style={{marginLeft: "16px"}}>
                                                    {device.DeviceName.length > 30 ? device.DeviceName.substring(0, 30) + '..' : device.DeviceName}
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <div>{renderDropDownMenu(device)}</div>     
                                    </div>
                                )
                            })}
                        </Menu.SubMenu>
                    )
                }
            })
        )
    }

    const renderIndependentDevices = () => {
        if(state.independentDevices != null && state.independentDevices.length > 0) {
            return (
                state.independentDevices?.map((device) => {
                    return (
                        <div 
                            deviceitemid={device.DeviceID} 
                            className='d-flex align-items-center deviceBlock' 
                            style={ selectedNodeID === device.DeviceID ? {backgroundColor: "#def1ff", borderBottom: "1px solid lightgray", borderBottomLeftRadius: "5px"} : {margin: "3px 0px", borderBottom: "1px solid lightgray", borderBottomLeftRadius: "5px"} }
                        >
                            {/* Rendering Device Onine/Offline Status  */}
                            <div id="deviceStatus" 
                                style={{borderRadius: "50%", border: "1px solid lightgrey", backgroundColor: renderDotBasedOnDeviceActiveStatus(device.DeviceID), height: "0.5rem", width:"0.6rem", marginLeft: "10px"}}>
                            </div> 

                            <Menu.Item
                                className='deviceItem'
                                id={device?.id}
                                style={{ padding: "7px", fontSize: "12px" , marginLeft: "7px" }}    
                                onClick={() => renderSelectedSingleDeviceData(device.DeviceID, device.DeviceName, device.parentID, device.SelectedNodeDeviceType, '')}
                                key={device?.key}
                            >
                                <div style={{ marginTop: "5px" }} className='deviceTitle' deviceitem={device.id}>
                                    <div id={device.DeviceID} className='d-flex align-items-start renderDeviceName' style={{marginLeft: "16px"}}>
                                        {device.DeviceName.length > 30 ? device.DeviceName.substring(0, 30) + '..' : device.DeviceName}
                                    </div>
                                </div>
                            </Menu.Item>
                            
                            <div> {renderDropDownMenu(device)} </div>     
                        </div>
                    )
                })
            )
        } else {
            return (
                state.noDeviceRegistered?.map((device) => (
                    <Menu.Item 
                        key={device?.id}
                        className='d-flex align-items-center' 
                        style={{ backgroundColor: "#def1ff", borderBottom: "1px solid lightgray", borderBottomLeftRadius: "5px", color: "black" }}
                    >
                        {device.title}
                    </Menu.Item>
                ))
            )
        }
    }

    const renderTrackDevices = () => {
        return (
            state.trackDeviceArr != null && state.trackDeviceArr.length > 0 && state.trackDeviceArr.map((device) => {
                return (
                    <div 
                        deviceitemid={device.DeviceID} 
                        className='d-flex align-items-center deviceBlock' 
                        style={ selectedNodeID === device.DeviceID ? {backgroundColor: "#def1ff", borderBottom: "1px solid lightgray", borderBottomLeftRadius: "5px"} : {margin: "3px 0px", borderBottom: "1px solid lightgray", borderBottomLeftRadius: "5px"} }
                    >
                        {/* Rendering Device Onine/Offline Status  */}
                        <div 
                            id="deviceStatus" 
                            style={{borderRadius: "50%", border: "1px solid lightgrey", backgroundColor: renderDotBasedOnDeviceActiveStatus(device.DeviceID), height: "0.5rem", width:"0.6rem", marginLeft: "10px"}}>
                        </div> 
                        
                        <Menu.Item
                            className='deviceItem'
                            id={device?.id}
                            style={{ padding: "7px", fontSize: "12px" , marginLeft: "7px" }}    
                            onClick={() => renderSelectedSingleDeviceData(device.DeviceID, device.DeviceName, null, device.SelectedNodeDeviceType, '')}    
                            key={device?.key}
                        >
                            <div style={{ marginTop: "5px" }} className='deviceTitle' deviceitem={device.id}>
                                <div id={device.DeviceID} className='d-flex align-items-start renderDeviceName' style={{marginLeft: "16px"}}>
                                    {device.DeviceName.length > 30 ? device.DeviceName.substring(0, 30) + '..' : device.DeviceName}
                                </div>
                            </div>
                        </Menu.Item>
                        
                        <div> {renderDropDownMenu(device)} </div>     
                    </div>
                )
            })
        )
    }   

    let deviceWidth = window.innerWidth;
    let deviceTreeSettingsEnabled = window.location.pathname == '/device/addNode';
    const devicesExist = context.devicesToTrack.DevicesInfo !== null && context.devicesToTrack.DevicesInfo.length > 0;
    const showNavLink = selectedNodeID !== NO_DEVC_FOUND_FOR_LOGGED_IN_USER && !devicesExist;
    
  return (
    <div style={{position:"sticky", top:"0"}}>
        <div style={{ position: "relative", width: "" }}>
            <div id='mainBlock flex flex-col' style={{ backgroundColor:"#f7f9fa"}}>
                <div className='flex justify-around items-center flex-col py-2 gap-2' style={{ flexGrow:"1" }}>
                    {
                        deviceWidth > 570 &&
                            <div className='relative'>
                                <img src={VilisoLogo} width={100} alt="SmartHHM_ Logo" className='' />
                            </div>
                    }
                    
                    <div className='d-flex  px-2 gap-2 relative flex-wrap w-100' style={{ borderRadius: "15px", backgroundColor: "#fafafa", position: "sticky"}}>
                        {deviceWidth > 570 ? (
                            showNavLink ? (
                                <NavLink
                                    to='/device/addNode'
                                    className={({ isActive }) => (isActive ? "side-nav-active side-nav bg-success text-white" : 'side-nav')}
                                    style={{ border: "1px solid lightgray", borderRadius: "10px" }}
                                >
                                    <div className="tooltipPar">
                                        <TbDeviceDesktopPlus
                                            style={{
                                                color: deviceTreeSettingsEnabled ? "#f7f9fa" : "darkgreen",
                                                fontSize: "20px"
                                            }}
                                        />
                                        <span className="tooltiptextRight">{t(IDS_AddToilet)}</span>
                                    </div>
                                </NavLink>
                            ) : null
                        ) : (
                            showNavLink ? (
                                <NavLink
                                    to='/device/addNode'
                                    onClick={() => setTreeOnMobile(false)}
                                    className={({ isActive }) => (isActive ? "side-nav-active side-nav text-white" : 'side-nav')}
                                    style={{ border: "1px solid lightgray", borderRadius: "10px" }}
                                >
                                    <div className="tooltipPar">
                                        <TbDeviceDesktopPlus
                                            style={{
                                                color: "darkgreen",
                                                fontSize: "20px"
                                            }}
                                        />
                                        <span className="tooltiptextRight">{t(IDS_AddToilet)}</span>
                                    </div>
                                </NavLink>
                            ) : null
                        )}

                        {/* {selectedNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER || (context.devicesToTrack.DevicesInfo !== null && context.devicesToTrack.DevicesInfo.length > 0) ?
                                null :
                                <NavLink to='/device/addNode'  
                                    className={({ isActive }) => (isActive ? "side-nav-active side-nav bg-success text-white" : 'side-nav ')}
                                    style={{border: "1px solid lightgray", borderRadius: "10px"}}
                                    >
                                    <div className="tooltipPar">
                                        <TbDeviceDesktopPlus style={{
                                            color: `${deviceTreeSettingsEnabled ? "#f7f9fa" : "darkgreen"}`,
                                            fontSize: "20px"}} 
                                        />
                                            <span className="tooltiptextRight">{t(IDS_AddToilet)}</span>
                                    </div>
                                </NavLink>
                        }
                    
                        {/* <div onClick={onRefreshClicked} >
                            
                            <Button 
                                style={{backgroundColor: "#f7f9fa",  border: "1px solid lightgray", borderRadius: "10px"}} 
                                icon={
                                    <div className='d-flex align-items-center'>
                                        <i className="fa fa-refresh" style={{color: "darkgreen", fontSize: "20px", marginLeft: "-8px"}} aria-hidden="true"></i>
                                    </div>
                                } 
                                size={"medium"} 
                            />
                        </div> */}
                        <button className='refreshButtonStyle' 
                            style={{boxShadow:'none'}}
                            onClick={onRefreshClicked} 
                            title={t(IDS_RefreshData)}
                        >
                            <i className="fa fa-refresh flex-center" id='deviceTreeRefresh'
                            style={{ paddingLeft: "0", fontSize: "20px"}} 
                            aria-hidden="true"></i>
                        </button>

                        
                        
                        <div className='flex-grow-1 graphTypeDropdwon'>
                            <Select
                                value={quickTrackParam} // Assuming quickTrackParam is the state variable holding the selected value
                                // onChange={onChangeSelectedQuickTrackParam} // Pass the function directly as onChange handler
                                onChange={(value) => context.onChangeQuickTrackParam(value)}
                                // defaultValue="Viliso Air Quality Index"
                                defaultValue={quickTrackParam}
                                // style={{ minWidth: '100%' }}
                                className='w-100  mySelector'
                                options={paramOptionsList()} // Assuming options is the array of options for the Select component
                            />
                        </div>
                    </div>
                </div>

                <div
                    id="treeView"
                    className='relative rounded'
                    style={{ height: `${deviceWidth > 550 ? '85vh' : '100vh'}`, overflowY: "scroll", width: "100%" }}
                    onScrollCapture={handleScrollUponTree}
                >
                    <Menu
                        className='fw-bold rounded myMenu'
                        mode="inline"
                        openKeys={openToiletNodesID ? openToiletNodesID : []}
                        style={{ height: '100%', borderRight: 0, color: "#e6e6e6", backgroundColor: "#fcfeff" }}
                    >
                        {
                            ((context.devicesToTrack.DevicesInfo != null && context.devicesToTrack.DevicesInfo.length > 0) && (context.devicesToTrack.ownerOfTrackedDevices != null && 
                                (context.devicesToTrack.ownerOfTrackedDevices.length <= 0 || context.devicesToTrack.ownerOfTrackedDevices === PROD_DEVC_OWNER_USER_ID))) ?
                                renderTrackDevices() :
                                <>
                                    {renderToiletNodeDevices()} 
                                    {renderIndependentDevices()}
                                </>
                        }
                    </Menu>
                </div>
            </div>
        </div>
    </div>
  );
};

export default VcDeviceTree;
