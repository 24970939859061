import React, { useState,useContext, useEffect, useRef } from 'react';
import App from '../../App';
import { AppRelevantDataContext } from '../../AppContext';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import {IDS_AUServerError, IDS_RegistNetworkError, IDS_SanitationQuestionnaireTitle, IDS_SaniFormBackBtn, IDS_SaniFormSaveNextBtn, IDS_SaniFormSubmitBtn,
        IDS_MsgForRequiredQues, IDS_WarningOnClickingBackBtn, IDS_ConfirmMsgOnClickingBackBtn, IDS_Yes, IDS_No, IDS_ClearSelection, IDS_SanitationFormSubmissionMsg,
        IDS_ExitSanitationFrom, IDS_SaniFormNextBtn, IDS_AlertOnBackBtn, IDS_Section, IDS_LoginServerIssue, IDS_AlertBeforeDeletingImgFromS3, IDS_AlertFileIsAlreadyUploaded, 
        IDS_FiledToUploadSaniImgOnS3, IDS_MsgOnExitBtn, IDS_PdfMaxSizeExceeded, IDS_NoSnapshotFound} from '../../VcLanguage';
import { Button, message, Layout, Checkbox, Input, InputNumber, Radio, Upload,Card, Space, Col, Row, Popconfirm, Image as AntdImage, notification, List, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Formik, Form } from 'formik';
import saniImage1 from '../IMAGES/saniImage1.png';
import saniImage2 from '../IMAGES/saniImage2.png';
import saniImage3 from '../IMAGES/saniImage3.png';
import saniImage4 from '../IMAGES/saniImage4.png';
import saniImage5 from '../IMAGES/saniImage5.png';
import saniImage6 from '../IMAGES/saniImage6.png';
import saniImage7 from '../IMAGES/saniImage7.png';
import saniImage8 from '../IMAGES/saniImage8.png';
import saniImage9 from '../IMAGES/saniImage9.png';
import { SANITATION_BUCKET_NAME} from '../../VcConstants';
import { MdClose } from 'react-icons/md';
import { GrMenu } from 'react-icons/gr';
import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import VcSideNav from './DeviceHome/VcSideNav';
import { ImBin } from "react-icons/im";
import { Modal, ModalHeader, ModalBody} from 'reactstrap';

const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { Content } = Layout;

function VcSanitationMappingForm (props) {

  const context = useContext(AppRelevantDataContext);
  let t = context.t;
  const navigate = useNavigate();
  const [mainFormState, setMainFormState] = useState({
    questionnaireArr: [],
    optionsArr: [],
    sectionsArr: [],
    formResponse: {},
    formResponseToStore: {},
    selectedNodeID : "",
    loggedInUserID: "",
    sectionList: [],
    nextSec: null,
    prevSec: null,
    currentSec: 1,
    formData:[],
    items: {},
    currentSection: {},
    hasSkipSectionQuestion: false,
        isSubmitBtnPressed: false,
    arrUploadedModifiedFileName: [],
    errors: {
      noDataFound: "",
      others: ""
    }
  })
  const location = useLocation();
  const { state } = location;
  const invokedTo = state?.invokedTo;
  const EntityLocnID = state?.EntityLocnID;
  const Name = state?.Name;
  const [visitedSections, setVisitedSections] = useState([1]); // Start with section 1
  const [messageApi, contextHolder] = message.useMessage();
  const prevNextSec = useRef(mainFormState.nextSec);
  const prevPrevSec = useRef(mainFormState.prevSec);
  const [lastMessage, setLastMessage] = useState('');
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showDrawer, setShowDrawer] = useState(false);
  
  // Function to get sanitation form data
  const getAllSanitationFrmData = async () => {

    let modifiedState = mainFormState; 

    // To avoid appending of array values.
    modifiedState.questionnaireArr = [];
    modifiedState.optionsArr = [];
    modifiedState.sectionsArr = [];
    modifiedState.errors.noDataFound = "";
    modifiedState.errors.others = "";

    let languageSelected = context.language.languageToViewIn;

    const jsonBody = {
      FrmLanguage: languageSelected, 
    };

    // API to get data of form sections, questions and options
    axios.post(`${getAPIHostURL()}/wclient/getAllSanitationFrmData`, jsonBody)
    .then(response => {
        if(response.data.code == "SUCCESS") {
          if(response.data.recievedQuesData == null || response.data.recievedQuesData.length <= 0 ||
            response.data.retreivedOptionData == null || response.data.retreivedOptionData.length <= 0 ||
            response.data.retrievedSectionData == null || response.data.retrievedSectionData.length <= 0 )
            {
              modifiedState.errors.noDataFound = "No Sanitation Form Data Found";

            } else {

                let recievedQuesData = response.data.recievedQuesData;
                let retreivedOptionData = response.data.retreivedOptionData;
                let retrievedSectionData = response.data.retrievedSectionData;

                // Make sectionList, which contains sections->questions->options
                modifiedState.sectionList = retrievedSectionData.map(section => {

                  let questions = recievedQuesData.filter(question => question.SectionID === section.SectionID)
                    .map(question => {

                      let options = retreivedOptionData.filter(option => option.OptionID === question.OptionID);
                      let optionList = options != null && options.length > 0 ? JSON.parse(options[0].Options) : null;
                      return {
                        id: question.QuestionID,
                        questionText: question.Question,
                        type: question.QuestionType,
                        required: question.isRequired,
                        skipSection: question.SkipSection,
                        options: optionList
                      };
                    });

                  return {
                    id: section.SectionID,
                    seq: section.SectionSeqID,
                    title: section.SectionTitle,
                    questions: questions
                  };
                });

                modifiedState.formData = modifiedState.sectionList;
                modifiedState.currentSection = modifiedState.formData.find(section => section.seq === modifiedState.currentSec);
                modifiedState.items = modifiedState.formData.map((item) => ({ key: item.id, title: item.title }));
                getFrmResToViewSanitationFrm(modifiedState);

            }

        } else {
            if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors.others = t(IDS_AUServerError);
            } else {
                console.log("Should not reach here.")
                modifiedState.errors.others = t(IDS_AUServerError);
            }
            setMainFormState({...modifiedState});
        }
    })
    .catch(error => {
        console.log(error);
        if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setMainFormState({...modifiedState});
        }  
    });
  }

  // Function to get form response in case of view and edit form 
  const getFrmResToViewSanitationFrm = (inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null){
        modifiedState = mainFormState;
    } else {
        modifiedState = inModifiedState;
    }

    // To avoid appending of array values.
    modifiedState.questionnaireArr = [];
    modifiedState.optionsArr = [];
    modifiedState.sectionsArr = [];
    modifiedState.errors.noDataFound = "";
    modifiedState.errors.others = "";

    const jsonBody = {
      EntityLocID: EntityLocnID, 
    };

    // API to get data of form sections, questions and options
    axios.post(`${getAPIHostURL()}/wclient/getFrmResToViewSanitationFrm`, jsonBody)
    .then(response => {

        if(response.data.code == "SUCCESS") {
          if(response.data.RecievedSanitationFrmRes != null && response.data.RecievedSanitationFrmRes.length > 0)
            {
                // store response of sanitation mapping form
                modifiedState.formResponse = JSON.parse(response.data.RecievedSanitationFrmRes[0].Response);
            }

        } else {
            if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors.others = t(IDS_AUServerError);
            } else {
                console.log("Should not reach here.")
                modifiedState.errors.others = t(IDS_AUServerError);
            }
        }
        setMainFormState({...modifiedState});
    })
    .catch(error => {
        console.log(error);
        if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setMainFormState({...modifiedState});
        }  
    });
  } 

  const fetchData = async () => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    try {
      if (appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0) {

        // User login information is not present in appRelevantDataContext.
        // Try to get this value if present in LocalStorage and update the app state (so that
        // re-render will happen). If app context data not present in LocalStorage then
        // show the Login Page
        if (appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false) {
          // App Relevant Data Context not present in LocalStorage as well.
          navigate('/', { replace: true });
        } else {
          // App Relevant Data Context loaded from LocalStorage at AppLeval.
          // Re-render will happen automatically (no action required).
          await getAllSanitationFrmData();

        }
      } else {
 
        await getAllSanitationFrmData();

        // if (invokedTo == "edit" || invokedTo == "view") {
          // getFrmResToViewSanitationFrm();
        // }
      }
    } catch (error) {
      console.error("Error in useEffect:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {

    if (prevNextSec.current !== mainFormState.nextSec || prevPrevSec.current !== mainFormState.prevSec) {

        let modifiedState = mainFormState;

        modifiedState.currentSection = modifiedState.formData.find(section => section.seq === modifiedState.currentSec);

        modifiedState.hasSkipSectionQuestion = modifiedState.currentSection.questions.some(question => question.skipSection === 1);
        setMainFormState({...modifiedState});
        
        prevNextSec.current = modifiedState.nextSec;
        prevPrevSec.current = modifiedState.prevSec;
    }
  }, [mainFormState.nextSec, mainFormState.prevSec, mainFormState.currentSec]);
  
  useEffect(() => {

    // Function to show the confirmation message
    const confirmExit = (e) => {

      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
      // Show the confirmation message
      const message = 'Do you want to save your changes before leaving?';
      e.returnValue = message; // For Chrome
      return message; // For other browsers
    };


    // Add event listeners for the beforeunload event
    window.addEventListener('beforeunload', confirmExit);
    window.addEventListener('popstate', confirmExit);

    // Remove event listener when the component is unmounted (optional)
    return () => {

      window.removeEventListener('beforeunload', confirmExit);
      window.removeEventListener('popstate', confirmExit);

      // if(isFormSubmitting == false && invokedTo != "view"){
      //   alert(t(IDS_AlertOnBackBtn));
      // }
    };
  }, [isFormSubmitting]);

  if( context.loggedInUserInfo.userFirstName.length <= 0 ) {
    // User login information is not present in appRelevantDataContext.
    // Next re-render will happen automatically when the AppState is updated again 
    // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
    return App.renderLoadingPage();
  }

  // Function to check if msg is already shown
  const isMessageShown = () => {
    // Check if a message is currently displayed
    return document.getElementsByClassName('ant-message-notice').length > 0;
  };

  // Function to show msg
  const showMessage = (msg) => {

    if (!isMessageShown() || msg !== lastMessage) {
      messageApi.open({
        type: 'warning',
        content: msg,
        duration: 5,
      });
      setLastMessage(msg);
    }
  };

  // Function to store sanitation form response
  const storeResOfSanitationFrm = (values, reasonToSubmit) => {
    let modifiedState = mainFormState; 

    // To avoid appending of array values.
    modifiedState.questionnaireArr = [];
    modifiedState.optionsArr = [];
    modifiedState.sectionsArr = [];
    modifiedState.errors.noDataFound = "";
    modifiedState.errors.others = "";
    
    modifiedState.formResponseToStore = JSON.stringify(values);
    modifiedState.loggedInUserID = context.loggedInUserInfo.userID;

    const jsonBody = {
      EntityLocID: EntityLocnID, 
      FormResponse: modifiedState.formResponseToStore,
      SetByUser: modifiedState.loggedInUserID
    };

    // API to get data of form sections, questions and options
    axios.post(`${getAPIHostURL()}/wclient/storeResOfSanitationFrm`, jsonBody)
    .then(response => {

        if(response.data.code == "SUCCESS") {
          
          if(reasonToSubmit == "SubmitBtnClicked"){
            alert( t(IDS_SanitationFormSubmissionMsg, Name));
            navigate('/sanitationMapping');
          }

        } else {
            if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors.others = t(IDS_AUServerError);
            } else {
                console.log("Should not reach here.")
                modifiedState.errors.others = t(IDS_AUServerError);
            }
        }
        setMainFormState({...modifiedState});
    })
    .catch(error => {
        console.log(error);
        if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setMainFormState({...modifiedState});
        }  
    });
  } 

  // Function to decide the next section
  const nextSection = (currentSection, fieldValues) => {
    let modifiedState = mainFormState;

    prevNextSec.current = modifiedState.nextSec;
    prevPrevSec.current = modifiedState.prevSec;

    // check if the current section has at least one question such that it can skip sections depending on seleted options
    let doesSectionHasSkipQuestions =  currentSection.questions.some(question => question.skipSection === 1);

    // If section has skip questions
    if(doesSectionHasSkipQuestions == true){

      // In these kind of sections, we have only one question.
      // get option selected in the question
      const option =  currentSection.questions[0].options.find((opt) => opt.id === parseInt(fieldValues[`question-${ currentSection.questions[0].id}`]));

      // Check if any option is selected or not, we can consider that any option is selected when,
      // option variable is != null and option is != undefined
      if(option != null && option != undefined ){

        // If the option is selected, we need to take nextSection and prevSection from selected option
        modifiedState.nextSec = option.hasOwnProperty(`nextSection`) ? modifiedState.formData.find(section => section.id === option.nextSection).seq : modifiedState.currentSec + 1;
        modifiedState.prevSec = option.hasOwnProperty(`nextSection`) ? modifiedState.formData.find(section => section.id === option.prevSection).seq : modifiedState.currentSec;

        // Assign next section of the option to modifiedState.currentSec
        modifiedState.currentSec = modifiedState.nextSec;

      } else {

        // If no option is selected, we need to check If the skip section question is required and answered or not
        let quesRequiredButNotAnswered =  currentSection.questions.some(question => question.required == '1' && fieldValues[`question-${question.id}`] == undefined) ;

        if(quesRequiredButNotAnswered == true ){

          // If question is required and not answered, we will show message "Please answer the questions marked with *"
          const generateRequiredMessage = () => (
            <span>
              {t(IDS_MsgForRequiredQues, <span style={{ color: "var(--errorColor)", fontSize: "1rem", marginRight: "0.2rem" }}>*</span>)}
            </span>
          );
          
          if(invokedTo != "view"){
            showMessage(t(IDS_MsgForRequiredQues));
          }

          return;

        } else {

          // If question is not required and not answered, we will update the next, prev and curr sections
          modifiedState.nextSec = modifiedState.currentSec + 1;
          modifiedState.prevSec = modifiedState.currentSec;
          modifiedState.currentSec = modifiedState.formData.find(section => section.id === modifiedState.nextSec).seq;
        }
      }

    } else {

      // We came here because, section does not have skip questions

      // Check If no options are selected for questions, we need to check If the question is required and answered or not
      let quesRequiredButNotAnswered =  currentSection.questions.some(question => question.required == '1' && fieldValues[`question-${question.id}`] == undefined);

      if( quesRequiredButNotAnswered == true){
        // If questions are required and not answered, we will show message "Please answer the questions marked with *"
          if(invokedTo != "view"){
            showMessage(t(IDS_MsgForRequiredQues));
          }        
          
          return;

      } else {

        // If questions in the sections are not required and not answered, we will update the next, prev and curr sections
        modifiedState.nextSec = modifiedState.currentSec + 1;
        modifiedState.prevSec = modifiedState.currentSec;
        modifiedState.currentSec = modifiedState.formData.find(section => section.seq === modifiedState.nextSec).seq;

      }
    }

    // Update array of visited sections
    setVisitedSections((prevSections) => {
      // VisitedSections is an array of section id's , which user has visited sequentially.
      let updatedSections = prevSections;
      if (!updatedSections.includes(modifiedState.currentSec)) {
          updatedSections.push(modifiedState.currentSec);
      }

      return updatedSections;
    });

    if(invokedTo == "fill"){
      handleFormSubmit(modifiedState.currentSection, fieldValues, "NextBtnClicked");
    } else if(invokedTo == "edit") {
      handleFormSubmit(modifiedState.currentSection, modifiedState.formResponse, "NextBtnClicked");
    }

    modifiedState.currentSection = modifiedState.formData.find(section => section.seq === modifiedState.currentSec);

    setMainFormState({...modifiedState});
  }

  // Function to decide prev section
  const prevSection = (currentSection, fieldValues, setFieldValue) => {

    let modifiedState = mainFormState;

    prevNextSec.current = modifiedState.nextSec;
    prevPrevSec.current = modifiedState.prevSec;

    const prevSectionIndex = visitedSections.indexOf(modifiedState.currentSec) - 1;

    let fieldsToDelete = [];
    let updatedFieldValues = {};
    if(invokedTo != "view" && invokedTo != "edit") {
      currentSection.questions.forEach(question => {

        const fieldName = `question-${question.id}`;
        if (fieldValues[fieldName]) {
          fieldsToDelete.push(fieldName);

          setFieldValue(fieldName, undefined);
        }
      });

      // Remove multiple keys from fieldValues
      updatedFieldValues = Object.fromEntries(
        Object.entries(fieldValues).filter(([key, value]) => !fieldsToDelete.includes(key))
      );
    }

    if (prevSectionIndex >= 0) {
      modifiedState.currentSec = visitedSections[prevSectionIndex];
    }

    // Remove last section from visitedSections
    if (visitedSections.length > 1) { 
      setVisitedSections(visitedSections.slice(0, visitedSections.length - 1));
    }   

    if(invokedTo != "view" && invokedTo != "edit"){
      handleFormSubmit(modifiedState.currentSection, updatedFieldValues, "BackBtnClicked");
    }

    modifiedState.currentSection = modifiedState.formData.find(section => section.seq === modifiedState.currentSec);

    setMainFormState({...modifiedState});
  }

  // Function called on form submit
  const handleFormSubmit = (currentSection, fieldValues, reasonToSubmit) => {

    let modifiedState = mainFormState;

    // Check If no options are selected for questions, we need to check If the question is required and answered or not
    let quesRequiredButNotAnswered =  invokedTo == "edit" 
                                      ? currentSection.questions.some(question => question.required == '1' && modifiedState.formResponse[`question-${question.id}`] == undefined)
                                      : currentSection.questions.some(question => question.required == '1' && fieldValues[`question-${question.id}`] == undefined);

    if( quesRequiredButNotAnswered == true && reasonToSubmit == "SubmitBtnClicked"){
      // If questions are required and not answered, we will show message "Please answer the questions marked with *"
      showMessage(t(IDS_MsgForRequiredQues));
      return;

    } else {
      storeResOfSanitationFrm(fieldValues, reasonToSubmit);
    }
  }

  const getSanitationMappingForm = () => {
    
    return (
      <div style={{overflow: 'none'}}>
        {contextHolder}
        <Layout hasSider style={{height: "100%", margin: "0rem", padding: "0rem 0rem 0.3rem"}}>
        <Layout className="site-layout" style={{paddingTop: "0.3rem"}}>
            <div className="rounded-3 justify-content-center align-items-center"
            style={{ margin: "0.5rem", paddingTop: "0.6rem", paddingBottom: "0.3rem", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <div>
                  <h5>{t(IDS_SanitationQuestionnaireTitle)}</h5>
              </div>
              <div>
                  <h5 style={{ color: "gray" }}>{Name}</h5>
              </div>
            </div>
          <Content class="container-fluid col col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10" style={{ width: "100hw", marginTop: "0.3rem", overflow: 'scroll', padding: "0rem"}}>
              <>
              {mainFormState.errors.others != null && mainFormState.errors.others.length > 0 && 
                <p className='addCustError' style={{textAlign: "center"}}>{mainFormState.errors.others}</p>}

              {mainFormState.errors.noDataFound != null && mainFormState.errors.noDataFound.length > 0 && 
                <p className='addCustError' style={{textAlign: "center"}}>{mainFormState.errors.noDataFound}</p>}

              {mainFormState.formData.length > 0 && mainFormState.formData.length > 0
              &&
                <Formik
                  initialValues={invokedTo == "edit" || invokedTo == "view" ?  mainFormState.formResponse : {}}
                  enableReinitialize={true}
                  onSubmit={async (values, { setSubmitting }) => {
                    if(invokedTo == "view"){
                      navigate(`/sanitationMapping`)
                    } else {
                      setIsFormSubmitting(true); // Set state to true when submitting
                    try {
                      await handleFormSubmit(mainFormState.currentSection, invokedTo === "fill" ? values : mainFormState.formResponse, "SubmitBtnClicked");
                    } finally {
                      setSubmitting(false); // Set isSubmitting to false after submission completes
                    }
                    }

                  }}
                >
                  {({ isSubmitting, handleSubmit, values, initialValues, setFieldValue }) => (          
                  <Form onSubmit={handleSubmit}>
                    <div>
                        <h6>{t(IDS_Section) + mainFormState.currentSection.seq + " : " + mainFormState.currentSection.title}</h6>

                        {mainFormState.currentSection.questions.map((question, index) => (
                          <div key={index}>

                            {question.type === 'radio' &&
                              <RadioQuestion formState={mainFormState} setMainFormState={setMainFormState} question={question} fieldValues={values} setFieldValue={setFieldValue} t={t} invokedTo={invokedTo}/>
                            }

                            {question.type === 'text' &&
                              <InputQuestion formState={mainFormState} setMainFormState={setMainFormState} question={question} fieldValues={values} setFieldValue={setFieldValue} t={t} invokedTo={invokedTo}/>
                            }

                            {question.type === 'checkbox' &&
                              <CheckboxQuestion formState={mainFormState} setMainFormState={setMainFormState} question={question} fieldValues={values} setFieldValue={setFieldValue} t={t} invokedTo={invokedTo}/>
                            }

                            {question.type === 'file' &&
                              <FileQuestion formState={mainFormState} setMainFormState={setMainFormState} question={question} fieldValues={values} setFieldValue={setFieldValue} invokedTo={invokedTo} EntityLocnID={EntityLocnID} getFrmResToViewSanitationFrm={getFrmResToViewSanitationFrm}/>
                            }

                            {question.type === 'number' &&
                              <InputNumberQuestion formState={mainFormState} setMainFormState={setMainFormState} question={question} fieldValues={values} setFieldValue={setFieldValue} t={t} invokedTo={invokedTo}/>
                            }
                            
                            {question.type === 'numberGroup' &&
                              <InputNumberGroupQuestion formState={mainFormState} setMainFormState={setMainFormState} question={question} fieldValues={values} setFieldValue={setFieldValue} t={t} invokedTo={invokedTo}/>
                            }
                              
                          </div>
                        ))}
                      </div>

                      <div style={{ marginTop: 24, marginBottom: 24, marginRight: "0.8rem", marginLeft: "0.8rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', flexGrow: 1 }}>
                          {invokedTo != "view" && (mainFormState.currentSec - 1) > 0 && (
                            <Popconfirm
                              title={t(IDS_WarningOnClickingBackBtn)}
                              description={t(IDS_ConfirmMsgOnClickingBackBtn)}
                              okText={t(IDS_Yes)}
                              cancelText={t(IDS_No)}
                              onConfirm={() => prevSection(mainFormState.currentSection, values, setFieldValue)}
                              disabled={invokedTo == "view" || invokedTo == "edit"}
                            >
                              <Button onClick={() => invokedTo == "view" || invokedTo == "edit" ? prevSection(mainFormState.currentSection, invokedTo == "fill" ? values : mainFormState.formResponse, setFieldValue) : null}>
                                {t(IDS_SaniFormBackBtn)}
                              </Button>
                            </Popconfirm>
                          )}

                          {invokedTo == "view" && (mainFormState.currentSec - 1) > 0 && (
                            <Button onClick={() => prevSection(mainFormState.currentSection, invokedTo == "fill" ? values : mainFormState.formResponse, setFieldValue)}>
                              {t(IDS_SaniFormBackBtn)}
                            </Button>
                          )}

                          {(mainFormState.currentSec - 1) < mainFormState.formData.length - 1 && (
                            <Button type="primary" onClick={() => nextSection(mainFormState.currentSection, invokedTo == "fill" ? values : mainFormState.formResponse)}>
                              {invokedTo == "view" ? t(IDS_SaniFormNextBtn) : t(IDS_SaniFormSaveNextBtn)}
                            </Button>
                          )}

                          {invokedTo != "view" && (mainFormState.currentSec - 1) == mainFormState.formData.length - 1 && (
                            <Button type="primary" disabled={isSubmitting} onClick={handleSubmit}>
                              {t(IDS_SaniFormSubmitBtn)}
                            </Button>
                          )}
                        </div>

                        <Button type="primary" style={{ margin: "0 0px" }} disabled={isSubmitting}
                          onClick={() => {
                            if (!window.confirm(t(IDS_MsgOnExitBtn))) {
                              return;
                            } else {
                              navigate(`/sanitationMapping`)
                            }
                          }}>
                          {t(IDS_ExitSanitationFrom)}
                        </Button>
                      </div>

                    </Form>
                  )}
                </Formik>
              }
              </>
            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }

  if(windowWidth < 570) {
   return  <>  
     <div  className={`drawerDiv ${showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
         <VcSideNav />
         <FaArrowAltCircleLeft 
             className="closingArrow top-nav" onClick={()=> setShowDrawer(false)}
         />
     </div>
     <div className='bg-white shadow flex items-center justify-between px-2 py-1 m-1 ' style={{ top:"0", borderRadius:"0.75rem"}}>
         <div className='' style={{width:"60px"}}>
           <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
         </div>
         {showDrawer ? 
         <MdClose classname='transition-all' onClick={()=> setShowDrawer(false)}/>:
         <GrMenu onClick={()=> setShowDrawer(true)}/> 
         }
     </div>

      {getSanitationMappingForm()}           
     
     </>
  } else {
    return (
      <div className='w-100 d-flex relative '>
          <div className='shadow' style={{width:"4%", position:"relative", zIndex:"5"}}>
              <VcSideNav/>
          </div>
          <div style={{width:"96%" }}>
            {getSanitationMappingForm()}   
          </div>
      </div>
    )
  }
}

// Function to generate questions of type - radio
const RadioQuestion = ({ formState, setMainFormState, question, fieldValues, setFieldValue, t, invokedTo }) => {

  const [radioState, setRadioState] = useState(
    {
      value: invokedTo == "fill" && fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined 
              ? fieldValues[`question-${question.id}`] 
              : (invokedTo == "edit" || invokedTo == "view") && formState.formResponse.hasOwnProperty(`question-${question.id}`) && formState.formResponse[`question-${question.id}`] != null && formState.formResponse[`question-${question.id}`] != undefined 
              ? formState.formResponse[`question-${question.id}`] 
              : null,
      otherInputVisible : false
    });
  const [otherInputVisible, setOtherInputVisible] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState('');

  const Question = question;
  useEffect(() => {
    let otherOption = question.options.find(option => option.text == "Other" || option.text == "अन्य" || option.text == "इतर");

    if(invokedTo == "fill" && fieldValues.hasOwnProperty(`question-${Question.id}`) && fieldValues[`question-${Question.id}`] != null && fieldValues[`question-${Question.id}`] != undefined ) {

      if(typeof fieldValues[`question-${question.id}`] === 'string' || (otherOption != null && fieldValues[`question-${question.id}`] == otherOption.id)) {

        setRadioState({ value: otherOption.id != null ? otherOption.id : null });
  
        setOtherInputVisible(true);
        if(fieldValues[`question-${question.id}`] != otherOption.id){
          setOtherInputValue(fieldValues[`question-${question.id}`]);
        }
      } else {
        setRadioState({ value: fieldValues[`question-${question.id}`] });

      }

    } else if((invokedTo == "edit" || invokedTo == "view") && fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined ) {

      if(typeof fieldValues[`question-${question.id}`] === 'string' || (otherOption != null && fieldValues[`question-${question.id}`] == otherOption.id)) {

        setRadioState({ value: otherOption != null && otherOption.id != null ? otherOption.id : null });
        
        setOtherInputVisible(true);
        if(fieldValues[`question-${question.id}`] != otherOption.id){
          setOtherInputValue(fieldValues[`question-${question.id}`]);
        }        
      } else {
        setRadioState({ value: fieldValues[`question-${question.id}`] });

      }
    } else {
        setRadioState({ value: null });

    }
  }, [question, fieldValues]); 

  const radioChange = (e) => {
    handleChange1(e.target.value);
  }

  // Function which removes keys after the passes key
  function removeKeyAfter(obj, key) {
    const newObj = {};
    let found = false;
  
    for (const k in obj) {
      if (k === key) {
        newObj[k] = obj[k];
        found = true;
      } else if (found) {
        break; // Stop adding key-value pairs after the specified key
      } else {
        newObj[k] = obj[k];
      }
    }
  
    return newObj;
  }

  // Function called when ever the radio button is selected
  const handleChange1 = (selectedOptionId) => {

    let updatedFieldValues = {};
    const option = Question.options.find((opt) => opt.id === parseInt(selectedOptionId));

    setRadioState({value: option.id, otherInputVisible: option.text == "Other" || option.text == "अन्य" || option.text == "इतर"}); // Use option.id instead of selectedOptionId

    if (Question.skipSection === 1) {

      if(invokedTo == "edit"){

        let prevOption = Question.options.find((opt) => opt.id === parseInt(fieldValues[`question-${Question.id}`]));

        if(prevOption != null && prevOption != undefined && prevOption.nextSection != option.nextSection){

          updatedFieldValues = removeKeyAfter(fieldValues, `question-${Question.id}`);
          updatedFieldValues[`question-${Question.id}`] = option.id;
          setMainFormState(prevState => ({
            ...prevState,
            formResponse: updatedFieldValues
          }));
          setFieldValue(updatedFieldValues); // Update the form value

        } else {
            formState.formResponse[`question-${question.id}`] = option.id;
            setMainFormState(prevState => ({
            ...prevState,
            formResponse: formState.formResponse
          }));
        }
        
      } else {

        setFieldValue(`question-${Question.id}`, option.id); // Update the form value

      }
 
    } else {
      if(invokedTo == "edit"){
        formState.formResponse[`question-${question.id}`] = option.id;

        setMainFormState(prevState => ({
            ...prevState,
            formResponse: formState.formResponse
          }));
      } else {
        setFieldValue(`question-${Question.id}`, option.id); // Update the form value
      }
    }

    setOtherInputVisible(option.text == "Other" || option.text == "अन्य" || option.text == "इतर"); // Show input for "Other" option
  };
  
  // Function to show images in image type questions
  const showOptionImages = (optionId) => {
    let imageSrc = '';
    switch(optionId) {
      case 1:
        imageSrc = saniImage1;
        break;
      case 2:
        imageSrc = saniImage2;
        break;
      case 3:
        imageSrc = saniImage3;
        break;
      case 4:
        imageSrc = saniImage4;
        break;
      case 5:
        imageSrc = saniImage5;
        break;
      case 6:
        imageSrc = saniImage6;
        break;
      case 7:
        imageSrc = saniImage7;
        break;
      case 8:
        imageSrc = saniImage8;
        break;
      case 9:
        imageSrc = saniImage9;
        break;
      default:
        imageSrc = ''; // Default to empty string if no image found
    }

    return(<img src={imageSrc} style={{height: "300px" , width: "320px"}}/>);
  }

  return (
    <div>
      <Card
        title={<span style={{whiteSpace: 'pre-wrap'}}>{question.id}. {question.questionText} {question.required == '1' ? <span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}>*</span> : null}</span>}
        size="small"
        style={{ textAlign: "left", margin: "0.8rem", padding: "0rem"  }}
      >

        <Radio.Group value={radioState.value} name={`question-${question.id}`} onChange={radioChange}>
            { question.options.some(option => option.image == "true") == false && question.options.map((option, optIndex) => (
              <div key={optIndex}> 
                <Radio value={option.id} disabled={invokedTo == "view" ? true : false}>{option.text}</Radio>
                {(option.text == "Other" || option.text == "अन्य" || option.text == "इतर") && otherInputVisible && fieldValues[`question-${Question.id}`] != undefined && (
                  <Input
                    value={otherInputValue}
                    onChange={(e) => {
                      setOtherInputValue(e.target.value);

                      setFieldValue(`question-${question.id}`, e.target.value); // Update the form value
                    }}
                    placeholder="Enter other"
                    style={{ marginLeft: "1.5rem", marginTop: "0.5rem", whiteSpace: 'pre-wrap'}}
                  />
                )}
              </div>
            ))}
            { question.options.some(option => option.image == "true") == true && (
              <Row gutter={[16, 16]}>
                {question.options.map((option, optIndex) => (
                  <Col xs={24} sm={12} key={optIndex}>
                    <div style={{ marginBottom: '1rem' }}>
                      <Radio value={option.id} disabled={invokedTo === "view"}>
                        {option.text}
                      </Radio>
                      {(option.text === "Other" || option.text === "अन्य" || option.text === "इतर") && otherInputVisible && fieldValues[`question-${question.id}`] !== undefined && (
                        <Input
                          value={otherInputValue}
                          onChange={(e) => {
                            setOtherInputValue(e.target.value);
                            setFieldValue(`question-${question.id}`, e.target.value); // Update the form value
                          }}
                          placeholder="Enter other"
                          style={{ marginTop: "0.5rem", width: "80%", whiteSpace: 'pre-wrap' }}
                        />
                      )}
                      {option.image && (
                        <div style={{ marginTop: '0.5rem' }}>
                          {showOptionImages(option.id)}
                        </div>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>)

            }
          {/* } */}
        </Radio.Group>
        { invokedTo != "view" &&
          <div style={{ marginTop: "2rem" }}>
            <Button type="text"
              onClick={() => {

                let selectedOptionId = fieldValues[`question-${Question.id}`];
                
                if(typeof fieldValues[`question-${question.id}`] === 'string') {
                  setRadioState({ ...radioState, value: null }); // Clear the selected value
                  if(invokedTo == "edit"){

                  const { ['question-' + question.id]: removedKey, ...updatedFormResponse } = fieldValues;

                  setMainFormState(prevState => ({
                    ...prevState,
                    formResponse: updatedFormResponse
                  }));
                  } else {
                    setFieldValue(`question-${question.id}`, undefined)
                  }
                  setOtherInputVisible(false);
                  setOtherInputValue("");
                } else {
                  if(invokedTo == "edit"){

                  const { ['question-' + question.id]: removedKey, ...updatedFormResponse } = fieldValues;

                  setMainFormState(prevState => ({
                    ...prevState,
                    formResponse: updatedFormResponse
                  }));
                  } else {
                    setFieldValue(`question-${question.id}`, undefined)
                  }                    
                  setRadioState({ ...radioState, value: null }); // Clear the selected value          
                }

              }}
              style={{ color: "gray", position: "absolute", bottom: "0", right: "0", marginBottom: "0.8rem", marginRight: "0.8rem" }}
            >
              {t(IDS_ClearSelection)}
            </Button>
          </div>
        } 
      </Card>
    </div>
  );
};

// Function to generate questions of type - Input text
const InputQuestion = ({ formState, setMainFormState, question, fieldValues, setFieldValue, t, invokedTo }) => {


  const [inputState, setInputState] = useState(
    {
      value: fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined 
              ? fieldValues[`question-${question.id}`] 
              : null,
      otherInputVisible : false
    });

    useEffect(() => {
      setInputState ({value: fieldValues.hasOwnProperty(`question-${Question.id}`) && fieldValues[`question-${Question.id}`] != null && fieldValues[`question-${Question.id}`] != undefined 
                      ? fieldValues[`question-${Question.id}`] 
                      : null});
    }, [question]);

    const Question = question;

  return (
    <div>
      <Card
        title={<span style={{whiteSpace: 'pre-wrap'}}>{question.id}. {question.questionText} {question.required == '1' ? <span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}>*</span> : null}</span>}
        size="small"
        style={{ textAlign: "left", margin: "0.8rem", padding: "0rem" }}
      >
          <Space direction="vertical">

            <TextArea value={inputState.value}
              onChange={(e) => {

                const newValue = e.target.value;
                setInputState({ value: newValue });

                const isTextValid = newValue.trim().length > 0 || /[!@#$%^&*(),.?":{}|<>/]/.test(newValue);
                  if(invokedTo == "edit"){

                    formState.formResponse = { ...formState.formResponse, ['question-' + question.id]: isTextValid == true ? newValue : undefined };
                    setMainFormState(prevState => ({
                      ...prevState,
                      formResponse: formState.formResponse
                    }));
                  } else {
                    setFieldValue(`question-${question.id}`, isTextValid == true ? newValue : undefined); // Update the form value
                  }
                // }
              }}
              placeholder={invokedTo == "view" ? "" : "Your Answer"} autoSize 
              style={{ marginLeft: "1.5rem", marginTop: "0.5rem", width: "100%" }}
              disabled={invokedTo == "view" ? true : false}
              />
              { invokedTo != "view" &&
                <div style={{ marginTop: "2rem" }}>
                  <Button type="text"
                    onClick={() => {
                      if(invokedTo == "edit"){

                        const { ['question-' + question.id]: removedKey, ...updatedFormResponse } = fieldValues;

                        setMainFormState(prevState => ({
                          ...prevState,
                          formResponse: updatedFormResponse
                        }));

                      } else {
                        setFieldValue(`question-${question.id}`, undefined)
                      }
                      setInputState({ ...inputState, value: null }); // Clear the selected value
                    }}
                    style={{ color: "gray", position: "absolute", bottom: "0", right: "0", marginBottom: "0.8rem", marginRight: "0.8rem" }}
                  >
                    {t(IDS_ClearSelection)}
                  </Button>
                </div>
              }
          </Space>
      </Card>
    </div>
  );

}

// Function to generate questions of type - Input number
const InputNumberQuestion = ({ formState, setMainFormState, question, fieldValues, setFieldValue, t, invokedTo }) => {


  const [inputState, setInputState] = useState(
    {
      value: fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined 
              ? fieldValues[`question-${question.id}`] 
              : null,
    });

    useEffect(() => {
      setInputState ({value: fieldValues.hasOwnProperty(`question-${Question.id}`) && fieldValues[`question-${Question.id}`] != null && fieldValues[`question-${Question.id}`] != undefined 
                      ? fieldValues[`question-${Question.id}`] 
                      : null});
    }, [question]);

    const Question = question;

  return (
    <div>
      <Card
        title={<span style={{whiteSpace: 'pre-wrap'}}>{question.id}. {question.questionText} {question.required == '1' ? <span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}>*</span> : null}</span>}
        size="small"
        style={{ textAlign: "left", margin: "0.8rem", padding: "0rem" }}
      >
          <Space direction="vertical">

            <InputNumber value={inputState.value}
              onChange={(value) => {
                const isValid = value >= 0 && value !== null && value !== undefined;
                const currentQuestionID = question.id;
                const currentQuestionIndex = formState.currentSection.questions.findIndex(question => question.id == currentQuestionID);
                const bIsNextQuesNumberGroup = formState.currentSection.questions[currentQuestionIndex + 1].type == "numberGroup";

                if(isValid == true){

                  setInputState({value: value})

                  if(invokedTo == "edit"){
                    
                    if(bIsNextQuesNumberGroup == true){
                      formState.formResponse = { ...formState.formResponse, ['question-' + question.id]: value, ['question-' + (question.id + 1)]: bIsNextQuesNumberGroup == true ? undefined : formState.formResponse['question-' + (question.id + 1)] };
                    } else {
                      formState.formResponse = { ...formState.formResponse, ['question-' + question.id]: value};
                    }

                    setMainFormState(prevState => ({
                      ...prevState,
                      formResponse: formState.formResponse
                    }));

                  } else {
                    setFieldValue(`question-${question.id}`, isValid ? value : undefined); // Update the form value

                    if(bIsNextQuesNumberGroup == true){
                      setFieldValue(`question-${(question.id + 1)}`, undefined); // Update the form value
                    }
                    setInputState({value: isValid ? value : null})

                  }
                } else{

                  setInputState({value: null})

                  if(invokedTo == "edit"){
                    if(bIsNextQuesNumberGroup == true){
                      formState.formResponse = { ...formState.formResponse, ['question-' + question.id]: value, ['question-' + (question.id + 1)]: bIsNextQuesNumberGroup == true ? undefined : formState.formResponse['question-' + (question.id + 1)] };
                    } else {
                      formState.formResponse = { ...formState.formResponse, ['question-' + question.id]: value};
                    }                    
                    
                    setMainFormState(prevState => ({
                      ...prevState,
                      formResponse: formState.formResponse
                    }));
                  } else {
                    setFieldValue(`question-${question.id}`, undefined); // Update the form value
                    if(bIsNextQuesNumberGroup == true){
                      setFieldValue(`question-${(question.id + 1)}`, undefined); // Update the form value
                    }

                  }
                }
              }}
              min={0} 
              // max={100}
              placeholder={invokedTo == "view" ? "" : "Your Answer"} autoSize 
              style={{ marginLeft: "1.5rem", marginTop: "0.5rem", width: "100%" }}
              disabled={invokedTo == "view" ? true : false}
              />
              { invokedTo != "view" &&
                <div style={{ marginTop: "2rem" }}>
                  <Button type="text"
                    onClick={() => {
                      if(invokedTo == "edit"){

                      const { ['question-' + question.id]: removedKey, ...updatedFormResponse } = fieldValues;

                        setMainFormState(prevState => ({
                          ...prevState,
                          formResponse: updatedFormResponse
                        }));
                      } else {
                        setFieldValue(`question-${question.id}`, undefined)
                      }
                      setInputState({ ...inputState, value: undefined }); // Clear the selected value
                    }}
                    style={{ color: "gray", position: "absolute", bottom: "0", right: "0", marginBottom: "0.8rem", marginRight: "0.8rem" }}
                  >
                    {t(IDS_ClearSelection)}
                  </Button>
                </div>
              }
          </Space>
      </Card>
    </div>
  );

}

// Function to generate questions of type - Input number group
const InputNumberGroupQuestion = ({ formState, setMainFormState, question, fieldValues, setFieldValue, t, invokedTo }) => {
  const [inputGroupValues, setInputGroupValues] = useState(
    fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined
      ? fieldValues[`question-${question.id}`] 
      : Array(question.options.length).fill(0)
  );

  useEffect(() => {
    setInputGroupValues(
      fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined &&
      fieldValues.hasOwnProperty(`question-${question.id-1}`) && fieldValues[`question-${question.id-1}`] != null && fieldValues[`question-${question.id-1}`] != undefined 
        ? fieldValues[`question-${question.id}`] 
        : Array(question.options.length).fill(0)
    );
  }, [question, fieldValues]);

  const handleInputChange = (value, index) => {
    const newValues = [...inputGroupValues];
    newValues[index] = value;

    setInputGroupValues(newValues);

    if(invokedTo == "edit"){

      formState.formResponse = { ...formState.formResponse, ['question-' + question.id]: newValues };
      setMainFormState(prevState => ({
        ...prevState,
        formResponse: formState.formResponse
      }));
    } else {
      setFieldValue(`question-${question.id}`, newValues); // Update the form value
    }
  
  };

  return (
    <div>
      <Card
        title={
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {question.id}. {question.questionText} {question.required === '1' ? <span style={{ color: "var(--errorColor)", fontSize: "1rem", marginRight: "0.2rem" }}>*</span> : null}
          </span>
        }
        size="small"
        style={{ textAlign: "left", margin: "0.8rem", padding: "0rem" }}
      >
        {question.options.map((option, optIndex) => (
          <div key={optIndex} style={{ width: '100%', paddingLeft: window.innerWidth <= 576 ? "0rem" : "2rem" }}>
            <Row gutter={[16, 16]} style={{ marginBottom: 16, paddingLeft: window.innerWidth <= 576 ? "0rem" : "1rem" }}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <label>{option.text}</label>
              </Col>
              <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                <InputNumber
                  value={inputGroupValues[optIndex]}
                  max={
                    fieldValues[`question-${question.id - 1}`] !== undefined &&
                    fieldValues[`question-${question.id - 1}`] > 0
                      ? fieldValues[`question-${question.id - 1}`]
                      : 0
                  }
                  defaultValue={0}
                  onChange={(value) => handleInputChange(value, optIndex)}
                  style={{ width: '100%', color: "black" }}
                  disabled={
                    invokedTo === "view"
                      ? true
                      : invokedTo === "fill" &&
                        (fieldValues[`question-${question.id - 1}`] === undefined ||
                          fieldValues[`question-${question.id - 1}`] === 0)
                      ? true
                      : invokedTo === "edit" &&
                        (formState.formResponse[`question-${question.id - 1}`] ===
                          undefined ||
                          formState.formResponse[`question-${question.id - 1}`] === 0)
                      ? true
                      : false
                  }
                  addonAfter={`Out of ${
                    invokedTo === "edit" &&
                    formState.formResponse[`question-${question.id - 1}`] !== undefined &&
                    formState.formResponse[`question-${question.id - 1}`] > 0
                      ? formState.formResponse[`question-${question.id - 1}`]
                      : fieldValues[`question-${question.id - 1}`] !== undefined &&
                        fieldValues[`question-${question.id - 1}`] > 0
                      ? fieldValues[`question-${question.id - 1}`]
                      : 0
                  }`}
                />
              </Col>
            </Row>

            {optIndex != question.options.length-1 &&
              <hr style={{ width: "95%" }} />
            }
          </div>         
        ))}
        { invokedTo != "view" &&
            <div style={{ marginTop: "2rem" }}>
              <Button type="text"
                onClick={() => {
                  if(invokedTo == "edit"){

                    const { ['question-' + question.id]: removedKey, ...updatedFormResponse } = fieldValues;

                    setMainFormState(prevState => ({
                      ...prevState,
                      formResponse: updatedFormResponse
                    }));
                  } else {
                    setFieldValue(`question-${question.id}`, undefined)
                  }
                  const clearedValues = Array(question.options.length).fill(0);
                  setInputGroupValues(clearedValues);
              
                }}
                style={{ color: "gray", position: "absolute", bottom: "0", right: "0", marginBottom: "0.8rem", marginRight: "0.8rem", marginTop: "2rem" }}
              >
                {t(IDS_ClearSelection)}
              </Button>
            </div>
          }
      </Card>
    </div>
  );
};

// Function to generate questions of type - checkbox
const CheckboxQuestion = ({ formState, setMainFormState, question, fieldValues, setFieldValue, t, invokedTo }) => {


  const [checkboxState, setCheckboxState] = useState(
    {
      value: fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined 
              ? fieldValues[`question-${question.id}`] 
              : [],
    });
    const [otherInputVisible, setOtherInputVisible] = useState(false);
    const [otherInputValue, setOtherInputValue] = useState('');


    useEffect(() => {
 
      if(fieldValues.hasOwnProperty(`question-${Question.id}`) && fieldValues[`question-${Question.id}`] != null && fieldValues[`question-${Question.id}`] != undefined ) {
        let otherOption = question.options.find(option => option.text === "Other" || option.text === "अन्य" || option.text === "इतर");

        if(fieldValues[`question-${question.id}`].find((element) => typeof element === "string") || (otherOption != null && fieldValues[`question-${question.id}`].includes(otherOption.id))) {

          setOtherInputVisible(fieldValues[`question-${question.id}`].includes(otherOption.id) ? true : false);
          setOtherInputValue(fieldValues[`question-${question.id}`].find((element) => typeof element === "string"));

          const index = fieldValues[`question-${question.id}`].findIndex((element) => typeof element === "string");

          // if field values contains string, remove that string from the array of particular function
          // and update CheckboxState
          if (index !== -1) {
            fieldValues[`question-${question.id}`].splice(index, 1, otherOption.id);
          }

          setCheckboxState({ value: fieldValues[`question-${question.id}`] });

        } else {

          setCheckboxState({ value: fieldValues[`question-${question.id}`] });
          setOtherInputVisible(false);
          setOtherInputValue(null);
        }
      } else {
        setCheckboxState({ value: []});

      }
    }, [question]);

    const Question = question;

    return (
      <div>
        <Card
          title={<span style={{whiteSpace: 'pre-wrap'}}>{question.id}. {question.questionText} {question.required == '1' ? <span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}>*</span> : null}</span>}
          size="small"
          style={{ textAlign: "left", margin: "0.8rem", padding: "0rem" }}
        >
          <Checkbox.Group  value={checkboxState.value} onChange={(values) => {
                setCheckboxState({value: values});
                let otherOption = question.options.find(option => option.text === "Other" || option.text === "अन्य" || option.text === "इतर");

                if(otherOption != null && otherOption != undefined){

                  setOtherInputVisible(values.includes(otherOption.id) == true ? true : false);

                }
                if(invokedTo == "edit"){
                  formState.formResponse[`question-${question.id}`] = values.length > 0 ? values : undefined;
                    setMainFormState(prevState => ({
                      ...prevState,
                      formResponse: formState.formResponse
                    }));
                } else {

                  setFieldValue(`question-${question.id}`, values.length > 0 ? values : undefined); // Update the form value
                }                
              }}>
            <Space direction="vertical">
              {question.options.map((option, optIndex) => (
                <div key={optIndex}>
                  <Checkbox value={option.id} disabled={invokedTo == "view" ? true : false} 
                  >
                    {option.text}
                  </Checkbox>
                  {(option.text === "Other" || option.text === "अन्य" || option.text === "इतर") && otherInputVisible && fieldValues[`question-${Question.id}`] != undefined && (
                  <Input
                    value={otherInputValue}
                    disabled={invokedTo == "view" ? true : false}
                    onChange={(e) => {
                        setOtherInputValue(e.target.value);
                        setFieldValue(`question-${question.id}`, [...checkboxState.value, e.target.value]);
                      
                    }}
                    placeholder="Enter other"
                    style={{ marginLeft: "1.5rem", marginTop: "0.5rem", whiteSpace: 'pre-wrap'}}
                  />
                )}
                </div>
              ))}
            </Space>
          </Checkbox.Group>
          { invokedTo != "view" &&
            <Button type="text"
              onClick={() => {
                if(fieldValues[`question-${question.id}`] != undefined && fieldValues[`question-${question.id}`].find((element) => typeof element === "string")) {

                  setOtherInputVisible(false);
                  setOtherInputValue("");
                  setCheckboxState({ ...checkboxState, value: null }); // Clear the selected value

                  if(invokedTo == "edit"){

                    const { ['question-' + question.id]: removedKey, ...updatedFormResponse } = fieldValues;

                    setMainFormState(prevState => ({
                      ...prevState,
                      formResponse: formState.formResponse
                    }));
                  } else {
                    setFieldValue(`question-${question.id}`, undefined)
                  }
        
                } else {

                  if(invokedTo == "edit"){

                    const { ['question-' + question.id]: removedKey, ...updatedFormResponse } = fieldValues;

                    setMainFormState(prevState => ({
                      ...prevState,
                      formResponse: updatedFormResponse
                    }));
                  } else {
                    setFieldValue(`question-${question.id}`, undefined)
                  }                  
                  setCheckboxState({ ...checkboxState, value: null }); // Clear the selected value


                }
              }}
              style={{ color: "gray", position: "absolute", bottom: "0", right: "0", marginBottom: "0.8rem", marginRight: "0.8rem" }}
            >
              {t(IDS_ClearSelection)}
            </Button>
          }
        </Card>
      </div>
    );

}

// Function to generate questions of type - file/Img
const FileQuestion = ({ formState, setMainFormState, question, fieldValues, setFieldValue, invokedTo, EntityLocnID, getFrmResToViewSanitationFrm }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const context = useContext(AppRelevantDataContext);
  let t = context.t;
  const [ImgFileState, setImgFileState] = useState(
    {
      value: fieldValues.hasOwnProperty(`question-${question.id}`) && fieldValues[`question-${question.id}`] != null && fieldValues[`question-${question.id}`] != undefined 
              ? fieldValues[`question-${question.id}`] 
              : [],
      arrUploadedModifiedFileName: [],
      signedUrl: "",
      errors: {
        others: ""
      }
    });

  useEffect(() => {
    getFrmResToViewSanitationFrm();
  },[ImgFileState.value]);

  // Function to show msg
  const showMessage = (msg) => {

    messageApi.open({
      type: 'warning',
      content: msg,
      duration: 5,
    });
  };
  
  const uploadSanitationImgsIntoS3Bucket = async (file) => {

    let modifiedState = ImgFileState;
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    modifiedState.errors.others = [];
    let fileData = new FormData(); 
    fileData.append('multipleImgFilesToUploaded', file);

    // Show spinner to restrict the user to perform any actions.
    appRelevantDataContextValue.onChangeProcessingReq(true);

    await axios.post(`${getAPIHostURL()}/wclient/uploadSanitationImgsIntoS3Bucket`, fileData)
    .then(response => {

        if(response.data.code == "SUCCESS") {

            if(response.data.uploadedFile == null || response.data.uploadedFile.length <= 0 ) {
              // Remove spinner and allow user to perform action.
              appRelevantDataContextValue.onChangeProcessingReq(false);   
              showMessage(t(IDS_FiledToUploadSaniImgOnS3));

              return false;

            } else {

              let arrMultipleUploadedFileInfo = response.data.uploadedFile;

              let arrUploadedModifiedFileName = [];

              let updatedCurrImgList = [];
              updatedCurrImgList = ImgFileState.value; 

              for(let i = 0;  i<arrMultipleUploadedFileInfo.length; i++) {

                  if (arrMultipleUploadedFileInfo[i]["key"] != null && arrMultipleUploadedFileInfo[i]["key"].length > 0 ){
                      arrUploadedModifiedFileName.push(arrMultipleUploadedFileInfo[i]["key"]);
                      updatedCurrImgList.push(arrMultipleUploadedFileInfo[i]["key"]);

                  }
              }
          
              // setFieldValue(`question-${question.id}`, updatedCurrImgList);
              
              // if the file is uploaded successfully into the s3 bucket then call this.updateFileTypeQuestionResInDB()
              updateFileTypeQuestionResInDB(updatedCurrImgList);

            }
        } else {

            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            if(response.data.code == 'UPLOAD_ERROR') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here.")
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            showMessage(t(IDS_FiledToUploadSaniImgOnS3));

            return false;
        }
        setImgFileState({...modifiedState});
      })
    .catch(error => {

      console.log("uploadSanitationImgsIntoS3Bucket error = ",error);
        // Remove spinner and allow user to perform action.
        appRelevantDataContextValue.onChangeProcessingReq(false);
        showMessage(t(IDS_FiledToUploadSaniImgOnS3));

        console.log(error);
        if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setImgFileState({...modifiedState});
          } 
        return false;
    });
  } 
  
  const updateFileTypeQuestionResInDB = async (updatedCurrImgList) => {

    let modifiedState = ImgFileState;
    modifiedState.errors.others = [];

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let jsobBody = {
      arrModifiedFileName: updatedCurrImgList,
      questionID: question.id,
      EntityLocID: EntityLocnID, 
    }

    await axios.post(`${getAPIHostURL()}/wclient/updateImgFileTypeQuestionResInDB`, jsobBody)
    .then(response => {

        if(response.data.code == "SUCCESS") {

          // Remove spinner and allow user to perform action.
          appRelevantDataContextValue.onChangeProcessingReq(false);

          setFieldValue(`question-${question.id}`, updatedCurrImgList);
          setImgFileState({...modifiedState});
          return true;

        } else {
            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            if(response.data.code == 'UPLOAD_ERROR') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here.")
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            setImgFileState({...modifiedState});
            showMessage(t(IDS_FiledToUploadSaniImgOnS3));
            return false;
        }
    })
    .catch(error => {
        // Remove spinner and allow user to perform action.

        console.log(error);
        if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          modifiedState.errors.others = t(IDS_RegistNetworkError);
        } 
        setImgFileState({...modifiedState});
        showMessage(t(IDS_FiledToUploadSaniImgOnS3));
        return false;
    });

  }

  const getSnapshot = async (selectedUrlBucketName, singleFileName) => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = ImgFileState;
    modifiedState.errors.others = [];

    const jsonBody = {
      ID: "SanitationImgs",
      S3BucketName:  selectedUrlBucketName,
      FileNameToView: singleFileName
    }

    modifiedState.signedUrl = "";

    axios.post( `${getAPIHostURL()}/wclient/getFilesFromS3Bucket`, jsonBody )
    .then(response => {
            
        if(response.data.code == 'SUCCESS') {

          if(response.data.signedUrl == null || response.data.signedUrl.length <= 0) {
            alert(t(IDS_NoSnapshotFound));

          } else {
            modifiedState.signedUrl = response.data.signedUrl;

            if(modifiedState.signedUrl != null && modifiedState.signedUrl.length > 0) {
              setPreviewImage(modifiedState.signedUrl);
              setPreviewVisible(true);
              setPreviewTitle(singleFileName);
              setImgFileState({...modifiedState});
            }
          }

        } else {
          if (response.data.code == 'SQL_ERROR' ) {
              // Tell the user that Server is experiencing errors
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else if (response.data.code == 'REQ_PARAMS_MISSING'){
              modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
          } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          setImgFileState({...modifiedState});
        }
      
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError);
      setImgFileState({...modifiedState});
    }); 
  }

  const handlePreviewImg = async (fileName) => {

    getSnapshot(SANITATION_BUCKET_NAME, fileName);

  };

  const deleteImageFromS3 = async (fileName) => {
    let modifiedState = ImgFileState;
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let updatedCurrImgList = [];
    modifiedState.errors.others = [];

    const jsonBody = {
      inBucket:  SANITATION_BUCKET_NAME,
      inFileName: fileName
    }

    axios.post( `${getAPIHostURL()}/wclient/deleteImageFromS3Bucket`, jsonBody )
    .then(response => {
            
        if(response.data.code == 'SUCCESS') {

        updatedCurrImgList = modifiedState.value; 

        updatedCurrImgList = updatedCurrImgList.filter(item => item !== fileName);

        modifiedState.value = updatedCurrImgList;
      
        setFieldValue(`question-${question.id}`, updatedCurrImgList);
        setImgFileState({...modifiedState});
        updateFileTypeQuestionResInDB(updatedCurrImgList);
          
        } else {
          if (response.data.code == 'SQL_ERROR' ) {
              // Tell the user that Server is experiencing errors
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else if (response.data.code == 'REQ_PARAMS_MISSING'){
              modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
          } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          // Display the specific error message for Reset Password failure
          setImgFileState({...modifiedState});
        }
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError);
      setImgFileState({...modifiedState});
    }); 

  };
 
  const compressImage = async (file, maxWidth, maxHeight, quality = 0.7) => {
    return new Promise(async (resolve, reject) => {
      if (!file || !(file instanceof Blob) || !(file instanceof File)) {
        reject(new Error('Invalid file provided.'));
        return;
      }
  
      const fileType = file.type;
  
      // Validate the file type
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
      if (!validTypes.includes(fileType)) {
        reject(new Error('Unsupported file type.'));
        return;
      }
  
      // Get the base64 data URL of the image
      const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };
  
      const base64Image = await getBase64(file);
  
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        let width = img.width;
        let height = img.height;
  
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
  
        ctx.drawImage(img, 0, 0, width, height);
  
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Compression failed.'));
            return;
          }
          resolve(new File([blob], file.name, { type: fileType, lastModified: Date.now() }));
        }, fileType === 'image/png' ? 'image/png' : 'image/jpeg', quality);
      };
  
      img.onerror = () => reject(new Error('Image loading failed.'));
      img.src = base64Image;
    });
  };

  const beforeUpload = async (file, {onSuccess, onError}) => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    let FileNames = [];

    for(let i = 0; i<ImgFileState.value.length; i++){
      FileNames.push(ImgFileState.value[i].split('_').pop());
    }
    
    const isNamePresent = FileNames.includes(file.name);
    const isFileTypePdf = file.name.split('.').pop().toLowerCase() == "pdf";

    // File is'nt already uploaded
    if(isNamePresent == false) {

      if(isFileTypePdf == false){

        // If file size is greater then 1MB, compress the file before uploading on s3
        if(file.size > 1 * 1024 * 1024){
          try {
            const compressedFile = await compressImage(file, 1200, 1600, 0.7);
            await uploadSanitationImgsIntoS3Bucket(compressedFile);

            // Proceed with uploading the compressed file or other logic
          } catch (error) {
            console.error('Compression error:', error);
            // Handle the error, e.g., show a notification to the user
            alert(`Error compressing the file: ${error.message}`);
          }
        } else {
          await uploadSanitationImgsIntoS3Bucket(file);

        }

      } else if (isFileTypePdf == true){
        // File type is PDF

        // If size of PDF file is greater than 2MB, show alert.
        if(file.size > 2 * 1024 * 1024){
          alert(t(IDS_PdfMaxSizeExceeded));
        } else {
          await uploadSanitationImgsIntoS3Bucket(file);

        }

      }

    } else {
      alert(t(IDS_AlertFileIsAlreadyUploaded));
    }
  }

  const uploadButton = (
    <div>
      <Button icon={<UploadOutlined />} disabled={invokedTo == "view" ? true : false}>Upload</Button>
    </div>
  );

  const getModalTitleStyle = () => {
    return {
      fontSize: window.innerWidth <= 576 ? '0.6rem' : '1.5rem', // Adjust font size for mobile
      flex: '1',
      textAlign: 'center', // Center the title
      marginRight: window.innerWidth <= 576 ? '4rem' : '2rem', // Adjust margin for mobile
    };
  };

  return (
    
    <div>
    {contextHolder}
    <Card
          title={<span style={{whiteSpace: 'pre-wrap'}}>{question.id}. {question.questionText} {question.required == '1' ? <span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}>*</span> : null}</span>}
          size="small"
          style={{ textAlign: "left", margin: "0.8rem", padding: "0rem" }}
        >
    <>
      <Upload
        beforeUpload={beforeUpload}
        fileList={[]}
        accept=".jpeg,.jpg,.png,.gif,.bmp,.tiff"
        multiple={true}
        maxCount={8}
        // disabled={invokedTo == "view" ? true : false}
        // disabled={true}
      >
        {ImgFileState.value.length >= 8 ? null : uploadButton}
      </Upload>

      {ImgFileState.value != null && ImgFileState.value.length > 0 && 
        <List
          size="small"
          style={{marginTop: "0.5rem"}}
          bordered
          dataSource={ImgFileState.value}
          renderItem={(item) => 
            <List.Item style={{ padding: '8px 16px' }}>
              <Row style={{ width: '100%' }} gutter={[16, 16]}>
                <Col xs={16} sm={16} md={16} lg={16}   style={{ display: 'flex', alignItems: 'center' }} // Add flexbox styles here
                >
                  <Typography.Link
                    style={{ wordWrap: 'break-word', wordBreak: 'break-all', cursor: 'pointer'}}    
                    onClick={() => {handlePreviewImg(item)}}    
                  >
                    {item}
                  </Typography.Link>
                </Col>
                {/* <Col xs={8} sm={8} md={8} lg={8} style={{ textAlign: 'right' }}>
                  <Row justify="end" gutter={[8, 8]}>
                    <Col>
                      <Button
                        type="link"
                        style={{color: "red"}}
                        onClick={() => {
                          if(!window.confirm(t(IDS_AlertBeforeDeletingImgFromS3, item))) {
                            return;
                          } else {
                            deleteImageFromS3(item)

                          }
                          
                          }}
                      >
                        <ImBin />
                      </Button>
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </List.Item>
          }
        />
      }

      {ImgFileState.errors.others != null && ImgFileState.errors.others.length > 0 && 
        <p className='addCustError' style={{textAlign: "center"}}>{ImgFileState.errors.others}</p>}

      <Modal size="xl" isOpen={previewVisible} backdrop={'static'}>
          <ModalHeader toggle={() => setPreviewVisible(false)} style={{whiteSpace: "pre-wrap", paddingRight: window.innerWidth <= 576 ? "3rem" : '2rem',}}>
          <span style={getModalTitleStyle()}>Sanitation Image Snapshot - {previewTitle}</span>
          </ModalHeader> 
          <ModalBody>
            <div>

              {previewImage.split('.').pop().toLowerCase() == "pdf" ? (
                <iframe
                  src={previewImage}
                  style={{ width: "100%", height: "auto", maxHeight: "50rem", maxWidth: "100%" }}

                  frameBorder="0"
                ></iframe>
              ) : (
                <img style={{ width: "100%", height: "auto", maxHeight: "50rem", maxWidth: "100%" }}
                  src={previewImage} alt="Preview" 
                />
              )}

            </div>
          </ModalBody>
      </Modal>
    </>
    </Card>
  </div>
  );

};



export default VcSanitationMappingForm; 