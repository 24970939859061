import React, { Component } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import '../CSS/VcHhmAPIs.css';
import "react-circular-progressbar/dist/styles.css";

class VcHhmAPIs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="desktopView" >
          <div style={{height:"100%"}} >
          <div className="col-xs-12 col-sm-12 col-md-12  sddSingleParam"
                  style={{height:"88vh", display:"flex", justifyContent:"center", alignItems:"center",width:'100vw'}}
              >
                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8">
                    <div style={{fontSize:"1.2rem", color: 'var(--secondaryColor)'}}>
                      HHM APIs Feature will be available soon.
                    </div>  
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

VcHhmAPIs.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods
export default VcHhmAPIs;
