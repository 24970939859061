import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AppRelevantDataContext} from '../../AppContext';
import VcDefinePcbTypeAndVersion from './VcDefinePcbTypeAndVersion';
import VcProductItemImpParts from './VcProductItemImpParts';
import VcHhmAPIs from './VcHhmAPIs';
import axios from 'axios';
import App from '../../App';
import { getAPIHostURL, getAllDeviceDataRefreshTime } from '../../ClientConfig';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import VcCustomer from './VcCustomer';
import { PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER, PVG_SALES_EXECUTIVE, PVG_SALES_SUPERVISOR, 
         PVG_BACK_OFFICE_EXECUTIVE, PVG_PRODUCT_OWNER, PVG_FIELD_ENGINEER, PRODUCT_OWNER_ROUTES, 
         PRODUCTION_SUPERVISOR_AND_WORKER_ROUTES, FIELD_ENG_ROUTES, SALES_SUPERVISOR_AND_EXECUTIVE_ROUTES, 
         BACK_OFFICE_EXECUTIVE_ROUTES, TOILET_ADMIN_ROUTES, PVG_TOILET_ADMIN, PVG_FWMR_FLASHING_ADMIN, 
         PVG_SW_DEV_ADMIN, SW_DEV_ADMIN_ROUTES, TREE_DEFINATION_ADMIN_ROUTES, PVG_TREE_DEFINITION_ADMIN, PVG_EXTERNAL_API_USER, EXTERNAL_API_USER_ROUTES } from '../../VcConstants';
import VcProductItemState from './VcProductItemState';
import VcProduct from './VcProduct';
import VcProductLine from './VcProductLine';
import VcStagewiseProdStat from './VcStagewiseProdStat';
import VcDeviceStateHistory from './VcDeviceStateHistory';
import VcTrackDevices from './VcTrackDevices';
import VcTrackRtcIssues from './VcTrackRtcIssues';
import VcDevcReplacement from './VcDevcReplacement';
import VcAllOrderDetailsInfo from './VcAllOrderDetailsInfo';
import VcProductSubscription from './VcProductSubscription';
import VcRentalProductDetails from './VcRentalProductDetails';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import VcRenewSubscription from './VcRenewSubscription';
import VcRenewHhmApiPkg from './VcRenewHhmApiPkg';
import VcTrackAppVersion from './VcTrackAppVersion';
import VcToiletRatingReport from './VcToiletRatingReport';
import VcUpdateNewAppVersion from './VcUpdateNewAppVersion';
import VcTreeDefinition from './VcTreeDefinition';
import VcOrganization from './VcOrganization';
import VcAddOrRemoveRawDataParams from './VcAddOrRemoveRawDataParams'
import VcChangeModel from './VcChangeModel';
import VcAllSubscriptionDetailsInfo from './VcAllSubscriptionDetailsInfo';
import VcBlockOrUnblockDeviceData from './VcBlockOrUnblockDeviceData';
import VcInactiveFieldDevices from './VcInactiveFieldDevices';
import ComplaintRegistrationForm from './ComplaintRegistrationForm';
import VcComplaintDetailsAndResolveComplaint from './VcComplaintDetailsAndResolveComplaint';
import VcCancelSubscription from './VcCancelSubscription';
import VcAllStateDashboard from './VcAllStateDashboard';
import VcAllTakenBackRentalDevices from './VcAllTakenBackRentalDevices';
import VcRawDataAnalysis from './VcRawDataAnalysis';
import VcCalibAppLinks from './VcCalibAppLinks';
import VcSmartHmmAppLinks from './VcSmartHmmAppLinks';
import VcSideNav from './DeviceHome/VcSideNav';
import VcNavBar from "./VcNavbar";

class VcCrmHome extends Component {
    constructor(props) {
        super(props)
        this.handleResize = this.handleResize.bind(this);
    
        this.state = {
            PrivilegeEncKey: "",
            objPrivilege: {},
            EnctyptedPrivilege: "",
            arrMergedRoutesBasedOnLoggedInUsrPvg: [],
            showDrawer: false,
            windowWidth: window.innerWidth,
            errors: {
                others: "",
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

        if(appRelevantDataContextValue.language.languageToViewIn != "en") {
            appRelevantDataContextValue.onChangeLanguageToViewIn("en")
        }
    
        if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
          // User login information is not present in appRelevantDataContext.
          // Try to get this value if present in LocalStorage and update the app state (so that
          // re-render will happen). If app context data not present in LocalStorage then
          // show the Login Page
          if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
            // App Relevant Data Context not present in LocalStorage as well.
            if (this.props.history == null || this.props.history.length <= 0) {
                window.location.replace('/');
            } else {
                this.props.history.replace('/');
            }
          } else {
            // App Relevant Data Context loaded from LocalStorage at AppLeval.
            // Re-render will happen automatically (no action required).
          }
    
        } else {
            this.checkPrivilegeOfLoggedInUser();
        }
        
        // User is viewing UI for "trackDevices" and he suddenly refresh the page 
        // then only clear the selected node info and stored tracked Devices info.
        // as this will help to avoid calling render infinite tym.
        appRelevantDataContextValue.onChangeTrackDeviceInfo([], {}, "");
        appRelevantDataContextValue.onSelectedDevice("", "", false);   
        appRelevantDataContextValue.onEnabledTreeStructureSettings(false);    
    }

    componentDidUpdate () {
        let currentState = this.state;
        let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext
    
        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0
        ) {
            console.log("VcAdminHome:componentDidUpdate - First time render. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }
    
        if(appRelevantDataContextValue.loggedInUserPrivilege.Privilege != currentState.EncryptedPvg) {
          // on Refresh values stored in LocalStorage doesnot refelect into appcontext hence 
          // we need to call componenentDidUpdate which again take privilege from context and store into state.
          this.checkPrivilegeOfLoggedInUser();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    
    handleResize = () => {
        if ((window.innerWidth <= 570 && this.state.windowWidth > 570) || (window.innerWidth > 570 && this.state.windowWidth <= 570)) {
            this.setState({ ...this.state, windowWidth: window.innerWidth });
        } 
    }

    checkPrivilegeOfLoggedInUser = () => {
        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  

        let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
        modifiedState.EncryptedPvg = encryptedPrivileges;
        modifiedState.selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        modifiedState.selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {
               if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                    modifiedState.errors.others = `Unable to get encryption key.`;
               } else {
                   modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

                //    let bytes  = CryptoJS.AES.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
                   let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);

                //    let strPrivilege = bytes.toString(CryptoJS.enc.Utf8);
                   let strPrivilege = bytes.toString(enc);

                   try {
                        modifiedState.objPrivilege = JSON.parse(strPrivilege);

                        let arrLoggedInUsrPvg = [];

                        for (const [key, value] of Object.entries(modifiedState.objPrivilege)) {
                            if(value == true) {
                                arrLoggedInUsrPvg.push(key);
                            }
                        }
                        
                        modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg = [];
                        for(let i = 0; i < arrLoggedInUsrPvg.length; i++ ) {
                            if(arrLoggedInUsrPvg[i] == PVG_PRODUCTION_SUPERVISOR || arrLoggedInUsrPvg[i] == PVG_PRODUCTION_WORKER) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...PRODUCTION_SUPERVISOR_AND_WORKER_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_SALES_SUPERVISOR || arrLoggedInUsrPvg[i] == PVG_SALES_EXECUTIVE) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...SALES_SUPERVISOR_AND_EXECUTIVE_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_BACK_OFFICE_EXECUTIVE) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...BACK_OFFICE_EXECUTIVE_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_PRODUCT_OWNER) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...PRODUCT_OWNER_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_FIELD_ENGINEER) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...FIELD_ENG_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_TOILET_ADMIN) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...TOILET_ADMIN_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_SW_DEV_ADMIN) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...SW_DEV_ADMIN_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_TREE_DEFINITION_ADMIN) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...TREE_DEFINATION_ADMIN_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == BACK_OFFICE_EXECUTIVE_ROUTES) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...BACK_OFFICE_EXECUTIVE_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_EXTERNAL_API_USER) {
                                modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg.push(...EXTERNAL_API_USER_ROUTES);
                            } else if(arrLoggedInUsrPvg[i] == PVG_FWMR_FLASHING_ADMIN) {
                                continue;
                            } else {
                                // modifiedState.arrMergedRoutesBasedOnLoggedInUsrPvg = [];
                                continue;
                            }
                        }
                   } catch(e) {
                        console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                   }
               }

            } else {
                if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch(err => {
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                console.log(modifiedState.errors.others);
                this.setState(modifiedState);
            }
        }) 
    }

    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        // let pathname = this.props.location.pathname;

        if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
            // User login information is not present in appRelevantDataContext.
    
            // Next re-render will happen automatically when the AppState is updated again 
            // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
            return App.renderLoadingPage();
        }

        let urlPathName = window.location.pathname;
        let showComponentBasedOnRoutes = this.state.arrMergedRoutesBasedOnLoggedInUsrPvg.includes(urlPathName) ? true : false;

        // Getting the Context value for the invokedLoginPgInfo, loginPagePath and showNextPagePathAfterLogin.
        let invokedLoginPgInfo = appRelevantDataContextValue.invokedLoginPgInfo;
        let loginPagePath = invokedLoginPgInfo.loginPagePath;
        let showNextPagePathAfterLogin = invokedLoginPgInfo.showNextPagePathAfterLogin;
        let bHideBasedOnLoginPathKeyValue =  ((invokedLoginPgInfo != null && Object.keys(invokedLoginPgInfo).length > 0) &&
          (loginPagePath != null && loginPagePath.length > 0) && (showNextPagePathAfterLogin != null 
            && showNextPagePathAfterLogin.length > 0)) ? true : false;

        return (
            (this.state.windowWidth <= 570) ?
                <div className='d-flex'>  
                    <div className="treeAndContentCRM">
                        <VcNavBar />
                        {/* <div className="BothComponentsFullScreenCSS"> </div> */}
                        <div className="container-fluid col-lg-12 col-md-12 col-sm-12">
                            {this.state.arrMergedRoutesBasedOnLoggedInUsrPvg.length <= 0 ? 
                                <div> 
                                    <p className = "customerTableHeading" style={{color:"var(--primaryColor)"}}>
                                        Please Wait.
                                    </p> 
                                </div>
                                :
                                showComponentBasedOnRoutes == true && bHideBasedOnLoginPathKeyValue == false ?
                                <div>
                                    <Routes>

                                        <Route path="/hhmApis" 
                                            element={<VcHhmAPIs key = "/hhmApis"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/track/trackDevices" 
                                            element={<VcTrackDevices key = "/track/trackDevices"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/track/trackRtcIssues" 
                                            element={<VcTrackRtcIssues key = "/track/trackRtcIssues"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/report/allStatesDashboard"
                                            element={<VcAllStateDashboard key = "/report/allStatesDashboard"
                                                objPrivilege = {this.state.objPrivilege}
                                                invokedFrom="allStatesDashboard" />}
                                        />

                                        <Route path="/more/rawDataParamsAction/addRawDataParams"
                                            element={<VcAddOrRemoveRawDataParams key = "/more/rawDataParamsAction/addRawDataParams"
                                                invokedFrom="AddRawDataParameters" />} 
                                        />

                                        <Route path="/more/rawDataParamsAction/removeRawDataParams"
                                            element={<VcAddOrRemoveRawDataParams key = "/more/rawDataParamsAction/addRawDataParams"
                                                invokedFrom="RemoveRawDataParameters" />} 
                                        />

                                        <Route path="/track/trackAppVersion"
                                            element={<VcTrackAppVersion key = "/track/trackAppVersion"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/more/productPartAction/definePcbTypeAndVersion" 
                                            element={<VcDefinePcbTypeAndVersion key = "/more/productPartAction/definePcbTypeAndVersion" />} 
                                        />

                                        <Route path="/more/productPartAction/productItemImpParts"
                                            element={<VcProductItemImpParts key = "/more/productPartAction/productItemImpParts" />}
                                        />

                                        <Route path="/action/productItemState"
                                            element={<VcProductItemState key = "/action/productItemState" 
                                                invokedFrom="ProductItemStateHistory" 
                                                objPrivilege = {this.state.objPrivilege} />}
                                        />   

                                        <Route path="/action/updateNewAppVersion"
                                            element={<VcUpdateNewAppVersion key = "/action/updateNewAppVersion" />}
                                        />

                                        <Route path="/report/stagewiseProductStatus" 
                                            element={<VcStagewiseProdStat ShowProductionCount = {false} key = "/report/stagewiseProductStatus" /> } 
                                        />

                                        <Route path="/report/productionCount"
                                            element={<VcStagewiseProdStat ShowProductionCount = {true} key = "/report/productionCount" />} 
                                        />

                                        {/* <Route path="/report/currentProductionInventory"
                                            element={<VcCurrentProductInventory
                                            objPrivilege = {this.state.objPrivilege}
                                            invokedFrom="currentProductionInventory"
                                            key = "/report/currentProductionInventory" />} 
                                        />

                                        <Route path="/report/currentFieldDevices"
                                            element={<VcCurrentProductInventory
                                            invokedFrom="currentFieldDevices"
                                            objPrivilege = {this.state.objPrivilege}
                                            key = "/report/currentFieldDevices" />} 
                                        /> */}

                                        <Route path="/report/currentStateInventory"
                                            element={<VcAllStateDashboard
                                            invokedFrom="currentStateInventory"
                                            objPrivilege = {this.state.objPrivilege}
                                            key = "/report/currentStateInventory" />} 
                                        />

                                        <Route path="/report/deviceHistory"
                                            element={<VcDeviceStateHistory key = "/report/deviceHistory" />} 
                                        />

                                        <Route path="/report/allOrderDetails" 
                                            element={<VcAllOrderDetailsInfo key = "/report/allOrderDetails"
                                            invokedFromProp = "AllOrderDetails" />} 
                                        />

                                        <Route path="/report/allSubscriptionOrRentalDetails" 
                                            element={<VcAllSubscriptionDetailsInfo key = "/report/allSubscriptionOrRentalDetails" 
                                                invokedFrom = "AllSubscriptionDetails"
                                                objPrivilege = {this.state.objPrivilege}/>} 
                                        />

                                        <Route path="/report/allTakenBackRentalDevices" 
                                            element={<VcAllTakenBackRentalDevices key = "/report/allTakenBackRentalDevices"
                                            invokedFrom = "AllTakenBackRentalDevices"
                                            objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/report/rawDataAnalysis" 
                                            element={<VcRawDataAnalysis key = "/report/rawDataAnalysis"
                                            invokedFrom = "RawDataAnalysis"
                                            objPrivilege = {this.state.objPrivilege} />} 
                                        />
                                        
                                        <Route path="/report/salesDashboard" 
                                            element={<VcAllOrderDetailsInfo key = "/report/salesDashboard"
                                            invokedFromProp = "SalesDashboard"  />} 
                                        />

                                        <Route path="/customerOrder/customer" 
                                            element={<VcCustomer key = "/customerOrder/customer" />} 
                                        />

                                        <Route path="/customerOrder/createOrder" 
                                            element={<VcCustomer key = "/customerOrder/createOrder" propAction = "CreateOrder" 
                                                objPrivilege = {this.state.objPrivilege}  />}     
                                        />

                                        <Route path="/customerOrder/fulfillOrder" 
                                            element={<VcCustomer key = "/customerOrder/fulfillOrder" propAction = "FulFillOrder" 
                                                objPrivilege = {this.state.objPrivilege}  />}     
                                        />

                                        <Route path="/customerOrder/organization" 
                                            element={<VcOrganization key = "/customerOrder/organization" />} 
                                        />

                                        <Route path="/customerOrder/renewSubscription" 
                                            element={<VcRenewSubscription key = "/customerOrder/renewSubscription" />} 
                                        />

                                        {/* HMM Api feature is disabled.*/}
                                        {/* <Route path="/customerOrder/renewHHmApiPkg" 
                                            element={<VcRenewHhmApiPkg key = "/customerOrder/renewHHmApiPkg" />} 
                                        /> */}

                                        <Route path = "/customerOrder/CancelSubscription" 
                                            element={<VcCancelSubscription key = "/customerOrder/CancelSubscription" />}
                                        /> 

                                        <Route path = "/productAction/productLine" 
                                            element={<VcProductLine key = "/productAction/productLine" />} 
                                        />

                                        <Route path = "/report/viewToiletAndItsRatingInfo" 
                                            element={<VcToiletRatingReport key = "/report/viewToiletAndItsRatingInfo" 
                                                invokedFrom="invokedFromToiletReport" />
                                            } 
                                        />

                                        <Route path = "/report/viewPublicFootContInfo" 
                                            element={<VcToiletRatingReport key = "/report/viewPublicFootContInfo"  invokedFrom="invokedFromPublicCountReport"/>
                                            }
                                        />

                                        <Route path = "/productAction/productDetails" 
                                            element={<VcProduct key = "/productAction/productDetails" />}
                                        />
                                        
                                        <Route path = "/productAction/productSubscription" 
                                            element={<VcProductSubscription key = "/productAction/productSubscription" />}
                                        />

                                        <Route path = "/productAction/rentalProductDetails" 
                                            element={<VcRentalProductDetails key = "/productAction/rentalProductDetails" />}
                                        />

                                        <Route path="/deviceAction/deviceReplacementUpgrade" 
                                            element={<VcDevcReplacement key = "/deviceAction/deviceReplacementUpgrade" />}
                                        />

                                        <Route path = "/deviceAction/takeBackDemoDevice" 
                                            element={<VcProductItemState key = "/deviceAction/takeBackDemoDevice" 
                                                invokedFrom = "takeBackDemoDevice" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />

                                        <Route path = "/deviceAction/takeBackRentalDevice" 
                                            element={<VcProductItemState key = "/deviceAction/takeBackRentalDevice" 
                                                invokedFrom = "takeBackRentalDevice" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />

                                        <Route path="/deviceAction/recallSoldDevice" 
                                            element={<VcProductItemState key = "/deviceAction/recallSoldDevice" 
                                                invokedFrom = "recallSoldDevice" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path = "/deviceAction/moveDevcInDecommisionSt" 
                                            element={<VcProductItemState key = "/deviceAction/moveDevcInDecommisionSt" 
                                                invokedFrom = "moveDevcInDecommisionSt" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />
                                        
                                        <Route path = "/deviceAction/moveDevcInUnderRepairSt" 
                                            element={<VcProductItemState key = "/deviceAction/moveDevcInUnderRepairSt" 
                                                invokedFrom = "moveDevcInUnderRepairSt" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />

                                        <Route path="/deviceAction/readyForShipment" 
                                            element={<VcProductItemState key = "/deviceAction/readyForShipment" 
                                                invokedFrom = "readyForShipment" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/deviceAction/replaceFulfilledFaultyDevice" 
                                            element={<VcProductItemState key = "/deviceAction/replaceFulfilledFaultyDevice" 
                                                invokedFrom = "replaceFulfilledFaultyDevice" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/deviceAction/changeModel" 
                                            element={<VcChangeModel key = "/deviceAction/changeModel" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/deviceAction/blockDeviceData" 
                                            element={<VcBlockOrUnblockDeviceData key = "/deviceAction/blockDeviceData" 
                                                objPrivilege = {this.state.objPrivilege}
                                                invokedFrom="blockDeviceData"
                                            />}
                                        />

                                        <Route path="/deviceAction/unblockDeviceData" 
                                            element={<VcBlockOrUnblockDeviceData key = "/deviceAction/unblockDeviceData" 
                                                objPrivilege = {this.state.objPrivilege}
                                                invokedFrom="unblockDeviceData"
                                            />}
                                        />

                                        <Route path="/more/treeDefinition" 
                                            element={<VcTreeDefinition key = "/more/treeDefinition"  />}
                                        />

                                        <Route path="/report/inactiveFieldDevices" 
                                            element={<VcInactiveFieldDevices key = "/report/inactiveFieldDevices" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/cms/registerComplaint" 
                                            element={<ComplaintRegistrationForm key = "cms/registerComplaint" />} 
                                        />

                                        <Route path="/cms/complaintDetails" 
                                            element={<VcComplaintDetailsAndResolveComplaint key = "/cms/complaintDetails" 
                                            invokedFrom="ComplaintDetails" />}
                                        />

                                        <Route path="/cms/resolveComplaint" 
                                            element={<VcComplaintDetailsAndResolveComplaint key = "/cms/resolveComplaint"  
                                            invokedFrom="ResolveComplaint"/>}
                                        />     

                                        <Route path="/more/calibAppLinks" 
                                            element={<VcCalibAppLinks key = "/more/calibAppLinks"  />}
                                        />    

                                        <Route path="/more/hhmAppLinks" 
                                            element={<VcSmartHmmAppLinks key = "/more/hhmAppLinks"  />}
                                        />    
                                        
                                    </Routes> 
                                </div>
                                :
                                <div>
                                    <Routes>  
                                        <Route path="/:id" 
                                        element={<p className = "customerTableHeading" key = "/:id" style={{color:"var(--errorColor)"}}>
                                                    The URL is invalid or you do not have privilege to access it.</p>}
                                        /> 
                                    </Routes>  
                                </div>
                            }
                            
                        </div>  
                    </div>  
                </div> :
                <div className="d-flex">
                    <div className='d-flex' style={{ width: "4%" }}>
                        <div className="shadow sideNav" style={{ width: "100%" }}>
                            <VcSideNav />
                        </div>
                        {/* <div className='treeAndContent'>   
                            <div className='flex px-1 desktopView' style={{position:"relative"}}>
                                {(urlPathName != "/device/allDevices") &&
                                    <div style={{position:"sticky"}} className={`desktopViewLayout ${this.state.deviceTreeVisible ? 'treeVisible ' : 'treeHidden '} `}>
                                        <VcDeviceTree />
                                    </div>
                                }

                                <div 
                                    className={` ${(this.state.deviceTreeVisible && urlPathName != "/device/allDevices")? 'contentWithTree ' : 'contentWithoutTree '} relative`}
                                    style={{ maxWidth:'100%'}}
                                >
                                    <div className='bg-white shadow-sm flex items-center  justify-between px-2 py-1 ' style={{ borderRadius:"0.75rem" }}>
                                        {
                                            urlPathName == "/device/allDevices" ? 
                                                <div className='' style={{width:"60px"}}></div> :
                                                <VcSearchBox/> 
                                        }

                                        <VcTopNav toggleDeviceTreeVisible={this.allDeviceDataDeviceTreeToggle} dashboardDeviceTreeToggle={this.dashboardDeviceTreeToggle} />
                                    </div>

                                    <Routes>
                                        <Route path="/allDevices" 
                                            element={<VcAllDeviceData dashboardDeviceTreeToggle={this.dashboardDeviceTreeToggle} fullPath={fullPath}/>} 
                                        />

                                        <Route path="/addNode"
                                            element={<VcTreeDefinition loggedInUserID= {loggedInUserID} InvokedFromDeviceTree={"deviceTree"} />}
                                        />

                                        <Route path="/dashboard" 
                                            element={<VcDevice key = "/dashboard" fullPath={fullPath}/>} 
                                        />

                                        <Route path="/settings" 
                                            element={<VcSettings key = "/settings" fullPath={fullPath}/>} 
                                        />

                                        <Route path="/charts" 
                                            element={<VcChart key = "/charts" fullPath={fullPath}/>} 
                                        />

                                        <Route path="/alerts"
                                            element={<VcAlerts key = "/alerts" fullPath={fullPath}/>}
                                        />
                                    </Routes> 
                                </div>
                            </div>
                        </div> */}
                    </div> 

                    <div className="treeAndContentCRM">
                        <VcNavBar />
                        {/* <div className="BothComponentsFullScreenCSS"> </div> */}
                        <div className="container-fluid col-lg-12 col-md-12 col-sm-12">
                            {this.state.arrMergedRoutesBasedOnLoggedInUsrPvg.length <= 0 ? 
                                <div> 
                                    <p className = "customerTableHeading" style={{color:"var(--primaryColor)"}}>
                                        Please Wait.
                                    </p> 
                                </div>
                                :
                                showComponentBasedOnRoutes == true && bHideBasedOnLoginPathKeyValue == false ?
                                <div>
                                    <Routes>

                                        <Route path="/hhmApis" 
                                            element={<VcHhmAPIs key = "/hhmApis"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/track/trackDevices" 
                                            element={<VcTrackDevices key = "/track/trackDevices"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/track/trackRtcIssues" 
                                            element={<VcTrackRtcIssues key = "/track/trackRtcIssues"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/report/allStatesDashboard"
                                            element={<VcAllStateDashboard key = "/report/allStatesDashboard"
                                                objPrivilege = {this.state.objPrivilege}
                                                invokedFrom="allStatesDashboard" />}
                                        />

                                        <Route path="/more/rawDataParamsAction/addRawDataParams"
                                            element={<VcAddOrRemoveRawDataParams key = "/more/rawDataParamsAction/addRawDataParams"
                                                invokedFrom="AddRawDataParameters" />} 
                                        />

                                        <Route path="/more/rawDataParamsAction/removeRawDataParams"
                                            element={<VcAddOrRemoveRawDataParams key = "/more/rawDataParamsAction/addRawDataParams"
                                                invokedFrom="RemoveRawDataParameters" />} 
                                        />

                                        <Route path="/track/trackAppVersion"
                                            element={<VcTrackAppVersion key = "/track/trackAppVersion"
                                                objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/more/productPartAction/definePcbTypeAndVersion" 
                                            element={<VcDefinePcbTypeAndVersion key = "/more/productPartAction/definePcbTypeAndVersion" />} 
                                        />

                                        <Route path="/more/productPartAction/productItemImpParts"
                                            element={<VcProductItemImpParts key = "/more/productPartAction/productItemImpParts" />}
                                        />

                                        <Route path="/action/productItemState"
                                            element={<VcProductItemState key = "/action/productItemState" 
                                                invokedFrom="ProductItemStateHistory" 
                                                objPrivilege = {this.state.objPrivilege} />}
                                        />   

                                        <Route path="/action/updateNewAppVersion"
                                            element={<VcUpdateNewAppVersion key = "/action/updateNewAppVersion" />}
                                        />

                                        <Route path="/report/stagewiseProductStatus" 
                                            element={<VcStagewiseProdStat ShowProductionCount = {false} key = "/report/stagewiseProductStatus" /> } 
                                        />

                                        <Route path="/report/productionCount"
                                            element={<VcStagewiseProdStat ShowProductionCount = {true} key = "/report/productionCount" />} 
                                        />

                                        {/* <Route path="/report/currentProductionInventory"
                                            element={<VcCurrentProductInventory
                                            objPrivilege = {this.state.objPrivilege}
                                            invokedFrom="currentProductionInventory"
                                            key = "/report/currentProductionInventory" />} 
                                        />

                                        <Route path="/report/currentFieldDevices"
                                            element={<VcCurrentProductInventory
                                            invokedFrom="currentFieldDevices"
                                            objPrivilege = {this.state.objPrivilege}
                                            key = "/report/currentFieldDevices" />} 
                                        /> */}

                                        <Route path="/report/currentStateInventory"
                                            element={<VcAllStateDashboard
                                            invokedFrom="currentStateInventory"
                                            objPrivilege = {this.state.objPrivilege}
                                            key = "/report/currentStateInventory" />} 
                                        />

                                        <Route path="/report/deviceHistory"
                                            element={<VcDeviceStateHistory key = "/report/deviceHistory" />} 
                                        />

                                        <Route path="/report/allOrderDetails" 
                                            element={<VcAllOrderDetailsInfo key = "/report/allOrderDetails"
                                            invokedFromProp = "AllOrderDetails" />} 
                                        />

                                        <Route path="/report/allSubscriptionOrRentalDetails" 
                                            element={<VcAllSubscriptionDetailsInfo key = "/report/allSubscriptionOrRentalDetails" 
                                                invokedFrom = "AllSubscriptionDetails"
                                                objPrivilege = {this.state.objPrivilege}/>} 
                                        />

                                        <Route path="/report/allTakenBackRentalDevices" 
                                            element={<VcAllTakenBackRentalDevices key = "/report/allTakenBackRentalDevices"
                                            invokedFrom = "AllTakenBackRentalDevices"
                                            objPrivilege = {this.state.objPrivilege} />} 
                                        />

                                        <Route path="/report/rawDataAnalysis" 
                                            element={<VcRawDataAnalysis key = "/report/rawDataAnalysis"
                                            invokedFrom = "RawDataAnalysis"
                                            objPrivilege = {this.state.objPrivilege} />} 
                                        />
                                        
                                        <Route path="/report/salesDashboard" 
                                            element={<VcAllOrderDetailsInfo key = "/report/salesDashboard"
                                            invokedFromProp = "SalesDashboard"  />} 
                                        />

                                        <Route path="/customerOrder/customer" 
                                            element={<VcCustomer key = "/customerOrder/customer" />} 
                                        />

                                        <Route path="/customerOrder/createOrder" 
                                            element={<VcCustomer key = "/customerOrder/createOrder" propAction = "CreateOrder" 
                                                objPrivilege = {this.state.objPrivilege}  />}     
                                        />

                                        <Route path="/customerOrder/fulfillOrder" 
                                            element={<VcCustomer key = "/customerOrder/fulfillOrder" propAction = "FulFillOrder" 
                                                objPrivilege = {this.state.objPrivilege}  />}     
                                        />

                                        <Route path="/customerOrder/organization" 
                                            element={<VcOrganization key = "/customerOrder/organization" />} 
                                        />

                                        <Route path="/customerOrder/renewSubscription" 
                                            element={<VcRenewSubscription key = "/customerOrder/renewSubscription" />} 
                                        />

                                        {/* HMM Api feature is disabled.*/}
                                        {/* <Route path="/customerOrder/renewHHmApiPkg" 
                                            element={<VcRenewHhmApiPkg key = "/customerOrder/renewHHmApiPkg" />} 
                                        /> */}

                                        <Route path = "/customerOrder/CancelSubscription" 
                                            element={<VcCancelSubscription key = "/customerOrder/CancelSubscription" />}
                                        /> 

                                        <Route path = "/productAction/productLine" 
                                            element={<VcProductLine key = "/productAction/productLine" />} 
                                        />

                                        <Route path = "/report/viewToiletAndItsRatingInfo" 
                                            element={<VcToiletRatingReport key = "/report/viewToiletAndItsRatingInfo" 
                                                invokedFrom="invokedFromToiletReport" />
                                            } 
                                        />

                                        <Route path = "/report/viewPublicFootContInfo" 
                                            element={<VcToiletRatingReport key = "/report/viewPublicFootContInfo"  invokedFrom="invokedFromPublicCountReport"/>
                                            }
                                        />

                                        <Route path = "/productAction/productDetails" 
                                            element={<VcProduct key = "/productAction/productDetails" />}
                                        />
                                        
                                        <Route path = "/productAction/productSubscription" 
                                            element={<VcProductSubscription key = "/productAction/productSubscription" />}
                                        />

                                        <Route path = "/productAction/rentalProductDetails" 
                                            element={<VcRentalProductDetails key = "/productAction/rentalProductDetails" />}
                                        />

                                        <Route path="/deviceAction/deviceReplacementUpgrade" 
                                            element={<VcDevcReplacement key = "/deviceAction/deviceReplacementUpgrade" />}
                                        />

                                        <Route path = "/deviceAction/takeBackDemoDevice" 
                                            element={<VcProductItemState key = "/deviceAction/takeBackDemoDevice" 
                                                invokedFrom = "takeBackDemoDevice" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />

                                        <Route path = "/deviceAction/takeBackRentalDevice" 
                                            element={<VcProductItemState key = "/deviceAction/takeBackRentalDevice" 
                                                invokedFrom = "takeBackRentalDevice" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />

                                        <Route path="/deviceAction/recallSoldDevice" 
                                            element={<VcProductItemState key = "/deviceAction/recallSoldDevice" 
                                                invokedFrom = "recallSoldDevice" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path = "/deviceAction/moveDevcInDecommisionSt" 
                                            element={<VcProductItemState key = "/deviceAction/moveDevcInDecommisionSt" 
                                                invokedFrom = "moveDevcInDecommisionSt" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />
                                        
                                        <Route path = "/deviceAction/moveDevcInUnderRepairSt" 
                                            element={<VcProductItemState key = "/deviceAction/moveDevcInUnderRepairSt" 
                                                invokedFrom = "moveDevcInUnderRepairSt" 
                                                objPrivilege = {this.state.objPrivilege} 
                                            />}
                                        />

                                        <Route path="/deviceAction/readyForShipment" 
                                            element={<VcProductItemState key = "/deviceAction/readyForShipment" 
                                                invokedFrom = "readyForShipment" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/deviceAction/replaceFulfilledFaultyDevice" 
                                            element={<VcProductItemState key = "/deviceAction/replaceFulfilledFaultyDevice" 
                                                invokedFrom = "replaceFulfilledFaultyDevice" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/deviceAction/changeModel" 
                                            element={<VcChangeModel key = "/deviceAction/changeModel" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/deviceAction/blockDeviceData" 
                                            element={<VcBlockOrUnblockDeviceData key = "/deviceAction/blockDeviceData" 
                                                objPrivilege = {this.state.objPrivilege}
                                                invokedFrom="blockDeviceData"
                                            />}
                                        />

                                        <Route path="/deviceAction/unblockDeviceData" 
                                            element={<VcBlockOrUnblockDeviceData key = "/deviceAction/unblockDeviceData" 
                                                objPrivilege = {this.state.objPrivilege}
                                                invokedFrom="unblockDeviceData"
                                            />}
                                        />

                                        <Route path="/more/treeDefinition" 
                                            element={<VcTreeDefinition key = "/more/treeDefinition"  />}
                                        />

                                        <Route path="/report/inactiveFieldDevices" 
                                            element={<VcInactiveFieldDevices key = "/report/inactiveFieldDevices" 
                                                objPrivilege = {this.state.objPrivilege}
                                            />}
                                        />

                                        <Route path="/cms/registerComplaint" 
                                            element={<ComplaintRegistrationForm key = "cms/registerComplaint" />} 
                                        />

                                        <Route path="/cms/complaintDetails" 
                                            element={<VcComplaintDetailsAndResolveComplaint key = "/cms/complaintDetails" 
                                            invokedFrom="ComplaintDetails" />}
                                        />

                                        <Route path="/cms/resolveComplaint" 
                                            element={<VcComplaintDetailsAndResolveComplaint key = "/cms/resolveComplaint"  
                                            invokedFrom="ResolveComplaint"/>}
                                        />     

                                        <Route path="/more/calibAppLinks" 
                                            element={<VcCalibAppLinks key = "/more/calibAppLinks"  />}
                                        />    

                                        <Route path="/more/hhmAppLinks" 
                                            element={<VcSmartHmmAppLinks key = "/more/hhmAppLinks"  />}
                                        />    
                                        
                                    </Routes> 
                                </div>
                            :
                                <div>
                                    <Routes>  
                                        <Route path="/:id" 
                                        element={<p className = "customerTableHeading" key = "/:id" style={{color:"var(--errorColor)"}}>
                                                    The URL is invalid or you do not have privilege to access it.</p>}
                                        /> 
                                    </Routes>  
                                </div>
                            }
                            
                        </div>  
                    </div>    
                </div>
        )
    }
}

VcCrmHome.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcCrmHome;