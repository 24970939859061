import React, { Component } from 'react'
import axios from 'axios';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_AUSrvrIssueReqParamsNotSent, IDS_LoginEmailError } from '../../VcLanguage';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
import {  convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateToStrYYYYMMDDHH24MMSS, convertLocalDateToStrYYYYMMDDHH24MMSS, convertLocalDateWithFormatDDMMMYY } from '../../vtUtil';
import { HHM_API_DURATION_ARRAY, HHM_API_HITS_ARRAY, HHM_API_RENEWADDON, HHM_API_RENEWPACKAGE} from '../../VcConstants';
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

export class VcRenewHHmApiPkg extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            LoggedInUserID: "",
            DeviceID:"",
            enableHhmApiRenewalField: false,
            arrAllOrdLineNo: [],
            userEmailID: "",
            UserName: "",
            selectedOrdLineNo: "",
            authInfoIdOfSelectedOrdLineNo: "",
            PkgStartDtTmOfselectedOrdLineNo: "",
            PkgEndDtTmOfselectedOrdLineNo: "",
            PkgStructure : "",
            AddOnStructure: "",
            PkgDurationInMonthOfselectedOrdLineNo:"",
            PkgDurationInDaysOfselectedOrdLineNo: "",
            selectedDurationForApiPkg: "",
            selectedDurationInDaysForApiPkg: "",
            apiHitsOfselectedOrdLineNo: "",
            StatusOfselectedOrdLineNo: "",
            productCodeOfSelectedOrdLineNo: "",
            renewalType: "",
            pricePerDayINR: "",
            pricePerHitINR: "",
            totalPriceOnSelectedDurationINR:"",
            TaxPriceINR: "0.00",
            TaxPercentage: "0",
            taxPercentageOnSelectedDuration: "",
            discountPercentage: "0",
            discountPriceINR: "0.00",
            totalPriceForApiPkgINR: "",
            DurationForApiOrder: HHM_API_DURATION_ARRAY,
            HitsForApiOrder: HHM_API_HITS_ARRAY,
            DurationForApiPkg: [],
            DurationInDaysForApiPkg: [],
            HitsForApiPkg: [],
            HitsForApiAddOn: [],

            errors: {
                others: "",
                email: "",
                errApiOrder: "",
                hhmApiPkgDurationErr: "",
                apiHitsForAddOnErr: "",
                taxPercntErr: "",
            },
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;

        modifiedState.errors.hhmApiPkgDurationErr = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE && 
            (modifiedState.selectedDurationForApiPkg == null || modifiedState.selectedDurationForApiPkg.length <= 0)
            ?
            "Please Select HHM API Package Duration"
            : "";

        modifiedState.errors.apiHitsForAddOnErr = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWADDON && 
            (modifiedState.apiHitsOfselectedOrdLineNo == null || modifiedState.apiHitsOfselectedOrdLineNo.length <= 0)
            ?
            "Please Select API Hits for Add-On"
            : "";

        modifiedState.errors.taxPercntErr = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && (modifiedState.renewalType == HHM_API_RENEWADDON || modifiedState.renewalType == HHM_API_RENEWPACKAGE) && 
            (modifiedState.TaxPercentage == null || modifiedState.TaxPercentage.length <= 0)
            ?
            "Please Enter Tax Percentage"
            : "";

        // if any one of the required fields is not entered by user then return the function and show error message.
        if(modifiedState.errors.hhmApiPkgDurationErr.length > 0 || modifiedState.errors.apiHitsForAddOnErr.length > 0 && modifiedState.errors.taxPercntErr.length > 0) {
            this.setState(modifiedState);
            return
        }

        // show alert message before renewing hhm api package
        if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE){
            if(!window.confirm(`Are you sure you want to Renew HHM API package for "${modifiedState.selectedDurationForApiPkg}(s)" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}" ?`)){
                return
            }
        } else if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWADDON){
            if(!window.confirm(`Are you sure you want to Renew HHM API Add-On for "${modifiedState.apiHitsOfselectedOrdLineNo} Hit(s)" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}" ?`)){
                return
            }
        }
        
        // make start date time to 23:59:59
        let renewedPkgStartDtTm = new Date(modifiedState.PkgEndDtTmOfselectedOrdLineNo.valueOf());
        renewedPkgStartDtTm.setDate(renewedPkgStartDtTm.getDate() + 1);
        renewedPkgStartDtTm.setHours(23);
        renewedPkgStartDtTm.setMinutes(59);
        renewedPkgStartDtTm.setSeconds(59);

        // make valid till date time to 00:00:00
        let renewedPkgValidTillDtTm = new Date(renewedPkgStartDtTm.valueOf());
        renewedPkgValidTillDtTm.setDate(renewedPkgValidTillDtTm.getDate() + modifiedState.selectedDurationInDaysForApiPkg);
        renewedPkgValidTillDtTm.setHours(0);
        renewedPkgValidTillDtTm.setMinutes(0);
        renewedPkgValidTillDtTm.setSeconds(0);        
        
        let strRenewedPkgStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(renewedPkgStartDtTm) );
        let strRenewedPkgValidTillDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(renewedPkgValidTillDtTm) );
        let CurrentPkgStartDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(new Date(modifiedState.PkgStartDtTmOfselectedOrdLineNo));

        let jsonParams = {
            UserID: modifiedState.userEmailID,
            AuthInfoId: modifiedState.authInfoIdOfSelectedOrdLineNo,
            OrderLineNumber: modifiedState.selectedOrdLineNo,
            StatusOfselectedOrdLineNo: modifiedState.StatusOfselectedOrdLineNo,
            ProductCode: modifiedState.productCodeOfSelectedOrdLineNo,
            PackageDuration: modifiedState.selectedDurationInDaysForApiPkg == "" ? "0" : modifiedState.selectedDurationInDaysForApiPkg,
            ApiHitsInPkg: modifiedState.apiHitsOfselectedOrdLineNo,
            PriceOfHitsInPkgINR: modifiedState.apiHitsOfselectedOrdLineNo * modifiedState.pricePerHitINR,
            PriceOfDurationInPkgINR: modifiedState.selectedDurationInDaysForApiPkg * modifiedState.pricePerDayINR,
            DiscountPercentage: modifiedState.discountPercentage,
            DiscountPriceINR: modifiedState.discountPriceINR,
            TaxPerPkgINR: modifiedState.TaxPercentage,
            TaxPricePerPkgINR: modifiedState.TaxPriceINR,
            IsRenewal: '1',
            RenewalType: modifiedState.renewalType,
            LoggedInUserID: modifiedState.LoggedInUserID,
            CurrentPkgStartDtTm: CurrentPkgStartDtTm,
            RenewedPkgStartDtTm: strRenewedPkgStartDtTm,
            RenewedPkgValidTillDtTm: strRenewedPkgValidTillDtTm
        }

        axios.post(`${getAPIHostURL()}/wclient/renewHHMApiPackage`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                // show alert message after renewing hhm api package
                if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE){
                    alert(`Succesfully Renewed HHM API package for "${modifiedState.selectedDurationForApiPkg}(s)" \nfrom Start Date: ` +
                        `"${strRenewedPkgStartDtTm}" to \nEnd Date: "${strRenewedPkgValidTillDtTm}" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}"`);

                } else if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWADDON){
                    alert(`Succesfully Renewed HHM API Add-on for "${modifiedState.apiHitsOfselectedOrdLineNo} Hit(s)" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}"`);
                }

                modifiedState.enableHhmApiRenewalField = false;
                modifiedState.selectedOrdLineNo = "";
                modifiedState.renewalType = "";
                modifiedState.arrAllOrdLineNo = [];
                modifiedState.DurationForApiPkg = [];
                modifiedState.DurationInDaysForApiPkg = [];
                modifiedState.HitsForApiPkg = [];
                modifiedState.HitsForApiAddOn = [];
                modifiedState.userEmailID = "";
                modifiedState.errors.others = "";
                modifiedState.errors.taxPercntErr = "";
                modifiedState.errors.apiHitsForAddOnErr = "";
                modifiedState.errors.errApiOrder = "";
                modifiedState.errors.hhmApiPkgDurationErr = "";
                modifiedState.errors.email = "";
                
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);

        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    handleDiscountChange = (event) => {
        event.preventDefault();
        let value = event.target.value;
        let modifiedState = this.state;
        
        value = value != null && value >= 0 ? value : "0";

        modifiedState.discountPercentage = value;
        modifiedState.discountPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null &&
            modifiedState.TaxPercentage != null && modifiedState.TaxPercentage.length > 0 
            ?
            (Number(modifiedState.totalPriceOnSelectedDurationINR * (Number(modifiedState.discountPercentage) / 100 )).toFixed(2))
            : 0.00;

        modifiedState.TaxPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null &&
            modifiedState.TaxPercentage != null && modifiedState.TaxPercentage.length > 0 
            ?
            (Number(modifiedState.totalPriceOnSelectedDurationINR - modifiedState.discountPriceINR) * (Number(modifiedState.TaxPercentage) / 100 )).toFixed(2) 
            : 0.00;

        modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.totalPriceOnSelectedDurationINR - modifiedState.discountPriceINR) + Number(modifiedState.TaxPriceINR)).toFixed(2) 
        this.setState(modifiedState);  
    }

    handleTaxChange = (event) => {
        event.preventDefault();
        let value = event.target.value;
        let modifiedState = this.state;

        modifiedState.errors.taxPercntErr = value < 0 || value.length <= 0 ? "Please Enter Tax Percentage" : "";
        modifiedState.TaxPercentage = value;
        modifiedState.TaxPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null &&
            modifiedState.TaxPercentage != null && modifiedState.TaxPercentage.length > 0 
            ?
            (Number(modifiedState.totalPriceOnSelectedDurationINR - modifiedState.discountPriceINR) * (Number(modifiedState.TaxPercentage) / 100 )).toFixed(2) 
            : 0.00;

        modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.totalPriceOnSelectedDurationINR - modifiedState.discountPriceINR) + Number(modifiedState.TaxPriceINR)).toFixed(2) 
        this.setState(modifiedState);  
    }

    // Radio Button to Switch between hhm api renewPackage and hhm api renew addOn.
    // on switch we also Empty the TextBoxes and Array.
    onRadioBtnChange = (e) => {
        
        let modifiedState = this.state;

        modifiedState.renewalType = e.target.value;

        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.hhmApiPkgDurationErr = "";
        modifiedState.errors.taxPercntErr = "";

        if(modifiedState.renewalType == HHM_API_RENEWPACKAGE){
            modifiedState.apiHitsOfselectedOrdLineNo = "";
            modifiedState.totalPriceOnSelectedDurationINR = "";
            modifiedState.TaxPriceINR = "";
            modifiedState.totalPriceForApiPkgINR = "";
            modifiedState.TaxPercentage = "";
            modifiedState.discountPercentage = "0";
            modifiedState.discountPriceINR = "0.00";
            modifiedState.selectedDurationForApiPkg = "";
        }
        else if(modifiedState.renewalType == HHM_API_RENEWADDON){
            modifiedState.apiHitsOfselectedOrdLineNo = "";
            modifiedState.selectedDurationForApiPkg = "";
            modifiedState.selectedDurationInDaysForApiPkg = "";
            modifiedState.totalPriceOnSelectedDurationINR = "";
            modifiedState.TaxPriceINR = "";
            modifiedState.TaxPercentage = "";
            modifiedState.totalPriceForApiPkgINR = "";
            modifiedState.discountPercentage = "0";
            modifiedState.discountPriceINR = "0.00";
        }
        this.setState(modifiedState);  
    }

    onChangeApiHitsForApiOrder = (e) => {
        let modifiedState = this.state;

        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.taxPercntErr = "";

        modifiedState.apiHitsOfselectedOrdLineNo = e.target.value;

        for(let i = 0; i < modifiedState.HitsForApiAddOn.length; i++){

            if(modifiedState.HitsForApiAddOn[i] == modifiedState.apiHitsOfselectedOrdLineNo){

            modifiedState.totalPriceOnSelectedDurationINR = modifiedState.pricePerDayINR != null && modifiedState.pricePerDayINR.length > 0 &&
                modifiedState.pricePerHitINR != null && modifiedState.pricePerHitINR.length > 0 
                ?
                (modifiedState.apiHitsOfselectedOrdLineNo * modifiedState.pricePerHitINR)
                : 0;

            modifiedState.TaxPercentage = modifiedState.taxPercentageOnSelectedDuration;
            modifiedState.TaxPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null &&
                modifiedState.TaxPercentage != null && modifiedState.TaxPercentage.length > 0 
                ?
                (modifiedState.totalPriceOnSelectedDurationINR * (Number(modifiedState.TaxPercentage) / 100 )).toFixed(2) 
                : 0;

            modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.totalPriceOnSelectedDurationINR) + Number(modifiedState.TaxPriceINR)).toFixed(2) 
            
            } 
        }
        this.setState(modifiedState);  
    }

    onChangeDurationForApiOrder = (e) => {
        let modifiedState = this.state;

        modifiedState.errors.hhmApiPkgDurationErr = "";
        modifiedState.errors.taxPercntErr = "";
        modifiedState.selectedDurationForApiPkg = e.target.value;

        for(let i = 0; i < modifiedState.DurationForApiPkg.length; i++){

            if(modifiedState.DurationForApiPkg[i] == modifiedState.selectedDurationForApiPkg){
                modifiedState.apiHitsOfselectedOrdLineNo = modifiedState.HitsForApiPkg[i];

                modifiedState.TaxPercentage = modifiedState.taxPercentageOnSelectedDuration;

                modifiedState.selectedDurationInDaysForApiPkg = modifiedState.DurationInDaysForApiPkg[i] * 30;

                modifiedState.totalPriceOnSelectedDurationINR = modifiedState.pricePerDayINR != null && modifiedState.pricePerDayINR.length > 0 &&
                    modifiedState.pricePerHitINR != null && modifiedState.pricePerHitINR.length > 0 
                    ?
                    (modifiedState.selectedDurationInDaysForApiPkg * modifiedState.pricePerDayINR) + (modifiedState.apiHitsOfselectedOrdLineNo * modifiedState.pricePerHitINR)
                    : 0.00;

                modifiedState.TaxPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null &&
                    modifiedState.TaxPercentage != null && modifiedState.TaxPercentage.length > 0 
                    ?
                    (modifiedState.totalPriceOnSelectedDurationINR * (Number(modifiedState.TaxPercentage) / 100 )).toFixed(2) 
                    : 0.00;
                    
                modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.totalPriceOnSelectedDurationINR) + Number(modifiedState.TaxPriceINR)).toFixed(2) 
            
            } 
        }
        this.setState(modifiedState);  

    }

    getSelectedOrdLineNoDetails = () => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;
            
        let jsonParams = {
            UserID: modifiedState.userEmailID,
            OrderLineNumber: modifiedState.selectedOrdLineNo,
            AuthInfoId: modifiedState.authInfoIdOfSelectedOrdLineNo
        }
    
        axios.post(`${getAPIHostURL()}/wclient/getHHMApiCurrentPkgInfo`, jsonParams)
        .then(response =>{
    
            if(response.data.code == 'SUCCESS') {
              if (response.data != null && response.data.hhmApiCurrentPkgInfo.length > 0) {
    
                let allOrdLineNoDetailsRecieved = response.data.hhmApiCurrentPkgInfo[0];

                modifiedState.taxPercentageOnSelectedDuration = allOrdLineNoDetailsRecieved.TaxPrcntINR != null && allOrdLineNoDetailsRecieved.TaxPrcntINR.length > 0 
                    ? allOrdLineNoDetailsRecieved.TaxPrcntINR
                    : "";

                modifiedState.pricePerDayINR = allOrdLineNoDetailsRecieved.PricePerDayINR != null && allOrdLineNoDetailsRecieved.PricePerDayINR.length > 0 
                    ? allOrdLineNoDetailsRecieved.PricePerDayINR
                    : "";

                modifiedState.pricePerHitINR = allOrdLineNoDetailsRecieved.PricePerHitINR != null && allOrdLineNoDetailsRecieved.PricePerHitINR.length > 0 
                    ? allOrdLineNoDetailsRecieved.PricePerHitINR
                    : "";

                modifiedState.productCodeOfSelectedOrdLineNo = allOrdLineNoDetailsRecieved.ProductCode != null
                    ? allOrdLineNoDetailsRecieved.ProductCode
                    : ""; 

                modifiedState.PkgStartDtTmOfselectedOrdLineNo = allOrdLineNoDetailsRecieved.PkgStartDtTm != null && allOrdLineNoDetailsRecieved.PkgStartDtTm.length > 0 
                    ? convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(allOrdLineNoDetailsRecieved.PkgStartDtTm)
                    : "";       

                modifiedState.PkgEndDtTmOfselectedOrdLineNo = allOrdLineNoDetailsRecieved.PkgEndDtTm != null && allOrdLineNoDetailsRecieved.PkgEndDtTm.length > 0 
                    ? convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(allOrdLineNoDetailsRecieved.PkgEndDtTm)
                    : "";  
                    
                modifiedState.PkgStructure = allOrdLineNoDetailsRecieved.PkgStructure != null && allOrdLineNoDetailsRecieved.PkgStructure.length > 0
                    ? allOrdLineNoDetailsRecieved.PkgStructure
                    : "";

                modifiedState.AddOnStructure = allOrdLineNoDetailsRecieved.AddOnStructure != null && allOrdLineNoDetailsRecieved.AddOnStructure.length > 0
                    ? allOrdLineNoDetailsRecieved.AddOnStructure
                    : "";

                let PkgStructure = JSON.parse(modifiedState.PkgStructure);
                let AddOnStructure = JSON.parse(modifiedState.AddOnStructure);

                // logic to display duration in form of year and months
                Object.keys(PkgStructure).forEach(function(key) {
                    modifiedState.DurationInDaysForApiPkg.push(key);
                    // if number of month is less than 12, display it as it is.
                    if(key <= 12){
                        modifiedState.DurationForApiPkg.push(key + " Month");
                    // if number of month is greater than 12, calculate and display it as year and month
                    } else if(key > 12){
                        let year = Math.trunc(key/12) > 0 ? Math.floor(key/12,1)+ " Year " : "";
                        let month = key%12 > 0 ? key%12+ " Month" : "";
                        modifiedState.DurationForApiPkg.push(year + "" + month);
                    }
                    modifiedState.HitsForApiPkg.push(PkgStructure[key]);
                })
                
                for(let i = 0; i < AddOnStructure.length; i++){
                    modifiedState.HitsForApiAddOn.push(AddOnStructure[i]);
                }

                let PkgStartDtTm = new Date(convertLocalDateWithFormatDDMMMYY(modifiedState.PkgStartDtTmOfselectedOrdLineNo));
                let PkgEndDtTm = new Date(convertLocalDateWithFormatDDMMMYY(modifiedState.PkgEndDtTmOfselectedOrdLineNo));

                modifiedState.PkgDurationInDaysOfselectedOrdLineNo = Math.floor(Math.abs((PkgStartDtTm - PkgEndDtTm)/ (1000*60*60*24)))
                modifiedState.PkgDurationInMonthOfselectedOrdLineNo = Math.round(modifiedState.PkgDurationInDaysOfselectedOrdLineNo/30, 1)
                
              }         
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING'){
                    // modifiedState.errors.others = 'Server experiencing issues (Device Params not received).\n Try again later.';
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if(response.data.code == 'SQL_ERROR') {
                    // modifiedState.errors = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    // modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
            }
            this.setState(modifiedState);
    
        })
        .catch(err => {
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled because of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
            }
            this.setState(modifiedState);
        })
    }

    onSelectOrderLineNumber = (e) => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;
    
        modifiedState.PkgStartDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgEndDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInMonthOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInDaysOfselectedOrdLineNo = "";
        modifiedState.StatusOfselectedOrdLineNo = "";
        modifiedState.totalPriceOnSelectedDurationINR = "";
        modifiedState.TaxPriceINR = "";
        modifiedState.totalPriceForApiPkgINR = "";
        modifiedState.selectedDurationForApiPkg = "";
        modifiedState.apiHitsOfselectedOrdLineNo = "";
        modifiedState.selectedDurationInDaysForApiPkg = "";
        modifiedState.TaxPercentage = "";
        modifiedState.discountPercentage = "0";
        modifiedState.discountPriceINR = "0.00";
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.errors.hhmApiPkgDurationErr = "";
        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.taxPercntErr = "";
        modifiedState.renewalType = "";
        modifiedState.DurationForApiPkg = [];
        modifiedState.DurationInDaysForApiPkg = [];
        modifiedState.HitsForApiPkg = [];
        modifiedState.HitsForApiAddOn = [];

        let orderlineNumber = e.target.value;
        modifiedState.selectedOrdLineNo = orderlineNumber;
        
        for(let i=0; i < modifiedState.arrAllOrdLineNo.length; i++){
          if(modifiedState.arrAllOrdLineNo[i].OrderLineNumber == orderlineNumber){
    
            modifiedState.authInfoIdOfSelectedOrdLineNo = modifiedState.arrAllOrdLineNo[i].AuthInfoId != null && modifiedState.arrAllOrdLineNo[i].AuthInfoId.length > 0 
            ? modifiedState.arrAllOrdLineNo[i].AuthInfoId : "";

            modifiedState.StatusOfselectedOrdLineNo = modifiedState.arrAllOrdLineNo[i].ApiKeyStatus != null && modifiedState.arrAllOrdLineNo[i].ApiKeyStatus.length > 0 
            ? modifiedState.arrAllOrdLineNo[i].ApiKeyStatus : "";

          }
        }

        modifiedState.errors.errApiOrder = modifiedState.StatusOfselectedOrdLineNo != null && modifiedState.StatusOfselectedOrdLineNo.length > 0 
            && modifiedState.StatusOfselectedOrdLineNo == "Blocked" ?
             "You cannot renew this order as this order is blocked. Please select different order line number." :
             "";
    
        this.getSelectedOrdLineNoDetails();

        this.setState(modifiedState);
    }

    getOrdLineNoWithHhmApiDetailsBasedOnUserID = () => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;
        
        modifiedState.errors.errApiOrder = "";
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        
        let jsonParams = {
          userID: modifiedState.userEmailID,
          invokedFrom: "renewHhmApiPkg"
        }
    
        axios.post(`${getAPIHostURL()}/wclient/getOrdLineNoWithHhmApiDetailsBasedOnUserID`, jsonParams)
        .then(response =>{
    
            if(response.data.code == 'SUCCESS') {

              if (response.data != null && response.data.ordLineNoForHhmApi.length > 0) {
    
                let allOrdLineNoDetailsRecieved = response.data.ordLineNoForHhmApi;

                modifiedState.UserName = allOrdLineNoDetailsRecieved[0].FirstName != null && allOrdLineNoDetailsRecieved[0].FirstName.length > 0
                    ? allOrdLineNoDetailsRecieved[0].FirstName 
                    : "";

                for(let i=0; i < allOrdLineNoDetailsRecieved.length; i++) {
                  modifiedState.arrAllOrdLineNo.push(allOrdLineNoDetailsRecieved[i]);
                }
                modifiedState.enableHhmApiRenewalField = true;
                
              } else {
                  modifiedState.errors.others = "HHM APIs Order Details Not Available for Entered EmailID. Please enter a valid EmailID.";
              }          
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
            }
            this.setState(modifiedState);
    
        })
        .catch(err => {
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled because of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
            }
            this.setState(modifiedState);
        })
      }    
      
    onClickProceed = () => {
        let modifiedState = this.state;  

        if(modifiedState.userEmailID == null || modifiedState.userEmailID.length <= 0) {
            modifiedState.errors.email = 'Please Enter Valid Email ID.';
            this.setState(modifiedState);
            return;
        } else { 
            this.getOrdLineNoWithHhmApiDetailsBasedOnUserID();
        }

    }

    handleEmailChange = (event) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;    

        modifiedState.errors.others = "";
        modifiedState.errors.email = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.errors.hhmApiPkgDurationErr = "";
        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.taxPercntErr = "";
        modifiedState.enableHhmApiRenewalField = false;
        modifiedState.selectedOrdLineNo = "";
        modifiedState.PkgStartDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgEndDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInMonthOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInDaysOfselectedOrdLineNo = "";
        modifiedState.renewalType = "";
        modifiedState.arrAllOrdLineNo = [];
        modifiedState.DurationForApiPkg = [];
        modifiedState.DurationInDaysForApiPkg = [];
        modifiedState.HitsForApiPkg = [];
        modifiedState.HitsForApiAddOn = [];

        const value = event.target.value;
      
        modifiedState.errors.email =  value.length > 0 ? ((validEmailRegex.test(value)) ? '' : t(IDS_LoginEmailError)) : "";
        modifiedState.userEmailID = value;
             
        this.setState(modifiedState);
      }

    render() {

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    const color = {
        color : "var(--errorColor)",
        fontSize:"13px"
    }
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2 col-md-12">
                        <div className="modal-body box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>Renew HHM API Package</div> 
                            <form onSubmit={this.onFormSubmit}>
                                <div>
                                    <label className="reg-form-label" 
                                        style={{width:"100%"}}
                                    >
                                        Enter EmailID of User:
                                    </label>
                                    <input style={{width:"100%"}}
                                           className="input-form" type='email'
                                           value= {this.state.userEmailID}
                                           onChange={this.handleEmailChange}
                                           required
                                    />
                                    {this.state.errors.email.length > 0 && 
                                        <span style={color} className='error'>{this.state.errors.email}</span>} 
                                    {this.state.enableHhmApiRenewalField == false &&
                                        <div style={{display:"flex", justifyContent: "center", flexDirection:"column"}}>
                                            <button type={"button"}
                                                    style={{borderRadius: "5px", 
                                                            width:'40%', 
                                                            marginLeft:'30%',
                                                            marginTop:"0.5rem",
                                                            backgroundColor: "var(--primaryColor)", color:"white"}}
                                                    className="btn-lg"
                                                    onClick={this.onClickProceed} 
                                            >
                                                Proceed
                                            </button>
                                            {this.state.errors.others.length > 0 && 
                                                <span style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop: "0.3rem"}} className='error'>{this.state.errors.others}</span>} 
                                        </div>
                                    }                          
                                </div>
                                {(this.state.enableHhmApiRenewalField == true) && 
                                    <div>
                                        <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem", marginTop: "0.5rem"}}                                                >
                                            Select Order To Renew:
                                        </label>
                                        <div>
                                            <select className="input-form"
                                            style={{width:"100%"}}
                                            required
                                            placeholder='Select Order Line Number...'
                                            value={this.state.selectedOrdLineNo}
                                            onChange={this.onSelectOrderLineNumber}
                                            >
                                            <option value="" select= "true" disabled>Select Order Line Number...</option> 
                                            {(this.state.arrAllOrdLineNo.map((singleOrdLineNo, index) => 
                                            <option key={index} value={singleOrdLineNo.OrderLineNumber}>{"ApiOrder" + this.state.UserName + singleOrdLineNo.OrderLineNumber}</option>))} 
                                            </select><br/>
                                        </div>
                                        {this.state.errors.errApiOrder.length > 0 && 
                                                <span  className='validationErrorLabel'>{this.state.errors.errApiOrder}</span>} 
                                    </div>
                                }

                                {(this.state.enableHhmApiRenewalField == true) && this.state.selectedOrdLineNo != null && this.state.selectedOrdLineNo.length > 0 && this.state.errors.errApiOrder == "" && this.state.errors.errApiOrder.length <= 0 &&
                                    <div>
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <div>
                                                <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                Current HHM API Package Details
                                                </label>
                                                <div>
                                                    <div className = "startAndEnddate" style={{marginTop: "0.5rem"}}>
                                                        <div style={{flex: "1"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                            Current HHM API Package Duration In Months:
                                                            </label>
                                                            <div style={{display: "flex"}}>
                                                                <input style={{fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}}
                                                                    className="input-form"
                                                                    value= {this.state.PkgDurationInMonthOfselectedOrdLineNo + " Month"}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{paddingLeft: "0.8rem", paddingRight: "0.8rem", paddingTop: "0.3rem", alignItems: "center"}}>
                                                        <br/>
                                                            =
                                                        </div>
                                                        <div style={{flex: "1"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                            Current HHM API Package Duration In Days:
                                                            </label>
                                                            <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", width:"100%"}}
                                                                    className="input-form"
                                                                    value= {this.state.PkgDurationInDaysOfselectedOrdLineNo + " Days"}
                                                                    readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className = "startAndEnddate" style = {{ marginTop: "0.5rem"}}>
                                                        <div style={{flex: "1", paddingRight:"1rem"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                            Current HHM API Package Start Date Time:
                                                            </label>
                                                            <input style={{color: "#505050", background:"#F5F5F5"}}
                                                                    className="input-form"
                                                                    value= {this.state.PkgStartDtTmOfselectedOrdLineNo}
                                                                    readOnly
                                                            />
                                                        </div>

                                                        <div style={{flex: "1", paddingLeft:"1rem"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                            Current HHM API Package End Date Time:
                                                            </label>
                                                            <input style={{color: "#505050", background:"#F5F5F5"}}
                                                                    className="input-form"
                                                                    value= {this.state.PkgEndDtTmOfselectedOrdLineNo}
                                                                    readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.selectedOrdLineNo != null && this.state.selectedOrdLineNo.length > 0 && this.state.errors.errApiOrder == "" && this.state.errors.errApiOrder.length <= 0 &&
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <div>
                                                <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>
                                            </div>
                                            <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                Select HHM APIs Renewal Type
                                                </label>
                                                <div className="trackRadioDiv">
                                                    <div className = "trackradioBox">
                                                        <input type='radio' name='trackBy'  
                                                            id = "trackBySingleDeviceID" 
                                                            value= {HHM_API_RENEWADDON}
                                                            onChange={this.onRadioBtnChange} noValidate 
                                                            
                                                        />
                                                        <span style={{marginLeft: "0.3rem"}}> Renew with Add-On Hits</span>

                                                        <input type='radio' name='trackBy'  
                                                            id = "trackBySingleDeviceID" 
                                                            value= {HHM_API_RENEWPACKAGE}
                                                            onChange={this.onRadioBtnChange} noValidate 
                                                            style={{marginLeft: "1rem"}}
                                                        />
                                                        <span style={{marginLeft: "0.3rem"}}> Renew with Package</span>
                                                    </div>
                                                </div>
                                            {this.state.renewalType != null && this.state.renewalType.length > 0 && 
                                            <div>
                                                <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>

                                                    <div>
                                                        <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                        {this.state.renewalType != null && this.state.renewalType.length > 0 && 
                                                                            this.state.renewalType == HHM_API_RENEWPACKAGE ? "HHM API Package Renewal Details" : "HHM API Add-On Hits Renewal Details"}
                                                        </label>
                                                        {this.state.renewalType != null && this.state.renewalType.length > 0 && 
                                                            this.state.renewalType == HHM_API_RENEWPACKAGE &&
                                                            <div>
                                                                <div className = "startAndEnddate" style={{ marginTop: "0.5rem"}}>
                                                                    <div style={{flex: "1"}}>
                                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                                        Select HHM API Package Renewal Duration:
                                                                            <span className="addCustRequiredMarkStar">*</span>
                                                                        </label>
                                                                        <div>
                                                                            <select  className="input-form" 
                                                                                        style={{height:"2.5rem", marginBottom: "0.1rem"}}
                                                                                        value={this.state.selectedDurationForApiPkg}
                                                                                        onChange={this.onChangeDurationForApiOrder}
                                                                            >
                                                                                <option value="" disabled select="true">Select...</option>
                                                                                {(this.state.DurationForApiPkg).map((singleDuration,index) => <option key={index} value={singleDuration}>{singleDuration}</option>)}
                                                                            </select>
                                                                            {this.state.errors.hhmApiPkgDurationErr.length > 0 && 
                                                                            <span  className='validationErrorLabel'>{this.state.errors.hhmApiPkgDurationErr}</span>} 
                                                                        </div>
                                                                    </div>
                                                                    <div style={{paddingLeft: "0.8rem", paddingRight: "0.8rem", paddingTop: "0.3rem", alignItems: "center"}}>
                                                                        <br/> =
                                                                    </div>
                                                                    <div style={{flex: "1"}}>
                                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                                        HHM API Package Renewal Duration In Days:
                                                                            <span className="addCustRequiredMarkStar"></span>

                                                                        </label>
                                                                        <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                                className="input-form"
                                                                                value= {this.state.selectedDurationInDaysForApiPkg + " Days"}
                                                                                readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                                    <div style={{flex:"1", paddingRight:"1rem"}}>
                                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                                        API hits on Selected Package Duration:<span className="addCustRequiredMarkStar"></span>
                                                                        </label>
                                                                    
                                                                        <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                            className="input-form"
                                                                            type= "number"
                                                                            value= {this.state.apiHitsOfselectedOrdLineNo}
                                                                            readOnly
                                                                            />
                                                                    </div>

                                                                    <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                                        Price on Selected Package Duration (INR):<span className="addCustRequiredMarkStar"></span>
                                                                        </label>
                                                                    
                                                                        <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                            className="input-form"
                                                                            type= "number"
                                                                            value= {this.state.totalPriceOnSelectedDurationINR}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.renewalType != null && this.state.renewalType.length > 0 && 
                                                            this.state.renewalType == HHM_API_RENEWADDON &&
                                                            <div>
                                                                <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                                    <div style={{flex:"1", paddingRight:"1rem"}}>
                                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                                        Select API hits for Add-On:
                                                                            <span className="addCustRequiredMarkStar">*</span>
                                                                        </label>
                                                                        <div>
                                                                            <select  className="input-form" 
                                                                                        style={{height:"2.5rem", marginBottom: "0.1rem"}}
                                                                                        value={this.state.apiHitsOfselectedOrdLineNo}
                                                                                        onChange={this.onChangeApiHitsForApiOrder}
                                                                            >
                                                                                <option value="" disabled select="true">Select...</option>
                                                                                {(this.state.HitsForApiAddOn).map((singleDuration,index) => <option key={index} value={singleDuration}>{singleDuration}</option>)}
                                                                            </select>
                                                                            {this.state.errors.apiHitsForAddOnErr.length > 0 && 
                                                                            <span className='validationErrorLabel'>{this.state.errors.apiHitsForAddOnErr}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                                        Price of Api-Hits for Add-On (INR):<span className="addCustRequiredMarkStar"></span>
                                                                        </label>
                                                                    
                                                                        <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                            className="input-form"
                                                                            type= "number"
                                                                            value= {this.state.totalPriceOnSelectedDurationINR}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>    

                                                    <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                        <div style={{flex:"1", paddingRight:"1rem"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                            Enter Discount %:
                                                            </label>
                                                        
                                                            <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                                className="input-form"
                                                                type= "number"
                                                                step="any"
                                                                min={0}
                                                                max={100}
                                                                value= {this.state.discountPercentage}
                                                                onChange= {this.handleDiscountChange}
                                                            />  
                                                        </div>

                                                        <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                                Discount Amount (INR):
                                                            </label>
                                                        
                                                            <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                className="input-form"
                                                                type= "number"
                                                                value= {this.state.discountPriceINR}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                        <div style={{flex:"1", paddingRight:"1rem"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                            Enter Tax %:<span className="addCustRequiredMarkStar">*</span>
                                                            </label>
                                                        
                                                            <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                                className="input-form"
                                                                type= "number"
                                                                step="any"
                                                                min={0}
                                                                max={100}
                                                                value= {this.state.TaxPercentage}
                                                                onChange= {this.handleTaxChange}
                                                            />  
                                                            {this.state.errors.taxPercntErr.length > 0 && 
                                                                            <span className='validationErrorLabel'>{this.state.errors.taxPercntErr}</span>}
                                                        </div>

                                                        <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                                <span className="addCustRequiredMarkStar"></span>
                                                                Applicable Tax After Discount (INR):
                                                            </label>
                                                        
                                                            <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                className="input-form"
                                                                type= "number"
                                                                value= {this.state.TaxPriceINR}
                                                                readOnly
                                                                />
                                                        </div>
                                                    </div>
                                                                
                                                    <div className = "startAndEnddate" style={{display:"flex", justifyContent:"center", width:"100%",marginTop:"0.5rem"}}>
                                                        <div style={{width:"48%"}}>
                                                            <label className="reg-form-label" style={{width:"100%",textAlign:"center"}}>
                                                            {this.state.renewalType != null && this.state.renewalType.length > 0 && 
                                                                this.state.renewalType == HHM_API_RENEWADDON ?  "Total Payable Amount for Add-Ons Hits" : "Total Payable Amount for Package Renewal"}                                                        
                                                            </label>
                                                        
                                                            <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", fontWeight:"bolder"}}
                                                                className="input-form"
                                                                value= {this.state.totalPriceForApiPkgINR}
                                                                readOnly
                                                            /> 
                                                        </div>
                                                    </div>
                                                </div> 
                                                                                 
                                            <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                                <button style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                                marginLeft:"35%",
                                                                backgroundColor: "var(--primaryColor)", color:"white",
                                                            }} className="btn-lg"
                                                >
                                                Save
                                                </button>
                                                {this.state.errors.others.length > 0 && 
                                                    <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>} 
                                            </div> 
                                        </div>
                                        }
                                        </div>
                                        }
                                    </div>
                                }
                                
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}
VcRenewHHmApiPkg.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcRenewHHmApiPkg
