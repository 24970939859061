import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { AppRelevantDataContext } from '../../AppContext';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters, useRowSelect }  from 'react-table';
import axios from 'axios';
import DateTimePicker from 'react-datetime-picker';
import { FaSearch, FaEdit, FaIoMdPersonAdd, FaEye, FaFilePdf} from 'react-icons/fa';
import { getAPIHostURL, getDevcSwLocnOnSrvr } from '../../ClientConfig';
import { getLocalTimezoneOffset, convertLocalDateToDisplayToday, convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateStringToLocaleString} from '../../vtUtil';
import '../CSS/VcOrder.css'
import { IDS_RegistEmailError, IDS_PrevBtn, IDS_NxtBtn, IDS_Search, IDS_TodaySearch} from '../../VcLanguage';
import { ORDER_STATE_PROCESSING, ORDER_STATE_MARKED_FOR_SHIPPING, ITM_STATE_READY_FOR_SHIPMENT, ORDER_TYPE_DEMO, ORDER_TYPE_SALE, DAYS_IN_MONTH, ORDER_TYPE_RENTAL, ORDER_TYPE_API} from '../../VcConstants';
import jsPDF from "jspdf";
import "jspdf-autotable"; 
import '../CSS/Logintest.css'
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa'
import { AiOutlineEnter } from 'react-icons/ai'
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"0.6rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.6rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, renderBasedOnTable, getCellProps = defaultPropGetter, passedStateVariable}) => {

    const [selectedRowIndexforUpdate, setSelectedRowIndex] = useState(null);

    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { 
                pageIndex: 0,
                // set page size based on tables
                pageSize: (renderBasedOnTable == "CustAllOrderTable" || renderBasedOnTable == "productdataTable" || 
                    renderBasedOnTable == "CustSelectedOrderDetailsTable") ? 5 : (renderBasedOnTable == "CustomerDetailsDataTable2" || renderBasedOnTable == "CustomerDetailsDataTable1") ?
                    1: renderBasedOnTable == "custFulfilledOrderDetailsTable" && passedStateVariable[0].length > 10 ? 10 : passedStateVariable[0].length + 1,
                // hide and show columns based on condition
                hiddenColumns: (passedStateVariable[1] == "CreateOrder" && renderBasedOnTable == "CustAllOrderTable") || 
                    (renderBasedOnTable == "productdataTable" && (passedStateVariable[2] == true ||
                        passedStateVariable[3] == true || passedStateVariable[4])) 
                    ? [] 
                    : ["edit"]
                },
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );
    
    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp; {renderBasedOnTable == "CustomerDetailsDataTable1" && !passedStateVariable[7] ? "No Customer Data Found." 
                        : renderBasedOnTable == "CustAllOrderTable" && !passedStateVariable[7] && passedStateVariable[5] ? "No Order Data Found."
                        : renderBasedOnTable == "CustAllOrderTable" && !passedStateVariable[7] && ! passedStateVariable[5] ? "No Api Order Data Found." 
                        : renderBasedOnTable == "CustSelectedOrderDetailsTable" && !passedStateVariable[7] && passedStateVariable[8] ? "No Order Data Found."
                        : renderBasedOnTable == "CustSelectedOrderDetailsTable" && passedStateVariable[7] && !passedStateVariable[8] ? "No Api Order Data Found."
                        : renderBasedOnTable == "CustomerDetailsDataTable2" && !passedStateVariable[7] ? "Customer Not Selected" 
                        : renderBasedOnTable == "productdataTable" && !passedStateVariable[7] ? "No Product Details"
                        : renderBasedOnTable == "custFulfilledOrderDetailsTable" && !passedStateVariable[7] ? "No Data Found." 
                        : ""}
                    </span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead hidden = {renderBasedOnTable == "CustomerDetailsDataTable1" || renderBasedOnTable == "CustomerDetailsDataTable2" 
                                        || renderBasedOnTable == "CustSelectedOrderDetailsTable" || renderBasedOnTable == "productdataTable" ? true : false}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                    onClick = {(e, handleOriginal) => {
                                        setSelectedRowIndex(row.index);
                                    }}     
                                    style={{
                                        cursor: '',
                                        background: row.index === selectedRowIndexforUpdate ? '#6c95ba' : '',
                                        color: row.index === selectedRowIndexforUpdate ? 'white' : 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {
                                            style: cell.column.style,
                                            },
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>
                        //                 {renderBasedOnTable == "CustomerDetailsDataTable1" && !passedStateVariable[7] ? "No Customer Data Found." 
                        //                     : renderBasedOnTable == "CustAllOrderTable" && !passedStateVariable[7] && passedStateVariable[5] ? "No Order Data Found."
                        //                     : renderBasedOnTable == "CustAllOrderTable" && !passedStateVariable[7] && ! passedStateVariable[5] ? "No Api Order Data Found." 
                        //                     : renderBasedOnTable == "CustSelectedOrderDetailsTable" && !passedStateVariable[7] && passedStateVariable[8] ? "No Order Data Found."
                        //                     : renderBasedOnTable == "CustSelectedOrderDetailsTable" && passedStateVariable[7] && !passedStateVariable[8] ? "No Api Order Data Found."
                        //                     : renderBasedOnTable == "CustomerDetailsDataTable2" && !passedStateVariable[7] ? "Customer Not Selected" 
                        //                     : renderBasedOnTable == "productdataTable" && !passedStateVariable[7] ? "No Product Details"
                        //                     : renderBasedOnTable == "custFulfilledOrderDetailsTable" && !passedStateVariable[7] ? "No Data Found." 
                        //                     : ""}
                        //             </span>
                        //         </td>
                        //     </tr>
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            {renderBasedOnTable !== "CustomerDetailsDataTable1" && renderBasedOnTable !== "CustomerDetailsDataTable2" ?
                <div className='w-100 d-flex justify-content-end antdPagination'>
                    <div className='d-flex py-1 justify-content-end align-items-center'>
                        <Pagination showQuickJumper 
                            defaultCurrent={pageIndex + 1} 
                            total={pageOptions.length * 10}
                            // total={10 * 10} 
                            onChange={(page) => gotoPage(page - 1)} 
                            showSizeChanger={false}
                            current={pageIndex + 1} 
                        />

                        <Select
                            style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                            aria-label="rows per page"
                            className="paginationDropdown mySelector"
                            value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                            onChange={e => setPageSize(Number(e))} 
                            options={[
                                {
                                    label: "5 rows",
                                    value: 5
                                },
                                {
                                    label: "10 rows",
                                    value: 10
                                },
                                {
                                    label: "20 rows",
                                    value: 20
                                },
                                {
                                    label: "25 rows",
                                    value: 25
                                },
                                {
                                    label: "100 rows",
                                    value: 100
                                }
                            ]} // Assuming options is the array of options for the Select component
                        />
                    </div>
                </div> :
                <div/>
            }

            {/* <div hidden = {(renderBasedOnTable == "CustomerDetailsDataTable2" || renderBasedOnTable == "CustomerDetailsDataTable1" || 
                    (renderBasedOnTable == "custFulfilledOrderDetailsTable" &&  passedStateVariable[0].length == 1)) ? true : false}>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcOrder (props) {
    const searchStringStyle = {fontSize: "0.6rem", widht:"85%"};

    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if((id != "RequiredDate") && (id != "FulfilledDate") && (id != "lastModifiedTime") && (id != "OrderDate") && (id != "FulFilledOrderDtTm")) {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                        : false
            })
        } else {
            return rows.filter(row => {

                let splittedStr =row.values[id] == null ? null : row.values[id].split(" ");
                const rowValue = row.values[id]

                if(splittedStr != null) {
                        
                    if(String(t(IDS_TodaySearch).toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                                new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                                new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                                new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                    ) {

                        // Only for the case where the string entered in the filter box is present in cell
                        // then show all the rows which includes the input string in the final result (by returning true)
                        // It will return only those rows which contains a current date.                
                        return true;
                    } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                                new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                                new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                                String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                        // only for A "Today" case where Searching will always happens in a Time String.
                        return true;
                    } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                    ) {
                        // It will return only those rows which should not contains a current date.
                        return false;
                    }
                    else {
                        return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    }
                } else {
                    return(false)
                }

            })
        }
    };

    const [state, setState] = useState({            
        errors: {
            others: "",
            errRelatedToAssigningState: "",
            succsessRelatedToAssigningState: "",
            isOrderTypeSelected: "",
            errApiOrder: "",
        },
        TotalQuantityOrdered: 0,
        TotalQyantityFulfilled: 0,
        TotalApiOneTimeChargePriceForPdf: 0,
        TotalApiOrderPriceWithoutTax: 0,
        TotalApiDiscountPrice: 0,
        TotalApiDiscountedPrice: 0,
        TotalPriceForPDF: 0,
        TotalTaxForPDF: 0,
        TotalSubscriptionPriceForPDF: 0,
        TotalSubscriptionTaxForPDF: 0,
        TotalOrderValueForPDF: 0,
        TotalSecurityDepositPrice: 0,
        TotalOneTimeChargePrice: 0,
        TotalOneTimeChargeTaxPrice: 0,
        page: 0,
        selectedRowIndex: null,
        SelectedCustNewOrderDetails: false,
        OrderNumber: null,
        OrderLineNumber: null,
        PackageID: null,
        QuantityOfUnitsScanned: null,
        QuantityOfUnitsScannedForApiOrder: null,
        formViewMode: '',
        bEditOrder: false,
        itemIDQRCodeFlag: false,
        backdrop: 'static',
        SelectedCustomerOrder:'',
        selectedOrderNumber: '',
        selectedOrderDetailsArr: [],
        CustomerOrderDetails: [],
        selectedOrderDetailsArrForApiOrder: [],
        CustomerOrderDetailsForApiOrder: [],
        maxLastModifiedTimeOfOrderInfo: '',
        maxLastModifiedTimeOfApiOrderInfo: '',
        maxLastModifiedTimeOfSelectedOrderDetails: '',
        maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder: '',
        showFulfilledDetailsModal: false,
        showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi: false,
        showEditAndQuantityOfUnitsScannedwhenRental: false,
        showEditAndQuantityOfUnitsScannedwhenApi: false,
        custFulfilledDetailsTableData: [],
        CustAllOrderData: [],
        CustAllApiOrderData: [],
        CustSelectedOrderDetails: [],
        CustSelectedOrderDetailsForApiOrder: [],
        isOrderWithFreeSubscription: false,
        isOrderInProcessingState: false,
        ProductsSubscriptionInfo: [],
        ExtApiPackageInfo : [],
        selectedSubscriptionPeriod: '1',
        SubscriptionPeriodPerUnit: null,
        ArrForOrderType: [ORDER_TYPE_DEMO, ORDER_TYPE_SALE, ORDER_TYPE_RENTAL],
        isSubscriptionInfoMissingForRental: true,
        createEditOrder: true,
        orderTypeApi: ORDER_TYPE_API,
        selectedDurationForApiOrder: "",
        apiHitOnSelectedDuration: "",
        totalTaxPrcntForSelectedDuration: "",
        totalTaxPriceForSelectedDuration: "",
        daysBasedOnSelectedDurationForApiOrder: "",
        finalTotalOrderPrice: "",
        totalOrderPriceWithoutTax: "",
        discountPercntForTotalOrderPrice: 0,
        discountPriceForTotalOrderPrice: 0,
        BasePkgStructForApiOrder: {},
        convertedPkgDuration: [],
        convertedPeriodInMonths: "",
        newEnteredQuantityOfUnitForApiOrder: 1,
        switchOrderMenus: false,
        disableOrderMenus: false,
        CustAllOrdercolumns:[
            {   
                Header:() => <div className="ShowOrderTableHeader">View FullFilled<br/>Details</div>, 
                accessor: "viewFullFiledDetails",
                width: 130,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
                disableFilters: true,
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Edit</div>, 
                accessor: "edit",
                width: 75,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
                disableFilters: true,
                Cell: (props) => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Download</div>, 
                accessor: "Download",
                width: 110,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
                disableFilters: true,
                Cell: (props) => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Type</div>,  
                accessor: 'OrderType',
                filter: filterCaseInsensitive,
                width: 90,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Number</div>,  
                accessor: 'OrderNumber',
                filter: filterCaseInsensitive,
                width: 90,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Order Date</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'OrderDate',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = firstRow.original[columnId] == null ? "" : new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = secondRow.original[columnId] == null ? "" : new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Required Date</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'RequiredDate',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = firstRow.original[columnId] == null ? "" : new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = secondRow.original[columnId] == null ? "" : new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Fulfilled Date</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'FulfilledDate',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = firstRow.original[columnId] == null ? "" : new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = secondRow.original[columnId] == null ? "" : new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Status</div>,  
                accessor: 'Status',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Grouping <br/> Organization <br/> Name</div>,  
                accessor: 'OrderOfOrganisation',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">SalesRepUserID</div>,  
                accessor: 'SalesRepUserID',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Comments</div>,  
                accessor: 'Comments',
                filter: filterCaseInsensitive,
                width: 170,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
        ],

        CustAllApiOrdercolumns:[
            {   
                Header:() => <div className="ShowOrderTableHeader">Edit</div>, 
                accessor: "edit",
                width: 75,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
                disableFilters: true,
                Cell: (props) => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Download</div>, 
                accessor: "Download",
                width: 110,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
                disableFilters: true,
                Cell: (props) => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Type</div>,  
                accessor: 'OrderType',
                filter: filterCaseInsensitive,
                width: 90,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Number</div>,  
                accessor: 'OrderNumber',
                filter: filterCaseInsensitive,
                width: 90,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Order Date</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'OrderDate',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = firstRow.original[columnId] == null ? "" : new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = secondRow.original[columnId] == null ? "" : new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Required Date</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'RequiredDate',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = firstRow.original[columnId] == null ? "" : new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = secondRow.original[columnId] == null ? "" : new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Status</div>,  
                accessor: 'Status',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Grouping <br/> Organization <br/> Name</div>,  
                accessor: 'OrderOfOrganisation',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">SalesRepUserID</div>,  
                accessor: 'SalesRepUserID',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Comments</div>,  
                accessor: 'Comments',
                filter: filterCaseInsensitive,
                width: 170,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
        ],

        CustSelectedOrderDetailsColumns: [
            {   
                Header:() => <div className="ShowOrderTableHeader">View FullFilled<br/>Details</div>, 
                accessor: "viewFullFiledDetails",
                width: 130,
                disableFilters: true,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Line No</div>, 
                accessor: 'OrderLineNumber',
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>No</div>, 
                accessor: 'OrderNumber',
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Line</div>, 
                accessor: 'ProductLine',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'ProductName',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign: "right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
        
            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of<br/>Unit Ordered</div>,  
                accessor: 'QuantityOfUnitOrdered',
                width: 120,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of<br/>Unit Scanned</div>,  
                accessor: 'QuantityOfUnitScanned',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Status</div>,  
                accessor: 'Status',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Price Per<br/>Unit Order (INR)</div>,  
                accessor: 'PricePerUnitOrder',
                width: 135,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax %<br/> Per Unit Order</div>,  
                accessor: 'TaxPercentPerUnitOrder',
                width: 135,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax Per Unit<br/>Order (INR)</div>,  
                accessor: 'TaxPerUnitOrder',
                width: 120,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Period (Month) <br/>Per Unit Order</div>,  
                accessor: 'SubscriptionPeriodPerUnitOrder',
                width: 220,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },  
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Price Per <br/>Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionPricePerUnitOrder',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Tax %<br/>Per Unit Period</div>,  
                accessor: 'SubscriptionPercentagePerUnitOrder',
                width: 160,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Tax Per <br/> Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionTaxPerUnitOrder',
                disableFilters: true,
                width: 200,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order Items<br/>Value (INR)</div>,  
                accessor: 'OrderItemsValue',
                disableFilters: true,
                width: 130,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            
        ],

        CustSelectedOrderDetailsColumnsForApiOrder: [
            {       
                Header:() => <div className="ShowOrderTableHeader">PackageID</div>, 
                accessor: 'PackageID',
                width: 160,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Line No</div>, 
                accessor: 'OrderLineNumber',
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>No</div>, 
                accessor: 'OrderNumber',
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Line</div>, 
                accessor: 'ProductLine',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'ProductName',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign: "right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of<br/>Unit Ordered</div>,  
                accessor: 'QuantityOfUnitOrdered',
                width: 120,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of<br/>Unit Scanned</div>,  
                accessor: 'QuantityOfUnitScanned',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Status</div>,  
                accessor: 'Status',
                disableFilters: true,               
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">One Time Charge<br/>Per Order (INR)</div>,  
                accessor: 'OneTimeChargePerUnitOrder',
                width: 135,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Package Duration<br/>(Month)</div>,  
                accessor: 'PackageDurationOnApiOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">API Hits Per<br/>Package Duration</div>,  
                accessor: 'ApiHitsPerPackageDuration',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Total Order Price<br/>without Tax</div>,  
                accessor: 'PricePerUnitOrder',
                disableFilters: true,
                width: 170,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Discount % on<br/>Total Order Price</div>,  
                accessor: 'DisctPrcntPerPkg',
                disableFilters: true,
                width: 170,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Discount Amount on<br/>Total Order Price</div>,  
                accessor: 'DisctPricePerPkg',
                disableFilters: true,
                width: 170,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax % on<br/>Discounted Amount</div>,  
                accessor: 'TaxPercentPerUnitOrder',
                disableFilters: true,
                width: 170,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax Price on<br/>Discounted Amount</div>,  
                accessor: 'TaxPerUnitOrder',
                disableFilters: true,
                width: 210,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/> Items Value (INR)</div>,  
                accessor: 'OrderItemsValue',
                disableFilters: true,
                width: 170,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },      
        ],

        // this columns are used when the order type is "Rental"
        // as there are diffrent price columns for Rental.
        CustSelectedOrderDetailsColumnsForRentalOrder: [
            {   
                Header:() => <div className="ShowOrderTableHeader">View FullFilled<br/>Details</div>, 
                accessor: "viewFullFiledDetails",
                width: 130,
                disableFilters: true,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Line No</div>, 
                accessor: 'OrderLineNumber',
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>No</div>, 
                accessor: 'OrderNumber',
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Line</div>, 
                accessor: 'ProductLine',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'ProductName',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign: "right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },
        
            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of<br/>Unit Ordered</div>,  
                accessor: 'QuantityOfUnitOrdered',
                width: 120,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of<br/>Unit Scanned</div>,  
                accessor: 'QuantityOfUnitScanned',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Status</div>,  
                accessor: 'Status',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Security Deposit<br/>Per Unit Order (INR)</div>,  
                accessor: 'SecurityDepositPerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">One Time Charge <br/> Per Unit Order (INR)</div>,  
                accessor: 'OneTimeChargePerUnitOrder',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax % on One Time Charge<br/> Per Unit Order</div>,  
                accessor: 'TaxPercentageOnOneTimeChargePerUnitOrder',
                width: 200,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax Price on One Time Charge<br/> Per Unit Order (INR)</div>,  
                accessor: 'TaxPriceOnOneTimeChargePerUnitOrder',
                width: 250,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Period (Month) <br/>Per Unit Order</div>,  
                accessor: 'SubscriptionPeriodPerUnitOrder',
                width: 210,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Price <br/>Per Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionPricePerUnitOrder',
                width: 210,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Tax %<br/> Per Unit Period</div>,  
                accessor: 'SubscriptionPercentagePerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Tax Price<br/> Per Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionTaxPerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"

                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order Items<br/>Value (INR)</div>,  
                accessor: 'OrderItemsValue',
                width: 130,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },
        ],
        // =========================================== 

        AllProductsInformation: [],
        AllRentalProductInformation: [],
        ProductLine: [],
        ProductName: [],
        ProductNameForApiOrder: [],
        selectedProductLine: "",
        selectedProductName: "",
        ProductCode: '',
        PricePerUnitINR: '',
        securityDepositPrice: '',
        oneTimeChargePrice: '',
        TaxPercentagePerUnit: '',
        TaxPerUnitINR: '',
        taxPercentageForOneTimeChargePrice: "",
        taxPriceForOneTimeCharge: "",
        rentaltaxPercentagePerUnit: null,
        rentaltaxPricePerUnit: null,
        SubscriptionPricePerUnitINR: null,
        rentalPricePerUnitINR: null,
        SubcriptionTaxPerUnitINR: null,
        SubcriptionTaxPercentagePerUnitINR: null,
        TotalOrderValue:0,
        OrderItemsValue: 0,
        newEnteredQuantityOfUnit: "",
        // ====================================               
        selectCustomerDetails: false,
        CustID: '',
        CustName: '',
        OrganisationName: '',
        CustEmailID: '',
        CustPhoneNo: '',
        OrderStatus: 'Placed',
        Comment: '',
        OrderDateTime: new Date(),
        RequireOrderTime: null,
        selectedOrderType: '',
        OrderType: ORDER_TYPE_SALE,
        loggedInUserID: "",
        data: [
            // { MeasuredParam : 'TEMP, Value: 25, Message: 'Temprature has crossed minimum limit', Notification Sent: 'Yes', Minimum Limit Value: 20, Maximum Limit Value: 60, CreatedByDeviceTime: 2019-03-13 12:40:32, ReceivedFromDeviceTime: 2019-03-13 12:40:32, NotificationSentTime: 2019-03-13 12:40:32 },
        ],
        productdata: [

        ],
        showCol: true,
        productColumns: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Edit</div>, 
                accessor: "edit",
                width: 75,
                // show: false,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
    
                }),
                disableFilters: true,
                Cell:  (props) => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Line</div>, 
                accessor: 'ProductLine',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'ProductName',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of <br/> Unit Ordered</div>,  
                accessor: 'QuantityOfUnitOrdered',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of <br/> Unit Scanned</div>,  
                accessor: 'QuantityOfUnitsScanned',
                // show: false,
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Price Per <br/>Unit Order (INR)</div>,  
                accessor: 'PricePerUnitOrder',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax %<br/> Per Unit Order</div>,  
                accessor: 'TaxPercentPerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax Per Unit <br/> Order (INR)</div>,  
                accessor: 'TaxPerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Period (Month) <br/>Per Unit Order</div>,  
                accessor: 'SubscriptionPeriodPerUnitOrder',
                width: 210,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Price Per <br/>Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionPricePerUnitOrder',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Tax %<br/>Per Unit Period</div>,  
                accessor: 'SubscriptionPercentagePerUnitOrder',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Subscription Tax Per <br/> Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionTaxPerUnitOrder',
                width: 190,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/> Items Value (INR)</div>,  
                accessor: 'OrderItemsValue',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
        ],

        productColumnsForApiOrder: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Edit</div>, 
                accessor: "edit",
                width: 75,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
    
                }),
                disableFilters: true,
                Cell:  (props) => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Line</div>, 
                accessor: 'ProductLine',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'ProductName',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of <br/> Unit Ordered</div>,  
                accessor: 'QuantityOfUnitOrdered',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of <br/> Unit Scanned</div>,  
                accessor: 'QuantityOfUnitsScanned',
                width: 170,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">One Time Charge<br/>Per Order (INR)</div>,  
                accessor: 'OneTimeChargePerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Package Duration<br/>(Month)</div>,  
                accessor: 'PackageDurationOnApiOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">API Hits Per<br/>Package Duration</div>,  
                accessor: 'ApiHitsPerPackageDuration',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Total Order Price<br/>without Tax</div>,  
                accessor: 'PricePerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Discount % on<br/>Total Order Price</div>,  
                accessor: 'DisctPrcntPerPkg',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Discount Amount on<br/>Total Order Price</div>,  
                accessor: 'DisctPricePerPkg',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax % on<br/>Discounted Amount</div>,  
                accessor: 'TaxPercentPerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax Price on<br/>Discounted Amount</div>,  
                accessor: 'TaxPerUnitOrder',
                width: 210,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/> Items Value (INR)</div>,  
                accessor: 'OrderItemsValue',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
        ],

        // this columns are used when the order type is "Rental"
        // as there are diffrent columns for Rental.
        rentalOrderColumns: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Edit</div>, 
                accessor: "edit",
                width: 75,
                style:({
                    fontSize:"0.6rem",
                    height:"2rem",
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
    
                }),
                disableFilters: true,
                Cell:  props => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Line</div>, 
                accessor: 'ProductLine',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'ProductName',
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 100,
                disableFilters: true,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of <br/> Unit Ordered</div>,  
                accessor: 'QuantityOfUnitOrdered',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Quantity Of <br/> Unit Scanned</div>,  
                accessor: 'QuantityOfUnitsScanned',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Security Deposit<br/>Per Unit Order (INR)</div>,  
                accessor: 'SecurityDepositPerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">One Time Charge <br/> Per Unit Order (INR)</div>,  
                accessor: 'OneTimeChargePerUnitOrder',
                width: 180,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax % on One Time Charge<br/> Per Unit Order</div>,  
                accessor: 'TaxPercentageOnOneTimeChargePerUnitOrder',
                width: 200,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Tax Price on One Time Charge<br/> Per Unit Order (INR)</div>,  
                accessor: 'TaxPriceOnOneTimeChargePerUnitOrder',
                width: 250,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Period (Month) <br/>Per Unit Order</div>,  
                accessor: 'SubscriptionPeriodPerUnitOrder',
                width: 210,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Price <br/>Per Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionPricePerUnitOrder',
                width: 210,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Tax %<br/> Per Unit Period</div>,  
                accessor: 'SubscriptionPercentagePerUnitOrder',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Rental Tax Price<br/> Per Unit Period Month (INR)</div>,  
                accessor: 'SubscriptionTaxPerUnitOrder',
                width: 220,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/> Items Value (INR)</div>,  
                accessor: 'OrderItemsValue',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
        ],
        columns: [
            {       
                Header:() => <div className="ShowOrderTableHeader">Customer<br/>ID</div>, 
                accessor: 'CustID',
                width: 230,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Customer Name</div>,  
                accessor: 'CustName',
                width: 200,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Customer Company/Org. Name</div>,  
                accessor: 'OrganisationName',
                width: 270,
                disableFilters: true,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
    
            {       
                Header:() => <div className="ShowOrderTableHeader">Customer PhoneNo</div>,  
                accessor: 'CustPhoneNo',
                width: 200,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Customer EmailID</div>,  
                accessor: 'CustEmailID',
                width: 200,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },                         
        ],

        custFulfilledOrderDetailscolumns: [
            {       
                Header:() => <div className="ShowOrderTableHeader">Item ID</div>, 
                accessor: 'FulFilledItemID',
                width: 180,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Replaced DeviceID</div>, 
                accessor: 'FulFilledReplacedDevcID',
                width: 180,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Line No</div>, 
                accessor: 'FulFilledOrderLineNumber',
                width: 90,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Order<br/>No</div>, 
                accessor: 'FulFilledOrderNumber',
                width: 90,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Line</div>, 
                accessor: 'FulFilledOrderProductLine',
                width: 90,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'FulFilledOrderProductName',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            }, 
            {       
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Code</div>,  
                accessor: 'FulFilledOrderProductCode',
                width: 90,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "right",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Grouping <br/> Organization <br/> Name</div>,  
                accessor: 'OrderOfOrganisation',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Status</div>,  
                accessor: 'FulFilledOrderStatus',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
    
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Fulfilled<br/>Order Date</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'FulFilledOrderDtTm',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    fontSize: "0.7rem",
                    height: "2rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
            },
            
        ],
        //  -------------------------------Set ProductItemState -----------------------
        objPrivilege: props.objPrivilege,
        enableFullfillOrder: false,
        arrProductItemState: [],
        disabledBtnOfFullFillOrder: false,
        showCustomerOrderDetails: false,
        ItemID: "",
        alreadyPresentProductLine: "",
        alreadyPresentProductName: "",
        alreadyPresentProductID: "",
        arrProductTypeAndItsRelevantInfo: [],
        arrProductLine: [],
        arrProductStates: [],
        arrProductStateSeq: [],
        enteredProductItemIdInfo: [],
        currStateOfProduct: "",
        nextStateToBeAssignedToProduct:"",
        nextStateIDToBeAssignedToProduct: 0,
        objToSendToOrderPg: {},
        isAllOrderFullfill: true,
        isAllOrderFullFillSendsEmailTeam: false,
        arrDevicesHavingSubscription: [],
        AllOrganisationInformation: [],
        selectedOrganisationName: '',
        selectedProductCodeForApiOrder: "",
        selectedProductLineForApiOrder:"",
        selectedProductNameForApiOrder:"",
        oneTimeChargePerUnitINRForApiOrder:"",
        PricePerDayINRForApiOrder: "",
        PricePerHitINRForApiOrder: "",
        TaxPrcntINRForApiOrder: "",
    });

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        if(inLogTime == null) {
            return inLogTime;
        }

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const onCreateOrder = (rowInfo, column) => {
        let modifiedState = state;

        if(modifiedState.selectedOrderNumber == rowInfo.original.OrderNumber) {
            return;
        }
        
        if(modifiedState.arrProductItemState.length > 0) {
            let strMsg = `You have unsaved changes. Your scanned item list will be lost. Do you want to proceed without Saving?`;

            if(window.confirm(strMsg)) {
                modifiedState.arrProductItemState = [];
            } else {
                return;
            }
        }

        // To avoid appending of array values.
        modifiedState.selectedOrderDetailsArr = [];
        modifiedState.selectedOrderDetailsArrForApiOrder = [];
        modifiedState.CustSelectedOrderDetails = [];
        modifiedState.CustSelectedOrderDetailsForApiOrder = [];
        modifiedState.disabledBtnOfFullFillOrder = false;
        modifiedState.enableFullfillOrder = false;
        modifiedState.isAllOrderFullfill = false;
        modifiedState.errors.errRelatedToAssigningState = "";
        modifiedState.errors.succsessRelatedToAssigningState = "";
        modifiedState.errors.others = "";
        modifiedState.errors.isOrderTypeSelected = "";
        modifiedState.errors.errApiOrder = "";

        modifiedState.SelectedCustomerOrder = rowInfo.original;
        modifiedState.selectedOrderNumber = modifiedState.SelectedCustomerOrder.OrderNumber;

        modifiedState.showCustomerOrderDetails = true;

        if(modifiedState.SelectedCustomerOrder["Status"] == ORDER_STATE_MARKED_FOR_SHIPPING) {
            modifiedState.disabledBtnOfFullFillOrder = true;
        }
        // to show selected order details push into 'selectedOrderDetailsArr'.
        for(let i=0; i< modifiedState.CustomerOrderDetails.length; i++) {
            if(modifiedState.CustomerOrderDetails[i]["OrderNumber"] == modifiedState.selectedOrderNumber) {
                modifiedState.selectedOrderDetailsArr.push(modifiedState.CustomerOrderDetails[i]);
            }
        }

        const receiveOrderDetailsData = modifiedState.selectedOrderDetailsArr;
        
        let noOfLogs = receiveOrderDetailsData.length;

        // order does not have details then setState.
        if(noOfLogs == 0) {
            setState({...modifiedState});
        }

        // for formatting order details
        for(let i = 0; i < noOfLogs; i++) {

            const selectedOrderDetails = receiveOrderDetailsData[i];
            let totalSubscriptionValue = 0;
            let SubscriptionPeriodInMonth = 0;

            modifiedState.OrderType = rowInfo.original.OrderType;

            // if the order type is other than api Order then perform listed operations.
            // the subscription price and tax which is stored in the database is multiplied by 30, in order to show price as per 1 month
            let subscriptionPricePerUnitINRtoShow = selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null ? 'NA' : (selectedOrderDetails.SubscriptionPricePerUnitOrderINR * 30).toFixed(2);
            let SubscriptionTaxPerUnitOrdertoShow = selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : ((selectedOrderDetails.SubscriptionTaxPrcntPUO / 100 ) * subscriptionPricePerUnitINRtoShow).toFixed(2);

            if(selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null &&  selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionPeriod == null) {
                totalSubscriptionValue = 0;
            } else {
                SubscriptionPeriodInMonth = selectedOrderDetails.SubscriptionPeriod / DAYS_IN_MONTH 
                totalSubscriptionValue = (Number(SubscriptionPeriodInMonth) * (Number(subscriptionPricePerUnitINRtoShow) + Number(SubscriptionTaxPerUnitOrdertoShow)));
            }

            // different caluclations are perfromed on the basis of order type.
            let selectedOrderItemsValue = (modifiedState.OrderType != null && modifiedState.OrderType.length > 0 && modifiedState.OrderType != ORDER_TYPE_RENTAL) ?
                (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.PricePerUnitOrderINR) + Math.abs(selectedOrderDetails.TaxPerUnitOrderINR) + Number(totalSubscriptionValue))).toFixed(2) : 
                (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.OneTimeChrgOnRentPUOI) + Math.abs(selectedOrderDetails.SecurityDepositOnRentPUOI) + Math.abs(selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI) + Number(totalSubscriptionValue))).toFixed(2);
            
            let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
        
            let singleOrderDetailinfo = {
                viewFullFiledDetails: selectedOrderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                OrderLineNumber: selectedOrderDetails.OrderLineNumber,
                OrderNumber:selectedOrderDetails.OrderNumber,
                ProductLine: selectedOrderDetails.ProductLine,
                ProductName: selectedOrderDetails.ProductName,
                ProductCode: selectedOrderDetails.ProductCode,
                QuantityOfUnitOrdered: selectedOrderDetails.QuantityOfUnitsOrdered,
                QuantityOfUnitScanned: selectedOrderDetails.QuantityOfUnitsScanned,
                PricePerUnitOrder: selectedOrderDetails.PricePerUnitOrderINR,
                TaxPercentPerUnitOrder: selectedOrderDetails.TaxPrcntPUO,
                TaxPerUnitOrder: selectedOrderDetails.TaxPerUnitOrderINR,
                SecurityDepositPerUnitOrder: selectedOrderDetails.SecurityDepositOnRentPUOI,
                OneTimeChargePerUnitOrder: selectedOrderDetails.OneTimeChrgOnRentPUOI,
                TaxPercentageOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPrcntOnOneTimeChrgPUO,
                TaxPriceOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI,
                OrderItemsValue: selectedOrderItemsValue,
                Status: selectedOrderDetails.Status,
                SubscriptionPricePerUnitOrder: subscriptionPricePerUnitINRtoShow,
                SubscriptionTaxPerUnitOrder: SubscriptionTaxPerUnitOrdertoShow,
                SubscriptionPercentagePerUnitOrder: selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : selectedOrderDetails.SubscriptionTaxPrcntPUO,
                SubscriptionPeriodPerUnitOrder: selectedOrderDetails.SubscriptionPeriod == null ? 'NA' : SubscriptionPeriodInMonth,
                LastModifiedUtcDtTm: selectedOrderDetails.LastModifiedUtcDtTm,
            }
            modifiedState.CustSelectedOrderDetails.push(singleOrderDetailinfo);
        }

        // if the order type is API Order then perform listed operations.
        for(let i=0; i< modifiedState.CustomerOrderDetailsForApiOrder.length; i++) {
            if(modifiedState.CustomerOrderDetailsForApiOrder[i]["OrderNumber"] == modifiedState.selectedOrderNumber) {
                modifiedState.selectedOrderDetailsArrForApiOrder.push(modifiedState.CustomerOrderDetailsForApiOrder[i]);
            }
        }

        const receiveOrderDetailsDataForApiOrder = modifiedState.selectedOrderDetailsArrForApiOrder;
        
        let noOfLogsForApiOrder = receiveOrderDetailsDataForApiOrder.length;

        // order does not have details then setState.
        if(noOfLogsForApiOrder == 0) {
            setState({...modifiedState});
        }

        // for formatting order details
        for(let i = 0; i < noOfLogsForApiOrder; i++) {

            const selectedOrderDetailsForApiOrder = receiveOrderDetailsDataForApiOrder[i];

            // Calculation for OrderItemsValue.
            let PackageDurationOnApiOrderInMonths  = (Number(selectedOrderDetailsForApiOrder.PackageDuration)) / 30;
            let DiscountedPrice = Number(selectedOrderDetailsForApiOrder.PricePerUnitOrderINR) - Number(selectedOrderDetailsForApiOrder.DisctPricePerPkg);
            let TaxOnDiscountedPrice = ((Number(DiscountedPrice)) * ((Number(selectedOrderDetailsForApiOrder.TaxPrcntPUO)) / 100)).toFixed(2);
            let selectedOrderItemsValueForApiOrder = (Number(DiscountedPrice) + Number(TaxOnDiscountedPrice));               
            
            let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;

            let singleOrderDetailinfo = {
                // viewFullFiledDetails: selectedOrderDetailsForApiOrder.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetailsForApiOrder.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                PackageID:  selectedOrderDetailsForApiOrder.PackageID,
                OrderLineNumber: selectedOrderDetailsForApiOrder.OrderLineNumber,
                OrderNumber:selectedOrderDetailsForApiOrder.OrderNumber,
                ProductLine: selectedOrderDetailsForApiOrder.ProductLine,
                ProductName: selectedOrderDetailsForApiOrder.ProductName,
                ProductCode: selectedOrderDetailsForApiOrder.ProductCode,
                QuantityOfUnitOrdered: selectedOrderDetailsForApiOrder.QuantityOfUnitsOrdered,
                QuantityOfUnitScanned: selectedOrderDetailsForApiOrder.QuantityOfUnitsScanned,
                PackageDurationOnApiOrder: PackageDurationOnApiOrderInMonths,
                ApiHitsPerPackageDuration: selectedOrderDetailsForApiOrder.ApiHitsInPkg,
                DisctPrcntPerPkg: selectedOrderDetailsForApiOrder.DisctPrcntPerPkg,
                DisctPricePerPkg: selectedOrderDetailsForApiOrder.DisctPricePerPkg,
                PricePerUnitOrder: selectedOrderDetailsForApiOrder.PricePerUnitOrderINR,
                TaxPercentPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPrcntPUO,
                TaxPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPerUnitOrderINR,
                OneTimeChargePerUnitOrder: selectedOrderDetailsForApiOrder.OneTimeChrgOnRentPUOI,
                OrderItemsValue: selectedOrderItemsValueForApiOrder.toFixed(2),
                Status: selectedOrderDetailsForApiOrder.Status,
                LastModifiedUtcDtTm: selectedOrderDetailsForApiOrder.LastModifiedUtcDtTm,
                PriceOfHitsInPkgINR: selectedOrderDetailsForApiOrder.PriceOfHitsInPkgINR,
                PricePerPkgDurINR: selectedOrderDetailsForApiOrder.PricePerPkgDurINR,
                PackageDuration: selectedOrderDetailsForApiOrder.PackageDuration
            };
            modifiedState.CustSelectedOrderDetailsForApiOrder.push(singleOrderDetailinfo);   
        }
        setState({...modifiedState});
    }

    const onEditOrderDetails = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.isOrderInProcessingState = false;
        
        if(rowInfo.original["Status"] == ORDER_STATE_MARKED_FOR_SHIPPING) {
            alert(`You can not modify this order detail as it is already fulfilled.`);
            return;
        }

        if(rowInfo.original["Status"] == ORDER_STATE_PROCESSING) {
            modifiedState.isOrderInProcessingState = true;
        }

        modifiedState.formViewMode = "editMode";
        modifiedState.SelectedCustNewOrderDetails = false;

        // if the order type is other than API Order then perform listed operations.
        if(modifiedState.createEditOrder) {

            modifiedState.selectedProductLine = rowInfo.original.ProductLine;
            modifiedState.selectedProductName = rowInfo.original.ProductName;
            modifiedState.ProductCode = rowInfo.original.ProductCode;
            modifiedState.newEnteredQuantityOfUnit = rowInfo.original.QuantityOfUnitOrdered;
            modifiedState.PricePerUnitINR = rowInfo.original.PricePerUnitOrder;
            modifiedState.TaxPercentagePerUnit = rowInfo.original.TaxPercentPerUnitOrder;
            modifiedState.TaxPerUnitINR = rowInfo.original.TaxPerUnitOrder;
            modifiedState.securityDepositPrice = rowInfo.original.SecurityDepositPerUnitOrder;
            modifiedState.oneTimeChargePrice = rowInfo.original.OneTimeChargePerUnitOrder;
            modifiedState.taxPercentageForOneTimeChargePrice = rowInfo.original.TaxPercentageOnOneTimeChargePerUnitOrder;
            modifiedState.taxPriceForOneTimeCharge = rowInfo.original.TaxPriceOnOneTimeChargePerUnitOrder;
            modifiedState.OrderItemsValue = rowInfo.original.OrderItemsValue;
            modifiedState.QuantityOfUnitsScanned = rowInfo.original.QuantityOfUnitsScanned;
            modifiedState.OrderNumber = rowInfo.original.OrderNumber;
            modifiedState.OrderLineNumber = rowInfo.original.OrderLineNumber;
            modifiedState.OrderStatus = rowInfo.original.Status;
            modifiedState.selectedSubscriptionPeriod = '1';
            modifiedState.SubscriptionPeriodPerUnit = (rowInfo.original.SubscriptionPeriodPerUnitOrder == 'NA') ? null : (rowInfo.original.SubscriptionPeriodPerUnitOrder);
            modifiedState.SubcriptionTaxPercentagePerUnitINR = (rowInfo.original.SubscriptionPercentagePerUnitOrder == 'NA') ? null : (rowInfo.original.SubscriptionPercentagePerUnitOrder);
            modifiedState.SubscriptionPricePerUnitINR = rowInfo.original.SubscriptionPricePerUnitOrder == 'NA' ? null : rowInfo.original.SubscriptionPricePerUnitOrder;
            modifiedState.SubcriptionTaxPerUnitINR = rowInfo.original.SubscriptionTaxPerUnitOrder == 'NA' ? null : rowInfo.original.SubscriptionTaxPerUnitOrder;
            modifiedState.rentalPricePerUnitINR = rowInfo.original.SubscriptionPricePerUnitOrder;
            modifiedState.rentaltaxPercentagePerUnit = rowInfo.original.SubscriptionPercentagePerUnitOrder;
            modifiedState.rentaltaxPricePerUnit = rowInfo.original.SubscriptionTaxPerUnitOrder;

            if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType != ORDER_TYPE_RENTAL){
                
                // if the order type is sale or demo and all Subscription Prices are equals to 0 
                // then make the isOrderWithFreeSubscription flag to true which means there is free subscription for this order
                // if the order type is sale or demo and all Subscription Prices are equals to "NA" then it means there is no subscription applicale for this order
                // then make the isOrderWithFreeSubscription flag to false which indicates there is no free subscription but anyway no subscription is applicale for this order
                // if both the cases are not true then make the isOrderWithFreeSubscription flag to false which indicates there is no free subscription for this order.
                if(rowInfo.original.SubscriptionPeriodPerUnitOrder != null && rowInfo.original.SubscriptionPeriodPerUnitOrder >= 1 && rowInfo.original.SubscriptionTaxPerUnitOrder != null && rowInfo.original.SubscriptionTaxPerUnitOrder == 0 &&
                    rowInfo.original.SubscriptionPricePerUnitOrder != null && rowInfo.original.SubscriptionPricePerUnitOrder == 0 && rowInfo.original.SubscriptionPercentagePerUnitOrder != null && 
                    rowInfo.original.SubscriptionPercentagePerUnitOrder == 0) {
                        modifiedState.isOrderWithFreeSubscription = true;

                } else if(rowInfo.original.SubscriptionPricePerUnitOrd == 'NA' && rowInfo.original.SubscriptionPeriodPerUnitOrder == 'NA' && rowInfo.original.SubscriptionTaxPerUnitOrder == 'NA'){
                    modifiedState.isOrderWithFreeSubscription = false;
                    modifiedState.SubscriptionPricePerUnitINR = null;
                    modifiedState.SubcriptionTaxPerUnitINR = null;
                    modifiedState.SubscriptionPeriodPerUnit = null;
                } else {
                    modifiedState.isOrderWithFreeSubscription = false;
                }
            } else{
                // if the order type is Rental and all rental Prices are equals to 0 
                // then make the isOrderWithFreeSubscription flag to true otherwise false
                if(rowInfo.original.SubscriptionPeriodPerUnitOrder != null && rowInfo.original.SubscriptionPeriodPerUnitOrder >= 1 && rowInfo.original.SubscriptionPricePerUnitOrder != null && rowInfo.original.SubscriptionPricePerUnitOrder == 0 &&
                    rowInfo.original.SubscriptionPercentagePerUnitOrder != null && rowInfo.original.SubscriptionPercentagePerUnitOrder == 0 && rowInfo.original.SubscriptionTaxPerUnitOrder != null && rowInfo.original.SubscriptionTaxPerUnitOrder == 0) {
                        modifiedState.isOrderWithFreeSubscription = true;
                } else {
                    modifiedState.isOrderWithFreeSubscription = false;
                }
            }

            modifiedState.selectCustomerDetails = true;

            for(let i=0; i< modifiedState.productdata.length; i++) {
                if(modifiedState.productdata[i].OrderLineNumber == 0 && 
                    modifiedState.productdata[i].ProductLine == rowInfo.original.ProductLine &&
                    modifiedState.productdata[i].OrderLineNumber == rowInfo.original.OrderLineNumber &&
                    modifiedState.productdata[i].ProductName == rowInfo.original.ProductName &&
                    modifiedState.productdata[i].ProductCode == rowInfo.original.ProductCode &&
                    modifiedState.productdata[i].QuantityOfUnitsScanned == rowInfo.original.QuantityOfUnitsScanned
                ) {
                    modifiedState.productdata.splice(i, 1);
                }
            }
        } else {
            // if the order type is API Order then perform listed operations.

            modifiedState.selectedProductLineForApiOrder = rowInfo.original.ProductLine;
            modifiedState.selectedProductNameForApiOrder = rowInfo.original.ProductName;
            modifiedState.selectedProductCodeForApiOrder = rowInfo.original.ProductCode;
            modifiedState.newEnteredQuantityOfUnitForApiOrder = rowInfo.original.QuantityOfUnitOrdered;
            modifiedState.oneTimeChargePerUnitINRForApiOrder = rowInfo.original.OneTimeChargePerUnitOrder;
            
            for(let i=0; i< modifiedState.ExtApiPackageInfo.length; i++) {
                if(modifiedState.ExtApiPackageInfo[i]["ProductCode"] == rowInfo.original.ProductCode) {
                    modifiedState.BasePkgStructForApiOrder = modifiedState.ExtApiPackageInfo[i]["BasePkgStruct"];      
                    // if the Product code fromm ExtApiPackageInfo is equals to the selected row product info 
                    // then fetch the json from ExtApiPackageInfo table and parse it to get the key value pairs             
                    let parsedJsonOfBasePkgStruct = JSON.parse(modifiedState.BasePkgStructForApiOrder);
                    let PkgStructKeyValue = parsedJsonOfBasePkgStruct.PkgStruct;
                    // fetch the keys from the json object
                    let obtainKeyOfPkgStruct = Object.keys(PkgStructKeyValue);
    
                    for(let i=0; i<obtainKeyOfPkgStruct.length; i++) {
                        if((Number(obtainKeyOfPkgStruct[i]))>=12) {
                            // if the key value is more than equals to 0 then divide the key value by 12.
                            let getRemainderOfObtainKeyOfPkgStruct =  Math.floor((Number(obtainKeyOfPkgStruct[i])) / 12);
                            // append the year word to the remainder which is obtained. 
                            let appendYearToRemainder = getRemainderOfObtainKeyOfPkgStruct + " " + "Year";
                            // To get the quotient.
                            var getQuotientOfObtainKeyOfPkgStruct = (Math.floor((Number(obtainKeyOfPkgStruct[i])) % 12));
                            // append Month word to the obtained quotient.
                            let appendMonthToQuotient = getQuotientOfObtainKeyOfPkgStruct + " " + "Month";
                            // append both the remaineder and quotient and push into the array.
                            let finalAppendedString = appendYearToRemainder + " " + appendMonthToQuotient;
                            modifiedState.convertedPkgDuration.push(finalAppendedString);

                            // console.log("modifiedState.convertedPkgDuration",modifiedState.convertedPkgDuration);
                        } else {
                            // if the he key value is less than 12 then append Month word to the key value and push into the array
                            let appendMonthToObtainKeyOfPkgStruct = (Number(obtainKeyOfPkgStruct[i])) + " " + "Month"
                            modifiedState.convertedPkgDuration.push(appendMonthToObtainKeyOfPkgStruct);

                            // console.log("modifiedState.convertedPkgDuration",modifiedState.convertedPkgDuration);
                        }

                        // so in modifiedState.convertedPkgDuration array, we will get all the converted values for months
                        // console.log("modifiedState.convertedPkgDuration",modifiedState.convertedPkgDuration);
                    }
                }
            }  

            let SelectedAndConvertedPkgDurationForEditOrder;

            if((Number(rowInfo.original.PackageDurationOnApiOrder))>=12) {
                // in order to get the converted value for selected month
                // divide the selected rowInfo.original.PackageDurationOnApiOrder by 12 and get the remainder
                let getRemainderOfObtainKeyOfPkgStruct =  Math.floor((Number(rowInfo.original.PackageDurationOnApiOrder)) / 12);
                let appendYearToRemainder = getRemainderOfObtainKeyOfPkgStruct + " " + "Year";
                var getQuotientOfObtainKeyOfPkgStruct = (Math.floor((Number(rowInfo.original.PackageDurationOnApiOrder)) % 12));
                let appendMonthToQuotient = getQuotientOfObtainKeyOfPkgStruct + " " + "Month";
                let finalAppendedString = appendYearToRemainder + " " + appendMonthToQuotient;
                SelectedAndConvertedPkgDurationForEditOrder = finalAppendedString;
            } else {
                let appendMonthToObtainKeyOfPkgStruct = (Number(rowInfo.original.PackageDurationOnApiOrder)) + " " + "Month";
                SelectedAndConvertedPkgDurationForEditOrder = appendMonthToObtainKeyOfPkgStruct;
            }

            modifiedState.selectedDurationForApiOrder = SelectedAndConvertedPkgDurationForEditOrder;
            modifiedState.apiHitOnSelectedDuration = rowInfo.original.ApiHitsPerPackageDuration;
            modifiedState.totalOrderPriceWithoutTax = rowInfo.original.PricePerUnitOrder;
            modifiedState.discountPercntForTotalOrderPrice = rowInfo.original.DisctPrcntPerPkg;
            modifiedState.discountPriceForTotalOrderPrice = rowInfo.original.DisctPricePerPkg;
            modifiedState.totalTaxPrcntForSelectedDuration = rowInfo.original.TaxPercentPerUnitOrder;
            modifiedState.totalTaxPriceForSelectedDuration = rowInfo.original.TaxPerUnitOrder;
            modifiedState.finalTotalOrderPrice = rowInfo.original.OrderItemsValue;
            modifiedState.OrderItemsValue = rowInfo.original.OrderItemsValue;
            modifiedState.QuantityOfUnitsScannedForApiOrder = rowInfo.original.QuantityOfUnitsScanned;
            modifiedState.OrderNumber = rowInfo.original.OrderNumber;
            modifiedState.OrderLineNumber = rowInfo.original.OrderLineNumber;
            modifiedState.OrderStatus = rowInfo.original.Status;
            modifiedState.PackageID = rowInfo.original.PackageID;

            modifiedState.selectCustomerDetails = true;

            for(let i=0; i< modifiedState.productdata.length; i++) {
                if(modifiedState.productdata[i].OrderLineNumber == 0 && 
                    modifiedState.productdata[i].PackageID == rowInfo.original.PackageID &&
                    modifiedState.productdata[i].ProductLine == rowInfo.original.ProductLine &&
                    modifiedState.productdata[i].OrderLineNumber == rowInfo.original.OrderLineNumber &&
                    modifiedState.productdata[i].ProductName == rowInfo.original.ProductName &&
                    modifiedState.productdata[i].ProductCode == rowInfo.original.ProductCode &&
                    modifiedState.productdata[i].QuantityOfUnitsScanned == rowInfo.original.QuantityOfUnitsScanned
                ) {
                    modifiedState.productdata.splice(i, 1);
                }
            }
        }

        setState({...modifiedState});
    }

    const onEditCustOrder = (rowInfo, column) => {

        let modifiedState = state;
        if(rowInfo.original["Status"] == ORDER_STATE_MARKED_FOR_SHIPPING) {
            alert(`You can not modify this order as it is already fulfilled.`);
            // make the  modifiedState.switchOrderMenus to true
            // on based on this bolean, clear the message when menus are switched
            modifiedState.switchOrderMenus = true;
            setState({...modifiedState});
            return;
        }

        modifiedState.SelectedCustomerOrder = rowInfo.original;
        modifiedState.selectedOrderType = rowInfo.original.OrderType;
        modifiedState.OrderType = modifiedState.SelectedCustomerOrder.OrderType;    
        
        // Based on the Order type show the edit icon in the respective react columns
        if(modifiedState.createEditOrder && modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType != ORDER_TYPE_RENTAL && modifiedState.selectedOrderType != ORDER_TYPE_API)){
            // then if the Order type is other than API and Rental then show the edit icon in the respective react columns 
            modifiedState.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi = true;
            for(let i=0; i<modifiedState.productColumns.length; i++) {
                if(modifiedState.productColumns[i]["accessor"] == "edit" || modifiedState.productColumns[i]["accessor"] == "QuantityOfUnitsScanned") {
                    modifiedState.productColumns[i]["show"] = true;
                    // break;
                }
            }
        } else if (modifiedState.createEditOrder && modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL){
            modifiedState.showEditAndQuantityOfUnitsScannedwhenRental = true;
            // then if the Order type is Rental then show the edit icon in the respective react columns 
            for(let i=0; i<modifiedState.rentalOrderColumns.length; i++) {
                if(modifiedState.rentalOrderColumns[i]["accessor"] == "edit" || modifiedState.rentalOrderColumns[i]["accessor"] == "QuantityOfUnitsScanned") {
                    modifiedState.rentalOrderColumns[i]["show"] = true;
                    // break;
                }
            }
        } else {
            modifiedState.showEditAndQuantityOfUnitsScannedwhenApi = true;
            // then if the Order type is API then show the edit icon in the respective react columns 
            for(let i=0; i<modifiedState.productColumnsForApiOrder.length; i++) {
                if(modifiedState.productColumnsForApiOrder[i]["accessor"] == "edit" || modifiedState.productColumnsForApiOrder[i]["accessor"] == "QuantityOfUnitsScanned") {
                    modifiedState.productColumnsForApiOrder[i]["show"] = true;
                    // break;
                }
            }
        }

        modifiedState.formViewMode = "editMode";

        modifiedState.productdata = [];
        modifiedState.selectedOrderDetailsArr = [];
        modifiedState.selectedOrderDetailsArrForApiOrder = [];

        modifiedState.OrderDateTime = new Date(rowInfo.original.OrderDate);
        modifiedState.OrderType = rowInfo.original.OrderType;
        modifiedState.RequireOrderTime = rowInfo.original.RequiredDate == null ? rowInfo.original.RequiredDate : new Date(rowInfo.original.RequiredDate);
        modifiedState.Status = rowInfo.original.Status;
        modifiedState.SalesRepUserID = rowInfo.original.SalesRepUserID;
        modifiedState.ShippedDate = rowInfo.original.ShippedDate;
        modifiedState.selectedOrderNumber = rowInfo.original.OrderNumber;
        modifiedState.Comment = rowInfo.original.Comments == null ? '' : rowInfo.original.Comments;
        modifiedState.OrderNumber= rowInfo.original.OrderNumber;
        modifiedState.selectedOrganisationName = rowInfo.original.OrderOfOrganisation; 

        for(let i=0; i < modifiedState.AllOrganisationInformation.length; i++){

            if(modifiedState.AllOrganisationInformation[i]['OrgName'] == rowInfo.original.OrderOfOrganisation && rowInfo.original.Status == 'Placed') {

                modifiedState.selectedOrganisationName = modifiedState.AllOrganisationInformation[i]['OrgID']
                break;
            } else if(rowInfo.original.OrderOfOrganisation != null && rowInfo.original.OrderOfOrganisation.length > 0 &&  rowInfo.original.Status == 'Placed' && rowInfo.original.OrderOfOrganisation == 'NA') {
                modifiedState.selectedOrganisationName = "";
            } 
        }

        // if the order type is other than API, perfrom below operations
        if(modifiedState.createEditOrder) {

            for(let i=0; i< modifiedState.CustomerOrderDetails.length; i++) {
                if(modifiedState.CustomerOrderDetails[i]["OrderNumber"] == modifiedState.selectedOrderNumber) {
                    modifiedState.selectedOrderDetailsArr.push(modifiedState.CustomerOrderDetails[i]);
                }
            }

            let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>

            for(let i=0; i<modifiedState.selectedOrderDetailsArr.length; i++) {

                let totalSubscriptionValue = 0;
                let SubscriptionPeriodInMonth = 0;

                // the subscription price and tax which is stored in the database is multiplied by 30, in order to show proce as per 1 month
                let subscriptionPricePerUnitINRtoShow = modifiedState.selectedOrderDetailsArr[i].SubscriptionPricePerUnitOrderINR == null ? 'NA' : Number(modifiedState.selectedOrderDetailsArr[i].SubscriptionPricePerUnitOrderINR * 30).toFixed(2);
                let SubscriptionTaxPerUnitOrdertoShow = modifiedState.selectedOrderDetailsArr[i].SubscriptionTaxPerUnitOrderINR == null && modifiedState.selectedOrderDetailsArr[i].SubscriptionTaxPrcntPUO == null ? 'NA' : 
                    ((Number(modifiedState.selectedOrderDetailsArr[i].SubscriptionTaxPrcntPUO) / 100 ) * subscriptionPricePerUnitINRtoShow).toFixed(2);
                    
                if(modifiedState.selectedOrderDetailsArr[i].SubscriptionPricePerUnitOrderINR == null &&  modifiedState.selectedOrderDetailsArr[i].SubscriptionTaxPerUnitOrderINR == null && modifiedState.selectedOrderDetailsArr[i].SubscriptionPeriod == null) {
                    totalSubscriptionValue = 0;
                } else {
                    SubscriptionPeriodInMonth = modifiedState.selectedOrderDetailsArr[i].SubscriptionPeriod / DAYS_IN_MONTH 
                    totalSubscriptionValue = (Number(SubscriptionPeriodInMonth) * (Number(subscriptionPricePerUnitINRtoShow) + Number(SubscriptionTaxPerUnitOrdertoShow)));
                }
                
                // Diffrent calculations are perfromed for Order type Rental
                if( modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)){
                    modifiedState.OrderItemsValue = (( Math.abs(modifiedState.selectedOrderDetailsArr[i].QuantityOfUnitsOrdered) * (Math.abs(modifiedState.selectedOrderDetailsArr[i].PricePerUnitOrderINR) + Math.abs(modifiedState.selectedOrderDetailsArr[i].TaxPerUnitOrderINR) + Number(totalSubscriptionValue)))).toFixed(2);
                    modifiedState.TotalOrderValue = (Math.abs(modifiedState.TotalOrderValue) + (( Math.abs(modifiedState.selectedOrderDetailsArr[i].QuantityOfUnitsOrdered) * (Math.abs(modifiedState.selectedOrderDetailsArr[i].PricePerUnitOrderINR) + Math.abs(modifiedState.selectedOrderDetailsArr[i].TaxPerUnitOrderINR) + Number(totalSubscriptionValue))))).toFixed(2);            
                } else {
                    modifiedState.OrderItemsValue = (( Math.abs(modifiedState.selectedOrderDetailsArr[i].QuantityOfUnitsOrdered) * (Math.abs(modifiedState.selectedOrderDetailsArr[i].SecurityDepositOnRentPUOI) + Math.abs(modifiedState.selectedOrderDetailsArr[i].OneTimeChrgOnRentPUOI) + Math.abs(modifiedState.selectedOrderDetailsArr[i].TaxPriceOnOneTimeChrgPUOI) + Number(totalSubscriptionValue)))).toFixed(2);
                    modifiedState.TotalOrderValue = (Math.abs(modifiedState.TotalOrderValue) + (( Math.abs(modifiedState.selectedOrderDetailsArr[i].QuantityOfUnitsOrdered) * (Math.abs(modifiedState.selectedOrderDetailsArr[i].SecurityDepositOnRentPUOI) + Math.abs(modifiedState.selectedOrderDetailsArr[i].OneTimeChrgOnRentPUOI)+ Math.abs(modifiedState.selectedOrderDetailsArr[i].TaxPriceOnOneTimeChrgPUOI) + Number(totalSubscriptionValue))))).toFixed(2);            
                }

                // from here the data goes to react columns and to database, based on order type.
                // if the order type is rental then all the Rental related columns will be shown in the react table and 
                // all the rental related values will be saved in the database 
                // all the values which are non rental will be passed as "NA" to the database 
                // the "NA" is passed instead of null beacuse the json data is passed to the database and json data doesnt allow null values
                // so on basis of "NA", null is stored in the database with the help of stored procedure.
                let singleProductData = {
                    edit: editIcon,
                    ProductLine: modifiedState.selectedOrderDetailsArr[i].ProductLine,
                    ProductName: modifiedState.selectedOrderDetailsArr[i].ProductName,
                    ProductCode: modifiedState.selectedOrderDetailsArr[i].ProductCode,
                    QuantityOfUnitOrdered: modifiedState.selectedOrderDetailsArr[i].QuantityOfUnitsOrdered,
                    PricePerUnitOrder: modifiedState.selectedOrderDetailsArr[i].PricePerUnitOrderINR == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].PricePerUnitOrderINR,
                    TaxPercentPerUnitOrder: modifiedState.selectedOrderDetailsArr[i].TaxPrcntPUO == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].TaxPrcntPUO,
                    TaxPerUnitOrder: modifiedState.selectedOrderDetailsArr[i].TaxPerUnitOrderINR == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].TaxPerUnitOrderINR,
                    SecurityDepositPerUnitOrder: modifiedState.selectedOrderDetailsArr[i].SecurityDepositOnRentPUOI == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].SecurityDepositOnRentPUOI,
                    OneTimeChargePerUnitOrder: modifiedState.selectedOrderDetailsArr[i].OneTimeChrgOnRentPUOI == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].OneTimeChrgOnRentPUOI,
                    TaxPercentageOnOneTimeChargePerUnitOrder: modifiedState.selectedOrderDetailsArr[i].TaxPrcntOnOneTimeChrgPUO == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].TaxPrcntOnOneTimeChrgPUO,
                    TaxPriceOnOneTimeChargePerUnitOrder: modifiedState.selectedOrderDetailsArr[i].TaxPriceOnOneTimeChrgPUOI == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].TaxPriceOnOneTimeChrgPUOI,
                    OrderItemsValue: modifiedState.OrderItemsValue,
                    QuantityOfUnitsScanned: modifiedState.selectedOrderDetailsArr[i].QuantityOfUnitsScanned == null ? 0 : modifiedState.selectedOrderDetailsArr[i].QuantityOfUnitsScanned,
                    OrderLineNumber: modifiedState.selectedOrderDetailsArr[i].OrderLineNumber == null ? 0 : modifiedState.selectedOrderDetailsArr[i].OrderLineNumber,
                    OrderNumber: modifiedState.selectedOrderDetailsArr[i].OrderNumber == null ? 0 : modifiedState.selectedOrderDetailsArr[i].OrderNumber,
                    Status: modifiedState.selectedOrderDetailsArr[i].Status == null ? null : modifiedState.selectedOrderDetailsArr[i].Status,
                    SubscriptionPricePerUnitOrder: subscriptionPricePerUnitINRtoShow,
                    SubscriptionTaxPerUnitOrder: SubscriptionTaxPerUnitOrdertoShow,
                    SubscriptionPercentagePerUnitOrder: modifiedState.selectedOrderDetailsArr[i].SubscriptionTaxPrcntPUO == null ? 'NA' : modifiedState.selectedOrderDetailsArr[i].SubscriptionTaxPrcntPUO,
                    SubscriptionPeriodPerUnitOrder: modifiedState.selectedOrderDetailsArr[i].SubscriptionPeriod ==null ? 'NA' : Number(SubscriptionPeriodInMonth),
                };
                modifiedState.productdata.push(singleProductData);
            }
        } else {

            for(let i=0; i< modifiedState.CustomerOrderDetailsForApiOrder.length; i++) {
                if(modifiedState.CustomerOrderDetailsForApiOrder[i]["OrderNumber"] == modifiedState.selectedOrderNumber) {
                    modifiedState.selectedOrderDetailsArrForApiOrder.push(modifiedState.CustomerOrderDetailsForApiOrder[i]);
                }
            }

            let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>

            for(let i=0; i<modifiedState.selectedOrderDetailsArrForApiOrder.length; i++) {

                // calculations to get the OrderItemsValue
                let DiscountedPrice = Number(modifiedState.selectedOrderDetailsArrForApiOrder[i].PricePerUnitOrderINR) - Number(modifiedState.selectedOrderDetailsArrForApiOrder[i].DisctPricePerPkg);
                let TaxOnDiscountedPrice = ((Number(DiscountedPrice)) * ((Number(modifiedState.selectedOrderDetailsArrForApiOrder[i].TaxPrcntPUO)) / 100)).toFixed(2);
                let selectedOrderItemsValueForApiOrder = (Number(DiscountedPrice) + Number(TaxOnDiscountedPrice)).toFixed(2);      
                modifiedState.TotalOrderValue = (Math.abs(modifiedState.TotalOrderValue) + ( Math.abs(selectedOrderItemsValueForApiOrder))).toFixed(2);            

                let singleProductData = {
                    edit: editIcon,
                    ProductLine: modifiedState.selectedOrderDetailsArrForApiOrder[i].ProductLine,
                    ProductName: modifiedState.selectedOrderDetailsArrForApiOrder[i].ProductName,
                    ProductCode: modifiedState.selectedOrderDetailsArrForApiOrder[i].ProductCode,
                    QuantityOfUnitOrdered: modifiedState.selectedOrderDetailsArrForApiOrder[i].QuantityOfUnitsOrdered,
                    PricePerUnitOrder: modifiedState.selectedOrderDetailsArrForApiOrder[i].PricePerUnitOrderINR,
                    TaxPercentPerUnitOrder: modifiedState.selectedOrderDetailsArrForApiOrder[i].TaxPrcntPUO,
                    TaxPerUnitOrder: modifiedState.selectedOrderDetailsArrForApiOrder[i].TaxPerUnitOrderINR,
                    SecurityDepositPerUnitOrder: 'NA',
                    OneTimeChargePerUnitOrder: modifiedState.selectedOrderDetailsArrForApiOrder[i].OneTimeChrgOnRentPUOI,
                    PackageDurationOnApiOrder: ((Number(modifiedState.selectedOrderDetailsArrForApiOrder[i].PackageDuration)) / 30).toFixed(1),
                    ApiHitsPerPackageDuration: modifiedState.selectedOrderDetailsArrForApiOrder[i].ApiHitsInPkg,
                    TaxPercentageOnOneTimeChargePerUnitOrder: 'NA',
                    TaxPriceOnOneTimeChargePerUnitOrder: 'NA',
                    OrderItemsValue: selectedOrderItemsValueForApiOrder,
                    QuantityOfUnitsScanned: modifiedState.selectedOrderDetailsArrForApiOrder[i].QuantityOfUnitsScanned == null ? 0 : modifiedState.selectedOrderDetailsArrForApiOrder[i].QuantityOfUnitsScanned,
                    OrderLineNumber: modifiedState.selectedOrderDetailsArrForApiOrder[i].OrderLineNumber == null ? 0 : modifiedState.selectedOrderDetailsArrForApiOrder[i].OrderLineNumber,
                    PackageID: modifiedState.selectedOrderDetailsArrForApiOrder[i].PackageID,
                    OrderNumber: modifiedState.selectedOrderDetailsArrForApiOrder[i].OrderNumber == null ? 0 : modifiedState.selectedOrderDetailsArrForApiOrder[i].OrderNumber,
                    Status: modifiedState.selectedOrderDetailsArrForApiOrder[i].Status == null ? null : modifiedState.selectedOrderDetailsArrForApiOrder[i].Status,
                    SubscriptionPricePerUnitOrder: 'NA',
                    SubscriptionTaxPerUnitOrder: 'NA',
                    SubscriptionPercentagePerUnitOrder: 'NA',
                    SubscriptionPeriodPerUnitOrder: 'NA',
                    DisctPrcntPerPkg: modifiedState.selectedOrderDetailsArrForApiOrder[i].DisctPrcntPerPkg,
                    DisctPricePerPkg: modifiedState.selectedOrderDetailsArrForApiOrder[i].DisctPricePerPkg,
                    PackageDuration: modifiedState.selectedOrderDetailsArrForApiOrder[i].PackageDuration,
                    PriceOfHitsInPkgINR: modifiedState.selectedOrderDetailsArrForApiOrder[i].PriceOfHitsInPkgINR,
                    PricePerPkgDurINR: modifiedState.selectedOrderDetailsArrForApiOrder[i].PricePerPkgDurINR
                };
                modifiedState.productdata.push(singleProductData);
            }
        }

        modifiedState.bEditOrder = true;

        setState({...modifiedState});
    }

    const toggleSelectOrderDetails = (e) => {

        let modifiedState = state;

        modifiedState.bEditOrder = !modifiedState.bEditOrder;
        modifiedState.productdata = [];
        modifiedState.formViewMode = '';
        modifiedState.errors.others = "";
        modifiedState.TotalOrderValue = 0;
        modifiedState.OrderNumber = null;
        modifiedState.OrderDateTime = new Date();
        modifiedState.selectedOrderType = "";
        modifiedState.OrderType = "";
        modifiedState.Comment = "";
        modifiedState.selectedOrganisationName = "";
        modifiedState.OrderType = modifiedState.SelectedCustomerOrder.OrderType;  
        modifiedState.errors.isOrderTypeSelected  = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.switchOrderMenus = false;
        modifiedState.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi = false;
        modifiedState.showEditAndQuantityOfUnitsScannedwhenRental = false;
        modifiedState.showEditAndQuantityOfUnitsScannedwhenApi = false;

        // As in these case we dont want to show 'Edit' column hence we are hiding it.
        // then if the Order type is sale or order then show the edit icon in the respective react columns 
        if(modifiedState.createEditOrder && modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType != ORDER_TYPE_RENTAL && modifiedState.selectedOrderType != ORDER_TYPE_API)){
            for(let i=0; i<modifiedState.productColumns.length; i++) {
                if(modifiedState.productColumns[i]["accessor"] == "edit" || modifiedState.productColumns[i]["accessor"] == "QuantityOfUnitsScanned") {
                    modifiedState.productColumns[i]["show"] = false;
                    // break;
                }
            }
        } else if(modifiedState.createEditOrder && modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) {
            // then if the Order type is Rental then show the edit icon in the respective react columns 
            for(let i=0; i<modifiedState.rentalOrderColumns.length; i++) {
                if(modifiedState.rentalOrderColumns[i]["accessor"] == "edit" || modifiedState.rentalOrderColumns[i]["accessor"] == "QuantityOfUnitsScanned") {
                    modifiedState.rentalOrderColumns[i]["show"] = false;
                    // break;
                }
            }
        } else {
            // then if the Order type is API then show the edit icon in the respective react columns 
            for(let i=0; i<modifiedState.productColumnsForApiOrder.length; i++) {
                if(modifiedState.productColumnsForApiOrder[i]["accessor"] == "edit" || modifiedState.productColumnsForApiOrder[i]["accessor"] == "QuantityOfUnitsScanned") {
                    modifiedState.productColumnsForApiOrder[i]["show"] = false;
                    // break;
                }
            }
        }
        setState({...modifiedState});
    }

    useEffect(() => {

        getProductCategoryAndItsRelevantInfo();

        // get Customer Details through Customer table.
        GetCustomerDetails();

        // get Customer Orders Details.
        GetCustomerOrderDetails();

        // get Customer Details through Customer table.
        GetOrderDetailsOfSelectedCust();

        // get Products Information like 'ProductLine', 'ProductName'.
        GetProductsInfo();

    }, []);


    const GetOrderDetailsOfSelectedCust = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let orderinsertedOrUpdated  = (modifiedState.maxLastModifiedTimeOfOrderInfo == null || 
            modifiedState.maxLastModifiedTimeOfOrderInfo.length <=0) ? false : true;

        let orderinsertedOrUpdatedForApiOrder  = (modifiedState.maxLastModifiedTimeOfApiOrderInfo == null || 
            modifiedState.maxLastModifiedTimeOfApiOrderInfo.length <=0) ? false : true;
    
        const jsonBody = {
            CustID: modifiedState.CustID, 
            LastModifiedUtcDtTm: (modifiedState.maxLastModifiedTimeOfOrderInfo == null || 
                                    modifiedState.maxLastModifiedTimeOfOrderInfo.length <=0) ? null : modifiedState.maxLastModifiedTimeOfOrderInfo,
            LastModifiedUtcDtTmForApiOrder: (modifiedState.maxLastModifiedTimeOfApiOrderInfo == null || 
                modifiedState.maxLastModifiedTimeOfApiOrderInfo.length <=0) ? null : modifiedState.maxLastModifiedTimeOfApiOrderInfo 
        };
        
        // modifiedState.CustAllOrderData = [];
        // to get all order details of selected customer 
        axios.post(`${getAPIHostURL()}/wclient/getCustAllOrderInfo`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') { 
 
                if(response.data.retrievedOredrInfoForNonApiOrders == null || response.data.retrievedOredrInfoForNonApiOrders.length <= 0) {
                    modifiedState.errors.others = "No Order Data Found.";
                } else {

                    modifiedState.errors.others = '';
                    let receiveOrderData = response.data.retrievedOredrInfoForNonApiOrders;
                    modifiedState.maxLastModifiedTimeOfOrderInfo = receiveOrderData[0].LastModifiedUtcDtTm;
        
                    let noOfLogs = receiveOrderData.length;
                    let stateCustAllOrderData = [];

                    for(let i = 0; i < noOfLogs; i++) {
    
                        const orderDetails = receiveOrderData[i];
                        stateCustAllOrderData = [...modifiedState.CustAllOrderData]

                        let selectOrderBtn = <button type = "button" className = "createOrderTableButton">
                                                Select Order
                                             </button> 

                        let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                        let Download = <FaFilePdf className="DonloadPdfIcon" title="PdfDownload"/>
                        let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
    
                        let singleOrderInfo = {
                            action: selectOrderBtn,
                            edit: editIcon,
                            viewFullFiledDetails: orderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || orderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                            Download: Download,
                            OrderNumber: orderDetails.OrderNumber,
                            OrderType: orderDetails.OrderType,
                            OrderDate: (orderDetails.OrderDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.OrderDate),
                            RequiredDate: (orderDetails.RequiredDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.RequiredDate),
                            FulfilledDate: (orderDetails.FulfilledDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.FulfilledDate),
                            Status: orderDetails.Status,
                            SalesRepUserID: orderDetails.UserIdOfSalesRep,
                            ShippedDate: orderDetails.ShippedDate,
                            Comments: orderDetails.Comments,
                            OrderOfOrganisation: orderDetails.OrganisationName,
                        };

                        if(orderinsertedOrUpdated ==  true) {
                            for(let j=0; j< stateCustAllOrderData.length; j++) {
                                if(stateCustAllOrderData[j].OrderNumber == singleOrderInfo.OrderNumber) {
                                    stateCustAllOrderData.splice(j, 1);
                                }
                            }
                            stateCustAllOrderData.unshift(singleOrderInfo);
                        } else {
                            stateCustAllOrderData.push(singleOrderInfo);
                        }

                        modifiedState.CustAllOrderData = stateCustAllOrderData;                        
                    }

                    // Data will be fetched and shown for APi order type
                    if(response.data.retrievedOredrInfoForApiOrders == null || response.data.retrievedOredrInfoForApiOrders.length <= 0) {
                        modifiedState.errors.errApiOrder = "No Api Order Data Found.";
                    } else {
    
                        modifiedState.errors.others = '';
                        modifiedState.errors.errApiOrder = '';
                        let receiveApiOrderData = response.data.retrievedOredrInfoForApiOrders;
                        modifiedState.maxLastModifiedTimeOfApiOrderInfo = receiveApiOrderData[0].LastModifiedUtcDtTm;
            
                        let noOfLogs = receiveApiOrderData.length;
                        let stateCustAllApiOrderData = [];
    
                        for(let i = 0; i < noOfLogs; i++) {
        
                            const apiOrderDetails = receiveApiOrderData[i];
                            stateCustAllApiOrderData = [...modifiedState.CustAllApiOrderData]
    
                            let selectOrderBtn = <button type = "button" className = "createOrderTableButton">
                                                    Select Order
                                                 </button> 
    
                            let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                            let Download = <FaFilePdf className="DonloadPdfIcon" title="PdfDownload"/>
                            let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
        
                            let singleOrderInfo = {
                                action: selectOrderBtn,
                                edit: editIcon,
                                Download: Download,
                                OrderNumber: apiOrderDetails.OrderNumber,
                                OrderType: apiOrderDetails.OrderType,
                                OrderDate: (apiOrderDetails.OrderDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(apiOrderDetails.OrderDate),
                                RequiredDate: (apiOrderDetails.RequiredDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(apiOrderDetails.RequiredDate),
                                Status: apiOrderDetails.Status,
                                SalesRepUserID: apiOrderDetails.UserIdOfSalesRep,
                                ShippedDate: apiOrderDetails.ShippedDate,
                                Comments: apiOrderDetails.Comments,
                                OrderOfOrganisation: apiOrderDetails.OrganisationName,
                            };
    
                            if(orderinsertedOrUpdatedForApiOrder ==  true) {
                                for(let j=0; j< stateCustAllApiOrderData.length; j++) {
                                    if(stateCustAllApiOrderData[j].OrderNumber == singleOrderInfo.OrderNumber) {
                                        stateCustAllApiOrderData.splice(j, 1);
                                    }
                                }
                                stateCustAllApiOrderData.unshift(singleOrderInfo);
                            } else {
                                stateCustAllApiOrderData.push(singleOrderInfo);
                            }
    
                            modifiedState.CustAllApiOrderData = stateCustAllApiOrderData;      
                        }
                    }
                }

            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
        setState({...modifiedState});
    }

    // to get all order product details of selected customer.
    const GetCustomerOrderDetails = (inModifiedState = null) => {
        
        let modifiedState; 
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState
        }
        
        // To avoid appending of array values.
        // modifiedState.CustomerOrderDetails = [];
        modifiedState.CustSelectedOrderDetails = [];
        modifiedState.CustSelectedOrderDetailsForApiOrder = [];

        const jsonBody = {
            CustID: modifiedState.CustID, 
            LastModifiedUtcDtTm: (modifiedState.maxLastModifiedTimeOfSelectedOrderDetails == null || 
                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetails.length <=0) ? null : modifiedState.maxLastModifiedTimeOfSelectedOrderDetails,
            LastModifiedUtcDtTmForApiOrder: (modifiedState.maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder == null || 
                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder.length <=0) ? null : modifiedState.maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder,                        
        };

        // To get selected Customer Orders information from database.
        axios.post(`${getAPIHostURL()}/wclient/getCustomerOrderDetails`, jsonBody)
        .then(response => {
            if(response.data.code == "SUCCESS") {

                // IF response gets inadequate data.
                if(response.data.retrievedOredrDetails == null || response.data.retrievedOredrDetails.length <= 0){
                    modifiedState.errors.others = "Order Detail Information is not present on Server.";
                    
                } else {
                    if(modifiedState.CustomerOrderDetails == null || modifiedState.CustomerOrderDetails.length <=0) {
                        modifiedState.CustomerOrderDetails = response.data.retrievedOredrDetails;
                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetails = modifiedState.CustomerOrderDetails[0].LastModifiedUtcDtTm
                    } else {
                    
                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetails = response.data.retrievedOredrDetails[0]["LastModifiedUtcDtTm"];

                        modifiedState.selectedOrderDetailsArr = [];
                        modifiedState.CustSelectedOrderDetails = [];

                        let newAddedOrderDetlInPrevOrder 
                                = response.data.retrievedOredrDetails.filter(function(o1){
                                    return !modifiedState.CustomerOrderDetails.some(function(o2){
                                        return o1.OrderLineNumber == o2.OrderLineNumber;          // assumes unique id
                                    });
                                })
                        
                        for(let i = 0; i< response.data.retrievedOredrDetails.length; i++) {

                            for(let j = 0; j < modifiedState.CustomerOrderDetails.length; j++) {

                                if(modifiedState.CustomerOrderDetails[j].OrderLineNumber == response.data.retrievedOredrDetails[i].OrderLineNumber){
                                    modifiedState.CustomerOrderDetails.splice(j, 1);
                                    modifiedState.CustomerOrderDetails.unshift(response.data.retrievedOredrDetails[i]); 
                                    // checkWhereOderLineNoExists = true;
                                }
                            }
                        } 

                        for(let i=0; i<newAddedOrderDetlInPrevOrder.length; i++) {
                            modifiedState.CustomerOrderDetails.unshift(newAddedOrderDetlInPrevOrder[i]);
                        }

                        // to show selected order details push into 'selectedOrderDetailsArr'.
                        for(let i=0; i< modifiedState.CustomerOrderDetails.length; i++) {
                            if(modifiedState.CustomerOrderDetails[i]["OrderNumber"] == modifiedState.CustAllOrderData[0]["OrderNumber"]) {
                                modifiedState.selectedOrderDetailsArr.push(modifiedState.CustomerOrderDetails[i]);
                            }
                        }
                
                        let receiveOrderDetailsData = modifiedState.selectedOrderDetailsArr;
                        
                        let noOfLogs = receiveOrderDetailsData.length;
                        // for formatting order details
                        for(let i = 0; i < noOfLogs; i++) {
                
                            const selectedOrderDetails = receiveOrderDetailsData[i];
                            let totalSubscriptionValue = 0;
                            let SubscriptionPeriodInMonth = 0;

                            modifiedState.OrderType = modifiedState.SelectedCustomerOrder.OrderType;       

                            // the subscription price and tax which is stored in the database is multiplied by 30, in order to show proce as per 1 month
                            let subscriptionPricePerUnitINRtoShow = selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null ? 'NA' : (selectedOrderDetails.SubscriptionPricePerUnitOrderINR * 30).toFixed(2);
                            let SubscriptionTaxPerUnitOrdertoShow = selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : ((selectedOrderDetails.SubscriptionTaxPrcntPUO / 100 ) * subscriptionPricePerUnitINRtoShow).toFixed(2);
                    
                            if(selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null &&  selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionPeriod == null) {
                                totalSubscriptionValue = 0;
                            } else {
                                SubscriptionPeriodInMonth = selectedOrderDetails.SubscriptionPeriod / DAYS_IN_MONTH
                                totalSubscriptionValue = (Number(SubscriptionPeriodInMonth) * (Number(subscriptionPricePerUnitINRtoShow) + Number(SubscriptionTaxPerUnitOrdertoShow)));
                            }

                            // Diffrent calculations are perfromed for Order type Rental
                            let selectedOrderItemsValue = (modifiedState.OrderType != null && modifiedState.OrderType.length > 0 && modifiedState.OrderType != ORDER_TYPE_RENTAL) ?
                                (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.PricePerUnitOrderINR) + Math.abs(selectedOrderDetails.TaxPerUnitOrderINR) + Number(totalSubscriptionValue))).toFixed(2) : 
                                (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.OneTimeChrgOnRentPUOI) + Math.abs(selectedOrderDetails.SecurityDepositOnRentPUOI) + Math.abs(selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI) + Number(totalSubscriptionValue))).toFixed(2);
                            
                            let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                
                            let singleOrderDetailinfo = {
                                viewFullFiledDetails: selectedOrderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                OrderLineNumber: selectedOrderDetails.OrderLineNumber,
                                OrderNumber:selectedOrderDetails.OrderNumber,
                                ProductLine: selectedOrderDetails.ProductLine,
                                ProductName: selectedOrderDetails.ProductName,
                                ProductCode: selectedOrderDetails.ProductCode,
                                QuantityOfUnitOrdered: selectedOrderDetails.QuantityOfUnitsOrdered,
                                QuantityOfUnitScanned: selectedOrderDetails.QuantityOfUnitsScanned,
                                PricePerUnitOrder: selectedOrderDetails.PricePerUnitOrderINR,
                                TaxPercentPerUnitOrder: selectedOrderDetails.TaxPrcntPUO,
                                TaxPerUnitOrder: selectedOrderDetails.TaxPerUnitOrderINR,
                                SecurityDepositPerUnitOrder: selectedOrderDetails.SecurityDepositOnRentPUOI,
                                OneTimeChargePerUnitOrder: selectedOrderDetails.OneTimeChrgOnRentPUOI,
                                TaxPercentageOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPrcntOnOneTimeChrgPUO,
                                TaxPriceOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI,
                                OrderItemsValue: selectedOrderItemsValue,
                                Status: selectedOrderDetails.Status,
                                SubscriptionPricePerUnitOrder: subscriptionPricePerUnitINRtoShow,
                                SubscriptionTaxPerUnitOrder: SubscriptionTaxPerUnitOrdertoShow,
                                SubscriptionPercentagePerUnitOrder: selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : selectedOrderDetails.SubscriptionTaxPrcntPUO,
                                SubscriptionPeriodPerUnitOrder: selectedOrderDetails.SubscriptionPeriod == null ? 'NA' : SubscriptionPeriodInMonth,
                                LastModifiedUtcDtTm: selectedOrderDetails.LastModifiedUtcDtTm,
                            };
                            modifiedState.CustSelectedOrderDetails.push(singleOrderDetailinfo);                                   
                        }

                        if(modifiedState.isAllOrderFullFillSendsEmailTeam) {

                            // Convert the input dates to UTC before sending to the Server
                            let strOrderDateTime = (modifiedState.SelectedCustomerOrder != null && modifiedState.SelectedCustomerOrder.OrderDate != null) ? 
                                                    convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(modifiedState.SelectedCustomerOrder.OrderDate.valueOf()) ) : null;
                            let strReuiredDateTime = (modifiedState.SelectedCustomerOrder != null && modifiedState.SelectedCustomerOrder.RequiredDate != null) ? 
                                                        convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(modifiedState.SelectedCustomerOrder.RequiredDate.valueOf()) ) : null;

                            let strLocaleFulfilledOrderDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date());
                            let strOrganizationName = modifiedState.SelectedCustomerOrder.OrderOfOrganisation;
                            let OrderType = modifiedState.SelectedCustomerOrder.OrderType; 

                            sendOrderDetailsToProductionTeam(strOrderDateTime, strReuiredDateTime, strLocaleFulfilledOrderDtTm, modifiedState.CustSelectedOrderDetails, strOrganizationName, OrderType);
                        }
                    }
                }

                // Data will be fetched and shown for APi order type
                if(response.data.retrievedOredrDetailsForApiOrder == null || response.data.retrievedOredrDetailsForApiOrder.length <= 0){
                    modifiedState.errors.errApiOrder = "Api Order Detail Information is not present on Server.";
                    
                } else {

                    if(modifiedState.CustomerOrderDetailsForApiOrder == null || modifiedState.CustomerOrderDetailsForApiOrder.length <=0) {
                        modifiedState.CustomerOrderDetailsForApiOrder = response.data.retrievedOredrDetailsForApiOrder;
                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder = modifiedState.CustomerOrderDetailsForApiOrder[0].LastModifiedUtcDtTm
                    } else {
                    
                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder = response.data.retrievedOredrDetailsForApiOrder[0]["LastModifiedUtcDtTm"];

                        modifiedState.selectedOrderDetailsArrForApiOrder = [];
                        modifiedState.CustSelectedOrderDetailsForApiOrder = [];

                        let newAddedOrderDetlInPrevOrder 
                                = response.data.retrievedOredrDetailsForApiOrder.filter(function(o1){
                                    return !modifiedState.CustomerOrderDetailsForApiOrder.some(function(o2){
                                        return o1.OrderLineNumber == o2.OrderLineNumber;          // assumes unique id
                                    });
                                })
                        
                        for(let i = 0; i< response.data.retrievedOredrDetailsForApiOrder.length; i++) {

                            for(let j = 0; j < modifiedState.CustomerOrderDetailsForApiOrder.length; j++) {

                                if(modifiedState.CustomerOrderDetailsForApiOrder[j].OrderLineNumber == response.data.retrievedOredrDetailsForApiOrder[i].OrderLineNumber){
                                    modifiedState.CustomerOrderDetailsForApiOrder.splice(j, 1);
                                    modifiedState.CustomerOrderDetailsForApiOrder.unshift(response.data.retrievedOredrDetailsForApiOrder[i]); 
                                    // checkWhereOderLineNoExists = true;
                                }
                            }
                        } 

                        for(let i=0; i<newAddedOrderDetlInPrevOrder.length; i++) {
                            modifiedState.CustomeCustomerOrderDetailsForApiOrderrOrderDetails.unshift(newAddedOrderDetlInPrevOrder[i]);
                        }

                        // to show selected order details push into 'selectedOrderDetailsArrForApiOrder'.
                        for(let i=0; i< modifiedState.CustomerOrderDetailsForApiOrder.length; i++) {
                            if(modifiedState.CustomerOrderDetailsForApiOrder[i]["OrderNumber"] == modifiedState.CustAllApiOrderData[0]["OrderNumber"]) {
                                modifiedState.selectedOrderDetailsArrForApiOrder.push(modifiedState.CustomerOrderDetailsForApiOrder[i]);
                            }
                        }
                
                        let receiveOrderDetailsData = modifiedState.selectedOrderDetailsArrForApiOrder;
                        
                        let noOfLogs = receiveOrderDetailsData.length;
                        // for formatting order details
                        for(let i = 0; i < noOfLogs; i++) {
                
                            const selectedOrderDetailsForApiOrder = receiveOrderDetailsData[i];

                            // calculation to get total OrderItemsValue.
                            let PackageDurationOnApiOrderInMonths  = (Number(selectedOrderDetailsForApiOrder.PackageDuration)) / 30;
                            let DiscountedPrice = Number(selectedOrderDetailsForApiOrder.PricePerUnitOrderINR) - Number(selectedOrderDetailsForApiOrder.DisctPricePerPkg);
                            let TaxOnDiscountedPrice = ((Number(DiscountedPrice)) * ((Number(selectedOrderDetailsForApiOrder.TaxPrcntPUO)) / 100)).toFixed(2);
                            let selectedOrderItemsValueForApiOrder = (Number(DiscountedPrice) + Number(TaxOnDiscountedPrice));                               
                            
                            let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                
                            let singleOrderDetailinfo = {
                                //viewFullFiledDetails: selectedOrderDetailsForApiOrder.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetailsForApiOrder.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                PackageID: selectedOrderDetailsForApiOrder.PackageID,
                                OrderLineNumber: selectedOrderDetailsForApiOrder.OrderLineNumber,
                                OrderNumber:selectedOrderDetailsForApiOrder.OrderNumber,
                                ProductLine: selectedOrderDetailsForApiOrder.ProductLine,
                                ProductName: selectedOrderDetailsForApiOrder.ProductName,
                                ProductCode: selectedOrderDetailsForApiOrder.ProductCode,
                                QuantityOfUnitOrdered: selectedOrderDetailsForApiOrder.QuantityOfUnitsOrdered,
                                QuantityOfUnitScanned: selectedOrderDetailsForApiOrder.QuantityOfUnitsScanned,
                                PackageDurationOnApiOrder: PackageDurationOnApiOrderInMonths,
                                ApiHitsPerPackageDuration: selectedOrderDetailsForApiOrder.ApiHitsInPkg,
                                DisctPrcntPerPkg: selectedOrderDetailsForApiOrder.DisctPrcntPerPkg,
                                DisctPricePerPkg: selectedOrderDetailsForApiOrder.DisctPricePerPkg,
                                PricePerUnitOrder: selectedOrderDetailsForApiOrder.PricePerUnitOrderINR,
                                TaxPercentPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPrcntPUO,
                                TaxPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPerUnitOrderINR,
                                OneTimeChargePerUnitOrder: selectedOrderDetailsForApiOrder.OneTimeChrgOnRentPUOI,
                                OrderItemsValue: selectedOrderItemsValueForApiOrder.toFixed(2),
                                Status: selectedOrderDetailsForApiOrder.Status,
                                LastModifiedUtcDtTm: selectedOrderDetailsForApiOrder.LastModifiedUtcDtTm,
                                PriceOfHitsInPkgINR: selectedOrderDetailsForApiOrder.PriceOfHitsInPkgINR,
                                PricePerPkgDurINR: selectedOrderDetailsForApiOrder.PricePerPkgDurINR, 
                                PackageDuration: selectedOrderDetailsForApiOrder.PackageDuration        
                            };

                            modifiedState.CustSelectedOrderDetailsForApiOrder.push(singleOrderDetailinfo);      
                        }
                    }
                }

            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }  
        });
    }

    //===========================================================================================================

    const GetCustomerDetails = (e) => {

        let modifiedState = state;;
        let selectedCustomerDetails = props.propsSelectedCustomerDetails;

        modifiedState.CustID = props.propsSelectedCustomerDetails.custID;
        modifiedState.CustName = props.propsSelectedCustomerDetails.custFirstName + " " +props.propsSelectedCustomerDetails.custLastName;
        modifiedState.OrganisationName = props.propsSelectedCustomerDetails.organizationName;
        modifiedState.CustEmailID = props.propsSelectedCustomerDetails.email;
        modifiedState.CustPhoneNo = props.propsSelectedCustomerDetails.mobileNo;
        
        let stateCustomerdata = [];

        stateCustomerdata = [...modifiedState.data]

        let singleCustData = {
            CustID: modifiedState.CustID,
            CustName: modifiedState.CustName,
            CustEmailID: modifiedState.CustEmailID,
            OrganisationName: modifiedState.OrganisationName,
            CustPhoneNo: modifiedState.CustPhoneNo,
        };

        stateCustomerdata.push(singleCustData);
        modifiedState.data = stateCustomerdata;
        setState({...modifiedState});

    }

    // For entering detailed decription for newly added Order.
    const onEnteredNewComment = (e) => {
        let modifiedState = state;
        modifiedState.Comment = e.target.value;

        setState({...modifiedState});
    }

    const toggleSelectCustomerDetails = (e) => {

        let modifiedState = state;

        if((modifiedState.selectedOrderType == null || modifiedState.selectedOrderType.length <= 0) && modifiedState.createEditOrder) {
            modifiedState.errors.isOrderTypeSelected = "Please Select Order Type."
            setState({...modifiedState});
            return;
        } else {
            setState({
                ...state,
                selectCustomerDetails: !state.selectCustomerDetails,
                SelectedCustNewOrderDetails: true,
                selectedProductName: "",
                selectedProductLine: "",
                ProductName: [],
                ProductCode: '',
                newEnteredQuantityOfUnit: '',
                QuantityOfUnitsScanned: 0,
                PricePerUnitINR: '',
                TaxPerUnitINR: '',
                TaxPercentagePerUnit: '',
                isOrderWithFreeSubscription: false,
                SubscriptionPricePerUnitINR: null,
                SubcriptionTaxPerUnitINR: null,
                SubcriptionTaxPercentagePerUnitINR: null,
                oneTimeChargePrice: "",
                securityDepositPrice: "",
                taxPercentageForOneTimeChargePrice: "",
                taxPriceForOneTimeCharge: "",
                rentalPricePerUnitINR:  null,
                rentaltaxPercentagePerUnit: null,
                rentaltaxPricePerUnit: null,
                SubscriptionPeriodPerUnit: null,
                selectedSubscriptionPeriod: '1',

                selectedProductNameForApiOrder: "",
                selectedProductCodeForApiOrder: '',
                oneTimeChargePerUnitINRForApiOrder: '',
                selectedDurationForApiOrder: '',
                apiHitOnSelectedDuration: '',
                totalOrderPriceWithoutTax: "",
                QuantityOfUnitsScannedForApiOrder: 0,
                totalTaxPrcntForSelectedDuration: "",
                totalTaxPriceForSelectedDuration: "",
                finalTotalOrderPrice: "",
                convertedPkgDuration: [],
                discountPercntForTotalOrderPrice: 0,
                discountPriceForTotalOrderPrice: 0,
                daysBasedOnSelectedDurationForApiOrder: "",
                convertedPeriodInMonths: "",

                errors: {
                    others: "",
                    errRelatedToAssigningState: "",
                    succsessRelatedToAssigningState: "",
                    isOrderTypeSelected: "",
                    errApiOrder: "",
                },
            });
        }
    }
    
    // ==============================================================
    
    // Getting Information of all Products present on server.
    const GetProductsInfo = () => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = state; 
        
        // To avoid appending of array values.
        modifiedState.AllProductsInformation = [];
        modifiedState.AllRentalProductInformation = [];
        modifiedState.ProductLine = [];
        modifiedState.ProductName = [];
        modifiedState.ProductsSubscriptionInfo = [];
        modifiedState.ExtApiPackageInfo = [];
        modifiedState.ProductNameForApiOrder = [];

        // To get Products information from database.
        axios.post(`${getAPIHostURL()}/wclient/getProductsInfo`)
        .then(response => {
            if(response.data.code == "SUCCESS") {
                // IF response gets inadequate data.
                if(response.data.retrievedProductInfo == null || response.data.retrievedProductInfo.length <= 0 )
                {
                    modifiedState.errors.others = "Products Information is not present on Server.";
                    
                } else {
                    modifiedState.AllProductsInformation = response.data.retrievedProductInfo;
                    modifiedState.ExtApiPackageInfo = response.data.retrievedExtApiPackageInfo;
                    modifiedState.AllRentalProductInformation =  (response.data.retrievedRentalInfo == null || response.data.retrievedRentalInfo.length <= 0) ? [] : response.data.retrievedRentalInfo;
                    modifiedState.AllOrganisationInformation = response.data.retrievedOrganisationInfo != null && response.data.retrievedOrganisationInfo.length > 0 ? response.data.retrievedOrganisationInfo : [];
                    modifiedState.ProductsSubscriptionInfo = response.data.retrievedSubscriptionInfo != null && response.data.retrievedSubscriptionInfo.length > 0 ? response.data.retrievedSubscriptionInfo : [];
                    
                    // For showing only one value in dropdown if value gets repeat.
                    // Remove API product line for non Api order.
                    let RemoveApiFromProductLine = [...new Set(modifiedState.AllProductsInformation.map(singleHWandFWVersion => singleHWandFWVersion.ProductLine))];
                    modifiedState.ProductLine = RemoveApiFromProductLine.filter(item => item !== ORDER_TYPE_API)

                    for(let i=0; i< modifiedState.AllProductsInformation.length; i++) {
                        if(modifiedState.AllProductsInformation[i]["ProductLine"] == ORDER_TYPE_API) {
                            modifiedState.selectedProductLineForApiOrder = modifiedState.AllProductsInformation[i]["ProductLine"];                     
                        }
                    }

                    for(let i=0; i< modifiedState.AllProductsInformation.length; i++) {
                        if(modifiedState.AllProductsInformation[i]["ProductLine"] == modifiedState.selectedProductLineForApiOrder) {
                            // Assigning values to Second dropdown based on selection of First dropdown.
                            modifiedState.ProductNameForApiOrder.push(modifiedState.AllProductsInformation[i]["ProductName"]);
                        }
                    }
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }  
        });
    }

    // For selecting values in dropdown of SELECT Product Name as per selection of dropdown of SELECT ProductLine.
    const onChangeProductLineSelection = (e) => {

        let modifiedState = state;
        modifiedState.selectedProductLine = e.target.value;
        modifiedState.selectedProductName = "";
        modifiedState.newEnteredQuantityOfUnit = ""
        modifiedState.ProductCode = "";
        modifiedState.PricePerUnitINR = "";
        modifiedState.TaxPerUnitINR = "";
        modifiedState.TaxPercentagePerUnit = "";
        modifiedState.SubcriptionTaxPerUnitINR = null;
        modifiedState.SubscriptionPricePerUnitINR = null;
        modifiedState.SubcriptionTaxPercentagePerUnitINR = null;     
        modifiedState.securityDepositPrice = '';
        modifiedState.oneTimeChargePrice = '';
        modifiedState.taxPercentageForOneTimeChargePrice = "";
        modifiedState.taxPriceForOneTimeCharge = "";
        modifiedState.rentaltaxPercentagePerUnit = null;
        modifiedState.rentaltaxPricePerUnit = null;
        modifiedState.rentalPricePerUnitINR = null;
        modifiedState.ProductName = [];
        modifiedState.errors.others = "";

        for(let i=0; i< modifiedState.AllProductsInformation.length; i++) {
            if(modifiedState.AllProductsInformation[i]["ProductLine"] == modifiedState.selectedProductLine) {
                // Assigning values to Second dropdown based on selection of First dropdown.
                modifiedState.ProductName.push(modifiedState.AllProductsInformation[i]["ProductName"]);
            }
        }
        setState({...modifiedState});
    }

    const onChangeProductNameSelectionForApiOrder = (e) => {

        let modifiedState = state;

        modifiedState.selectedProductNameForApiOrder = e.target.value;
        modifiedState.errors.errApiOrder = "";

        for(let i=0; i< modifiedState.AllProductsInformation.length; i++) {
            if((modifiedState.AllProductsInformation[i]["ProductName"] == modifiedState.selectedProductNameForApiOrder) && (modifiedState.AllProductsInformation[i]["ProductLine"] == modifiedState.selectedProductLineForApiOrder)) {
                // Assigning values to 'selectedProductCodeForApiOrder', 'oneTimeChargePerUnitINRForApiOrder', 'taxPrcntOnOneTimeChargePerUnitINRForApiOrder' based on Api Product Line and Product Name
                modifiedState.selectedProductCodeForApiOrder = modifiedState.AllProductsInformation[i]["ProductCode"];
                modifiedState.oneTimeChargePerUnitINRForApiOrder = modifiedState.AllProductsInformation[i]["PricePerUnitINR"];
                modifiedState.taxPrcntOnOneTimeChargePerUnitINRForApiOrder = modifiedState.AllProductsInformation[i]["TaxPrcntPerUnit"];
            }
        }

        for(let i=0; i< modifiedState.ExtApiPackageInfo.length; i++) {
            if(modifiedState.ExtApiPackageInfo[i]["ProductCode"] == modifiedState.selectedProductCodeForApiOrder) {
                modifiedState.BasePkgStructForApiOrder = modifiedState.ExtApiPackageInfo[i]["BasePkgStruct"];
                modifiedState.PricePerDayINRForApiOrder = modifiedState.ExtApiPackageInfo[i]["PricePerDayINR"];
                modifiedState.PricePerHitINRForApiOrder = modifiedState.ExtApiPackageInfo[i]["PricePerHitINR"];
                modifiedState.TaxPrcntINRForApiOrder = modifiedState.ExtApiPackageInfo[i]["TaxPrcntINR"];                        
                let parsedJsonOfBasePkgStruct = JSON.parse(modifiedState.BasePkgStructForApiOrder);
                let PkgStructKeyValue = parsedJsonOfBasePkgStruct.PkgStruct;
                let obtainKeyOfPkgStruct = Object.keys(PkgStructKeyValue);

                // Convert Package duration  
                for(let i=0; i<obtainKeyOfPkgStruct.length; i++) {
                    if((Number(obtainKeyOfPkgStruct[i]))>=12) {
                        let getRemainderOfObtainKeyOfPkgStruct =  Math.floor((Number(obtainKeyOfPkgStruct[i])) / 12);
                        let appendYearToRemainder = getRemainderOfObtainKeyOfPkgStruct + " " + "Year";
                        var getQuotientOfObtainKeyOfPkgStruct = (Math.floor((Number(obtainKeyOfPkgStruct[i])) % 12));
                        let appendMonthToQuotient = getQuotientOfObtainKeyOfPkgStruct + " " + "Month";
                        let finalAppendedString = appendYearToRemainder + " " + appendMonthToQuotient;
                        modifiedState.convertedPkgDuration.push(finalAppendedString);
                    } else {
                        let appendMonthToObtainKeyOfPkgStruct = (Number(obtainKeyOfPkgStruct[i])) + " " + "Month"
                        modifiedState.convertedPkgDuration.push(appendMonthToObtainKeyOfPkgStruct);
                    }
                }
            }
        }     
        
        setState({...modifiedState});
    }

    const onEnterOneTimeChargePerUnitINRForApiOrder = (e) => {
        let modifiedState = state;

        if(modifiedState.selectedProductNameForApiOrder == null || modifiedState.selectedProductNameForApiOrder == ""){
            alert(`Please select Product Name First.`);
            return; 
        } else {
            modifiedState.oneTimeChargePerUnitINRForApiOrder = e.target.value;
            modifiedState.errors.errApiOrder = "";

            //dont allow user to enter negetive value
            if(modifiedState.oneTimeChargePerUnitINRForApiOrder != null && modifiedState.oneTimeChargePerUnitINRForApiOrder < 0)
            {
                modifiedState.oneTimeChargePerUnitINRForApiOrder = "";
            }

            if(modifiedState.totalOrderPriceWithoutTax != null && modifiedState.totalTaxPriceForSelectedDuration != null && modifiedState.totalTaxPriceForSelectedDuration != "" &&
                modifiedState.oneTimeChargePerUnitINRForApiOrder != null && modifiedState.oneTimeChargePerUnitINRForApiOrder != "" && modifiedState.totalTaxPrcntForSelectedDuration != null && modifiedState.totalTaxPrcntForSelectedDuration != "")
            {
                if(modifiedState.formViewMode == "editMode" && modifiedState.SelectedCustNewOrderDetails == false) {

                    for(let i=0; i< modifiedState.ExtApiPackageInfo.length; i++) {
                        if(modifiedState.ExtApiPackageInfo[i]["ProductCode"] == modifiedState.selectedProductCodeForApiOrder) {
                            modifiedState.BasePkgStructForApiOrder = modifiedState.ExtApiPackageInfo[i]["BasePkgStruct"];
                            modifiedState.PricePerDayINRForApiOrder = modifiedState.ExtApiPackageInfo[i]["PricePerDayINR"];
                            modifiedState.PricePerHitINRForApiOrder = modifiedState.ExtApiPackageInfo[i]["PricePerHitINR"];
                            modifiedState.TaxPrcntINRForApiOrder = modifiedState.ExtApiPackageInfo[i]["TaxPrcntINR"];                        
                        }
                    }

                    let splitSelectedDurationForApiOrder = modifiedState.selectedDurationForApiOrder.split(" ");

                    if(splitSelectedDurationForApiOrder.length == 4){
                        modifiedState.convertedPeriodInMonths  = (Number(splitSelectedDurationForApiOrder[0]) * 12) + Number(splitSelectedDurationForApiOrder[2]);
                    } else {
                        modifiedState.convertedPeriodInMonths = Number(splitSelectedDurationForApiOrder[0]);
                    }

                    // if visited from edit order get the modifiedState.daysBasedOnSelectedDurationForApiOrder 
                    // on onEnterOneTimeChargePerUnitINRForApiOrder to get further calculations.
                    modifiedState.daysBasedOnSelectedDurationForApiOrder = Number(modifiedState.convertedPeriodInMonths) * 30;
                }            

                modifiedState.totalOrderPriceWithoutTax = (modifiedState.PricePerDayINRForApiOrder != null && (Number(modifiedState.PricePerDayINRForApiOrder) > 0 || modifiedState.PricePerDayINRForApiOrder != "0" ||  modifiedState.PricePerDayINRForApiOrder != 0) &&
                    modifiedState.daysBasedOnSelectedDurationForApiOrder != null && (Number(modifiedState.daysBasedOnSelectedDurationForApiOrder) > 0 || modifiedState.daysBasedOnSelectedDurationForApiOrder != "0" ||  modifiedState.daysBasedOnSelectedDurationForApiOrder != 0) &&
                    modifiedState.apiHitOnSelectedDuration != null && (Number(modifiedState.apiHitOnSelectedDuration) > 0 || modifiedState.apiHitOnSelectedDuration != "0" ||  modifiedState.apiHitOnSelectedDuration != 0) &&
                    modifiedState.PricePerHitINRForApiOrder != null && (Number(modifiedState.PricePerHitINRForApiOrder) > 0 || modifiedState.PricePerHitINRForApiOrder != "0" ||  modifiedState.PricePerHitINRForApiOrder != 0) && 
                    modifiedState.oneTimeChargePerUnitINRForApiOrder != null &&  modifiedState.oneTimeChargePerUnitINRForApiOrder != "") ?
                    ((Number(modifiedState.PricePerDayINRForApiOrder) * Number(modifiedState.daysBasedOnSelectedDurationForApiOrder)) + (Number(modifiedState.PricePerHitINRForApiOrder) * Number(modifiedState.apiHitOnSelectedDuration)) + Number(modifiedState.oneTimeChargePerUnitINRForApiOrder)).toFixed(2) : 0;

                modifiedState.discountPriceForTotalOrderPrice =  (modifiedState.totalOrderPriceWithoutTax != null && 
                    modifiedState.discountPercntForTotalOrderPrice != null) ? 
                    (((Number(modifiedState.totalOrderPriceWithoutTax)) * ((Number(modifiedState.discountPercntForTotalOrderPrice)) / 100 ))).toFixed(2) : 0;
    
                let DiscountedPrice = (modifiedState.totalOrderPriceWithoutTax != null && modifiedState.discountPriceForTotalOrderPrice != null) ?
                    ((Number(modifiedState.totalOrderPriceWithoutTax)) - (Number(modifiedState.discountPriceForTotalOrderPrice))).toFixed(2) : 0;
    
                modifiedState.totalTaxPriceForSelectedDuration = (DiscountedPrice != null &&
                    modifiedState.totalTaxPrcntForSelectedDuration != null && modifiedState.totalTaxPrcntForSelectedDuration != "") ? 
                    (((Number(DiscountedPrice)) * ((Number(modifiedState.totalTaxPrcntForSelectedDuration)) / 100 ))).toFixed(2) : 0;
    
                modifiedState.finalTotalOrderPrice = (DiscountedPrice != null &&
                    modifiedState.totalTaxPriceForSelectedDuration != null && modifiedState.totalTaxPriceForSelectedDuration != "") ? 
                    (Number(DiscountedPrice) + Number(modifiedState.totalTaxPriceForSelectedDuration)).toFixed(2) : 0;  
            }
            
            setState({...modifiedState});
        }
    }

    const onChangeDurationForApiOrder = (e) => {

        let modifiedState = state;

        if(modifiedState.selectedProductNameForApiOrder == null || modifiedState.selectedProductNameForApiOrder == ""){
            alert(`Please select Product Name First.`);
            return;
        } else {

            modifiedState.selectedDurationForApiOrder = e.target.value;
            modifiedState.errors.errApiOrder = "";

            // get api hits based on selected duration 
            // split the modifiedState.selectedDurationForApiOrder.
            let splitSelectedDurationForApiOrder = modifiedState.selectedDurationForApiOrder.split(" ");

            // get the total months of modifiedState.selectedDurationForApiOrder
            if(splitSelectedDurationForApiOrder.length == 4){
                modifiedState.convertedPeriodInMonths  = (Number(splitSelectedDurationForApiOrder[0]) * 12) + Number(splitSelectedDurationForApiOrder[2]);
            } else {
                modifiedState.convertedPeriodInMonths = Number(splitSelectedDurationForApiOrder[0]);
            }

            let parsedJsonOfBasePkgStruct = JSON.parse(modifiedState.BasePkgStructForApiOrder);
            // based on json key value pair, get the value with respect to month as a key 
            // and get the value for modifiedState.apiHitOnSelectedDuration.
            modifiedState.apiHitOnSelectedDuration = parsedJsonOfBasePkgStruct["PkgStruct"][modifiedState.convertedPeriodInMonths];
            modifiedState.daysBasedOnSelectedDurationForApiOrder =  modifiedState.convertedPeriodInMonths * 30;

            modifiedState.totalOrderPriceWithoutTax = (modifiedState.PricePerDayINRForApiOrder != null && (Number(modifiedState.PricePerDayINRForApiOrder) > 0 || modifiedState.PricePerDayINRForApiOrder != "0" ||  modifiedState.PricePerDayINRForApiOrder != 0) &&
                modifiedState.daysBasedOnSelectedDurationForApiOrder != null && (Number(modifiedState.daysBasedOnSelectedDurationForApiOrder) > 0 || modifiedState.daysBasedOnSelectedDurationForApiOrder != "0" ||  modifiedState.daysBasedOnSelectedDurationForApiOrder != 0) &&
                modifiedState.apiHitOnSelectedDuration != null && (Number(modifiedState.apiHitOnSelectedDuration) > 0 || modifiedState.apiHitOnSelectedDuration != "0" ||  modifiedState.apiHitOnSelectedDuration != 0) &&
                modifiedState.PricePerHitINRForApiOrder != null && (Number(modifiedState.PricePerHitINRForApiOrder) > 0 || modifiedState.PricePerHitINRForApiOrder != "0" ||  modifiedState.PricePerHitINRForApiOrder != 0) && 
                modifiedState.oneTimeChargePerUnitINRForApiOrder != null &&  modifiedState.oneTimeChargePerUnitINRForApiOrder != "") ?
                ((Number(modifiedState.PricePerDayINRForApiOrder) * Number(modifiedState.daysBasedOnSelectedDurationForApiOrder)) + (Number(modifiedState.PricePerHitINRForApiOrder) * Number(modifiedState.apiHitOnSelectedDuration)) + Number(modifiedState.oneTimeChargePerUnitINRForApiOrder)).toFixed(2) : 0;
            
            if(modifiedState.formViewMode == "editMode" && modifiedState.SelectedCustNewOrderDetails == false) {

                modifiedState.discountPriceForTotalOrderPrice =  (modifiedState.totalOrderPriceWithoutTax != null && 
                    modifiedState.discountPercntForTotalOrderPrice != null) ? 
                    (((Number(modifiedState.totalOrderPriceWithoutTax)) * ((Number(modifiedState.discountPercntForTotalOrderPrice)) / 100 ))).toFixed(2) : 0;
    
                let DiscountedPrice = (modifiedState.totalOrderPriceWithoutTax != null && modifiedState.discountPriceForTotalOrderPrice != null) ?
                    ((Number(modifiedState.totalOrderPriceWithoutTax)) - (Number(modifiedState.discountPriceForTotalOrderPrice))).toFixed(2) : 0;
    
                modifiedState.totalTaxPriceForSelectedDuration = (DiscountedPrice != null &&
                    modifiedState.totalTaxPrcntForSelectedDuration != null && modifiedState.totalTaxPrcntForSelectedDuration != "") ? 
                    (((Number(DiscountedPrice)) * ((Number(modifiedState.totalTaxPrcntForSelectedDuration)) / 100 ))).toFixed(2) : 0;
    
                modifiedState.finalTotalOrderPrice = (DiscountedPrice != null &&
                    modifiedState.totalTaxPriceForSelectedDuration != null && modifiedState.totalTaxPriceForSelectedDuration != "") ? 
                    (Number(DiscountedPrice) + Number(modifiedState.totalTaxPriceForSelectedDuration)).toFixed(2) : 0;  

            } else {

                modifiedState.discountPriceForTotalOrderPrice = 0;
                modifiedState.discountPercntForTotalOrderPrice = 0;

                modifiedState.totalTaxPrcntForSelectedDuration = modifiedState.TaxPrcntINRForApiOrder;

                modifiedState.totalTaxPriceForSelectedDuration = (modifiedState.totalOrderPriceWithoutTax != null && 
                    modifiedState.totalTaxPrcntForSelectedDuration != null && modifiedState.totalTaxPrcntForSelectedDuration != "") ? 
                    (((Number(modifiedState.totalOrderPriceWithoutTax)) * ((Number(modifiedState.totalTaxPrcntForSelectedDuration)) / 100 ))).toFixed(2) : 0;
                
                modifiedState.finalTotalOrderPrice = (modifiedState.totalOrderPriceWithoutTax != null &&
                    modifiedState.totalTaxPriceForSelectedDuration != null && modifiedState.totalTaxPriceForSelectedDuration != "") ? 
                    (Number(modifiedState.totalOrderPriceWithoutTax) + Number(modifiedState.totalTaxPriceForSelectedDuration)).toFixed(2) : 0;   
            } 
               
            setState({...modifiedState});
        }
    }

    const onChnageDiscountPercntForTotalOrderPrice = (e) => {

        let modifiedState = state;
        if(modifiedState.selectedDurationForApiOrder == null || modifiedState.selectedDurationForApiOrder == "" || 
            modifiedState.selectedProductNameForApiOrder == null || modifiedState.selectedProductNameForApiOrder == ""){
            alert(`Please select Product Name First.\nIf Product Name is selected Please Select package Duration.`);
            return;
        } else {
            modifiedState.discountPercntForTotalOrderPrice = e.target.value;
            modifiedState.errors.errApiOrder = "";

            if(modifiedState.discountPercntForTotalOrderPrice != null && modifiedState.discountPercntForTotalOrderPrice < 0)
            {
                modifiedState.discountPercntForTotalOrderPrice = "";
            }

            modifiedState.discountPriceForTotalOrderPrice =  (modifiedState.totalOrderPriceWithoutTax != null && 
                modifiedState.discountPercntForTotalOrderPrice != null) ? 
                (((Number(modifiedState.totalOrderPriceWithoutTax)) * ((Number(modifiedState.discountPercntForTotalOrderPrice)) / 100 ))).toFixed(2) : 0;

            let DiscountedPrice = (modifiedState.totalOrderPriceWithoutTax != null && modifiedState.discountPriceForTotalOrderPrice != null) ?
            ((Number(modifiedState.totalOrderPriceWithoutTax)) - (Number(modifiedState.discountPriceForTotalOrderPrice))).toFixed(2) : 0;

            modifiedState.totalTaxPriceForSelectedDuration = (DiscountedPrice != null &&
                modifiedState.totalTaxPrcntForSelectedDuration != null && modifiedState.totalTaxPrcntForSelectedDuration != "") ? 
                (((Number(DiscountedPrice)) * ((Number(modifiedState.totalTaxPrcntForSelectedDuration)) / 100 ))).toFixed(2) : 0;

            modifiedState.finalTotalOrderPrice = (DiscountedPrice != null &&
                modifiedState.totalTaxPriceForSelectedDuration != null && modifiedState.totalTaxPriceForSelectedDuration != "") ? 
                (Number(DiscountedPrice) + Number(modifiedState.totalTaxPriceForSelectedDuration)).toFixed(2) : 0;  
                
                setState({...modifiedState});
            }

    }

    const onChnageTotalTaxPrcntForSelectedPackageDuration = (e) => {

        let modifiedState = state;
        if(modifiedState.selectedDurationForApiOrder == null || modifiedState.selectedDurationForApiOrder == "" || 
            modifiedState.selectedProductNameForApiOrder == null || modifiedState.selectedProductNameForApiOrder == ""){
            alert(`Please select Product Name First.\nIf Product Name is selected Please Select package Duration.`);
            return;
        } else {
            modifiedState.totalTaxPrcntForSelectedDuration = e.target.value;
            modifiedState.errors.errApiOrder = "";

            if(modifiedState.totalTaxPrcntForSelectedDuration != null && modifiedState.totalTaxPrcntForSelectedDuration < 0)
            {
                modifiedState.totalTaxPrcntForSelectedDuration = "";
            }

            let DiscountedPrice = (modifiedState.totalOrderPriceWithoutTax != null && modifiedState.discountPriceForTotalOrderPrice != null) ?
                ((Number(modifiedState.totalOrderPriceWithoutTax)) - (Number(modifiedState.discountPriceForTotalOrderPrice))).toFixed(2) : 0;

            modifiedState.totalTaxPriceForSelectedDuration = (DiscountedPrice != null &&
                modifiedState.totalTaxPrcntForSelectedDuration != null && modifiedState.totalTaxPrcntForSelectedDuration != "") ? 
                (((Number(DiscountedPrice)) * ((Number(modifiedState.totalTaxPrcntForSelectedDuration)) / 100 ))).toFixed(2) : 0;

            modifiedState.finalTotalOrderPrice = (DiscountedPrice != null &&
                modifiedState.totalTaxPriceForSelectedDuration != null && modifiedState.totalTaxPriceForSelectedDuration != "") ? 
                (Number(DiscountedPrice) + Number(modifiedState.totalTaxPriceForSelectedDuration)).toFixed(2) : 0;  

                setState({...modifiedState});
            }
    }

    // For selecting values of 'ProductCode', 'PricePerUnitINR', 'TaxPerUnitINR' as per selection of dropdown of SELECT Product Name.
    const onChangeProductNameSelection = (e) => {

        let modifiedState = state;

        modifiedState.selectedProductName = e.target.value;
        modifiedState.ProductCode = "";
        modifiedState.newEnteredQuantityOfUnit = "";
        modifiedState.securityDepositPrice =  '';
        modifiedState.oneTimeChargePrice = '';
        modifiedState.TaxPercentagePerUnit = '';
        modifiedState.TaxPerUnitINR = '';
        modifiedState.taxPercentageForOneTimeChargePrice =  "";
        modifiedState.taxPriceForOneTimeCharge = "";
        modifiedState.SubscriptionPricePerUnitINR = null;
        modifiedState.SubcriptionTaxPercentagePerUnitINR = null;
        modifiedState.SubcriptionTaxPerUnitINR = null;
        modifiedState.SubscriptionPeriodPerUnit = null;
        modifiedState.rentaltaxPercentagePerUnit = null;
        modifiedState.rentalPricePerUnitINR = null;
        modifiedState.rentaltaxPricePerUnit = null;
        modifiedState.isOrderWithFreeSubscription = false;
        // this flag is set to true First
        // if susbcription is present for that particular Product Name then flag becomes false
        modifiedState.isSubscriptionInfoMissingForRental = true;
        modifiedState.errors.others = "";

        let TotalSubscriptionPeriodinDays;

        // get all the price details for demo and sale order type
        for(let i=0; i< modifiedState.AllProductsInformation.length; i++) {
            if((modifiedState.AllProductsInformation[i]["ProductName"] == modifiedState.selectedProductName) && (modifiedState.AllProductsInformation[i]["ProductLine"] == modifiedState.selectedProductLine)) {
                // Assigning values to 'ProductCode', 'PricePerUnitINR', 'TaxPercentagePerUnit' based on selection of Second dropdown.
                modifiedState.ProductCode = modifiedState.AllProductsInformation[i]["ProductCode"];
                modifiedState.PricePerUnitINR = modifiedState.AllProductsInformation[i]["PricePerUnitINR"];
                modifiedState.TaxPercentagePerUnit = modifiedState.AllProductsInformation[i]["TaxPrcntPerUnit"];
            }
        }

        // get all the subscription Price/ Rental Price details for Rental, demo and sale order type
        for(let i=0; i< modifiedState.ProductsSubscriptionInfo.length; i++) {
            if((modifiedState.ProductsSubscriptionInfo[i]["ProductCode"] == modifiedState.ProductCode)) {
                
                // this flag is used to check if the subscription is Present for the particular Product Name or not
                // if the subscription is present then make the flag false
                modifiedState.isSubscriptionInfoMissingForRental = false;
                
                // Assigning values to Subscription 'SubscriptionPricePerUnitINR', 'SubcriptionTaxPercentagePerUnitINR' based on selection of Second dropdown.
                modifiedState.SubscriptionPricePerUnitINR = (modifiedState.ProductsSubscriptionInfo[i]["PricePerUnitINR"] == null ||
                                                            modifiedState.ProductsSubscriptionInfo[i]["PricePerUnitINR"].length <=0) ? null : modifiedState.ProductsSubscriptionInfo[i]["PricePerUnitINR"];
                modifiedState.SubcriptionTaxPercentagePerUnitINR = (modifiedState.ProductsSubscriptionInfo[i]["TaxPrcntPerUnit"] == null ||
                                                            modifiedState.ProductsSubscriptionInfo[i]["TaxPrcntPerUnit"].length <=0) ? null : modifiedState.ProductsSubscriptionInfo[i]["TaxPrcntPerUnit"];
                
                // modifiedState.SubscriptionPeriodPerUnit is common variable for all the order type 
                // so when the order type is sale or demo then the value is stored in this variable 
                if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType != ORDER_TYPE_RENTAL) {
                    modifiedState.SubscriptionPeriodPerUnit =  (modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"] == null || modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"].length <= 0) ? null : 1;
                    TotalSubscriptionPeriodinDays =  (modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"] == null || modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"].length <= 0) ? null :  (DAYS_IN_MONTH * modifiedState.SubscriptionPeriodPerUnit);
                }

                // subscription price is fetching from the database per unit day 
                // here we are calulating the subscription price per unit month and then showing on the UI
                modifiedState.SubscriptionPricePerUnitINR = TotalSubscriptionPeriodinDays != null && TotalSubscriptionPeriodinDays > 0 ? (TotalSubscriptionPeriodinDays * modifiedState.SubscriptionPricePerUnitINR).toFixed(2) : null;
            }
        }

        // if the subscription info is not present for that particular Product Name 
        // then dont allow user to create order for that Product Name and show the alert message
        // If the isSubscriptionInfoMissingForRental is true and the order is rental 
        // then dont allow user to make the order for that Product Name.
        if(modifiedState.isSubscriptionInfoMissingForRental != null && modifiedState.isSubscriptionInfoMissingForRental == true &&
            modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL ){
                alert(`Subscription Info is not present for ${modifiedState.selectedProductName} with Product code ${modifiedState.ProductCode}. You cannot create Rental order for this product.\nIf you want to Create Rental order for this product, Please first create Subscription details for this Product and then create Rental order.`);
                modifiedState.selectedProductName = "";
                modifiedState.ProductCode = "";
                setState({...modifiedState});
                return;
        } else {
            for(let i=0; i<  modifiedState.AllRentalProductInformation.length; i++) {
                if((modifiedState.AllRentalProductInformation[i]["ProductCode"] == modifiedState.ProductCode)) {
                    // if the order type is Rental then assign "securityDepositPrice", "oneTimeChargePrice", "taxPercentageForOneTimeChargePrice"
                    // Assigning values to Rental 'rentalPricePerUnitINR', 'rentaltaxPercentagePerUnit' based on selection of Second dropdown.
                    modifiedState.securityDepositPrice = modifiedState.AllRentalProductInformation[i]["RentalSecurityDepositPUI"];
                    modifiedState.oneTimeChargePrice = modifiedState.AllRentalProductInformation[i]["RentalOneTimeChrgPUI"];
                    modifiedState.taxPercentageForOneTimeChargePrice = modifiedState.AllRentalProductInformation[i]["RentalTaxPrcntOnOneTimeChrgPU"];
                    modifiedState.rentalPricePerUnitINR = modifiedState.AllRentalProductInformation[i]["RentalPricePUPI"];
                    modifiedState.rentaltaxPercentagePerUnit = modifiedState.AllRentalProductInformation[i]["RentalTaxPrcntPUP"];
                    if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL){
                        // modifiedState.SubscriptionPeriodPerUnit is common variable for all the order type 
                        // so when the order type is Rental then the value is stored in this variable 
                        modifiedState.SubscriptionPeriodPerUnit = (modifiedState.AllRentalProductInformation[i]["BasicUnit"] == null || modifiedState.AllRentalProductInformation[i]["BasicUnit"].length <= 0) ? null : 1;
                        TotalSubscriptionPeriodinDays =  (modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"] == null || modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"].length <= 0) ? null :  (DAYS_IN_MONTH * modifiedState.SubscriptionPeriodPerUnit);
                    }
                    // Rental price is fetching from the database per unit day 
                    // here we are calulating the Rental price per unit month and then showing on the UI
                    modifiedState.rentalPricePerUnitINR = TotalSubscriptionPeriodinDays != null && TotalSubscriptionPeriodinDays > 0 ? (TotalSubscriptionPeriodinDays * modifiedState.rentalPricePerUnitINR).toFixed(2) : null;
                } 
            }
        }

        // get all the calculations on On change
        GetAllTaxCalculations(modifiedState);
    }

   // For entering Quantity of Unit Order for giving Order of Units.
   const onEnteredQuantityOfUnitOrder = (e) => {
        let modifiedState = state;
        modifiedState.errors.others = "";

        let ToRoundOffEnteredQuantityOfUnit;

        if(modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == 'editMode' && modifiedState.SelectedCustNewOrderDetails == false) {
            if(e.target.value > modifiedState.QuantityOfUnitsScanned) {
                ToRoundOffEnteredQuantityOfUnit = e.target.value;
                modifiedState.newEnteredQuantityOfUnit =  Math.round(ToRoundOffEnteredQuantityOfUnit);
            } else {
                ToRoundOffEnteredQuantityOfUnit = e.target.value;
                modifiedState.newEnteredQuantityOfUnit = Math.round(ToRoundOffEnteredQuantityOfUnit);
                modifiedState.errors.others = "Quantity Of Units Ordered should be greater than Quantity Of Units Scanned."
            }
        } else {
            ToRoundOffEnteredQuantityOfUnit = e.target.value;
            modifiedState.newEnteredQuantityOfUnit = Math.round(ToRoundOffEnteredQuantityOfUnit);
        }

        // if the negetive value is entered then make the input box empty
        if(modifiedState.newEnteredQuantityOfUnit != null && modifiedState.newEnteredQuantityOfUnit < 0)
        {
            modifiedState.newEnteredQuantityOfUnit = "";
        }
        
        setState({...modifiedState});
    }

    const onEnterPricePerUnitINR = (e) => {
        let modifiedState = state;
        modifiedState.PricePerUnitINR = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.PricePerUnitINR != null && modifiedState.PricePerUnitINR < 0)
        {
            modifiedState.PricePerUnitINR = "";
        }

        GetAllTaxCalculations(modifiedState);
    }

    const onEnterTaxPercentagePerUnit = (e) => {
        let modifiedState = state;
        modifiedState.TaxPercentagePerUnit = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.TaxPercentagePerUnit != null && modifiedState.TaxPercentagePerUnit < 0)
        {
            modifiedState.TaxPercentagePerUnit = "";
        } 

        GetAllTaxCalculations(modifiedState);
    }

    const onEnterSubcriptionPricePerUnitINR = (e) => {
        let modifiedState = state;
        modifiedState.SubscriptionPricePerUnitINR = e.target.value;
        
        modifiedState.errors.others = "";

        if(modifiedState.SubscriptionPricePerUnitINR != null && modifiedState.SubscriptionPricePerUnitINR < 0)
        {
            modifiedState.SubscriptionPricePerUnitINR = "";
        } 

        GetAllTaxCalculations(modifiedState);
    }

    const onEnterSubcriptionTaxPercentPerUnitINR = (e) => {
        let modifiedState = state;
        modifiedState.SubcriptionTaxPercentagePerUnitINR = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.SubcriptionTaxPercentagePerUnitINR != null && modifiedState.SubcriptionTaxPercentagePerUnitINR < 0)
        {
            modifiedState.SubcriptionTaxPercentagePerUnitINR = "";
        } 

        GetAllTaxCalculations(modifiedState);
    }

    const onEnterSecurityDepositPricePerUnitINR = (e) => {
        let modifiedState = state;
        modifiedState.securityDepositPrice = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.securityDepositPrice != null && modifiedState.securityDepositPrice < 0)
        {
            modifiedState.securityDepositPrice = "";
        } 

        setState({...modifiedState});
    }

    const onEnterOneTimeChargePricePerUnitOrder = (e) => {
        let modifiedState = state;
        modifiedState.oneTimeChargePrice = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.oneTimeChargePrice != null && modifiedState.oneTimeChargePrice < 0)
        {
            modifiedState.oneTimeChargePrice = "";
        } 

        GetAllTaxCalculations(modifiedState);
    }

    const onEnterTaxPercentageOnOneTimeChargePrice = (e) => {
        let modifiedState = state;
        modifiedState.taxPercentageForOneTimeChargePrice = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.taxPercentageForOneTimeChargePrice != null && modifiedState.taxPercentageForOneTimeChargePrice < 0)
        {
            modifiedState.taxPercentageForOneTimeChargePrice = "";
        }

        GetAllTaxCalculations(modifiedState);
    }

    const onEnterRentalPricePerUnitINR = (e) => {
        let modifiedState = state;
        modifiedState.rentalPricePerUnitINR = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.rentalPricePerUnitINR != null && modifiedState.rentalPricePerUnitINR < 0)
        {
            modifiedState.rentalPricePerUnitINR = "";
        }

        GetAllTaxCalculations(modifiedState);
    } 

    const onEnterRentalTaxPercentagePerUnit = (e) => {
        let modifiedState = state;
        modifiedState.rentaltaxPercentagePerUnit = e.target.value;
        modifiedState.errors.others = "";

        if(modifiedState.rentaltaxPercentagePerUnit != null && modifiedState.rentaltaxPercentagePerUnit < 0)
        {
            modifiedState.rentaltaxPercentagePerUnit = "";
        } 

        GetAllTaxCalculations(modifiedState);
    } 

    const onEnterSubscriptionPeriodPerUnit = (e) => {
        let modifiedState = state;
        modifiedState.SubscriptionPeriodPerUnit = e.target.value <= 1 ? 1 : e.target.value;
        modifiedState.errors.others = "";
        setState({...modifiedState});
    }

    const GetAllTaxCalculations = (inModifiedState = null) =>  {

        let modifiedState;

        if(inModifiedState == null){
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        if (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL){
        
            modifiedState.taxPriceForOneTimeCharge = (modifiedState.oneTimeChargePrice != null && (Number(modifiedState.oneTimeChargePrice) > 0 
                || modifiedState.oneTimeChargePrice != "0" || modifiedState.oneTimeChargePrice != 0) && modifiedState.taxPercentageForOneTimeChargePrice != null && (Number(modifiedState.taxPercentageForOneTimeChargePrice) > 0 ||
                modifiedState.oneTimeChargePrice != "0" ||  modifiedState.oneTimeChargePrice != 0)) ? ((Number(modifiedState.oneTimeChargePrice) * ((Number(modifiedState.taxPercentageForOneTimeChargePrice)) / 100 ))).toFixed(2) : 0;

            modifiedState.rentaltaxPricePerUnit = (modifiedState.rentalPricePerUnitINR != null && (Number(modifiedState.rentalPricePerUnitINR) > 0 || modifiedState.rentalPricePerUnitINR != "0" || 
             modifiedState.rentalPricePerUnitINR != 0) && modifiedState.rentaltaxPercentagePerUnit != null && (Number(modifiedState.rentaltaxPercentagePerUnit) > 0 ||
                modifiedState.rentaltaxPercentagePerUnit != "0" || modifiedState.rentaltaxPercentagePerUnit != 0)) ? 
                    ((Number(modifiedState.rentalPricePerUnitINR) * ((Number(modifiedState.rentaltaxPercentagePerUnit)) / 100 ))).toFixed(2) : 0
        } else {

            modifiedState.TaxPerUnitINR = (modifiedState.PricePerUnitINR != null && (Number(modifiedState.PricePerUnitINR) > 0 || modifiedState.PricePerUnitINR != "0" ||  modifiedState.PricePerUnitINR != 0) &&
            modifiedState.TaxPercentagePerUnit != null && (Number(modifiedState.TaxPercentagePerUnit) > 0 || modifiedState.TaxPercentagePerUnit != "0" ||  modifiedState.TaxPercentagePerUnit != 0)) ? 
                ((Number(modifiedState.PricePerUnitINR) * ((Number(modifiedState.TaxPercentagePerUnit)) / 100 ))).toFixed(2) : 0;
        
            if(modifiedState.SubscriptionPricePerUnitINR == null){
                modifiedState.SubcriptionTaxPerUnitINR = null
            } else {
                modifiedState.SubcriptionTaxPerUnitINR =  (modifiedState.SubscriptionPricePerUnitINR != null && (Number(modifiedState.SubscriptionPricePerUnitINR) > 0 || modifiedState.SubscriptionPricePerUnitINR != "0" ||
                  modifiedState.SubscriptionPricePerUnitINR != 0 ) && modifiedState.SubcriptionTaxPercentagePerUnitINR != null && 
                    (Number(modifiedState.SubcriptionTaxPercentagePerUnitINR) > 0 || modifiedState.SubcriptionTaxPercentagePerUnitINR != "0" || modifiedState.SubcriptionTaxPercentagePerUnitINR != 0)) ? 
                        ((Number(modifiedState.SubscriptionPricePerUnitINR) * ((Number(modifiedState.SubcriptionTaxPercentagePerUnitINR)) / 100 ))).toFixed(2) : 0;     
            }            
        }

        setState({...modifiedState});
    }

    // ===================================================================== 
    
    const onOkButtonClick = (e) => {

        let modifiedState = state;

        if(modifiedState.createEditOrder){

            if((modifiedState.selectedProductLine == null || modifiedState.selectedProductLine.length <= 0) ||
                (modifiedState.selectedProductName == null || modifiedState.selectedProductName.length <= 0)) 
            {
                modifiedState.errors.others = "Please select Product Line Name or Product Name.";
                setState({...modifiedState});
                return; // Dont process any further
            } else if((modifiedState.newEnteredQuantityOfUnit == null || modifiedState.newEnteredQuantityOfUnit.length <= 0))
            {
                modifiedState.errors.others = "Please enter Quantity of unit ordered.";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.newEnteredQuantityOfUnit <= modifiedState.QuantityOfUnitsScanned) 
            {
                modifiedState.errors.others = "Quantity Of Units Ordered should be greater than Quantity Of Units Scanned.";
                setState({...modifiedState});
                return; // Dont process any further 

            } else if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO) &&
                (modifiedState.PricePerUnitINR == null || modifiedState.PricePerUnitINR.length <= 0)) 
            {
                modifiedState.errors.others = "Please enter Price Per Unit Order.";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO) &&
                (modifiedState.PricePerUnitINR != null || modifiedState.PricePerUnitINR.length > 0) && (modifiedState.TaxPercentagePerUnit == null || modifiedState.TaxPercentagePerUnit.length <= 0))
            {
                modifiedState.errors.others = "Please enter Tax % Per Unit Order.";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL &&
                (modifiedState.securityDepositPrice == null || modifiedState.securityDepositPrice.length <= 0))
            {
                modifiedState.errors.others = "Please enter Security Deposit Per Unit Order.";
                setState({...modifiedState});
                return; // Dont process any further
            } else if (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL &&
                (modifiedState.oneTimeChargePrice == null || modifiedState.oneTimeChargePrice.length <= 0))
            {
                modifiedState.errors.others = "Please enter One Time Charge Per Unit Order..";
                setState({...modifiedState});
                return; // Dont process any further
            } else if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 &&  modifiedState.selectedOrderType == ORDER_TYPE_RENTAL &&
                (modifiedState.oneTimeChargePrice != null || modifiedState.oneTimeChargePrice.length > 0) && (modifiedState.taxPercentageForOneTimeChargePrice == null || modifiedState.taxPercentageForOneTimeChargePrice.length <= 0))
            {
                modifiedState.errors.others = "Please enter Tax % on One time Charge Per Unit Order.";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 &&  modifiedState.selectedOrderType == ORDER_TYPE_RENTAL &&
                (modifiedState.SubscriptionPeriodPerUnit == null || modifiedState.SubscriptionPeriodPerUnit == 0))
            {
                modifiedState.errors.others = "Please enter Rental Period";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 &&  modifiedState.selectedOrderType == ORDER_TYPE_RENTAL &&
                (modifiedState.rentalPricePerUnitINR == null || modifiedState.rentalPricePerUnitINR.length <= 0))
            {
                modifiedState.errors.others = "Please enter Rental Price Per Unit.";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 &&  modifiedState.selectedOrderType == ORDER_TYPE_RENTAL &&
                (modifiedState.rentalPricePerUnitINR != null || modifiedState.rentalPricePerUnitINR.length > 0) && (modifiedState.rentaltaxPercentagePerUnit == null || modifiedState.rentaltaxPercentagePerUnit.length <= 0))
            {
                modifiedState.errors.others = "Please enter Tax % on Rental Price Per Unit.";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if (modifiedState.formViewMode == 'editMode' && state.formViewMode.length > 0 && (modifiedState.newEnteredQuantityOfUnit < modifiedState.QuantityOfUnitsScanned)) {
                modifiedState.errors.others = "Quantity Of Unit Order should be greter than Quantity Of UnitsScanned.";
            }

            let TotalSubscriptionCost;

            // Diffrent varaibles are used for rental 
            // so diffrent calculations are shown for Rental order.
            if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)){
                TotalSubscriptionCost = (modifiedState.SubscriptionPricePerUnitINR != null && modifiedState.SubcriptionTaxPerUnitINR != null && modifiedState.SubscriptionPeriodPerUnit != null) ? ((Number(modifiedState.SubscriptionPricePerUnitINR) + Number(modifiedState.SubcriptionTaxPerUnitINR)) * (Number(modifiedState.SubscriptionPeriodPerUnit) * (Number(modifiedState.selectedSubscriptionPeriod)))) : 0;
            } else {
                TotalSubscriptionCost = ((Number(modifiedState.rentalPricePerUnitINR) + Number(modifiedState.rentaltaxPricePerUnit)) * (Number(modifiedState.SubscriptionPeriodPerUnit) * (Number(modifiedState.selectedSubscriptionPeriod))))
            }
            
            let SubScriptionPricePerUnit = modifiedState.SubscriptionPricePerUnitINR == null ? 'NA' : 
                modifiedState.SubscriptionPricePerUnitINR != null && (modifiedState.SubscriptionPricePerUnitINR.length <= 0 || modifiedState.SubscriptionPricePerUnitINR == "")
                ? 0 : modifiedState.SubscriptionPricePerUnitINR;
            let SubScriptionTaxpercentPerUnit = modifiedState.SubcriptionTaxPercentagePerUnitINR == null ? 'NA' : 
                modifiedState.SubcriptionTaxPercentagePerUnitINR != null && (modifiedState.SubcriptionTaxPercentagePerUnitINR.length <= 0 || modifiedState.SubcriptionTaxPercentagePerUnitINR == "")
                ? 0 : modifiedState.SubcriptionTaxPercentagePerUnitINR; 
            let SubScriptionTaxPerUnit = modifiedState.SubcriptionTaxPerUnitINR == null ? 'NA' : modifiedState.SubcriptionTaxPerUnitINR; 
            let SubscriptionPeriodPerUnitOrder = modifiedState.SubscriptionPeriodPerUnit == null ? 'NA' : ((Number(modifiedState.SubscriptionPeriodPerUnit) * (Number(modifiedState.selectedSubscriptionPeriod)))).toFixed(1);

            let RentalPeriodPerUnitOrder;
            
            // mathamatical operation for showing total value of order.
            // modifiedState.TotalOrderValue = (Math.abs(modifiedState.TotalOrderValue) + (( Math.abs(modifiedState.newEnteredQuantityOfUnit) * (Math.abs(modifiedState.PricePerUnitINR) + Math.abs(modifiedState.TaxPerUnitINR))))).toFixed(2);
            if(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)){
                modifiedState.OrderItemsValue = (( Math.abs(modifiedState.newEnteredQuantityOfUnit) * (Math.abs(modifiedState.PricePerUnitINR) + Math.abs(modifiedState.TaxPerUnitINR) + Number(TotalSubscriptionCost)))).toFixed(2);
            } else {
                modifiedState.OrderItemsValue = (( Math.abs(modifiedState.newEnteredQuantityOfUnit) * (Math.abs(modifiedState.securityDepositPrice) + Math.abs(modifiedState.oneTimeChargePrice) + Math.abs(modifiedState.taxPriceForOneTimeCharge) + Number(TotalSubscriptionCost)))).toFixed(2);
                RentalPeriodPerUnitOrder = ((Number(modifiedState.SubscriptionPeriodPerUnit) * (Number(modifiedState.selectedSubscriptionPeriod)))).toFixed(1);
            }

            let newProductData = [...modifiedState.productdata]
            let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>

            let singleProductData = {
                edit:(modifiedState.formViewMode == 'editMode') ? editIcon : null,
                ProductLine: modifiedState.selectedProductLine,
                ProductName: modifiedState.selectedProductName,
                ProductCode: modifiedState.ProductCode,
                QuantityOfUnitsScanned:modifiedState.QuantityOfUnitsScanned == null ? 0 : modifiedState.QuantityOfUnitsScanned,
                QuantityOfUnitOrdered: modifiedState.newEnteredQuantityOfUnit,
                PricePerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)) ? modifiedState.PricePerUnitINR : 'NA',
                TaxPercentPerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)) ? modifiedState.TaxPercentagePerUnit :'NA',
                TaxPerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)) ? modifiedState.TaxPerUnitINR : 'NA',
                SecurityDepositPerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? Number(modifiedState.securityDepositPrice).toFixed(2) : 'NA',
                OneTimeChargePerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? Number(modifiedState.oneTimeChargePrice).toFixed(2) : 'NA',
                TaxPercentageOnOneTimeChargePerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? modifiedState.taxPercentageForOneTimeChargePrice : 'NA',
                TaxPriceOnOneTimeChargePerUnitOrder :(modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? modifiedState.taxPriceForOneTimeCharge: 'NA',
                OrderItemsValue: modifiedState.OrderItemsValue,
                OrderNumber: modifiedState.OrderNumber == null ? null : modifiedState.OrderNumber,
                OrderLineNumber: modifiedState.OrderLineNumber == null ? 0 : modifiedState.OrderLineNumber,
                Status:modifiedState.OrderStatus,
                SubscriptionPricePerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)) ? 
                    SubScriptionPricePerUnit : (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? modifiedState.rentalPricePerUnitINR : 'NA',
                SubscriptionPercentagePerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)) ? 
                    SubScriptionTaxpercentPerUnit : (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? modifiedState.rentaltaxPercentagePerUnit : 'NA',
                SubscriptionTaxPerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)) ? 
                    SubScriptionTaxPerUnit : (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? modifiedState.rentaltaxPricePerUnit : 'NA',
                SubscriptionPeriodPerUnitOrder: (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && (modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO)) ? 
                    SubscriptionPeriodPerUnitOrder : (modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) ? RentalPeriodPerUnitOrder : 'NA',
            };
            
            if(modifiedState.formViewMode == 'editMode' && state.formViewMode.length) {
                for(let j = 0; j <newProductData.length; j++){
                    // if(newProductData[j].ProductLine == singleProductData.ProductLine &&
                    //     newProductData[j].ProductName == singleProductData.ProductName &&
                    //     newProductData[j].ProductCode == singleProductData.ProductCode &&
                    //     newProductData[j].QuantityOfUnitsScanned == singleProductData.QuantityOfUnitsScanned
                    //     ){
                    //         newProductData.splice(j, 1);
                    // }
                    if(singleProductData.OrderLineNumber != 0 && newProductData[j].OrderLineNumber == singleProductData.OrderLineNumber) {
                        newProductData.splice(j, 1);
                    }
                }
                if(singleProductData.OrderLineNumber == 0) {
                    newProductData.unshift(singleProductData); 

                } else {
                    newProductData.unshift(singleProductData); 
                }
                // newProductData.unshift(singleProductData); 
            } else {
                newProductData.push(singleProductData);
            }

            modifiedState.productdata = newProductData;
            modifiedState.TotalOrderValue = 0;

            if(modifiedState.productdata != null && modifiedState.productdata.length > 0) {
                for(let i=0; i<modifiedState.productdata.length; i++) {
                    modifiedState.TotalOrderValue = (Math.abs(modifiedState.TotalOrderValue) + Math.abs(modifiedState.productdata[i]["OrderItemsValue"])).toFixed(2);
                }
            }

        } else {

            if(modifiedState.selectedProductNameForApiOrder == null || modifiedState.selectedProductNameForApiOrder.length <= 0)
            {
                modifiedState.errors.errApiOrder = "Please select Product Name.";
                setState({...modifiedState});
                return; // Dont process any further
            } else if(modifiedState.oneTimeChargePerUnitINRForApiOrder == null || modifiedState.oneTimeChargePerUnitINRForApiOrder.length <= 0)
            {
                modifiedState.errors.errApiOrder = "Please enter One time Charge Per Order (INR).";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.selectedDurationForApiOrder == null || modifiedState.selectedDurationForApiOrder.length <= 0)
            {
                modifiedState.errors.errApiOrder = "Please Select Package Duration";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.discountPercntForTotalOrderPrice == null || modifiedState.discountPercntForTotalOrderPrice.length <= 0)
            {
                modifiedState.errors.errApiOrder = "Please Enter Discount % on Total Order Price.";
                setState({...modifiedState});
                return; // Dont process any further 
            } else if(modifiedState.totalTaxPrcntForSelectedDuration == null || modifiedState.totalTaxPrcntForSelectedDuration.length <= 0)
            {
                modifiedState.errors.errApiOrder = "Please enter Enter Tax % on Total Price for Selected Package.";
                setState({...modifiedState});
                return; // Dont process any further
            }

            let PricePerPkgDurINR = (modifiedState.PricePerDayINRForApiOrder != null && modifiedState.PricePerDayINRForApiOrder != "" && (Number(modifiedState.PricePerDayINRForApiOrder) > 0 || modifiedState.PricePerDayINRForApiOrder != "0" ||  modifiedState.PricePerDayINRForApiOrder != 0) &&
                modifiedState.daysBasedOnSelectedDurationForApiOrder != null && modifiedState.daysBasedOnSelectedDurationForApiOrder != "" && (Number(modifiedState.daysBasedOnSelectedDurationForApiOrder) > 0 || modifiedState.daysBasedOnSelectedDurationForApiOrder != "0" ||  modifiedState.daysBasedOnSelectedDurationForApiOrder != 0)) ?
                (Number(modifiedState.PricePerDayINRForApiOrder) * Number(modifiedState.daysBasedOnSelectedDurationForApiOrder)).toFixed(2) : 0;
            
            let PriceOfHitsInPkgINR = (modifiedState.apiHitOnSelectedDuration != null && modifiedState.apiHitOnSelectedDuration != "" && (Number(modifiedState.apiHitOnSelectedDuration) > 0 || modifiedState.apiHitOnSelectedDuration != "0" ||  modifiedState.apiHitOnSelectedDuration != 0) &&
                modifiedState.PricePerHitINRForApiOrder != null && modifiedState.PricePerHitINRForApiOrder != "" && (Number(modifiedState.PricePerHitINRForApiOrder) > 0 || modifiedState.PricePerHitINRForApiOrder != "0" ||  modifiedState.PricePerHitINRForApiOrder != 0)) ?
                (Number(modifiedState.apiHitOnSelectedDuration) * Number(modifiedState.PricePerHitINRForApiOrder)).toFixed(2) : 0;

            let newProductData = [...modifiedState.productdata]
            let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>

            let singleProductData = {
                edit:(modifiedState.formViewMode == 'editMode') ? editIcon : null,
                ProductLine: modifiedState.selectedProductLineForApiOrder,
                ProductName: modifiedState.selectedProductNameForApiOrder,
                ProductCode: modifiedState.selectedProductCodeForApiOrder,
                QuantityOfUnitsScanned: modifiedState.QuantityOfUnitsScannedForApiOrder == null ? 0 : modifiedState.QuantityOfUnitsScannedForApiOrder,
                QuantityOfUnitOrdered: modifiedState.newEnteredQuantityOfUnitForApiOrder,
                PricePerUnitOrder: modifiedState.totalOrderPriceWithoutTax,
                TaxPercentPerUnitOrder: modifiedState.totalTaxPrcntForSelectedDuration,
                TaxPerUnitOrder: modifiedState.totalTaxPriceForSelectedDuration,
                SecurityDepositPerUnitOrder: 'NA',
                OneTimeChargePerUnitOrder: modifiedState.oneTimeChargePerUnitINRForApiOrder,
                PackageDurationOnApiOrder: (Number(modifiedState.convertedPeriodInMonths)).toFixed(1),
                ApiHitsPerPackageDuration: modifiedState.apiHitOnSelectedDuration,
                TaxPercentageOnOneTimeChargePerUnitOrder: 'NA',
                TaxPriceOnOneTimeChargePerUnitOrder: 'NA',
                OrderItemsValue: modifiedState.finalTotalOrderPrice,
                OrderNumber: modifiedState.OrderNumber == null ? null : modifiedState.OrderNumber,
                OrderLineNumber: modifiedState.OrderLineNumber == null ? 0 : modifiedState.OrderLineNumber,
                PackageID: modifiedState.PackageID == null ? 0 : modifiedState.PackageID,
                Status:modifiedState.OrderStatus,
                SubscriptionPricePerUnitOrder: 'NA',
                SubscriptionPercentagePerUnitOrder: 'NA',
                SubscriptionTaxPerUnitOrder: 'NA',
                SubscriptionPeriodPerUnitOrder: 'NA',
                PricePerPkgDurINR: PricePerPkgDurINR,
                PriceOfHitsInPkgINR: PriceOfHitsInPkgINR,
                PackageDuration: modifiedState.daysBasedOnSelectedDurationForApiOrder,
                DisctPrcntPerPkg: modifiedState.discountPercntForTotalOrderPrice,
                DisctPricePerPkg: modifiedState.discountPriceForTotalOrderPrice
            };
            
            if(modifiedState.formViewMode == 'editMode' && state.formViewMode.length) {
                for(let j = 0; j <newProductData.length; j++){
                    if(singleProductData.OrderLineNumber != 0 && newProductData[j].OrderLineNumber == singleProductData.OrderLineNumber) {
                        newProductData.splice(j, 1);
                    }
                }
                if(singleProductData.OrderLineNumber == 0) {
                    newProductData.unshift(singleProductData); 

                } else {
                    newProductData.unshift(singleProductData); 
                }
            } else {
                newProductData.push(singleProductData);
            }
            
            modifiedState.productdata = newProductData;

            modifiedState.TotalOrderValue = 0;

            if(modifiedState.productdata != null && modifiedState.productdata.length > 0) {
                for(let i=0; i<modifiedState.productdata.length; i++) {
                    modifiedState.TotalOrderValue = (Math.abs(modifiedState.TotalOrderValue) + Math.abs(modifiedState.productdata[i]["OrderItemsValue"])).toFixed(2);
                }
            }
        }

        modifiedState.OrderItemsValue = '';
        modifiedState.selectCustomerDetails = false;
        modifiedState.selectedProductLine = '';
        modifiedState.selectedProductName = '';
        modifiedState.ProductCode = '';
        modifiedState.newEnteredQuantityOfUnit = '';
        modifiedState.PricePerUnitINR = '';
        modifiedState.securityDepositPrice = "";
        modifiedState.oneTimeChargePrice = "";
        modifiedState.taxPercentageForOneTimeChargePrice = "";
        modifiedState.taxPriceForOneTimeCharge = "";
        modifiedState.TaxPerUnitINR = '';
        modifiedState.errors.others = '';
        modifiedState.TaxPercentagePerUnit = '';
        modifiedState.OrderLineNumber = null;
        modifiedState.PackageID = null;
        modifiedState.SelectedCustNewOrderDetails = false;
        modifiedState.SubscriptionPricePerUnitINR = null;
        modifiedState.rentalPricePerUnitINR  = null;
        modifiedState.rentaltaxPercentagePerUnit = null;
        modifiedState.rentaltaxPricePerUnit = null;
        modifiedState.SubcriptionTaxPercentagePerUnitINR = null;
        modifiedState.SubcriptionTaxPerUnitINR = null;
        modifiedState.SubscriptionPeriodPerUnit = null;
        modifiedState.isOrderInProcessingState = false;
        modifiedState.selectedSubscriptionPeriod =  '1';

        modifiedState.PackageID = null;
        modifiedState.finalTotalOrderPrice = "";
        modifiedState.selectedProductNameForApiOrder = "";
        modifiedState.selectedProductCodeForApiOrder = '';
        modifiedState.oneTimeChargePerUnitINRForApiOrder = '';
        modifiedState.selectedDurationForApiOrder = '';
        modifiedState.convertedPkgDuration = [];
        modifiedState.discountPercntForTotalOrderPrice = 0;
        modifiedState.discountPriceForTotalOrderPrice = 0;
        modifiedState.apiHitOnSelectedDuration = '';
        modifiedState.totalOrderPriceWithoutTax = "";
        modifiedState.totalTaxPrcntForSelectedDuration = "";
        modifiedState.totalTaxPriceForSelectedDuration =  "";
        modifiedState.finalTotalOrderPrice = "";
        modifiedState.errors.errApiOrder = '';
        modifiedState.daysBasedOnSelectedDurationForApiOrder =  "";
        modifiedState.convertedPeriodInMonths = "";

        setState({...modifiedState});
    }

    // For saving all information related to orders in database.
    const onSaveButtonClick = (e) => {
        e.preventDefault();

        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let strOrganizationName = "";
        modifiedState.loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        // modifiedState.arrProductItemState = [];

        // Convert the input dates to UTC before sending to the Server
        let strOrderDateTime = (modifiedState.OrderDateTime != null) ? 
                            convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(modifiedState.OrderDateTime.valueOf()) ) : null;
        let strReuiredDateTime = (modifiedState.RequireOrderTime != null) ?
                            convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(modifiedState.RequireOrderTime.valueOf()) ) : null;

        if(modifiedState.selectedOrganisationName == null || modifiedState.selectedOrganisationName.length <=0 || modifiedState.selectedOrganisationName == 'NA') {
            modifiedState.selectedOrganisationName = null;
        } else if (modifiedState.selectedOrganisationName != null && modifiedState.selectedOrganisationName.length > 0 && isNaN(modifiedState.selectedOrganisationName)) {
            modifiedState.selectedOrganisationName = modifiedState.selectedOrganisationName;
            for(let i=0; i < modifiedState.AllOrganisationInformation.length; i++){

                if(modifiedState.AllOrganisationInformation[i]['OrgName'] == modifiedState.selectedOrganisationName) {
                    modifiedState.selectedOrganisationName = modifiedState.AllOrganisationInformation[i]['OrgID']
                }
            }

        } else {
            modifiedState.selectedOrganisationName = modifiedState.selectedOrganisationName;
            for(let j=0; j < modifiedState.AllOrganisationInformation.length; j++){
                if(modifiedState.AllOrganisationInformation[j]['OrgID'] == modifiedState.selectedOrganisationName) {
                    strOrganizationName = modifiedState.AllOrganisationInformation[j]['OrgName']
                    break;
                }
            }
        }

        // replace all the single quotes to double quotes and escape character with double escape character while storing into database.
        let ReplacedCharacOfComment = modifiedState.Comment.replace(/'/g, "''").replace(/\\/g, "\\\\");

        let JsonBody = {
            ProductData: modifiedState.productdata,
            loggedInUserID: modifiedState.loggedInUserID,
            CustID: modifiedState.CustID,
            OrderStatus: modifiedState.OrderStatus,
            Comment: ReplacedCharacOfComment,
            OrderDateTime: strOrderDateTime,
            OrderType: modifiedState.createEditOrder ? modifiedState.selectedOrderType : modifiedState.orderTypeApi,
            RequireOrderTime: strReuiredDateTime,
            OrganisationID: modifiedState.selectedOrganisationName,
            InvokedFromOrder: modifiedState.createEditOrder ? "createEditOrder" : "createEditApiOrder",
        }

        // For saving new Order Details Information.
        axios.post(`${getAPIHostURL()}/wclient/saveNewOrderDetails`, JsonBody)
        .then(response => {
            if(response.data.code == "SUCCESS") {

                alert(`Successfully saved new order details. Sending email of order details to Production and Sales team.`);

                modifiedState.bEditOrder = false;
                modifiedState.SelectedCustNewOrderDetails = false;
                modifiedState.page = 0;

                const jsonBody = {
                    CustID: modifiedState.CustID,
                    LastModifiedUtcDtTm: modifiedState.maxLastModifiedTimeOfOrderInfo, 
                    LastModifiedUtcDtTmForApiOrder: modifiedState.maxLastModifiedTimeOfApiOrderInfo,
                };

                // Show spinner to restrict other user actions to be perform.
                appRelevantDataContextValue.onChangeProcessingReq(true);

                axios.post(`${getAPIHostURL()}/wclient/getCustAllOrderInfo`, jsonBody)
                .then(response => {
                    
                    if(response.data.code == 'SUCCESS') { 
                        
                        const jsonBody = {
                            CustID: modifiedState.CustID,
                            LastModifiedUtcDtTm: modifiedState.maxLastModifiedTimeOfSelectedOrderDetails, 
                            LastModifiedUtcDtTmForApiOrder: modifiedState.maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder,
                        };
                
                        // To get selected Customer Orders information from database.
                        axios.post(`${getAPIHostURL()}/wclient/getCustomerOrderDetails`, jsonBody)
                        .then(response => {
                            if(response.data.code == "SUCCESS") {
                                if(modifiedState.createEditOrder) {
                                    // IF response gets inadequate data.
                                    if(response.data.retrievedOredrDetails == null || response.data.retrievedOredrDetails.length <= 0){
                                        // modifiedState.errors.others = "Order Detail Information is not present on Server.";
                                        
                                        // Remove spinner and allow user to perform action.
                                        appRelevantDataContextValue.onChangeProcessingReq(false);

                                        modifiedState.errors.others = "";
                                        
                                    } else {

                                        let noOfOrderDetails = response.data.retrievedOredrDetails.length;
                                        modifiedState.CustSelectedOrderDetails = [];

                                        let newAddedOrderDetlInPrevOrder 
                                                    = response.data.retrievedOredrDetails.filter(function(o1){
                                                        return !modifiedState.CustomerOrderDetails.some(function(o2){
                                                            return o1.OrderLineNumber == o2.OrderLineNumber;          // assumes unique id
                                                        });
                                                    })

                                        if(modifiedState.formViewMode == 'editMode') {
                                                                                
                                            for(let i = 0; i< noOfOrderDetails; i++) {

                                                for(let j = 0; j < modifiedState.CustomerOrderDetails.length; j++) {

                                                    if(modifiedState.CustomerOrderDetails[j].OrderLineNumber == response.data.retrievedOredrDetails[i].OrderLineNumber){
                                                        modifiedState.CustomerOrderDetails.splice(j, 1);
                                                        modifiedState.CustomerOrderDetails.unshift(response.data.retrievedOredrDetails[i]); 
                                                        // checkWhereOderLineNoExists = true;
                                                    } 
                                                }
                                            } 

                                            for(let i=0; i<newAddedOrderDetlInPrevOrder.length; i++) {
                                                modifiedState.CustomerOrderDetails.unshift(newAddedOrderDetlInPrevOrder[i]);
                                            }

                                            for(let i=0; i<modifiedState.CustomerOrderDetails.length; i++) {

                                                if(modifiedState.CustomerOrderDetails[i].OrderNumber == modifiedState.CustAllOrderData[0]["OrderNumber"]) {
                                                    //modifiedState.selectedCustOrderType = modifiedState.CustAllOrderData[0]["OrderType"]
                                                    const selectedOrderDetails = modifiedState.CustomerOrderDetails[i];
                                                    let totalSubscriptionValue = 0;
                                                    let SubscriptionPeriodInMonth = 0;        
                                                    
                                                    modifiedState.OrderType = modifiedState.SelectedCustomerOrder.OrderType; 

                                                    // the subscription price and tax which is stored in the database is multiplied by 30, in order to show proce as per 1 month
                                                    let subscriptionPricePerUnitINRtoShow = selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null ? 'NA' : (selectedOrderDetails.SubscriptionPricePerUnitOrderINR * 30).toFixed(2);
                                                    let SubscriptionTaxPerUnitOrdertoShow = selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : ((selectedOrderDetails.SubscriptionTaxPrcntPUO / 100 ) * subscriptionPricePerUnitINRtoShow).toFixed(2);

                                                    if(selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null &&  selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionPeriod == null) {
                                                        totalSubscriptionValue = 0;
                                                    } else {
                                                        SubscriptionPeriodInMonth = selectedOrderDetails.SubscriptionPeriod / DAYS_IN_MONTH 
                                                        totalSubscriptionValue = (Number(SubscriptionPeriodInMonth) * (Number(subscriptionPricePerUnitINRtoShow) + Number(SubscriptionTaxPerUnitOrdertoShow)));
                                                    }

                                                    let selectedOrderItemsValue = (modifiedState.OrderType != null && modifiedState.OrderType.length > 0 && modifiedState.OrderType != ORDER_TYPE_RENTAL) ?
                                                        (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.PricePerUnitOrderINR) + Math.abs(selectedOrderDetails.TaxPerUnitOrderINR) + Number(totalSubscriptionValue))).toFixed(2) : 
                                                        (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.OneTimeChrgOnRentPUOI) + Math.abs(selectedOrderDetails.SecurityDepositOnRentPUOI) + Math.abs(selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI) + Number(totalSubscriptionValue))).toFixed(2);
                                                    
                                                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                                                    let singleOrderDetailinfo = {
                                                        viewFullFiledDetails: selectedOrderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                                        OrderLineNumber: selectedOrderDetails.OrderLineNumber,
                                                        OrderNumber:selectedOrderDetails.OrderNumber,
                                                        ProductLine: selectedOrderDetails.ProductLine,
                                                        ProductName: selectedOrderDetails.ProductName,
                                                        ProductCode: selectedOrderDetails.ProductCode,
                                                        QuantityOfUnitOrdered: selectedOrderDetails.QuantityOfUnitsOrdered,
                                                        QuantityOfUnitScanned: selectedOrderDetails.QuantityOfUnitsScanned,
                                                        PricePerUnitOrder: selectedOrderDetails.PricePerUnitOrderINR,
                                                        TaxPercentPerUnitOrder: selectedOrderDetails.TaxPrcntPUO,
                                                        TaxPerUnitOrder: selectedOrderDetails.TaxPerUnitOrderINR,
                                                        SecurityDepositPerUnitOrder: selectedOrderDetails.SecurityDepositOnRentPUOI,
                                                        OneTimeChargePerUnitOrder: selectedOrderDetails.OneTimeChrgOnRentPUOI,
                                                        TaxPercentageOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPrcntOnOneTimeChrgPUO,
                                                        TaxPriceOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI,
                                                        OrderItemsValue: selectedOrderItemsValue,
                                                        Status: selectedOrderDetails.Status,
                                                        SubscriptionPricePerUnitOrder: subscriptionPricePerUnitINRtoShow,
                                                        SubscriptionTaxPerUnitOrder: SubscriptionTaxPerUnitOrdertoShow,
                                                        SubscriptionPercentagePerUnitOrder: selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : selectedOrderDetails.SubscriptionTaxPrcntPUO,
                                                        SubscriptionPeriodPerUnitOrder: selectedOrderDetails.SubscriptionPeriod == null ? 'NA' : SubscriptionPeriodInMonth,
                                                        LastModifiedUtcDtTm: selectedOrderDetails.LastModifiedUtcDtTm
                                                    };
                                                    modifiedState.CustSelectedOrderDetails.push(singleOrderDetailinfo);
                                                    // modifiedState.CustSelectedOrderDetails.unshift(modifiedState.CustomerOrderDetails[i]); 
                                                    // checkWhereOderLineNoExists = true;
                                                } 
                                            }

                                        } else {
                                            for(let i=0; i<noOfOrderDetails; i++ ) {
                                                modifiedState.CustomerOrderDetails.unshift(response.data.retrievedOredrDetails[i])
                                            }

                                            for(let i=0; i<modifiedState.CustomerOrderDetails.length; i++) {

                                                if(modifiedState.CustomerOrderDetails[i].OrderNumber == modifiedState.CustAllOrderData[0]["OrderNumber"]) {
                                                    const selectedOrderDetails = modifiedState.CustomerOrderDetails[i];
                                                    let totalSubscriptionValue = 0;
                                                    let SubscriptionPeriodInMonth = 0;

                                                    modifiedState.OrderType = modifiedState.SelectedCustomerOrder.OrderType; 

                                                    // the subscription price and tax which is stored in the database is multiplied by 30, in order to show proce as per 1 month
                                                    let subscriptionPricePerUnitINRtoShow = selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null ? 'NA' : (selectedOrderDetails.SubscriptionPricePerUnitOrderINR * 30).toFixed(2);
                                                    let SubscriptionTaxPerUnitOrdertoShow = selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : ((selectedOrderDetails.SubscriptionTaxPrcntPUO / 100 ) * subscriptionPricePerUnitINRtoShow).toFixed(2);

                                                    if(selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null &&  selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionPeriod == null) {
                                                        totalSubscriptionValue = 0;
                                                    } else {
                                                        SubscriptionPeriodInMonth = selectedOrderDetails.SubscriptionPeriod / DAYS_IN_MONTH 
                                                        totalSubscriptionValue = (Number(SubscriptionPeriodInMonth) * (Number(subscriptionPricePerUnitINRtoShow) + Number(SubscriptionTaxPerUnitOrdertoShow)));
                                                    }

                                                    let selectedOrderItemsValue = (modifiedState.OrderType != null && modifiedState.OrderType.length > 0 && modifiedState.OrderType != ORDER_TYPE_RENTAL) ?
                                                        (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.PricePerUnitOrderINR) + Math.abs(selectedOrderDetails.TaxPerUnitOrderINR) + Number(totalSubscriptionValue))).toFixed(2) : 
                                                        (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.OneTimeChrgOnRentPUOI) + Math.abs(selectedOrderDetails.SecurityDepositOnRentPUOI) + Math.abs(selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI) + Number(totalSubscriptionValue))).toFixed(2);
                                                    
                                                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                                                    let singleOrderDetailinfo = {
                                                        viewFullFiledDetails: selectedOrderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                                        OrderLineNumber: selectedOrderDetails.OrderLineNumber,
                                                        OrderNumber:selectedOrderDetails.OrderNumber,
                                                        ProductLine: selectedOrderDetails.ProductLine,
                                                        ProductName: selectedOrderDetails.ProductName,
                                                        ProductCode: selectedOrderDetails.ProductCode,
                                                        QuantityOfUnitOrdered: selectedOrderDetails.QuantityOfUnitsOrdered,
                                                        QuantityOfUnitScanned: selectedOrderDetails.QuantityOfUnitsScanned,
                                                        PricePerUnitOrder: selectedOrderDetails.PricePerUnitOrderINR,
                                                        TaxPercentPerUnitOrder: selectedOrderDetails.TaxPrcntPUO,
                                                        TaxPerUnitOrder: selectedOrderDetails.TaxPerUnitOrderINR,
                                                        SecurityDepositPerUnitOrder: selectedOrderDetails.SecurityDepositOnRentPUOI,
                                                        OneTimeChargePerUnitOrder: selectedOrderDetails.OneTimeChrgOnRentPUOI,
                                                        TaxPercentageOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPrcntOnOneTimeChrgPUO,
                                                        TaxPriceOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI,
                                                        OrderItemsValue: selectedOrderItemsValue,
                                                        Status: selectedOrderDetails.Status,
                                                        SubscriptionPricePerUnitOrder: subscriptionPricePerUnitINRtoShow,
                                                        SubscriptionTaxPerUnitOrder: SubscriptionTaxPerUnitOrdertoShow,
                                                        SubscriptionPercentagePerUnitOrder: selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : selectedOrderDetails.SubscriptionTaxPrcntPUO,
                                                        SubscriptionPeriodPerUnitOrder: selectedOrderDetails.SubscriptionPeriod == null ? 'NA' : SubscriptionPeriodInMonth,
                                                        LastModifiedUtcDtTm: selectedOrderDetails.LastModifiedUtcDtTm
                                                    };
                                                    modifiedState.CustSelectedOrderDetails.push(singleOrderDetailinfo);
                                                    // modifiedState.CustSelectedOrderDetails.unshift(modifiedState.CustomerOrderDetails[i]); 
                                                    // checkWhereOderLineNoExists = true;
                                                } 
                                            }

                                        }
                                        sendOrderDetailsToProductionTeam(strOrderDateTime, strReuiredDateTime, null, modifiedState.CustSelectedOrderDetails, strOrganizationName, modifiedState.OrderType);
                                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetails = modifiedState.CustomerOrderDetails[0].LastModifiedUtcDtTm
                                    }
                                } else {

                                    if(response.data.retrievedOredrDetailsForApiOrder == null || response.data.retrievedOredrDetailsForApiOrder.length <= 0){
                                        
                                        // Remove spinner and allow user to perform action.
                                        appRelevantDataContextValue.onChangeProcessingReq(false);

                                        modifiedState.errors.others = "";
                                        modifiedState.errors.errApiOrder = "";
                                        
                                    } else {

                                        let noOfOrderDetails = response.data.retrievedOredrDetailsForApiOrder.length;
                                        modifiedState.CustSelectedOrderDetailsForApiOrder = [];

                                        let newAddedOrderDetlInPrevOrder 
                                                    = response.data.retrievedOredrDetailsForApiOrder.filter(function(o1){
                                                        return !modifiedState.CustomerOrderDetailsForApiOrder.some(function(o2){
                                                            return o1.OrderLineNumber == o2.OrderLineNumber;          // assumes unique id
                                                        });
                                                    })


                                        if(modifiedState.formViewMode == 'editMode') {
                                  
                                            for(let i = 0; i< noOfOrderDetails; i++) {

                                                for(let j = 0; j < modifiedState.CustomerOrderDetailsForApiOrder.length; j++) {

                                                    if(modifiedState.CustomerOrderDetailsForApiOrder[j].OrderLineNumber == response.data.retrievedOredrDetailsForApiOrder[i].OrderLineNumber){
                                                        modifiedState.CustomerOrderDetailsForApiOrder.splice(j, 1);
                                                        modifiedState.CustomerOrderDetailsForApiOrder.unshift(response.data.retrievedOredrDetailsForApiOrder[i]); 
                                                    } 
                                                }
                                            } 

                                            for(let i=0; i<newAddedOrderDetlInPrevOrder.length; i++) {
                                                modifiedState.CustomerOrderDetailsForApiOrder.unshift(newAddedOrderDetlInPrevOrder[i]);
                                            }

                                            for(let i=0; i<modifiedState.CustomerOrderDetailsForApiOrder.length; i++) {

                                                if(modifiedState.CustomerOrderDetailsForApiOrder[i].OrderNumber == modifiedState.CustAllApiOrderData[0]["OrderNumber"]) {

                                                    const selectedOrderDetailsForApiOrder = modifiedState.CustomerOrderDetailsForApiOrder[i];

                                                    let PackageDurationOnApiOrderInMonths  = (Number(selectedOrderDetailsForApiOrder.PackageDuration)) / 30;
                                                    let DiscountedPrice = Number(selectedOrderDetailsForApiOrder.PricePerUnitOrderINR) - Number(selectedOrderDetailsForApiOrder.DisctPricePerPkg);
                                                    let TaxOnDiscountedPrice = ((Number(DiscountedPrice)) * ((Number(selectedOrderDetailsForApiOrder.TaxPrcntPUO)) / 100)).toFixed(2);
                                                    let selectedOrderItemsValueForApiOrder = (Number(DiscountedPrice) + Number(TaxOnDiscountedPrice));       
                                                    
                                                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                                                    let singleOrderDetailinfo = {
                                                        //viewFullFiledDetails: selectedOrderDetailsForApiOrder.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetailsForApiOrder.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                                        PackageID: selectedOrderDetailsForApiOrder.PackageID,
                                                        OrderLineNumber: selectedOrderDetailsForApiOrder.OrderLineNumber,
                                                        OrderNumber:selectedOrderDetailsForApiOrder.OrderNumber,
                                                        ProductLine: selectedOrderDetailsForApiOrder.ProductLine,
                                                        ProductName: selectedOrderDetailsForApiOrder.ProductName,
                                                        ProductCode: selectedOrderDetailsForApiOrder.ProductCode,
                                                        QuantityOfUnitOrdered: selectedOrderDetailsForApiOrder.QuantityOfUnitsOrdered,
                                                        QuantityOfUnitScanned: selectedOrderDetailsForApiOrder.QuantityOfUnitsScanned,
                                                        PackageDurationOnApiOrder: PackageDurationOnApiOrderInMonths,
                                                        ApiHitsPerPackageDuration: selectedOrderDetailsForApiOrder.ApiHitsInPkg,
                                                        DisctPrcntPerPkg: selectedOrderDetailsForApiOrder.DisctPrcntPerPkg,
                                                        DisctPricePerPkg: selectedOrderDetailsForApiOrder.DisctPricePerPkg,
                                                        PricePerUnitOrder: selectedOrderDetailsForApiOrder.PricePerUnitOrderINR,
                                                        TaxPercentPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPrcntPUO,
                                                        TaxPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPerUnitOrderINR,
                                                        OneTimeChargePerUnitOrder: selectedOrderDetailsForApiOrder.OneTimeChrgOnRentPUOI,
                                                        OrderItemsValue: selectedOrderItemsValueForApiOrder.toFixed(2),
                                                        Status: selectedOrderDetailsForApiOrder.Status,
                                                        LastModifiedUtcDtTm: selectedOrderDetailsForApiOrder.LastModifiedUtcDtTm,
                                                        PriceOfHitsInPkgINR: selectedOrderDetailsForApiOrder.PriceOfHitsInPkgINR,
                                                        PricePerPkgDurINR: selectedOrderDetailsForApiOrder.PricePerPkgDurINR,
                                                        PackageDuration: selectedOrderDetailsForApiOrder.PackageDuration
                                                    };
                                                    modifiedState.CustSelectedOrderDetailsForApiOrder.push(singleOrderDetailinfo);   
                                                } 
                                            }

                                        } else {

                                            for(let i=0; i<noOfOrderDetails; i++ ) {
                                                modifiedState.CustomerOrderDetailsForApiOrder.unshift(response.data.retrievedOredrDetailsForApiOrder[i])
                                            }

                                            for(let i=0; i<modifiedState.CustomerOrderDetailsForApiOrder.length; i++) {
                                                

                                                if(modifiedState.CustomerOrderDetailsForApiOrder[i].OrderNumber == modifiedState.CustAllApiOrderData[0]["OrderNumber"]) {

                                                    const selectedOrderDetailsForApiOrder = modifiedState.CustomerOrderDetailsForApiOrder[i];

                                                    let PackageDurationOnApiOrderInMonths  = (Number(selectedOrderDetailsForApiOrder.PackageDuration)) / 30;
                                                    let DiscountedPrice = Number(selectedOrderDetailsForApiOrder.PricePerUnitOrderINR) - Number(selectedOrderDetailsForApiOrder.DisctPricePerPkg);
                                                    let TaxOnDiscountedPrice = ((Number(DiscountedPrice)) * ((Number(selectedOrderDetailsForApiOrder.TaxPrcntPUO)) / 100)).toFixed(2);
                                                    let selectedOrderItemsValueForApiOrder = (Number(DiscountedPrice) + Number(TaxOnDiscountedPrice));                                                        
                                                    
                                                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;

                                                    let singleOrderDetailinfo = {
                                                        //viewFullFiledDetails: selectedOrderDetailsForApiOrder.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetailsForApiOrder.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                                        PackageID: selectedOrderDetailsForApiOrder.PackageID,
                                                        OrderLineNumber: selectedOrderDetailsForApiOrder.OrderLineNumber,
                                                        OrderNumber:selectedOrderDetailsForApiOrder.OrderNumber,
                                                        ProductLine: selectedOrderDetailsForApiOrder.ProductLine,
                                                        ProductName: selectedOrderDetailsForApiOrder.ProductName,
                                                        ProductCode: selectedOrderDetailsForApiOrder.ProductCode,
                                                        QuantityOfUnitOrdered: selectedOrderDetailsForApiOrder.QuantityOfUnitsOrdered,
                                                        QuantityOfUnitScanned: selectedOrderDetailsForApiOrder.QuantityOfUnitsScanned,
                                                        PackageDurationOnApiOrder: PackageDurationOnApiOrderInMonths,
                                                        ApiHitsPerPackageDuration: selectedOrderDetailsForApiOrder.ApiHitsInPkg,
                                                        DisctPrcntPerPkg: selectedOrderDetailsForApiOrder.DisctPrcntPerPkg,
                                                        DisctPricePerPkg: selectedOrderDetailsForApiOrder.DisctPricePerPkg,
                                                        PricePerUnitOrder: selectedOrderDetailsForApiOrder.PricePerUnitOrderINR,
                                                        TaxPercentPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPrcntPUO,
                                                        TaxPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPerUnitOrderINR,
                                                        OneTimeChargePerUnitOrder: selectedOrderDetailsForApiOrder.OneTimeChrgOnRentPUOI,
                                                        OrderItemsValue: selectedOrderItemsValueForApiOrder.toFixed(2),
                                                        Status: selectedOrderDetailsForApiOrder.Status,
                                                        LastModifiedUtcDtTm: selectedOrderDetailsForApiOrder.LastModifiedUtcDtTm,
                                                        PriceOfHitsInPkgINR: selectedOrderDetailsForApiOrder.PriceOfHitsInPkgINR,
                                                        PricePerPkgDurINR: selectedOrderDetailsForApiOrder.PricePerPkgDurINR,
                                                        PackageDuration: selectedOrderDetailsForApiOrder.PackageDuration
                                                    };
                                                    modifiedState.CustSelectedOrderDetailsForApiOrder.push(singleOrderDetailinfo); 
                                                } 
                                            }
                                        }   
                                        sendOrderDetailsToProductionTeam(strOrderDateTime, strReuiredDateTime, null, modifiedState.CustSelectedOrderDetailsForApiOrder, strOrganizationName, modifiedState.orderTypeApi);
                                        modifiedState.maxLastModifiedTimeOfSelectedOrderDetailsForApiOrder = modifiedState.CustomerOrderDetailsForApiOrder[0].LastModifiedUtcDtTm
                                    }
                                }
                            } else {
                                // Remove spinner and allow user to perform action.
                                appRelevantDataContextValue.onChangeProcessingReq(false);

                                if(response.data.code == 'SQL_ERROR') {
                                    // Tell the user that Server is experiencing errors
                                    if(modifiedState.createEditOrder){
                                        modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                                    } else {
                                        modifiedState.errors.errApiOrder = "Server experiencing issues.\nTry again later.";
                                    }
                                } else {
                                    console.log("Should not reach here.")
                                    if(modifiedState.createEditOrder){
                                        modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                                    } else {
                                        modifiedState.errors.errApiOrder = "Server experiencing issues.\nTry again later.";
                                    }
                                }
                            }
                            setState({...modifiedState});
                        })
                        .catch(error => {
                            // Remove spinner and allow user to perform action.
                            appRelevantDataContextValue.onChangeProcessingReq(false);

                            console.log(error);
                            if (axios.isCancel(error)) {
                                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                            } else {
                                if(modifiedState.createEditOrder){
                                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                                } else {
                                    modifiedState.errors.errApiOrder = 'Network issues.\nCheck your Internet and Try again later.';
                                }
                                setState({...modifiedState});
                            }  
                        });
                        
                        if(!modifiedState.createEditOrder){
                            if(response.data.retrievedOredrInfoForApiOrders == null || response.data.retrievedOredrInfoForApiOrders.length <= 0) {

                                modifiedState.errors.errApiOrder = "No Api Order Data Found.";
                            } else {
            
                                modifiedState.errors.others = '';
                                modifiedState.errors.errApiOrder = '';

                                const receiveOrderData = response.data.retrievedOredrInfoForApiOrders;
                                modifiedState.maxLastModifiedTimeOfApiOrderInfo = receiveOrderData[0].LastModifiedUtcDtTm;
                    
                                let noOfLogs = receiveOrderData.length;
                                let stateCustAllOrderData = [];
            
                                for(let i = 0; i < noOfLogs; i++) {
                
                                    const orderDetails = receiveOrderData[i];
                                    stateCustAllOrderData = [...modifiedState.CustAllApiOrderData]
            
                                    let selectOrderBtn = <button type = "button" className = "createOrderTableButton">
                                                            Select Order
                                                        </button> 

                                    let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                                    let Download = <FaFilePdf className="DonloadPdfIcon" title="PdfDownload"/>

                                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;

                                    let singleOrderInfo = {
                                        action: selectOrderBtn,
                                        edit: editIcon,
                                        Download: Download,
                                        //viewFullFiledDetails: orderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || orderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                        OrderNumber: orderDetails.OrderNumber,
                                        OrderType: orderDetails.OrderType,
                                        OrderDate: (orderDetails.OrderDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.OrderDate),
                                        RequiredDate: (orderDetails.RequiredDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.RequiredDate),
                                        //FulfilledDate: (orderDetails.FulfilledDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.FulfilledDate),
                                        Status: orderDetails.Status,
                                        SalesRepUserID: orderDetails.UserIdOfSalesRep,
                                        ShippedDate: orderDetails.ShippedDate,
                                        Comments: orderDetails.Comments,
                                        OrderOfOrganisation: orderDetails.OrganisationName,
                                    };

                                    if(modifiedState.formViewMode == 'editMode') {

                                        for(let j=0; j< stateCustAllOrderData.length; j++) {
                                            if(stateCustAllOrderData[j].OrderNumber == singleOrderInfo.OrderNumber) {
                                                stateCustAllOrderData.splice(j, 1);
                                            }
                                        }
                                        stateCustAllOrderData.unshift(singleOrderInfo);
                                    } else {
                                        stateCustAllOrderData.unshift(singleOrderInfo);
                                    }
                                    modifiedState.CustAllApiOrderData = stateCustAllOrderData;
                                }
                            }
                            modifiedState.SelectedCustomerOrder = modifiedState.CustAllApiOrderData[0];

                            modifiedState.selectedRowIndex = 0;
                        } else {
                            if(response.data.retrievedOredrInfoForNonApiOrders == null || response.data.retrievedOredrInfoForNonApiOrders.length <= 0) {

                                modifiedState.errors.others = "No Order Data Found.";
                            } else {
            
                                modifiedState.errors.others = '';
                                const receiveOrderData = response.data.retrievedOredrInfoForNonApiOrders;
                                modifiedState.maxLastModifiedTimeOfOrderInfo = receiveOrderData[0].LastModifiedUtcDtTm;
                    
                                let noOfLogs = receiveOrderData.length;
                                let stateCustAllOrderData = [];
            
                                for(let i = 0; i < noOfLogs; i++) {
                
                                    const orderDetails = receiveOrderData[i];
                                    stateCustAllOrderData = [...modifiedState.CustAllOrderData]
            
                                    let selectOrderBtn = <button type = "button" className = "createOrderTableButton">
                                                            Select Order
                                                        </button> 

                                    let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                                    let Download = <FaFilePdf className="DonloadPdfIcon" title="PdfDownload"/>

                                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;

                                    let singleOrderInfo = {
                                        action: selectOrderBtn,
                                        edit: editIcon,
                                        Download: Download,
                                        viewFullFiledDetails: orderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || orderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                                        OrderNumber: orderDetails.OrderNumber,
                                        OrderType: orderDetails.OrderType,
                                        OrderDate: (orderDetails.OrderDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.OrderDate),
                                        RequiredDate: (orderDetails.RequiredDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.RequiredDate),
                                        FulfilledDate: (orderDetails.FulfilledDate == null) ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(orderDetails.FulfilledDate),
                                        Status: orderDetails.Status,
                                        SalesRepUserID: orderDetails.UserIdOfSalesRep,
                                        ShippedDate: orderDetails.ShippedDate,
                                        Comments: orderDetails.Comments,
                                        OrderOfOrganisation: orderDetails.OrganisationName,
                                    };

                                    if(modifiedState.formViewMode == 'editMode') {

                                        for(let j=0; j< stateCustAllOrderData.length; j++) {
                                            if(stateCustAllOrderData[j].OrderNumber == singleOrderInfo.OrderNumber) {
                                                stateCustAllOrderData.splice(j, 1);
                                            }
                                        }
                                        stateCustAllOrderData.unshift(singleOrderInfo);
                                    } else {
                                        stateCustAllOrderData.unshift(singleOrderInfo);
                                    }
                                    modifiedState.CustAllOrderData = stateCustAllOrderData;
                                }
                            }
                            modifiedState.SelectedCustomerOrder = modifiedState.CustAllOrderData[0];

                            modifiedState.selectedRowIndex = 0;
                        }
                    } else {

                        // Remove spinner and allow user to perform action.
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                        if(response.data.code == 'SQL_ERROR') {
                            // Tell the user that Server is experiencing errors
                            if(modifiedState.createEditOrder){
                                modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                            } else {
                                modifiedState.errors.errApiOrder = "Server experiencing issues.\nTry again later.";
                            }
                        } else {
                            console.log('Should not reach here');
                            if(modifiedState.createEditOrder){
                                modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                            } else {
                                modifiedState.errors.errApiOrder = "Server experiencing issues.\nTry again later.";
                            }
                        }
                    }
                    setState({...modifiedState});
                })
                .catch(error => {
                    // Remove spinner and allow user to perform action.
                    appRelevantDataContextValue.onChangeProcessingReq(false);
                    
                    console.log(error);
                    console.log("Network error:");
                    if (axios.isCancel(error)) {
                        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                    } else {
                       if(modifiedState.createEditOrder){
                            modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                        } else {
                            modifiedState.errors.errApiOrder = 'Network issues.\nCheck your Internet and Try again later.';
                        }
                        setState({...modifiedState});
                    }
                })
        
                // Clear all fields in the form when user successfully ordered Units.
                modifiedState.errors.others = "";
                modifiedState.errors.errApiOrder = "";
                modifiedState.selectedProductLine = "";
                modifiedState.selectedProductNameForApiOrder = "";
                modifiedState.newEnteredQuantityOfUnit = "";
                // modifiedState.Comment = '';
                modifiedState.RequireOrderTime = null;
                modifiedState.OrderDateTime = new Date();
                modifiedState.selectedOrderType = "";
                modifiedState.selectedProductName = '';
                modifiedState.selectedProductNameForApiOrder = '';
                modifiedState.PricePerUnitINR = '';
                modifiedState.TaxPerUnitINR = '';
                modifiedState.newEnteredQuantityOfUnit = '';
                modifiedState.loggedInUserID = '';
                modifiedState.TotalOrderValue = '';
                modifiedState.OrderType = '';
                modifiedState.productdata = [];
                modifiedState.selectedOrderType = "";
                modifiedState.selectedSubscriptionPeriod =  '1';
                modifiedState.TaxPercentagePerUnit = '';
                modifiedState.securityDepositPrice = '';
                modifiedState.oneTimeChargePrice = '';
                modifiedState.taxPercentageForOneTimeChargePrice = '';
                modifiedState.taxPriceForOneTimeCharge = '';
                modifiedState.oneTimeChargePerUnitINRForApiOrder = '';
                modifiedState.selectedDurationForApiOrder = '';
                modifiedState.apiHitOnSelectedDuration = '';
                modifiedState.totalOrderPriceWithoutTax = "";
                modifiedState.totalTaxPrcntForSelectedDuration = '';
                modifiedState.totalTaxPriceForSelectedDuration = '';
                modifiedState.finalTotalOrderPrice = '';
                modifiedState.convertedPkgDuration = [];
                modifiedState.discountPercntForTotalOrderPrice = 0;
                modifiedState.discountPriceForTotalOrderPrice = 0;
                modifiedState.daysBasedOnSelectedDurationForApiOrder =  "";
                modifiedState.convertedPeriodInMonths = "";

            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    if(modifiedState.createEditOrder){
                        modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                    } else{
                        modifiedState.errors.errApiOrder = "Server experiencing issues.\nTry again later.";
                    }
                } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                    if(modifiedState.createEditOrder){
                        modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                    } else{
                        modifiedState.errors.errApiOrder = "Server experiencing issues (required parameters not sent). Try again later.";
                    }
                } else if(response.data.code == 'ERR_ORDER_ALREADY_FULFILLED_CANNOT_CHANGE') {
                    if(modifiedState.createEditOrder){
                        modifiedState.errors.others = "You can not modify this order as it is already fullfilled.";
                    } else{
                        modifiedState.errors.errApiOrder = "You can not modify this order as it is already fullfilled.";
                    }
                } else if(response.data.code == 'ERR_SOME_ORDER_DTL_TO_UPDATE_ALREADY_FULFILLED_CANNOT_CHANGE') {
                    if(modifiedState.createEditOrder){
                        modifiedState.errors.others = "You can not modify this order as some order details are already fulfilled.";
                    } else{
                        modifiedState.errors.errApiOrder = "You can not modify this order as some order details are already fulfilled.";
                    }
                } else if(response.data.code == 'ERR_SOME_ORDER_DTL_QTY_UNIT_ORDERED_NOT_GREATER_THAN_UNITS_SCANNED') {
                    if(modifiedState.createEditOrder){
                        modifiedState.errors.others = "You can not modify this order as quantity of units ordered can not be less than quantity of units scanned.";
                    } else{
                        modifiedState.errors.errApiOrder = "You can not modify this order as quantity of units ordered can not be less than quantity of units scanned.";
                    }
                } else {
                    console.log("Should not reach here.")
                    if(modifiedState.createEditOrder){
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else{
                        modifiedState.errors.errApiOrder = 'Server experiencing issues.\nTry again later.';
                    }
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                if(modifiedState.createEditOrder){
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                } else{
                    modifiedState.errors.errApiOrder = 'Network issues.\nCheck your Internet and Try again later.';
                }
                setState({...modifiedState});
            }   
        });
    }

    const onCancelButtonClick = (e) => {
        let modifiedState =state

        modifiedState.selectedProductLine = '';
        modifiedState.selectedProductName = '';
        modifiedState.ProductCode = '';
        modifiedState.newEnteredQuantityOfUnit = '';
        modifiedState.PricePerUnitINR = '';
        modifiedState.TaxPerUnitINR = '';
        modifiedState.securityDepositPrice = "";
        modifiedState.oneTimeChargePrice = "";
        modifiedState.taxPercentageForOneTimeChargePrice = "";
        modifiedState.taxPriceForOneTimeCharge = "";
        modifiedState.TaxPercentagePerUnit = '';
        modifiedState.rentalPricePerUnitINR  = null;
        modifiedState.rentaltaxPercentagePerUnit = null;
        modifiedState.rentaltaxPricePerUnit = null;
        modifiedState.SubcriptionTaxPercentagePerUnitINR = null;
        modifiedState.SubcriptionTaxPerUnitINR = null;
        modifiedState.SubscriptionPeriodPerUnit = null;
        modifiedState.selectedSubscriptionPeriod =  '1';
        modifiedState.errors.others = '';
        modifiedState.OrderLineNumber = null;
        modifiedState.selectCustomerDetails = false;
        modifiedState.SelectedCustNewOrderDetails = false;

        modifiedState.PackageID = null;
        modifiedState.selectedProductNameForApiOrder = "";
        modifiedState.selectedProductCodeForApiOrder = "";
        modifiedState.oneTimeChargePerUnitINRForApiOrder = "";
        modifiedState.selectedDurationForApiOrder = "";
        modifiedState.apiHitOnSelectedDuration = "";
        modifiedState.totalOrderPriceWithoutTax = "";
        modifiedState.totalTaxPrcntForSelectedDuration = "";
        modifiedState.totalTaxPriceForSelectedDuration = "";
        modifiedState.finalTotalOrderPrice = "";
        modifiedState.convertedPkgDuration = [];
        modifiedState.discountPercntForTotalOrderPrice = 0;
        modifiedState.discountPriceForTotalOrderPrice = 0;
        modifiedState.errors.errApiOrder = '';
        modifiedState.daysBasedOnSelectedDurationForApiOrder =  "";
        modifiedState.convertedPeriodInMonths = "";

        setState({...modifiedState});
    }

    const onChangeOrderTime = updatedTime => {
        let updatedActiveOrderTime = updatedTime;
        let modifiedState = state;

        // Dont Allow the user to enter null time. If he enters null 
        // time (For ex. Using X Button), then set the Date back to the original value.
        modifiedState.OrderDateTime = 
            (updatedActiveOrderTime == null) ? modifiedState.OrderDateTime : updatedActiveOrderTime ;
        setState({...modifiedState});

    }

    const onRequireOrderDtTmCalendarOrClockClose = () => {

        let modifiedState = state;

        let updatedEndViewTime = modifiedState.RequireOrderTime;

        // Give a tolerance of -5 minutes to the current date time to prevent unnecessary alert message
        let currentDateTime = new Date();
        currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

        if(updatedEndViewTime < currentDateTime) {

            let strMsg = "Require Date Time cannot be less than the current date time and it can not be Current Date Time.\nIt can be set 30min ahead of current date time.";
            alert(strMsg);

            // Calculate Require Time based on the currentDateTime
            let RequireOrderTime = new Date(); // Eliminate the 30 minute tolerance added earlier so that we get time more than current Date Time.
            RequireOrderTime.setMinutes(RequireOrderTime.getMinutes() + 30);

            modifiedState.RequireOrderTime = RequireOrderTime;
        }
               
        setState({...modifiedState});

    }

    const onChangeRequireOrderTime = updatedTime => {
        let updatedRequireOrder = updatedTime;
        let modifiedState = state;

        modifiedState.RequireOrderTime = updatedRequireOrder;
        setState({...modifiedState});

    }
// =========================================================================================================

    const onFullfillOrderNew = () => {

        if(state.CustSelectedOrderDetails == null || state.CustSelectedOrderDetails.length <= 0) {
            alert("Please select a Customer Order first for Fulfillment.")
            return;
        } else {

            let modifiedState = state;
            modifiedState.itemIDQRCodeFlag = false;
            modifiedState.ItemID = "";
            modifiedState.enableFullfillOrder = !modifiedState.enableFullfillOrder;
            modifiedState.errors.errRelatedToAssigningState = "";
            setState({...modifiedState});
        }
    }

    const onSaveFullFillOrder = () => {
        let modifiedState = state;
       if(modifiedState.isAllOrderFullfill == true) {
            let strMsg = `All product items required for fulfilling the current Order have not been scanned. Do you want to partially fulfill the order ?`;
            if(window.confirm(strMsg)) {
                FulFillCustomerOrder(modifiedState);
            }
        } else {
            FulFillCustomerOrder(modifiedState);
        }
        
    }

    const FulFillCustomerOrder = (inModifiedState = null) => {

        let modifiedState = state;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }
        if(modifiedState.arrProductItemState.length <=0) {
            modifiedState.errors.others = "All orders are already fullfilled.";
            setState({...modifiedState});
            return;
        }

        modifiedState.OrderType = modifiedState.SelectedCustomerOrder.OrderType;  
        let OrderTypeToStore = modifiedState.OrderType;

        let appRelevantDataContextValue = context;
        modifiedState.loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let JsonBody = {
            loggedInUserID: modifiedState.loggedInUserID,
            arrProductItemState : modifiedState.arrProductItemState,
            ProductData: modifiedState.CustSelectedOrderDetails,
            selectedOrganisationID: modifiedState.selectedOrganisationName != null && modifiedState.selectedOrganisationName == 'NA' ? null : modifiedState.selectedOrganisationName,
            OrderType: OrderTypeToStore
        }

        axios.post(`${getAPIHostURL()}/wclient/setProuctItemStateThroughOrdersModule`, JsonBody)
        .then(response => {
            if(response.data.code == "SUCCESS") {

                if(modifiedState.isAllOrderFullfill == true) {
                    alert(`Successfully saved your partially fulfilled order.`);

                } else {
                    modifiedState.isAllOrderFullFillSendsEmailTeam = true;
                    alert(`Successfully Fulfilled your order, Sending order fulfillment email to Production and Sales team.`);
                }

                // Clear all fields in the form when user successfully ordered Units.
                modifiedState.errors.errRelatedToAssigningState = "";
                modifiedState.errors.succsessRelatedToAssigningState = "";
                modifiedState.errors.others = "";
                // modifiedState.enableFullfillOrder = false;
                modifiedState.isAllOrderFullfill = false;
                // modifiedState.showCustomerOrderDetails = false;
                // modifiedState.disabledBtnOfFullFillOrder = false;
                modifiedState.ItemID = "";
                modifiedState.arrProductItemState = [];
                modifiedState.selectedRowIndex = 0;
                modifiedState.page = 0;

                GetOrderDetailsOfSelectedCust(modifiedState);
                GetCustomerOrderDetails(modifiedState);

            } else {
                let strMsg = "";
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                    
                } else {

                    modifiedState.selectedRowIndex = 0;
                    modifiedState.page = 0;
                    modifiedState.arrProductItemState = [];
                    modifiedState.errors.errRelatedToAssigningState = "";
                    modifiedState.errors.succsessRelatedToAssigningState = "";

                    if(response.data.code == 'ERR_ORDER_ALREADY_FULFILLED_CANNOT_CHANGE') {
                        strMsg = 'You can not fulfill this order as it is already fullfilled. The screen will be refreshed to reflect the latest order state.';
                        alert(strMsg);
                        
                    } else if(response.data.code == 'ERR_SOME_ORD_DTL_IS_ALREADY_IN_MARKED_FOR_SHIPPIN_ST') {
                        strMsg = "You can not fulfill this order as some of the order details are already fulfilled. The screen will be refreshed to reflect the latest order state.";
                        alert(strMsg);
                        
                    } else if(response.data.code == 'ERR_SOME_PROD_ITM_ST_IS_NOT_IN_READY_FOR_SHIPPMENT_ST') {
                        strMsg = "You can not fulfill this order as some of the product item(s) are not in 'ReadyForShippment' state. The screen will be refreshed to reflect the latest order state.";
                        alert(strMsg);
                                    
                    } else if(response.data.code == 'QT_OF_UNIT_SCANNED_GREATER_THAN_QT_UNIT_ORDERED') {
                        strMsg = "The scanned item count is more than the quantity of units ordered. The screen will be refreshed to reflect the latest order state.";
                        alert(strMsg);
                        
                    }
                    GetOrderDetailsOfSelectedCust(modifiedState);
                    GetCustomerOrderDetails(modifiedState);
    
                }
                setState({...modifiedState});

            }
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }   
        });
    }

    const sendOrderDetailsToProductionTeam = (strOrderDateTime, strReuiredDateTime, strLocaleFulfilledOrderDtTm, ProductData, strOrganizationName, OrderType) => {

        let modifiedState = state;

        let appRelevantDataContextValue = context;
        modifiedState.loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let JsonBody = {
            ProductData: ProductData,
            loggedInUserID: modifiedState.loggedInUserID,
            CustID: modifiedState.CustID,
            OrderStatus: modifiedState.OrderStatus,
            Comment: modifiedState.Comment,
            OrderDateTime: strOrderDateTime,
            RequiredDateTime: strReuiredDateTime,
            fulfilOrderDate: strLocaleFulfilledOrderDtTm,
            CustEmailID: modifiedState.CustEmailID,
            CustPhoneNo: modifiedState.CustPhoneNo,
            CustName: modifiedState.CustName,
            OrganisationName: modifiedState.OrganisationName,
            invokedFrom: (state.formViewMode == "editMode") ? "editMode" : null,
            OrderOfOrganisation: strOrganizationName,
            SelectedCustOrderType: OrderType,
            InvokedFromOrder: modifiedState.createEditOrder ? "createEditOrder" : "createEditApiOrder",
        }

        // To not allow user to perform any action.
        appRelevantDataContextValue.onChangeProcessingReq(true);

        axios.post(`${getAPIHostURL()}/wclient/sendOrderDetailsEmailToTeamMembers`, JsonBody)
        .then(response => {
            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            if(response.data.code == "SUCCESS") {
                let strMsg = '';
                strMsg = (state.formViewMode == "editMode" && state.SelectedCustNewOrderDetails == false) ?
                            'Successfully sent Edited order details email to Production and Sales team.'
                          : strLocaleFulfilledOrderDtTm != null ?
                          'Successfully sent Fullfilled order details email to Production and Sales team.'
                          : 'Successfully sent new order details email to Production and Sales team.'
                alert(strMsg);
                // modifiedState.CustSelectedOrderDetails = [...modifiedState.CustSelectedOrderDetails];
                modifiedState.isAllOrderFullFillSendsEmailTeam = false;
                modifiedState.Comment = '';
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                } else if(response.data.code == 'SUPPORT_DETAILS_MISSING'){
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                }
                setState({...modifiedState});
            }
        })
        .catch(error => {
            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }   
        });
    }

    const onChangeItemID = (e) => {
        let modifiedState = state;
        modifiedState.ItemID = e.target.value;

        // Explicitly Make all state value to blank in order to avoid wrong values.
        modifiedState.alreadyPresentProductName = "";
        modifiedState.alreadyPresentProductLine = "";
        modifiedState.alreadyPresentProductID = "";
        modifiedState.currStateOfProduct = "";
        modifiedState.nextStateToBeAssignedToProduct = "";
        modifiedState.nextStateIDToBeAssignedToProduct = 0;
        modifiedState.errors.errRelatedToAssigningState = "";
        modifiedState.errors.succsessRelatedToAssigningState = "";
        modifiedState.errors.others = "";

        setState({...modifiedState});
    }

    const handleKeyDown = (e) => {
       
        let appRelevantDataContextValue = context;
        if (e.key == 'Enter') {
        
            appRelevantDataContextValue.onChangeProcessingReq(true);

            verifyEnteredDeviceID();
        }
    }

    const VerifyDeviceOnSave = () => {
        let modifiedState = state;

        appRelevantDataContextValue.onChangeProcessingReq(true);

        verifyEnteredDeviceID(modifiedState);
    }


    const verifyEnteredDeviceID = (inModifiedState = null) => {

        let modifiedState;
        
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let appRelevantDataContextValue = context;

        if(modifiedState.ItemID == null || modifiedState.ItemID.length <=0 ) {
            modifiedState.errors.errRelatedToAssigningState = 'Please enter ItemID.';
            appRelevantDataContextValue.onChangeProcessingReq(false);

            // setState({...modifiedState});
            return;
        }

        let jsonParams = {
            EnteredDeviceID: modifiedState.ItemID
        }

        axios.post(`${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data.isDeviceIDExist == null) {
                    modifiedState.errors.errRelatedToAssigningState = "Server experiencing issues.\nTry again later.";
                    // setState({...modifiedState});
                    appRelevantDataContextValue.onChangeProcessingReq(false);


                } else {
                    if(response.data.isDeviceIDExist == 0) {
                        modifiedState.errors.errRelatedToAssigningState = "Invalid DeviceID.";
                        // setState({...modifiedState});
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                    } else {
                        modifiedState.errors.deviceExistenceErr = "";
                        getProductItemState(modifiedState);
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUSrvrIssueOwnerAddEmailNotRec);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError);
                }
                // setState({...modifiedState});
                appRelevantDataContextValue.onChangeProcessingReq(false);
                
            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.errRelatedToAssigningState = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                // setState({...modifiedState});
                appRelevantDataContextValue.onChangeProcessingReq(false);

            }
        }); 
        
    }

    const getProductItemState = (inModifiedState = null) => {

        let modifiedState;
        let appRelevantDataContextValue = context;

        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }
        modifiedState.enteredProductItemIdInfo = [];

        let jsonParams = {
            ProductItemID: modifiedState.ItemID
        }

        axios.post(`${getAPIHostURL()}/wclient/getProductItemState`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if( (response.data["retrievedProductStateAndItsInfo"] == null || response.data["retrievedProductStateAndItsInfo"].length <=0) &&
                    (response.data["retrievedProductModelRelavantInfo"] == null || response.data["retrievedProductModelRelavantInfo"].length <=0)
                ) {

                    modifiedState.errors.errRelatedToAssigningState = `The scanned Product Item cannot be used to fulfill the current Order. 
                                                                        \nThe Prduct item is not yet undergo any state whereas it needs to be in 'Ready For Shipment' state.`;
                    // setState({...modifiedState});
                    appRelevantDataContextValue.onChangeProcessingReq(false);

                  
                } else if( (response.data["retrievedProductStateAndItsInfo"] == null || response.data["retrievedProductStateAndItsInfo"].length <=0) &&
                            (response.data["retrievedProductModelRelavantInfo"] != null || response.data["retrievedProductModelRelavantInfo"].length > 0)
                ) {

                    modifiedState.currStateOfProduct = 'No state assigned yet.';


                    modifiedState.errors.errRelatedToAssigningState = `The scanned Product Item cannot be used to fulfill the current Order. 
                                                    \nThe Prduct item is not yet undergo any state whereas it needs to be in 'Ready For Shipment' state.`;

                    // setState({...modifiedState});
                    appRelevantDataContextValue.onChangeProcessingReq(false);


                } else {

                    modifiedState.enteredProductItemIdInfo = response.data["retrievedProductStateAndItsInfo"];

                    // Here we are retriving all product relevant information ["ProductLine", "ProductName"] from arrProductTypeAndItsRelevantInfo based on ProductCode.
                    let arrCurrentProductInfo = modifiedState.arrProductTypeAndItsRelevantInfo.filter((stateInfo) => stateInfo["ProductCode"] == modifiedState.enteredProductItemIdInfo[0]["ProductCode"]);
                    
                    let StateSeqId = arrCurrentProductInfo[0]["StateSeqId"];
                    modifiedState.alreadyPresentProductLine = arrCurrentProductInfo[0]["ProductLine"];
                    modifiedState.alreadyPresentProductName = arrCurrentProductInfo[0]["ProductName"];
                    modifiedState.alreadyPresentProductID = arrCurrentProductInfo[0]["ProductCode"];

                    // get curent StateID.
                    let StateIDOfCurrentProductState = modifiedState.enteredProductItemIdInfo[0]["StateID"];

                    // Find out the current state of the product from the array of state based on curent StateID.
                    let arrCurrentProductStateInfo = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == StateIDOfCurrentProductState);

                    modifiedState.currStateOfProduct = arrCurrentProductStateInfo[0]["State"];

                    let arrNextProductStateInfoBasedOnCurrentState = [];

                    if(arrCurrentProductStateInfo[0]["State"] == ITM_STATE_READY_FOR_SHIPMENT) {

                        // Find out the next state of the product from the array of state sequence based on its StateSeqID (GenericSeqID, ThirdPartySeqID) and current state of sequence.
                        arrNextProductStateInfoBasedOnCurrentState 
                            = modifiedState.arrProductStateSeq.filter((stateInfo) => stateInfo["StateSeqId"] == StateSeqId && 
                                                                                    stateInfo["CurrentStateID"] == StateIDOfCurrentProductState
                                                                   );

                        let arrSplitedNextState = (arrNextProductStateInfoBasedOnCurrentState[0]["NextStateIDs"]).split(",");

                        let arrNextStateInfo = [];
                        if(modifiedState.SelectedCustomerOrder.OrderType == ORDER_TYPE_DEMO) {
                            arrNextStateInfo 
                                = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == arrSplitedNextState[0]);
                        } else if (modifiedState.SelectedCustomerOrder.OrderType == ORDER_TYPE_RENTAL) {
                            arrNextStateInfo 
                                = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == arrSplitedNextState[3]);
                        } else {
                            arrNextStateInfo 
                                = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == arrSplitedNextState[1]);
                        }

                        modifiedState.nextStateToBeAssignedToProduct = arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"];
                        modifiedState.nextStateIDToBeAssignedToProduct = arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"];
            
                        checkUserPvgBeforeMarkingProductState(modifiedState);
                    } else {

                        modifiedState.errors.errRelatedToAssigningState 
                                    = `The scanned Product Item cannot be used to fulfill the current Order. 
                                        \nThe current state of the item is '${modifiedState.currStateOfProduct}' whereas it needs to be in 'Ready For Shipment' state.`;
                        
                        // setState({...modifiedState});
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                    }                   
                }
             
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUSrvrIssueOwnerAddEmailNotRec);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError);
                }
                // setState({...modifiedState});
                appRelevantDataContextValue.onChangeProcessingReq(false);

            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.errRelatedToAssigningState = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                // setState({...modifiedState});
                appRelevantDataContextValue.onChangeProcessingReq(false);

            }
        }); 
    }

    const checkUserPvgBeforeMarkingProductState = (inModifiedState = null) => {

        let modifiedState;
        let appRelevantDataContextValue = context;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let arrLoggedInUserAssignedPvg = [];

        // here we are getting loggedinUser privileges.
        for (let [key, value] of Object.entries(modifiedState.objPrivilege)) {
            if(value == true) {
                arrLoggedInUserAssignedPvg.push(key);
            }
        }

        let jsonParams = {
            nextStateID: modifiedState.nextStateIDToBeAssignedToProduct,
            arrLoggedInUserAssignedPvg: arrLoggedInUserAssignedPvg
        }

        axios.post(`${getAPIHostURL()}/wclient/checkUserPvgBeforeMarkingProductState`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["arrPvg"] == null || response.data["arrPvg"].length <=0 ) {

                    modifiedState.errors.errRelatedToAssigningState = 'You do not have required privileges to move the product item into next state.';
                    
                } else {
                    let arrHasLoggedInUserPvgToAssignedNxtState = response.data["arrPvg"];

                    let hasPvgToAssignedNextState = false;

                    for(let i=0; i<arrHasLoggedInUserPvgToAssignedNxtState.length; i++) {
                        if(arrHasLoggedInUserPvgToAssignedNxtState[i]["bPrivilege"] == 'true') {
                            hasPvgToAssignedNextState = true;
                        }
                    }

                    if(hasPvgToAssignedNextState == false) {
                        modifiedState.errors.errRelatedToAssigningState = 'You do not have required privileges to move the product item into next state.';
                        // setState({...modifiedState});
                        appRelevantDataContextValue.onChangeProcessingReq(false);


                    } else {
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                        onClickProductNextState(modifiedState);
                    }
                }


            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUSrvrIssueOwnerAddEmailNotRec);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.errRelatedToAssigningState = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError); 
                }
                setState({...modifiedState});

            }

        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.errRelatedToAssigningState = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                setState({...modifiedState});
            }
        }); 
    }

    const getProductCategoryAndItsRelevantInfo = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState ==  null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.arrProductTypeAndItsRelevantInfo = [];
        modifiedState.arrProductLine = [];
        modifiedState.arrProductStates = [];
        modifiedState.arrProductStateSeq = [];

        axios.post(`${getAPIHostURL()}/wclient/getProductCategoryAndItsRelevantInfo`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data["retrievedProdTypeAndItsRelvantInfo"] == null ||
                    response.data["retrievedProdTypeAndItsRelvantInfo"].length <= 0 ||
                    response.data["retrievedStateSeq"] == null ||
                    response.data["retrievedStateSeq"].length <= 0 ||
                    response.data["retrievedStates"] == null ||
                    response.data["retrievedStates"].length <= 0 

                ) {
                    modifiedState.errors.others = 'Product relevant information or its States are not present on server.'
                } else {
                    // Include all Product and its relevant infrmation (MasterData)
                    modifiedState.arrProductTypeAndItsRelevantInfo = response.data["retrievedProdTypeAndItsRelvantInfo"];

                    // Include all state through product goes (MasterData)
                    modifiedState.arrProductStates = response.data["retrievedStates"];

                    // GenericProductSeq (MasterData)
                    modifiedState.arrProductStateSeq = response.data["retrievedStateSeq"];
                    
                    // filter out distinct Product Line.
                    for(let i=0; i<modifiedState.arrProductTypeAndItsRelevantInfo.length; i++) {
                        let ProductLine = modifiedState.arrProductTypeAndItsRelevantInfo[i]["ProductLine"];
                        if( !modifiedState.arrProductLine.includes(ProductLine) ) {
                            modifiedState.arrProductLine.push(ProductLine);
                        }
                    }
                }
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    // modifiedState.errors.others = t(IDS_AUServerError);
                }
            }
            setState({...modifiedState});
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                setState({...modifiedState});
            }
        }); 

    }

    const onClickProductNextState = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }
        
        modifiedState.objToSendToOrderPg = {
            ProductLine: modifiedState.alreadyPresentProductLine,
            ProductName: modifiedState.alreadyPresentProductName,
            ProductCode: modifiedState.alreadyPresentProductID,
            ItemID: modifiedState.ItemID,
            StateIDToBeAssigned: modifiedState.nextStateIDToBeAssignedToProduct,
            SetByUser: modifiedState.loggedInUserID
        };

        let receivedProdItemInfo = modifiedState.objToSendToOrderPg;

        let stateArrProductInfo = [...modifiedState.CustSelectedOrderDetails];
        let CustAllOrderData = [...modifiedState.CustAllOrderData];

        for(let i=0; i<modifiedState.arrProductItemState.length; i++) {
            if(modifiedState.arrProductItemState[i]["ItemID"] == receivedProdItemInfo["ItemID"]) {
                modifiedState.errors.errRelatedToAssigningState =  "Scanned item has been already added to the Order. Please can scan the next item.";
                setState({...modifiedState});
                return;
            }
        }

        let filteredArrayBasedOnProdcutInfo = stateArrProductInfo.filter((orderDetails) => 
                                                    orderDetails["ProductLine"] == receivedProdItemInfo["ProductLine"] &&
                                                    orderDetails["ProductName"] == receivedProdItemInfo["ProductName"] &&
                                                    orderDetails["ProductCode"] == receivedProdItemInfo["ProductCode"] 
                                            );
        

        if(filteredArrayBasedOnProdcutInfo == null || filteredArrayBasedOnProdcutInfo.length <= 0) {

            modifiedState.errors.errRelatedToAssigningState = `The scanned Product Item is not required to fulfill the current Order. 
                                                                \nThe Product Line and Product are not present in any of the Order Details.`;
            setState({...modifiedState});
            return;
        }

        let filteredArray = stateArrProductInfo.filter((orderDetails) => 
                                            orderDetails["ProductLine"] == receivedProdItemInfo["ProductLine"] &&
                                            orderDetails["ProductName"] == receivedProdItemInfo["ProductName"] &&
                                            orderDetails["ProductCode"] == receivedProdItemInfo["ProductCode"] &&
                                            orderDetails["QuantityOfUnitOrdered"] > orderDetails["QuantityOfUnitScanned"]
                            );

        if(filteredArray != null && filteredArray.length > 0) {

            let objIncludesOrderInfo = {
                OrderLineNumber: filteredArray[0]["OrderLineNumber"],
                OrderNumber: filteredArray[0]["OrderNumber"]
            }

            let objSubscriptionInfo = {};

            if(filteredArray[0]["SubscriptionPeriodPerUnitOrder"] != 'NA') {
                objSubscriptionInfo = {
                    PeriodOfSubscription: filteredArray[0]["SubscriptionPeriodPerUnitOrder"],
                    PricePerUnitOrderINR: filteredArray[0]["SubscriptionPricePerUnitOrder"],
                    TaxPerUnitOrderINR: filteredArray[0]["SubscriptionTaxPerUnitOrder"]
                }
            }
            let objOrganisationID = {};

            if(modifiedState.selectedOrganisationName != null && modifiedState.selectedOrganisationName.length > 0 && modifiedState.selectedOrganisationName != 'NA'){
                objOrganisationID = {
                    OrganisationID: modifiedState.selectedOrganisationName
                }
            }

            let objIncludesOrderAndProductInfo = {...receivedProdItemInfo, ...objIncludesOrderInfo, ...objSubscriptionInfo, ...objOrganisationID};

            if(objIncludesOrderAndProductInfo.hasOwnProperty("PeriodOfSubscription")) {
                modifiedState.arrDevicesHavingSubscription.push(objIncludesOrderAndProductInfo);
                let AlertToShow = `Please note that the Device with ID [${objIncludesOrderAndProductInfo["ItemID"]}] has subscription.\n`;
                alert(AlertToShow);
            }

            modifiedState.arrProductItemState.push(objIncludesOrderAndProductInfo);
            // modifiedState.arrItemID.push(receivedProdItemInfo["ItemID"]);
            
            let ArrMinDateProductData = filteredArray.reduce((a, b) => {
                return new Date(a.OrderLineNumber) < new Date(b.OrderLineNumber) ? a : b;
            });

            let count = 0;

            for(let i=0; i<stateArrProductInfo.length; i++) {
                
                if(stateArrProductInfo[i]["OrderLineNumber"] == ArrMinDateProductData["OrderLineNumber"]
                ) {
                    stateArrProductInfo[i]["QuantityOfUnitScanned"] = stateArrProductInfo[i]["QuantityOfUnitScanned"] + 1;

                    if(stateArrProductInfo[i]["QuantityOfUnitOrdered"] == stateArrProductInfo[i]["QuantityOfUnitScanned"]) {
                        stateArrProductInfo[i]["Status"] = ORDER_STATE_MARKED_FOR_SHIPPING;
                    } else {
                        stateArrProductInfo[i]["Status"] = ORDER_STATE_PROCESSING;
                    }
                    // break;
                }
          
                modifiedState.ItemID = "";
                modifiedState.errors.succsessRelatedToAssigningState =` Scanned item has been successfully added to the Current Order. You can scan the next item.`;

                if(stateArrProductInfo[i]["QuantityOfUnitOrdered"] == stateArrProductInfo[i]["QuantityOfUnitScanned"] &&
                    stateArrProductInfo[i]["Status"] == ORDER_STATE_MARKED_FOR_SHIPPING
                ) {
                    count++;
                
                    if(count == stateArrProductInfo.length) {
                       
                        // for(let i = 0; i< CustAllOrderData.length; i++) {
                        //     if(CustAllOrderData[i]["OrderNumber"] == modifiedState.SelectedCustomerOrder["OrderNumber"]) {
                                // CustAllOrderData[i]["Status"] = ORDER_STATE_MARKED_FOR_SHIPPING;
                                modifiedState.disabledBtnOfFullFillOrder = true;
                                modifiedState.enableFullfillOrder = false;
                                modifiedState.errors.succsessRelatedToAssigningState = "";
                                modifiedState.isAllOrderFullfill = false;
                        //     }
                        // }
                    } else {
                        modifiedState.isAllOrderFullfill = true;
                    }
                } else {
                    modifiedState.isAllOrderFullfill = true;
                }
            }
          
            modifiedState.CustSelectedOrderDetails = stateArrProductInfo;
            modifiedState.CustAllOrderData = CustAllOrderData;
        } else {

            modifiedState.errors.errRelatedToAssigningState = `The scanned Product Item is not required to fulfill the current Order. 
                                                                \nThe required number of items have already been scanned for corresponding order details.`;
        }
        setState({...modifiedState});
    }

    const toggleCustomerOrder = () => {
        let modifiedState = state;
        
        if(modifiedState.arrProductItemState.length > 0) {
            let strMsg = `You have unsaved changes. Your scanned item list will be lost. Do you want to proceed without Saving?`;

            if(window.confirm(strMsg)) {
                props.toggleCreateOrder();
            }
        } else {
            props.toggleCreateOrder();
        }
    }

    const getSelectedTrProps = (rowInfo) => {

        let modifiedState = state;
        modifiedState.switchOrderMenus = true;

        if (rowInfo != undefined) {
            return {
                onClick: (e, handleOriginal) => {
                    if(props.propAction != "CreateOrder") {
                        for(let i=0; i < modifiedState.AllOrganisationInformation.length; i++){
            
                            if(modifiedState.AllOrganisationInformation[i]['OrgName'] == rowInfo.row.original.OrderOfOrganisation) {
                                modifiedState.selectedOrganisationName = modifiedState.AllOrganisationInformation[i]['OrgID']
                                break;
                            } else {
                                modifiedState.selectedOrganisationName  = "";
                            }
                        }
                    }

                    if(rowInfo.column.id == "viewFullFiledDetails") {
                        modifiedState.selectedRowIndex = null;
                        onClickViewCustFullfiledDetails(rowInfo.row, rowInfo.column)  
                    } else if(rowInfo.column.id == "edit"){
                        modifiedState.selectedRowIndex = null;
                        onEditCustOrder(rowInfo.row, rowInfo.column)
                    } else if(rowInfo.column.id == "Download"){
                        modifiedState.selectedRowIndex = null;
                        exportPDF(rowInfo.row, rowInfo.column)
                    } 

                    if( modifiedState.selectedRowIndex == rowInfo.row.index && modifiedState.bEditOrder == false) {
                        return;
                    }

                    if( modifiedState.selectedRowIndex == rowInfo.row.index && modifiedState.arrProductItemState.length > 0) {
                        let strMsg = `You have unsaved changes. Your scanned item list will be lost. Do you want to proceed without Saving?`;
            
                        if(window.confirm(strMsg)) {
                            modifiedState.arrProductItemState = [];
                        } else {
                            modifiedState.bEditOrder = false;
                            setState({...modifiedState});
                            return;
                        }
                    } else if(modifiedState.arrProductItemState.length > 0) {
                        let strMsg = `You have unsaved changes. Your scanned item list will be lost. Do you want to proceed without Saving?`;
            
                        if(window.confirm(strMsg)) {
                            modifiedState.arrProductItemState = [];
                        } else {
                            modifiedState.bEditOrder = false;
                            setState({...modifiedState});
                            return;
                        }
                    }

                    modifiedState.selectedRowIndex = rowInfo.row.index;

                    modifiedState.selectedOrderDetailsArr = [];
                    modifiedState.selectedOrderDetailsArrForApiOrder = [];
                    modifiedState.CustSelectedOrderDetails = [];
                    modifiedState.CustSelectedOrderDetailsForApiOrder = []
                    modifiedState.disabledBtnOfFullFillOrder = false;
                    modifiedState.enableFullfillOrder = false;
                    modifiedState.isAllOrderFullfill = false;
                    modifiedState.errors.errRelatedToAssigningState = "";
                    modifiedState.errors.succsessRelatedToAssigningState = "";
                    modifiedState.errors.others = "";
                    modifiedState.errors.errApiOrder = "";
                    modifiedState.SelectedCustomerOrder = rowInfo.row.original;

                    modifiedState.selectedOrderNumber = modifiedState.SelectedCustomerOrder.OrderNumber;
                    modifiedState.OrderType = modifiedState.SelectedCustomerOrder.OrderType;            
            
                    modifiedState.showCustomerOrderDetails = true;
            
                    if(modifiedState.SelectedCustomerOrder["Status"] == ORDER_STATE_MARKED_FOR_SHIPPING) {
                        modifiedState.disabledBtnOfFullFillOrder = true;
                    }

                    // to show selected order details push into 'selectedOrderDetailsArr'.
                    for(let i=0; i< modifiedState.CustomerOrderDetails.length; i++) {
                        if(modifiedState.CustomerOrderDetails[i]["OrderNumber"] == modifiedState.selectedOrderNumber) {
                            modifiedState.selectedOrderDetailsArr.push(modifiedState.CustomerOrderDetails[i]);
                        }
                    }
            
                    const receiveOrderDetailsData = modifiedState.selectedOrderDetailsArr;
                    
                    let noOfLogs = receiveOrderDetailsData.length;
            
                    // order does not have details then setState.
                    if(noOfLogs == 0) {
                        setState({...modifiedState});
                    }
            
                    // for formatting order details
                    for(let i = 0; i < noOfLogs; i++) {
            
                        const selectedOrderDetails = receiveOrderDetailsData[i];
                        let totalSubscriptionValue = 0;
                        let SubscriptionPeriodInMonth = 0;

                        // the subscription price and tax which is stored in the database is multiplied by 30, in order to show proce as per 1 month
                        let subscriptionPricePerUnitINRtoShow = selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null ? 'NA' : (selectedOrderDetails.SubscriptionPricePerUnitOrderINR * 30).toFixed(2);
                        let SubscriptionTaxPerUnitOrdertoShow = selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : ((selectedOrderDetails.SubscriptionTaxPrcntPUO / 100 ) * subscriptionPricePerUnitINRtoShow).toFixed(2);

                        if(selectedOrderDetails.SubscriptionPricePerUnitOrderINR == null &&  selectedOrderDetails.SubscriptionTaxPerUnitOrderINR == null && selectedOrderDetails.SubscriptionPeriod == null) {
                            totalSubscriptionValue = 0;
                        } else {
                            SubscriptionPeriodInMonth = selectedOrderDetails.SubscriptionPeriod / DAYS_IN_MONTH 
                            totalSubscriptionValue = (Number(SubscriptionPeriodInMonth) * (Number(subscriptionPricePerUnitINRtoShow) + Number(SubscriptionTaxPerUnitOrdertoShow)));
                        }

                        let selectedOrderItemsValue = (modifiedState.OrderType != null && modifiedState.OrderType.length > 0 && modifiedState.OrderType != ORDER_TYPE_RENTAL) ?
                                                    (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.PricePerUnitOrderINR) + Math.abs(selectedOrderDetails.TaxPerUnitOrderINR) + Number(totalSubscriptionValue))).toFixed(2) : 
                                                    (Math.abs(selectedOrderDetails.QuantityOfUnitsOrdered) * (Math.abs(selectedOrderDetails.OneTimeChrgOnRentPUOI) + Math.abs(selectedOrderDetails.SecurityDepositOnRentPUOI) + Math.abs(selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI) + Number(totalSubscriptionValue))).toFixed(2);
                        
                        let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                        let singleOrderDetailinfo = {
                            viewFullFiledDetails: selectedOrderDetails.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetails.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                            OrderLineNumber: selectedOrderDetails.OrderLineNumber,
                            OrderNumber:selectedOrderDetails.OrderNumber,
                            ProductLine: selectedOrderDetails.ProductLine,
                            ProductName: selectedOrderDetails.ProductName,
                            ProductCode: selectedOrderDetails.ProductCode,
                            QuantityOfUnitOrdered: selectedOrderDetails.QuantityOfUnitsOrdered,
                            QuantityOfUnitScanned: selectedOrderDetails.QuantityOfUnitsScanned,
                            PricePerUnitOrder: selectedOrderDetails.PricePerUnitOrderINR,
                            TaxPercentPerUnitOrder: selectedOrderDetails.TaxPrcntPUO,
                            TaxPerUnitOrder: selectedOrderDetails.TaxPerUnitOrderINR,
                            SecurityDepositPerUnitOrder: selectedOrderDetails.SecurityDepositOnRentPUOI,
                            OneTimeChargePerUnitOrder: selectedOrderDetails.OneTimeChrgOnRentPUOI,
                            TaxPercentageOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPrcntOnOneTimeChrgPUO,
                            TaxPriceOnOneTimeChargePerUnitOrder: selectedOrderDetails.TaxPriceOnOneTimeChrgPUOI,
                            OrderItemsValue: selectedOrderItemsValue,
                            Status: selectedOrderDetails.Status,
                            SubscriptionPricePerUnitOrder: subscriptionPricePerUnitINRtoShow,
                            SubscriptionTaxPerUnitOrder: SubscriptionTaxPerUnitOrdertoShow,
                            SubscriptionPercentagePerUnitOrder: selectedOrderDetails.SubscriptionTaxPrcntPUO == null ? 'NA' : selectedOrderDetails.SubscriptionTaxPrcntPUO,
                            SubscriptionPeriodPerUnitOrder: selectedOrderDetails.SubscriptionPeriod == null ? 'NA' : SubscriptionPeriodInMonth,
                            LastModifiedUtcDtTm: selectedOrderDetails.LastModifiedUtcDtTm
                        };
                        modifiedState.CustSelectedOrderDetails.push(singleOrderDetailinfo);
                        setState({...modifiedState});
                    }

                    for(let i=0; i< modifiedState.CustomerOrderDetailsForApiOrder.length; i++) {
                        if(modifiedState.CustomerOrderDetailsForApiOrder[i]["OrderNumber"] == modifiedState.selectedOrderNumber) {
                            modifiedState.selectedOrderDetailsArrForApiOrder.push(modifiedState.CustomerOrderDetailsForApiOrder[i]);
                        }
                    }
            
                    const receiveOrderDetailsDataForApiOrder = modifiedState.selectedOrderDetailsArrForApiOrder;
                    
                    let noOfLogsForApiOrder = receiveOrderDetailsDataForApiOrder.length;
            
                    // order does not have details then setState.
                    if(noOfLogsForApiOrder == 0) {
                        setState({...modifiedState});
                    }
            
                    // for formatting order details
                    for(let i = 0; i < noOfLogsForApiOrder; i++) {

                        const selectedOrderDetailsForApiOrder = receiveOrderDetailsDataForApiOrder[i];

                        let PackageDurationOnApiOrderInMonths  = (Number(selectedOrderDetailsForApiOrder.PackageDuration)) / 30;
                        let DiscountedPrice = Number(selectedOrderDetailsForApiOrder.PricePerUnitOrderINR) - Number(selectedOrderDetailsForApiOrder.DisctPricePerPkg);
                        let TaxOnDiscountedPrice = ((Number(DiscountedPrice)) * ((Number(selectedOrderDetailsForApiOrder.TaxPrcntPUO)) / 100)).toFixed(2);
                        let selectedOrderItemsValueForApiOrder = (Number(DiscountedPrice) + Number(TaxOnDiscountedPrice));    
                        
                        let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
            
                        let singleOrderDetailinfo = {
                            //viewFullFiledDetails: selectedOrderDetailsForApiOrder.Status == ORDER_STATE_MARKED_FOR_SHIPPING || selectedOrderDetailsForApiOrder.Status == ORDER_STATE_PROCESSING ? viewEyeIcon : "-",
                            PackageID: selectedOrderDetailsForApiOrder.PackageID,
                            OrderLineNumber: selectedOrderDetailsForApiOrder.OrderLineNumber,
                            OrderNumber:selectedOrderDetailsForApiOrder.OrderNumber,
                            ProductLine: selectedOrderDetailsForApiOrder.ProductLine,
                            ProductName: selectedOrderDetailsForApiOrder.ProductName,
                            ProductCode: selectedOrderDetailsForApiOrder.ProductCode,
                            QuantityOfUnitOrdered: selectedOrderDetailsForApiOrder.QuantityOfUnitsOrdered,
                            QuantityOfUnitScanned: selectedOrderDetailsForApiOrder.QuantityOfUnitsScanned,
                            PackageDurationOnApiOrder: PackageDurationOnApiOrderInMonths,
                            ApiHitsPerPackageDuration: selectedOrderDetailsForApiOrder.ApiHitsInPkg,
                            DisctPrcntPerPkg: selectedOrderDetailsForApiOrder.DisctPrcntPerPkg,
                            DisctPricePerPkg: selectedOrderDetailsForApiOrder.DisctPricePerPkg,
                            PricePerUnitOrder: selectedOrderDetailsForApiOrder.PricePerUnitOrderINR,
                            TaxPercentPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPrcntPUO,
                            TaxPerUnitOrder: selectedOrderDetailsForApiOrder.TaxPerUnitOrderINR,
                            OneTimeChargePerUnitOrder: selectedOrderDetailsForApiOrder.OneTimeChrgOnRentPUOI,
                            OrderItemsValue: selectedOrderItemsValueForApiOrder.toFixed(2),
                            Status: selectedOrderDetailsForApiOrder.Status,
                            LastModifiedUtcDtTm: selectedOrderDetailsForApiOrder.LastModifiedUtcDtTm,
                            PriceOfHitsInPkgINR: selectedOrderDetailsForApiOrder.PriceOfHitsInPkgINR,
                            PricePerPkgDurINR: selectedOrderDetailsForApiOrder.PricePerPkgDurINR,
                            PackageDuration: selectedOrderDetailsForApiOrder.PackageDuration
                        };
                        
                        modifiedState.CustSelectedOrderDetailsForApiOrder.push(singleOrderDetailinfo);   
                        setState({...modifiedState});
                    }

                    setState({...modifiedState});
                },

                style: {
                    cursor: 'pointer',
                    background: rowInfo.row.index == state.selectedRowIndex ? '#6c95ba' : '',
                    color: rowInfo.row.index == state.selectedRowIndex ? 'white' : 'black',
                    alignItems: "center"
                }
            }
        } else {
            return {}
        }
    }

    const setOrderState = (e) => {
        let modifiedState = state;
        modifiedState.selectedOrderType = e.target.value;
        modifiedState.errors.isOrderTypeSelected = "";
        modifiedState.OrderType = modifiedState.selectedOrderType 
        setState({...modifiedState});
    }

    const setOrderSubscription = (e) => {
        let modifiedState = state;
        modifiedState.isOrderWithFreeSubscription = e.target.checked;

        if(modifiedState.isOrderWithFreeSubscription) {
            modifiedState.SubscriptionPricePerUnitINR = 0;
            modifiedState.SubcriptionTaxPercentagePerUnitINR = 0;
            modifiedState.SubcriptionTaxPerUnitINR = 0;
            modifiedState.rentalPricePerUnitINR = 0;
            modifiedState.rentaltaxPercentagePerUnit = 0;
            modifiedState.rentaltaxPricePerUnit = 0;

        } else {

            // if modifiedState.AllRentalProductInformation is not present for the product then made rental values to 0 if the flag is set to false again.
            if(modifiedState.AllRentalProductInformation ==  null || modifiedState.AllRentalProductInformation.length <= 0 && 
                modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL){
                    modifiedState.rentalPricePerUnitINR = 0;
                    modifiedState.rentaltaxPercentagePerUnit = 0;
                    modifiedState.rentaltaxPricePerUnit = 0;
            } else {

                for(let i=0; i< modifiedState.ProductsSubscriptionInfo.length; i++) {
                    if((modifiedState.ProductsSubscriptionInfo[i]["ProductCode"] == modifiedState.ProductCode)) {
                        // Assigning values to Subscription 'PricePerUnitINR', 'TaxPerUnitINR' based on selection of Second dropdown.
                        modifiedState.SubscriptionPricePerUnitINR = modifiedState.ProductsSubscriptionInfo[i]["PricePerUnitINR"];
                        modifiedState.SubcriptionTaxPercentagePerUnitINR = modifiedState.ProductsSubscriptionInfo[i]["TaxPrcntPerUnit"];
                        modifiedState.SubscriptionPeriodPerUnit = modifiedState.SubscriptionPeriodPerUnit != null ? modifiedState.SubscriptionPeriodPerUnit : 1;
                        // subscription price is fetching from the database per unit day 
                        // here we are calulating the subscription price per unit month and then showing on the UI
                        let SubscriptionPeriodinDays =  (modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"] == null || modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"].length <= 0) ? null :  (DAYS_IN_MONTH * 1);
                        modifiedState.SubscriptionPricePerUnitINR = SubscriptionPeriodinDays != null && SubscriptionPeriodinDays > 0 ? (SubscriptionPeriodinDays * modifiedState.SubscriptionPricePerUnitINR).toFixed(2) : null;

                        if(modifiedState.AllRentalProductInformation !=  null && modifiedState.AllRentalProductInformation.length > 0 && 
                            modifiedState.selectedOrderType != null && modifiedState.selectedOrderType.length > 0 && modifiedState.selectedOrderType == ORDER_TYPE_RENTAL){
                                modifiedState.rentaltaxPercentagePerUnit = modifiedState.AllRentalProductInformation[i]["RentalTaxPrcntPUP"];
                                modifiedState.rentalPricePerUnitINR =  modifiedState.AllRentalProductInformation[i]["RentalPricePUPI"];
                                let SubscriptionPeriodinDays =  (modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"] == null || modifiedState.ProductsSubscriptionInfo[i]["BasicUnit"].length <= 0) ? null :  (DAYS_IN_MONTH * modifiedState.SubscriptionPeriodPerUnit);
                                // Rental price is fetching from the database per unit day 
                                // here we are calulating the Rental price per unit month and then showing on the UI
                                modifiedState.rentalPricePerUnitINR = SubscriptionPeriodinDays != null && SubscriptionPeriodinDays > 0 ? (SubscriptionPeriodinDays * modifiedState.rentalPricePerUnitINR).toFixed(2) : null;
                        }
                    }
                }
            }
        }

        GetAllTaxCalculations(modifiedState);
    }

    const onChangeSubscriptionPeriod = (e) => {
        let modifiedState = state;
        modifiedState.selectedSubscriptionPeriod = e.target.value;
        setState({...modifiedState});
    }

    const onPageChange = (page) => {
        let modifiedState = state;
        modifiedState.page = page;
        setState({...modifiedState});
    }

    const exportPDF = (rowInfo, columns) => {

        let modifiedState = state;

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
    
        const doc = new jsPDF(orientation, unit, size);

        if(modifiedState.createEditOrder){
     
            // const title = "Order Details";
            let title = `Order No. ` + rowInfo.original.OrderNumber + ` and Details`;
            const headerTitle = [["Order Details"]];
            const headersOrder = [["Order No.", "Order Date", "Required Order Date", "Order Fulfilled Date", "Sales Representative", "Status"]];
            const headerOrderComments = [['Order Comments']];
            const headerOrderGroupingOrganization =[['Order Grouping Organization Name']];
            const headersCustomer = [["Customer ID", "Customer Company/Org. Name", "Customer Phone No.", "", "Customer Name", "Customer Email"]];
            
            const headersTotalValue = [['Total Order Value (INR)']];
            const headersNetTotalValue = [["No. of Order \nLine Items", "Total \nQuantity \nof Units \nOrdered", "Total \nQuantity \nof Units \nFulfilled", "Total \nPrice \n(INR)", "Total \nTax \n(INR)", "Total \nSubscription \nprice (INR)", "Total \nSubscription \nTax (INR)", "Total \nOrder \nValue"]];
            const headersOrderDetails = [["Product \nLine", "Product \nName", "Product \nCode", "Quantity \nof Unit \nOrdered", "Quantity \nof Unit \nFulfilled", 
                                        "Status"]];
            const headersOrderPriceDetails = [["Price \nPer Unit \n(INR)","Tax % \nPer Unit Order", "Tax \nPer Unit \n(INR)"]];
            
            const headersOrderSubscriptionPriceDetails = [["Subscription \nPeriod (Month) \nPer Unit Order", 
                                                        "Subscription \nPrice Per \nUnit Period (Month)", "Subscription tax % \nPer \nUnit Period", "Subscription \nTax Per \nUnit Period (Month)", "Order \nItems \nValue"]]
        
            const headersOrderSubscriptionPriceDetailsForRental = [["Rental \nPeriod (Month) \nPer Unit Order", 
                                                        "Rental \nPrice Per \nUnit Period (Month)", "Rental % \nPer \nUnit Period", "Rental \nTax Per \nUnit Period (Month)", "Order \nItems \nValue"]]
            
            const headersOrderPriceDetailsForRentalOrder = [["Security Deposit \nPer Unit Order \n(INR)","One Time Charge \nPer Unit Order \n(INR)", "Tax % \nOn One Time Charge \nPer Unit Order", "Tax Price \nOn One Time Charge \nPer Unit Order(INR)"]]
            const headersNetTotalValueForRentalOrder = [["No. of Order \nLine Items", "Total \nQuantity \nof Units \nOrdered", "Total \nQuantity \nof Units \nFulfilled", "Total Security Deposit \nPrice \n(INR)", "Total One Time Charge \nPrice \n(INR)","Total One Time Charge \n Tax Price \n(INR)", "Total \nSubscription \nprice (INR)", "Total \nSubscription \nTax (INR)", "Total \nOrder \nValue"]]

            let dataOrder = [[rowInfo.original.OrderNumber, rowInfo.original.OrderDate, rowInfo.original.RequiredDate, 
                            rowInfo.original.FulfilledDate, rowInfo.original.SalesRepUserID, rowInfo.original.Status]];

            let dataOrderComments = [[rowInfo.original.Comments]];

            let dataOrderGroupingOrganization =[[(rowInfo.original.OrderOfOrganisation == null || rowInfo.original.OrderOfOrganisation.length <= 0) ? 'NA' : rowInfo.original.OrderOfOrganisation]];

            let dataCustomer = [[modifiedState.CustID, modifiedState.OrganisationName, 
                                modifiedState.CustPhoneNo, "                        ", 
                                modifiedState.CustName, modifiedState.CustEmailID]];

            modifiedState.TotalQuantityOrdered = 0;
            modifiedState.TotalQyantityFulfilled = 0;
            modifiedState.TotalPriceForPDF = 0;
            modifiedState.TotalTaxForPDF = 0;
            modifiedState.TotalSecurityDepositPrice = 0;
            modifiedState.TotalOneTimeChargePrice = 0;
            modifiedState.TotalOneTimeChargeTaxPrice = 0;
            modifiedState.TotalSubscriptionPriceForPDF = 0;
            modifiedState.TotalSubscriptionTaxForPDF = 0;
            modifiedState.TotalOrderValueForPDF = 0;
            let dataOrderDetailsArr = [];
            let dataOrderPriceDetailsArr = [];
            let dataOrderPriceDetailsForRentalOrderArr = [];
            let dataOrderSubscriptionDetailsArr = [];

            modifiedState.selectedOrderDetailsArr = [];

            let PDFOrderType = rowInfo.original.OrderType;

            for(let i=0; i< modifiedState.CustomerOrderDetails.length; i++) {
                if(modifiedState.CustomerOrderDetails[i]["OrderNumber"] == rowInfo.original.OrderNumber) {
                    modifiedState.selectedOrderDetailsArr.push(modifiedState.CustomerOrderDetails[i]);
                }
            }

            const receiveOrderDetailsData = modifiedState.selectedOrderDetailsArr;
            let noOfLogs = receiveOrderDetailsData.length;

            for(let j=0; j<noOfLogs; j++){

                // the subscription price and tax which is stored in the database is multiplied by 30, in order to show price as per 1 month
                let subscriptionPricePerUnitINRtoShow = receiveOrderDetailsData[j].SubscriptionPricePerUnitOrderINR == null == null ? 'NA' : (receiveOrderDetailsData[j].SubscriptionPricePerUnitOrderINR * 30).toFixed(2);
                let SubscriptionTaxPerUnitOrdertoShow = receiveOrderDetailsData[j].SubscriptionTaxPerUnitOrderINR == null && receiveOrderDetailsData[j].SubscriptionTaxPrcntPUO == null ? 'NA' : ((receiveOrderDetailsData[j].SubscriptionTaxPrcntPUO  / 100 ) * subscriptionPricePerUnitINRtoShow).toFixed(2);

                let TotalSubscriptionValPerProduct = ( (receiveOrderDetailsData[j].SubscriptionPricePerUnitOrderINR == null && receiveOrderDetailsData[j].SubscriptionTaxPerUnitOrderINR == null) ||
                                                        (receiveOrderDetailsData[j].SubscriptionPricePerUnitOrderINR == 0 && receiveOrderDetailsData[j].SubscriptionTaxPerUnitOrderINR == 0 &&
                                                            receiveOrderDetailsData[j].SubscriptionTaxPrcntPUO == 0 && receiveOrderDetailsData[j].SubscriptionTaxPrcntPUO == null) 
                                                    ) ? 0 
                                                    : ((Number(subscriptionPricePerUnitINRtoShow) + Number(SubscriptionTaxPerUnitOrdertoShow)) * Number(receiveOrderDetailsData[j].SubscriptionPeriod / DAYS_IN_MONTH));
                
                let selectedOrderItemsValue
                if(PDFOrderType != null && PDFOrderType.length > 0 && PDFOrderType != ORDER_TYPE_RENTAL){
                    selectedOrderItemsValue = (Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered) * (Number(receiveOrderDetailsData[j].PricePerUnitOrderINR) + Number(receiveOrderDetailsData[j].TaxPerUnitOrderINR) + Number(TotalSubscriptionValPerProduct))).toFixed(2);
                } else {
                    selectedOrderItemsValue = (Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered) * (Number(receiveOrderDetailsData[j].SecurityDepositOnRentPUOI) + Number(receiveOrderDetailsData[j].OneTimeChrgOnRentPUOI) + Number(receiveOrderDetailsData[j].TaxPriceOnOneTimeChrgPUOI) + Number(TotalSubscriptionValPerProduct))).toFixed(2);
                }

                dataOrderDetailsArr.push([receiveOrderDetailsData[j].ProductLine, 
                    receiveOrderDetailsData[j].ProductName, 
                    receiveOrderDetailsData[j].ProductCode , 
                    receiveOrderDetailsData[j].QuantityOfUnitsOrdered, 
                    receiveOrderDetailsData[j].QuantityOfUnitsScanned, 
                    receiveOrderDetailsData[j].Status])

                dataOrderPriceDetailsArr.push([
                    receiveOrderDetailsData[j].PricePerUnitOrderINR, 
                    receiveOrderDetailsData[j].TaxPrcntPUO, 
                    receiveOrderDetailsData[j].TaxPerUnitOrderINR])
                    
                dataOrderPriceDetailsForRentalOrderArr.push([receiveOrderDetailsData[j].SecurityDepositOnRentPUOI, 
                    receiveOrderDetailsData[j].OneTimeChrgOnRentPUOI, 
                    receiveOrderDetailsData[j].TaxPrcntOnOneTimeChrgPUO, 
                    receiveOrderDetailsData[j].TaxPriceOnOneTimeChrgPUOI])
            
                dataOrderSubscriptionDetailsArr.push([ 
                    (receiveOrderDetailsData[j].SubscriptionPeriod == null ? 'NA' : Number(receiveOrderDetailsData[j].SubscriptionPeriod / DAYS_IN_MONTH)), 
                    subscriptionPricePerUnitINRtoShow, 
                    (receiveOrderDetailsData[j].SubscriptionTaxPrcntPUO == null ? 'NA' : receiveOrderDetailsData[j].SubscriptionTaxPrcntPUO), 
                    SubscriptionTaxPerUnitOrdertoShow,
                    selectedOrderItemsValue]);

                modifiedState.TotalQuantityOrdered += Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered);
                modifiedState.TotalQyantityFulfilled += Number(receiveOrderDetailsData[j].QuantityOfUnitsScanned);
                modifiedState.TotalPriceForPDF += (Number(receiveOrderDetailsData[j].PricePerUnitOrderINR) * Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered));
                modifiedState.TotalTaxForPDF += (Number(receiveOrderDetailsData[j].TaxPerUnitOrderINR) * Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered));
                modifiedState.TotalOrderValueForPDF += Number(selectedOrderItemsValue);
                modifiedState.TotalSecurityDepositPrice += (Number(receiveOrderDetailsData[j].SecurityDepositOnRentPUOI) * Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered));
                modifiedState.TotalOneTimeChargePrice += (Number(receiveOrderDetailsData[j].OneTimeChrgOnRentPUOI) * Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered));
                modifiedState.TotalOneTimeChargeTaxPrice += (Number(receiveOrderDetailsData[j].TaxPriceOnOneTimeChrgPUOI) * Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered));
                modifiedState.TotalSubscriptionPriceForPDF += ((receiveOrderDetailsData[j].SubscriptionPricePerUnitOrderINR == null || receiveOrderDetailsData[j].SubscriptionPricePerUnitOrderINR == 0) ? 0 : (Number(subscriptionPricePerUnitINRtoShow) * (receiveOrderDetailsData[j].SubscriptionPeriod == null ? 0 : Number(receiveOrderDetailsData[j].SubscriptionPeriod / DAYS_IN_MONTH)) * Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered)));
                modifiedState.TotalSubscriptionTaxForPDF += ((receiveOrderDetailsData[j].SubscriptionTaxPerUnitOrderINR == null || receiveOrderDetailsData[j].SubscriptionTaxPerUnitOrderINR == 0) ? 0 : (Number(SubscriptionTaxPerUnitOrdertoShow) * (receiveOrderDetailsData[j].SubscriptionPeriod == null ? 0 : Number(receiveOrderDetailsData[j].SubscriptionPeriod / DAYS_IN_MONTH))* Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered)));
            }
            
            let dataTotalValue = [[Number(modifiedState.TotalOrderValueForPDF).toFixed(2)]];
            let dataNetTotalValue = [[noOfLogs,
                                    Number(modifiedState.TotalQuantityOrdered),
                                    Number(modifiedState.TotalQyantityFulfilled),
                                    Number(modifiedState.TotalPriceForPDF).toFixed(2), 
                                    Number(modifiedState.TotalTaxForPDF).toFixed(2),
                                    Number(modifiedState.TotalSubscriptionPriceForPDF).toFixed(2),
                                    Number(modifiedState.TotalSubscriptionTaxForPDF).toFixed(2),
                                    Number(modifiedState.TotalOrderValueForPDF).toFixed(2)]];
            let dataNetTotalValueForRentalOrder = [[noOfLogs,
                                        Number(modifiedState.TotalQuantityOrdered),
                                        Number(modifiedState.TotalQyantityFulfilled),
                                        Number(modifiedState.TotalSecurityDepositPrice).toFixed(2), 
                                        Number(modifiedState.TotalOneTimeChargePrice).toFixed(2),
                                        Number(modifiedState.TotalOneTimeChargeTaxPrice).toFixed(2),
                                        Number(modifiedState.TotalSubscriptionPriceForPDF).toFixed(2),
                                        Number(modifiedState.TotalSubscriptionTaxForPDF).toFixed(2),
                                        Number(modifiedState.TotalOrderValueForPDF).toFixed(2)]];
                            
            setState({...modifiedState});
        
            let contentOrder = {
            head: headersOrder,
            body: dataOrder
            };

            let contentTotalValue = {
                head : headersTotalValue,
                body: dataTotalValue
            }

            let contentOrderComments = {
                head : headerOrderComments,
                body: dataOrderComments
            }

            let contentGroupingOrganization = {
                head: headerOrderGroupingOrganization,
                body: dataOrderGroupingOrganization
            }

            let contentCustomer = {
                head : headersCustomer,
                body: dataCustomer
            }

            let contentOrderDetails = {
                head: headersOrderDetails,
                body: dataOrderDetailsArr
            };

            let contentOrderPriceDetails = {
                head: headersOrderPriceDetails,
                body: dataOrderPriceDetailsArr
            };

            let contentOrderPriceDetailsForRentalOrder = {
                head: headersOrderPriceDetailsForRentalOrder,
                body: dataOrderPriceDetailsForRentalOrderArr
            };

            let contentOrderSubscriptionDetails = {
                head: (PDFOrderType != null && PDFOrderType.length > 0 && (PDFOrderType == ORDER_TYPE_DEMO ||PDFOrderType == ORDER_TYPE_SALE)) ? headersOrderSubscriptionPriceDetails : headersOrderSubscriptionPriceDetailsForRental,
                body: dataOrderSubscriptionDetailsArr
            };

            let contentNetTotalValue = {
                head : headersNetTotalValue,
                body: dataNetTotalValue
            }

            let contentNetTotalValueForRentalOrder = {
                head : headersNetTotalValueForRentalOrder,
                body: dataNetTotalValueForRentalOrder
            }
        
            doc.setFontSize(30);
            doc.text(260, 30, title);      
            doc.autoTable(contentOrder);
            doc.autoTable(contentTotalValue);
            doc.autoTable(contentOrderComments);
            doc.autoTable(contentGroupingOrganization);
            doc.autoTable(contentCustomer);
            doc.autoTable(contentOrderDetails);

            // diffrent columns are show for rental order
            if(PDFOrderType != null && PDFOrderType.length > 0 && (PDFOrderType == ORDER_TYPE_DEMO ||PDFOrderType == ORDER_TYPE_SALE)){
                doc.autoTable(contentOrderPriceDetails);
            } else {
                doc.autoTable(contentOrderPriceDetailsForRentalOrder);
            }

            doc.autoTable(contentOrderSubscriptionDetails);

            if(PDFOrderType != null && PDFOrderType.length > 0 && (PDFOrderType == ORDER_TYPE_DEMO || PDFOrderType == ORDER_TYPE_SALE)){
                doc.autoTable(contentNetTotalValue);
            } else {
                doc.autoTable(contentNetTotalValueForRentalOrder);
            }

            doc.save("OrderDetails.pdf")
        } else {

            // const title = "Order Details";
            let title = `Order No. ` + rowInfo.original.OrderNumber + ` and Details`;
            const headerTitle = [["Order Details"]];
            const headersOrder = [["Order No.", "Order Date", "Required Order Date", "Order Fulfilled Date", "Sales Representative", "Status"]];
            const headersTotalValue = [['Total Order Value (INR)']];
            const headerOrderComments = [['Order Comments']];
            const headerOrderGroupingOrganization =[['Order Grouping Organization Name']];

            const headersCustomer = [["Customer ID", "Customer Company/Org. Name", "Customer Phone No.", "", "Customer Name", "Customer Email"]];
            const headersOrderDetails1 = [["Product \nLine", "Product \nName", "Product \nCode", "Quantity \nof Unit \nOrdered", "Quantity \nof Unit \nFulfilled", 
                                        "Status"]];
            const headersOrderDetails2 = [["One Time Charge \nPer Order \n(INR)", "Package \nDuration \n(Month)", "API Hits Per \nPackage Duration", 
                                        "Total Order \nPrice \nWithout Tax"]];
            const headersOrderDetails3 = [["Discount % on \nTotal Order \nPrice", "Discount Amount on\nTotal Order \nPrice",
                                        "Total % on \nDiscounted \nAmount", "Total Price on \nDiscounted \nAmount", "Order \nItems \nValue"]];

            const headersNetTotalValue = [["No. of Order \nLine Items", "Total \nQuantity \nof Units \nOrdered", "Total \nQuantity \nof Units \nFulfilled", 
                                            "Total \nOne Time Charge \nPer Order \n(INR)", "Total Order \nPrice \nWithout Tax", "Total \nDiscount Amount on\nTotal Order \nPrice", 
                                            "Total Price on \nDiscounted \nAmount", "Total \nOrder \nValue"]];
            
            let dataOrder = [[rowInfo.original.OrderNumber, rowInfo.original.OrderDate, rowInfo.original.RequiredDate, 
                            rowInfo.original.FulfilledDate, rowInfo.original.SalesRepUserID, rowInfo.original.Status]];

            let dataOrderComments = [[rowInfo.original.Comments]];

            let dataOrderGroupingOrganization =[[(rowInfo.original.OrderOfOrganisation == null || rowInfo.original.OrderOfOrganisation.length <= 0) ? 'NA' : rowInfo.original.OrderOfOrganisation]];

            let dataCustomer = [[modifiedState.CustID, modifiedState.OrganisationName, 
                                modifiedState.CustPhoneNo, "                        ", 
                                modifiedState.CustName, modifiedState.CustEmailID]];

            modifiedState.TotalQuantityOrdered = 0;
            modifiedState.TotalQyantityFulfilled = 0;
            modifiedState.TotalApiOneTimeChargePriceForPdf = 0;
            modifiedState.TotalApiOrderPriceWithoutTax = 0;
            modifiedState.TotalApiDiscountPrice = 0;
            modifiedState.TotalApiDiscountedPrice = 0;
            modifiedState.TotalOrderValueForPDF = 0;
            let dataApiOrderDetailsArr1 = [];
            let dataApiOrderDetailsArr2 = [];
            let dataApiOrderDetailsArr3 = [];

            modifiedState.selectedOrderDetailsArrForApiOrder = [];

            for(let i=0; i< modifiedState.CustomerOrderDetailsForApiOrder.length; i++) {
                if(modifiedState.CustomerOrderDetailsForApiOrder[i]["OrderNumber"] == rowInfo.original.OrderNumber) {
                    modifiedState.selectedOrderDetailsArrForApiOrder.push(modifiedState.CustomerOrderDetailsForApiOrder[i]);
                }
            }

            const receiveOrderDetailsData = modifiedState.selectedOrderDetailsArrForApiOrder;
            let noOfLogs = receiveOrderDetailsData.length;

            for(let j=0; j<noOfLogs; j++){

                let DiscountedPrice = Number(receiveOrderDetailsData[j].PricePerUnitOrderINR) - Number(receiveOrderDetailsData[j].DisctPricePerPkg);
                let TaxOnDiscountedPrice = ((Number(DiscountedPrice)) * ((Number(receiveOrderDetailsData[j].TaxPrcntPUO)) / 100)).toFixed(2);
                let selectedOrderItemsValue = (Number(DiscountedPrice) + Number(TaxOnDiscountedPrice)); 

                dataApiOrderDetailsArr1.push([
                    receiveOrderDetailsData[j].ProductLine, 
                    receiveOrderDetailsData[j].ProductName, 
                    receiveOrderDetailsData[j].ProductCode, 
                    receiveOrderDetailsData[j].QuantityOfUnitsOrdered, 
                    receiveOrderDetailsData[j].QuantityOfUnitsScanned, 
                    receiveOrderDetailsData[j].Status, 
                ]);

                dataApiOrderDetailsArr2.push([
                    receiveOrderDetailsData[j].OneTimeChrgOnRentPUOI, 
                    receiveOrderDetailsData[j].PackageDuration / 30, 
                    receiveOrderDetailsData[j].ApiHitsInPkg, 
                    receiveOrderDetailsData[j].PricePerUnitOrderINR,  
                    selectedOrderItemsValue]);

                dataApiOrderDetailsArr3.push([
                    receiveOrderDetailsData[j].DisctPrcntPerPkg, 
                    receiveOrderDetailsData[j].DisctPricePerPkg, 
                    receiveOrderDetailsData[j].TaxPerUnitOrderINR, 
                    receiveOrderDetailsData[j].TaxPrcntPUO, 
                    selectedOrderItemsValue]);

                modifiedState.TotalQuantityOrdered += Number(receiveOrderDetailsData[j].QuantityOfUnitsOrdered);
                modifiedState.TotalQyantityFulfilled += Number(receiveOrderDetailsData[j].QuantityOfUnitsScanned);
                modifiedState.TotalApiOneTimeChargePriceForPdf += (Number(receiveOrderDetailsData[j].OneTimeChrgOnRentPUOI));
                modifiedState.TotalApiOrderPriceWithoutTax += (Number(receiveOrderDetailsData[j].PricePerUnitOrderINR));
                modifiedState.TotalOrderValueForPDF += Number(selectedOrderItemsValue);
                modifiedState.TotalApiDiscountPrice += (Number(receiveOrderDetailsData[j].DisctPricePerPkg));
                modifiedState.TotalApiDiscountedPrice += (Number(receiveOrderDetailsData[j].TaxPerUnitOrderINR));
            }

            let dataTotalValue = [[Number(modifiedState.TotalOrderValueForPDF).toFixed(2)]];
            let dataNetTotalValue = [[noOfLogs,
                                    Number(modifiedState.TotalQuantityOrdered),
                                    Number(modifiedState.TotalQyantityFulfilled),
                                    Number(modifiedState.TotalApiOneTimeChargePriceForPdf).toFixed(2), 
                                    Number(modifiedState.TotalApiOrderPriceWithoutTax).toFixed(2),
                                    Number(modifiedState.TotalApiDiscountPrice).toFixed(2),
                                    Number(modifiedState.TotalApiDiscountedPrice).toFixed(2),
                                    Number(modifiedState.TotalOrderValueForPDF).toFixed(2)]];

            setState({...modifiedState});

            // let contentTitle = {
            //     head: headerTitle,
            // }

            let contentOrder = {
            head: headersOrder,
            body: dataOrder
            };

            let contentTotalValue = {
                head : headersTotalValue,
                body: dataTotalValue
            }

            let contentOrderComments = {
                head : headerOrderComments,
                body: dataOrderComments
            }

            let contentGroupingOrganization = {
                head: headerOrderGroupingOrganization,
                body: dataOrderGroupingOrganization
            }

            let contentCustomer = {
                head : headersCustomer,
                body: dataCustomer
            }

            let contentOrderDetails1 = {
                head: headersOrderDetails1,
                body: dataApiOrderDetailsArr1
            };

            let contentOrderDetails2 = {
                head: headersOrderDetails2,
                body: dataApiOrderDetailsArr2
            };

            let contentOrderDetails3 = {
                head: headersOrderDetails3,
                body: dataApiOrderDetailsArr3
            };

            let contentNetTotalValue = {
                head : headersNetTotalValue,
                body: dataNetTotalValue
            }

            doc.setFontSize(30);
            doc.text(260, 30, title);

            doc.autoTable(contentOrder);
            doc.autoTable(contentTotalValue);
            doc.autoTable(contentOrderComments);
            doc.autoTable(contentGroupingOrganization);
            doc.autoTable(contentCustomer);
            doc.autoTable(contentOrderDetails1);
            doc.autoTable(contentOrderDetails2);
            doc.autoTable(contentOrderDetails3);
            doc.autoTable(contentNetTotalValue);
            doc.save("OrderDetails.pdf")
        }
    }

    const handleError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    const openQRCameraForItemID = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        let modifiedState = state;
        modifiedState.itemIDQRCodeFlag = !modifiedState.itemIDQRCodeFlag;
        modifiedState.ItemID = "";
        modifiedState.errors.errRelatedToAssigningState = "";
        modifiedState.errors.succsessRelatedToAssigningState = "";
        setState({...modifiedState});
    }

    const handleScanResultOfItemID = (data) => {
        if(data) {
            let modifiedState = state;
            modifiedState.ItemID = data;
            if((modifiedState.ItemID != null && modifiedState.ItemID.length > 0)) {
                modifiedState.itemIDQRCodeFlag = false;
                verifyEnteredDeviceID(modifiedState);
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            }
            setState({...modifiedState});
        }
    }

    const toggleFulfilledDetailsModal = () => {
        let modifiedState = state;
        modifiedState.showFulfilledDetailsModal = !modifiedState.showFulfilledDetailsModal;
        setState({...modifiedState});

    } 

    const onClickViewCustFullfiledDetails = (rowInfo, column) => {
        let modifiedState = state;

        let selectedRowOrderNumber = rowInfo["original"]["OrderNumber"];
        let selectedRowOrderStatus = rowInfo["original"]["Status"];
        let selectedRowOrderDetailsArr = [];
        let selectedRowOrderLineNumberArr = [];

        if (selectedRowOrderStatus == ORDER_STATE_MARKED_FOR_SHIPPING || selectedRowOrderStatus == ORDER_STATE_PROCESSING) {
            for(let i = 0; i < modifiedState.CustomerOrderDetails.length; i++) {
                if(modifiedState.CustomerOrderDetails[i]["OrderNumber"] == selectedRowOrderNumber) {
                    selectedRowOrderDetailsArr.push(modifiedState.CustomerOrderDetails[i]);
                    selectedRowOrderLineNumberArr.push(modifiedState.CustomerOrderDetails[i]["OrderLineNumber"])
                    
                }
            }
            modifiedState.selectedOrganisationName = rowInfo["original"]["OrderOfOrganisation"]

            getFulfilledDetails(modifiedState, selectedRowOrderDetailsArr, selectedRowOrderLineNumberArr);

        } else {
            return;
        }

    }

    const onClickViewSelectedOrderFullfiledDetails = (rowInfo, column) => {
        let modifiedState = state;

        let selectedRowOrderLineNumber = rowInfo["original"]["OrderLineNumber"];
        let selectedRowOrderStatus = rowInfo["original"]["Status"];
        let selectedRowOrderDetailsArr = [];
        let selectedRowOrderLineNumberArr = [];

        if (selectedRowOrderStatus == ORDER_STATE_MARKED_FOR_SHIPPING || selectedRowOrderStatus == ORDER_STATE_PROCESSING) {
            for(let i = 0; i < modifiedState.CustomerOrderDetails.length; i++) {
                if(modifiedState.CustomerOrderDetails[i]["OrderLineNumber"] == selectedRowOrderLineNumber) {
                    selectedRowOrderDetailsArr.push(modifiedState.CustomerOrderDetails[i]);
                    selectedRowOrderLineNumberArr.push(modifiedState.CustomerOrderDetails[i]["OrderLineNumber"])
                    
                }
            }

            getFulfilledDetails(modifiedState, selectedRowOrderDetailsArr, selectedRowOrderLineNumberArr);

        } else {
            return;
        }
    }
    
    const getFulfilledDetails = (inModifiedState = null, selectedRowOrderDetailsArr, selectedRowOrderLineNumberArr) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.custFulfilledDetailsTableData = [];

        if( selectedRowOrderLineNumberArr == null || selectedRowOrderLineNumberArr.length <= 0) {
            setState({...modifiedState});
            return
        } 

        // Create comma separated list of selectedRowOrderLineNumberArr.
        let strCommaSeperatedOrderLineNumber = "'" + selectedRowOrderLineNumberArr[0] + "'";   
        for(let i=1; i<selectedRowOrderLineNumberArr.length; i++) {
            strCommaSeperatedOrderLineNumber += "," + "'" + selectedRowOrderLineNumberArr[i] + "'";
        }
    
        let jsonBody = {
            SelectedRowOrderLineNumberArr: strCommaSeperatedOrderLineNumber,
        };

        axios.post(`${getAPIHostURL()}/wclient/getFulfilledDetails`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {

                if(response.data.retrieveFulfilledOrderDetails == null || response.data.retrieveFulfilledOrderDetails.length <= 0) {
                    // retrieveFulfilledOrderDetails Data not found on server.
                } else {
                    let stateCustFulfilledDetailsTableData = [];

                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing product line Details data.
                    stateCustFulfilledDetailsTableData = [...modifiedState.custFulfilledDetailsTableData]
                    let fullfilledOrderOrganisationName = "NA";

                    for(let k=0; k < modifiedState.AllOrganisationInformation.length; k++){

                        if((props.propAction != "CreateOrder" && modifiedState.AllOrganisationInformation[k]['OrgID'] == modifiedState.selectedOrganisationName) || 
                            (props.propAction == "CreateOrder" && modifiedState.AllOrganisationInformation[k]['OrgName'] == modifiedState.selectedOrganisationName)) 
                        {
                            fullfilledOrderOrganisationName = modifiedState.AllOrganisationInformation[k]['OrgName']
                        }
                    }
                                            
                    const retrieveFulfilledOrderDetails = response.data.retrieveFulfilledOrderDetails;
                    let retrievedRelaceDevcId = response.data.retrievedRelaceDevcId;

                    let fulfilledOrderDetailsWithItemID = [];
                    for(let i = 0; i < retrieveFulfilledOrderDetails.length; i++) {

                        let replacedDevcID = null;

                        let fileteredArrBasedOnItemId = retrievedRelaceDevcId != null && retrievedRelaceDevcId.length > 0 ? retrievedRelaceDevcId.filter((singleReplceDevc) => singleReplceDevc.DeviceID == retrieveFulfilledOrderDetails[i]["ItemID"]) : [];

                        if(fileteredArrBasedOnItemId != null && fileteredArrBasedOnItemId.length > 0) {
                            let DeviceContinuityID = fileteredArrBasedOnItemId[0]["DeviceContinuityID"];

                            let fileteredArrBasedOnDeviceContinutyID = 
                                retrievedRelaceDevcId.filter((singleReplceDevc) => singleReplceDevc.DeviceContinuityID == DeviceContinuityID);

                            if(fileteredArrBasedOnDeviceContinutyID != null && fileteredArrBasedOnDeviceContinutyID.length > 0) {
                                let maxReplacementSeqNo = Math.max.apply(null, fileteredArrBasedOnDeviceContinutyID.map(singleReplceDevc => singleReplceDevc.ReplacementSeqNo));

                                let fileteredArrBasedOnMaxReplacementSeqNo = 
                                    fileteredArrBasedOnDeviceContinutyID.filter((singleFilteredArrBasedOnContinuityID) => singleFilteredArrBasedOnContinuityID.ReplacementSeqNo == maxReplacementSeqNo);

                                if(fileteredArrBasedOnMaxReplacementSeqNo != null && fileteredArrBasedOnMaxReplacementSeqNo.length > 0) {
                                    replacedDevcID = fileteredArrBasedOnMaxReplacementSeqNo[0]["DeviceID"];
                                }
                            }
                        }

                        for(let j = 0; j < selectedRowOrderDetailsArr.length; j++) {
                            if(retrieveFulfilledOrderDetails[i].AdditionalID == selectedRowOrderDetailsArr[j].OrderLineNumber) {
                                fulfilledOrderDetailsWithItemID.push({...retrieveFulfilledOrderDetails[i], ...selectedRowOrderDetailsArr[j]});
                                break;
                            }
                        }
                        
                        const SingleFulfilledOrderDetailsWithItemID = fulfilledOrderDetailsWithItemID[i];
                        let strLocaleFulfilledOrderDtTm = SingleFulfilledOrderDetailsWithItemID.FulFilledOrderDtTm == null || SingleFulfilledOrderDetailsWithItemID.FulFilledOrderDtTm.length <= 0 ? "-" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(SingleFulfilledOrderDetailsWithItemID.FulFilledOrderDtTm);

                        let singleFulFilledDetailsToShow = {
                            FulFilledItemID: SingleFulfilledOrderDetailsWithItemID.ItemID == null || SingleFulfilledOrderDetailsWithItemID.ItemID.length <= 0  ? "-" : SingleFulfilledOrderDetailsWithItemID.ItemID,
                            FulFilledReplacedDevcID: replacedDevcID,
                            FulFilledOrderLineNumber: SingleFulfilledOrderDetailsWithItemID.OrderLineNumber == null || SingleFulfilledOrderDetailsWithItemID.OrderLineNumber.length <= 0  ? "-" : SingleFulfilledOrderDetailsWithItemID.OrderLineNumber,
                            FulFilledOrderNumber: SingleFulfilledOrderDetailsWithItemID.OrderNumber == null || SingleFulfilledOrderDetailsWithItemID.OrderNumber.length <= 0  ? "-" : SingleFulfilledOrderDetailsWithItemID.OrderNumber,
                            FulFilledOrderProductLine: SingleFulfilledOrderDetailsWithItemID.ProductLine == null || SingleFulfilledOrderDetailsWithItemID.ProductLine.length <= 0  ? "-" : SingleFulfilledOrderDetailsWithItemID.ProductLine,
                            FulFilledOrderProductName: SingleFulfilledOrderDetailsWithItemID.ProductName == null || SingleFulfilledOrderDetailsWithItemID.ProductName.length <= 0  ? "-" : SingleFulfilledOrderDetailsWithItemID.ProductName,
                            FulFilledOrderProductCode: SingleFulfilledOrderDetailsWithItemID.ProductCode == null || SingleFulfilledOrderDetailsWithItemID.ProductCode.length <= 0  ? "-" : SingleFulfilledOrderDetailsWithItemID.ProductCode,
                            FulFilledOrderStatus: SingleFulfilledOrderDetailsWithItemID.Status == null || SingleFulfilledOrderDetailsWithItemID.Status.length <= 0  ? "-" : SingleFulfilledOrderDetailsWithItemID.Status,
                            FulFilledOrderDtTm: strLocaleFulfilledOrderDtTm,
                            OrderOfOrganisation: fullfilledOrderOrganisationName
                        };
                        stateCustFulfilledDetailsTableData.push(singleFulFilledDetailsToShow);
                    } 
                    
                    modifiedState.showFulfilledDetailsModal = !modifiedState.showFulfilledDetailsModal;
                    modifiedState.custFulfilledDetailsTableData = stateCustFulfilledDetailsTableData;
                    
                }
            } else {
                if(response.data.code == 'REQ_PARAM_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Params not received).\n Try again later.';
        
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
    }

    const onChangeOrganisationSelection = (e) => {
        let modifiedState = state;
        modifiedState.selectedOrganisationName = e.target.value;
        setState({...modifiedState});
    }

    const switchToCreateEditApiOrder = (e) => {
        let modifiedState = state;
        modifiedState.createEditOrder = false;    
        modifiedState.page = 0;
        modifiedState.selectedRowIndex = null;
        modifiedState.CustSelectedOrderDetails = [];
        modifiedState.CustSelectedOrderDetailsForApiOrder = [];
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.switchOrderMenus = false;

        setState({...modifiedState});
    }

    const switchToCreateEditOrder = (e) => {
        let modifiedState = state;
        modifiedState.createEditOrder = true;   
        modifiedState.page = 0;
        modifiedState.selectedRowIndex = null;
        modifiedState.CustSelectedOrderDetails = [];
        modifiedState.CustSelectedOrderDetailsForApiOrder = [];
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.switchOrderMenus = false;
   
        setState({...modifiedState});
    }
    
    const switchToCreateFullfillApiOrder = (e) => {
        let modifiedState = state;
        modifiedState.createEditOrder = false;    
        modifiedState.page = 0;
        modifiedState.selectedRowIndex = null;
        modifiedState.CustSelectedOrderDetails = []; 
        modifiedState.CustSelectedOrderDetailsForApiOrder = [];
        modifiedState.enableFullfillOrder = false;
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.arrProductItemState = [];
        modifiedState.switchOrderMenus = false;
        modifiedState.disableOrderMenus = true;
 
        setState({...modifiedState});
    }

    const switchToCreateFullfillOrder = (e) => {
        let modifiedState = state;
        modifiedState.createEditOrder = true;  
        modifiedState.page = 0;
        modifiedState.selectedRowIndex = null;
        modifiedState.CustSelectedOrderDetails = [];
        modifiedState.CustSelectedOrderDetailsForApiOrder = [];
        modifiedState.enableFullfillOrder = false;
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.arrProductItemState = [];
        modifiedState.switchOrderMenus = false;
        modifiedState.disableOrderMenus = false;
        modifiedState.disabledBtnOfFullFillOrder = false;
    
        setState({...modifiedState});
    }

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    return (
        <div>
            <div className="modal-header" style={{padding:"0.5rem 0.5rem"}}>
                <h5 className="modal-title"> 
                    {props.propAction == "CreateOrder" && state.createEditOrder ? "Create/Edit Order" : 
                    props.propAction == "CreateOrder" && !state.createEditOrder ? "Create/Edit Api Order" :
                    props.propAction != "CreateOrder" && state.createEditOrder ? "Fulfill Order" : "Fulfill Api Order"} 
                </h5>
                
                { 
                state.createEditOrder && props.propAction == "CreateOrder" ?
                    <div/>
                    // HMM Api feature is disabled.
                    // For enabling the api feature please delete the current vcHhmApis.js page 
                    // and rename the current VcHhmAPIsDisabledFeature.js page as vcHhmApis.js.
                    // for enabling the Renew Api feature please enable the renewHHmApiPkg NavLink from VcNavBar.js page 
                    // and enable the route VcRenewHhmApiPkg from VcCrmHome.js page.
                    // <button className="createOrderTopButton" onClick ={switchToCreateEditApiOrder} type="button" style = {{marginLeft:"40rem"}}>
                    //     <span aria-hidden="true">Switch to Create/Edit Api Order</span>
                    // </button>
                    
                : 
                !state.createEditOrder && props.propAction == "CreateOrder" ?
                    <button className="createOrderTopButton" onClick ={switchToCreateEditOrder} type="button" style = {{marginLeft:"40rem"}}>
                        <span aria-hidden="true">Switch to Create/Edit Order</span>
                    </button>
                : state.createEditOrder && props.propAction != "CreateOrder" ? 
                    <div/>
                    // HMM Api feature is disabled.
                    // <button className="createOrderTopButton" onClick ={switchToCreateFullfillApiOrder} type="button" style = {{marginLeft:"40rem"}}>
                    //     <span aria-hidden="true">Switch to Fullfill Api Order</span>
                    // </button>
                :
                    <button className="createOrderTopButton" onClick ={switchToCreateFullfillOrder} type="button" style = {{marginLeft:"40rem"}}>
                        <span aria-hidden="true">Switch to Fullfill Order</span>
                    </button>
                }
        
                <button type="button" onClick ={toggleCustomerOrder}className="close" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>

            </div>
            {/* <div style={{border: "1px solid black", textAlign:"center"}}> */}
            <div style={{textAlign:"center"}}>
                <div className = "CustomerTableHeading" style ={{ fontSize:"1.5rem"}}>
                        Customer Details
                </div>
                <div className="border border-3 ReactTableStyle">
                   <ReactTable
                        data={state.data}
                        columns={state.columns}
                        renderBasedOnTable = {"CustomerDetailsDataTable1"}
                        getCellProps = {(cellInfo) => ({})}
                        // as React Table is outsdde the main function, state varaibles cant be accessed there 
                        // so passing all the state variables on which react table is dependent through passedStateVariable
                        passedStateVariable = {[state.custFulfilledDetailsTableData, props.propAction, state.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi,
                            state.showEditAndQuantityOfUnitsScannedwhenRental, state.showEditAndQuantityOfUnitsScannedwhenApi,
                            state.createEditOrder, state.selectedRowIndex, state.loading, state.OrderType]}
                    />
                </div>
            </div>    

            {/* <div style={{marginTop:"2rem", border: "1px solid black", textAlign:"center"}}> */}
            <div style={{marginTop:"2rem", textAlign:"center"}}>
                <div style={{display:"flex"}}>
                    <div style={{display: props.propAction == "CreateOrder" ? "" : "none", fontSize:"1.5rem", flex:"1"}}>
                    </div>
                    <div className = "CustomerTableHeading" style={{fontSize:"1.5rem", flex:"4"}}>
                        { props.propAction == "CreateOrder" ? "Customer Orders" : "Select Customer Order for Fulfillment"}
                    </div>
                    <div style={{display: props.propAction == "CreateOrder" ? "" : "none", fontSize:"1.5rem", flex:"1", paddingBottom: "0.7rem", paddingTop: "0.5rem"}}>
                        {state.createEditOrder ?
                            <button className="createOrderTopButton" btn_id = {"createOrderBtn"} type="button" onClick={toggleSelectOrderDetails}>Create New Order</button>
                            :
                            <button className="createOrderTopButton" btn_id = {"createOrderBtn"} type="button" onClick={toggleSelectOrderDetails}>Create New Api Order</button>
                        }
                    </div>
                </div>
                {/* <div style ={{borderTop:" 1px solid rgba(0,0,0,.1)"}}> */}
                <div className="border border-3 ReactTableStyle">
                    <ReactTable
                        data={state.createEditOrder ? state.CustAllOrderData : state.CustAllApiOrderData}
                        columns={state.createEditOrder ? state.CustAllOrdercolumns : state.CustAllApiOrdercolumns}
                        renderBasedOnTable = {"CustAllOrderTable"}
                        getCellProps = {getSelectedTrProps}
                        // as React Table is outsdde the main function, state varaibles cant be accessed there 
                        // so passing all the state variables on which react table is dependent through passedStateVariable
                        passedStateVariable = {[state.custFulfilledDetailsTableData, props.propAction, state.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi,
                            state.showEditAndQuantityOfUnitsScannedwhenRental, state.showEditAndQuantityOfUnitsScannedwhenApi,
                            state.createEditOrder, state.selectedRowIndex, state.loading, state.OrderType]}
                    />
                </div>

                {state.createEditOrder
                ?
                    <div> 
                        {state.errors.others.length > 0 && 
                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop:"0.7rem"}} className='error'>{state.errors.others}</p> }       

                    </div>
                :
                    <div> 
                        {state.errors.errApiOrder.length > 0 && 
                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop:"0.7rem"}} className='error'>{state.errors.errApiOrder}</p> }       
                    </div>
                }

            </div>
            <div>
                {state.createEditOrder 
                ?
                    <div className ="createOrdermobile" style={{marginTop:"1rem"}}>
                        <div style={{display: props.propAction == "FulFillOrder" ? "block" : "none"}}
                            className = {state.arrProductItemState.length > 0 ? "RightAndCenterAlignedButton" : "CenterAlignedButton"}
                        >
                            {state.createEditOrder && !state.disableOrderMenus
                            ?
                                <button type="button"
                                        className="btn-md AddOrderDetailButton"
                                        onClick={onFullfillOrderNew}
                                        style={{
                                                backgroundColor: state.disabledBtnOfFullFillOrder && "#F5F5F5", 
                                                color: state.disabledBtnOfFullFillOrder && "gray",
                                                width: state.arrProductItemState.length > 0 ? "50%" : "25%"
                                                

                                            }}                            
                                        disabled={state.disabledBtnOfFullFillOrder}
                                > {state.disabledBtnOfFullFillOrder ? "Required Product Items scanned" : "Fulfill Order"}
                                </button>
                            :
                                <div/>
                            }
                        </div>

                        {state.arrProductItemState.length > 0 &&
                            <div className = {state.arrProductItemState.length > 0 ? "LeftAndCenterAlignedButton" : "CenterAlignedButton"}>
                                <button type="button"
                                className="btn-md AddOrderDetailButton"
                                        style={{ 
                                            width: state.arrProductItemState.length > 0 ? "50%" : "25%",
                                        }}
                                        onClick={onSaveFullFillOrder} 
                                >
                                    <b>Save</b>
                                </button>
                                {state.errors.others.length > 0 && 
                                    <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop:"0.7rem"}} className='error'>{state.errors.others}</p> }       

                            </div>
                        }
                    </div>
                :
                    <div/>
                }
                
                {state.createEditOrder ?
                    <div className="box" style={{ display: (state.enableFullfillOrder) ? "block": "none", marginTop:"1rem", padding:"0.5rem", boxSizing:"border-box", boxShadow: "0 0.3rem 0.7rem var(--primaryColor)"}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="container col-lg-8 col-lg-offset-2
                                                        col-md-12">
                                    <div style={{ display: (state.disabledBtnOfFullFillOrder) ? "none": "block"}}>
                                        <label className="reg-form-label" 
                                            style={{width:"90%"}}
                                        >
                                            Scan QR Code for Serial No / Device ID of the Product Item or Type the same. <br/>Hit Enter key when scan finished:
                                        </label>
                                        <input style={{width:"90%"}}
                                            className="input-form"
                                            value= {state.ItemID}
                                            onChange = {onChangeItemID}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <label onClick= {openQRCameraForItemID} className = "qrcodeTextBtn">
                                            <FaQrcode className = "qrcodeTextBtnIcon"/>
                                        </label>
                                        <button type="button"
                                            className="btn-md ordEnterButton"
                                                    style={{ height: "2.3rem", marginLeft: "0.5rem"}}
                                                    onClick={VerifyDeviceOnSave} 
                                            >
                                                <AiOutlineEnter style={{fontWeight: "bolder", paddingRight: "0.1rem"}}/>
                                        </button>
                                        <div>
                                            {(state.itemIDQRCodeFlag == true)
                                            ?
                                                // <div style={{display: "flex", justifyContent: "center"}}>
                                                //     <QrReader
                                                //         scanDelay={300}
                                                //         onResult={(result, error) => {
                                                //             if (!!result) {
                                                //                 handleScanResultOfItemID(result?.text);
                                                //             }
                                                //         }}
                                                //         className= "QRCodeCamBox"
                                                //     />
                                                // </div>
                                                <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                                    
                                                    <QrScanner
                                                        scanDelay={300}
                                                        onResult={(result, error) => {
                                                            if (!!result) {
                                                                handleScanResultOfItemID(result?.text);
                                                            }
                                                        }}
                                                        className= "QRCodeCamBox"
                                                    />
                                                </div>
                                            :
                                                <div/>
                                            }
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        {state.errors.errRelatedToAssigningState.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop:"0.7rem"}} className='error'>{state.errors.errRelatedToAssigningState}</p> }       

                        {state.errors.succsessRelatedToAssigningState.length > 0 && 
                                        <p style={{color:"green", fontSize:"0.9rem", textAlign:"center", marginTop:"0.7rem"}} className='error'>{state.errors.succsessRelatedToAssigningState}</p> }       

                    </div>
                :
                    <div/>
                }

                {state.switchOrderMenus
                ?
                    <div className="box" style={{ display: (state.disabledBtnOfFullFillOrder) ? "block": "none", padding:"0.2rem", boxSizing:"border-box", boxShadow: "0 0.3rem 0.7rem var(--primaryColor)"}}>
                        <p style={{color:"green", fontSize:"1rem", textAlign:"center", marginTop:"0.5rem"}} className='error'>
                        {props.propAction == "FulFillOrder" ? 
                            "All product items required for fulfilling the current Order have  been scanned Successfully." : 
                            "The selected Order has been Fulfilled successfully."
                        } <br/>
                            {state.arrProductItemState.length > 0 && "Please Click 'Save' to finalize."}
                        </p>   
                    </div>
                :
                    <div/>
                }

                {/* <div style={{marginTop:"1rem", border: "1px solid black", textAlign:"center"}} hidden={state.CustSelectedOrderDetails != null && state.CustSelectedOrderDetails.length > 0 && state.createEditOrder ? false :
                                                                                                        state.CustSelectedOrderDetailsForApiOrder != null && state.CustSelectedOrderDetailsForApiOrder.length > 0 && !state.createEditOrder ? false : true}> */}
                <div style={{marginTop:"1rem", textAlign:"center"}} hidden={state.CustSelectedOrderDetails != null && state.CustSelectedOrderDetails.length > 0 && state.createEditOrder ? false :
                    state.CustSelectedOrderDetailsForApiOrder != null && state.CustSelectedOrderDetailsForApiOrder.length > 0 && !state.createEditOrder ? false : true}>
                    {/* <div className = "CustomerTableHeading" style={{fontSize:"1.5rem", borderBottom:" 1px solid rgba(0,0,0,.1)"}}> */}
                    <div className = "CustomerTableHeading" style={{fontSize:"1.5rem"}}>
                            Selected Order Details
                    </div>
                    <div className="border border-3 ReactTableStyle">
                        <ReactTable
                            data={state.createEditOrder ? state.CustSelectedOrderDetails : state.CustSelectedOrderDetailsForApiOrder}
                            columns={state.createEditOrder && state.OrderType != null && state.OrderType.length > 0 && state.OrderType == ORDER_TYPE_RENTAL ? state.CustSelectedOrderDetailsColumnsForRentalOrder : 
                                state.createEditOrder && state.OrderType != null && state.OrderType.length > 0 && state.OrderType != ORDER_TYPE_RENTAL ? state.CustSelectedOrderDetailsColumns : state.CustSelectedOrderDetailsColumnsForApiOrder}
                            renderBasedOnTable = {"CustSelectedOrderDetailsTable"}
                            getCellProps={(cellInfo) => {
                                return {
                                    onClick: () => {
                                        if(cellInfo.column.id == "viewFullFiledDetails") {
                                            onClickViewSelectedOrderFullfiledDetails(cellInfo.row, cellInfo.column)     
                                        }         
                                    },
                                }}
                            }
                            // as React Table is outsdde the main function, state varaibles cant be accessed there 
                            // so passing all the state variables on which react table is dependent through passedStateVariable
                            passedStateVariable = {[state.custFulfilledDetailsTableData, props.propAction, state.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi,
                                state.showEditAndQuantityOfUnitsScannedwhenRental, state.showEditAndQuantityOfUnitsScannedwhenApi,
                                state.createEditOrder, state.selectedRowIndex, state.loading, state.OrderType]}
                        />
                    </div>
                </div>
                
            </div>
            <Modal size="xl" isOpen={state.bEditOrder} toggle={toggleSelectOrderDetails} backdrop={state.backdrop}>
                <ModalHeader toggle={toggleSelectOrderDetails}> {state.formViewMode =="editMode" ? "Edit Order" : "New Order"}</ModalHeader>
                <ModalBody>
                    <div >
                        {/* <div  style={{border: "1px solid rgba(0,0,0,.1)"}}> */}
                        <div className="border border-3 ReactTableStyle">
                            <ReactTable
                                data={state.data}
                                columns={state.columns}
                                renderBasedOnTable = {"CustomerDetailsDataTable2"}
                                getCellProps = {(cellInfo) => ({})}
                                // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                // so passing all the state variables on which react table is dependent through passedStateVariable
                                passedStateVariable = {[state.custFulfilledDetailsTableData, props.propAction, state.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi,
                                    state.showEditAndQuantityOfUnitsScannedwhenRental, state.showEditAndQuantityOfUnitsScannedwhenApi,
                                    state.createEditOrder, state.selectedRowIndex, state.loading, state.OrderType]}
                            />
                        </div>

                        <div className="createOrdermobile">
                            <div>
                                <span style={{fontSize: "1rem"}}>Order Date:</span>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeOrderTime}
                                        format={"yyyy/MM/dd HH:mm:ss"} 
                                        value={state.OrderDateTime} 
                                        // className="input-form-datetime"
                                        disabled = {state.formViewMode == "editMode" ? true : false}
                                        name="OrderDateTime"
                                    />
                            </div>

                            <div>
                                <span style={{fontSize: "1rem"}}>Required Date:</span>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeRequireOrderTime}
                                        onCalendarClose={onRequireOrderDtTmCalendarOrClockClose}
                                        onClockClose={onRequireOrderDtTmCalendarOrClockClose}
                                        format={"yyyy/MM/dd HH:mm:ss"} 
                                        // className="input-form-datetime"
                                        value={state.RequireOrderTime} 
                                        name="RequireOrderTime"
                                    />
                            </div>
                        </div>

                        <div className="createOrdermobile">
                            <div className="createOrdermobile1" style={{marginLeft:"-4.5rem"}}>
                                <div style={{width:"80%", fontSize: "0.97rem"}}>Grouping Organization Name:</div>
                                {state.Status != null && state.Status != "Placed" && state.formViewMode == "editMode" ?
                                    <input className="input-form" style={{height:"2rem", marginLeft:"1rem"}} value={state.selectedOrganisationName} disabled/>
                                    :
                                    <select className="input-form" 
                                        style={{height:"1.8rem", marginLeft:"0.5rem"}}
                                        value={state.selectedOrganisationName}
                                        onChange={onChangeOrganisationSelection}
                                    >
                                        <option value="" disabled={(state.Status != null && state.Status == "Placed" && state.formViewMode == "editMode" && state.selectedOrganisationName != null && state.selectedOrganisationName.length > 0) ? false : true} select="true">{(state.Status != null && state.Status == "Placed" && state.formViewMode == "editMode" && state.selectedOrganisationName != null && state.selectedOrganisationName.length > 0) ? "Clear Organization..." : "Select Organization..."}</option>
                                        {(state.AllOrganisationInformation).map((singleProductLine,index) => <option key={index} value={singleProductLine['OrgID']}>{singleProductLine["OrgName"]}</option>)}
                                    </select>
                                }

                            </div>

                            <div className="createOrdermobile1" >
                                <div className="createOrdermobile1"
                                    style = {{width:"80%",fontSize: "1rem"}}
                                    >
                                        Select Order Type:
                                </div>
                                <div style={{marginLeft:"1%"}}>
                                    {(state.formViewMode == "editMode" || (state.productdata != null && state.productdata.length > 0) && state.createEditOrder)
                                    ? 
                                        <input className="input-form" style={{width:"130%",height:"2rem", marginRight:"-5rem"}} value={state.selectedOrderType} disabled/>
                                    :
                                    (state.formViewMode == "editMode" || (state.productdata != null && state.productdata.length > 0) && !state.createEditOrder)
                                    ?
                                        <input className="input-form" style={{width:"130%",height:"2rem", marginRight:"-5rem"}} value={state.orderTypeApi} disabled/>
                                    :
                                    !state.createEditOrder ?
                                        <input className="input-form" style={{width:"130%",height:"2rem", marginRight:"-5rem"}} value={state.orderTypeApi} disabled/>
                                    :
                                    <select className="input-formt"
                                            style={{width:"130%", background:"white", height:"1.8rem"}} 
                                            required
                                            value={state.selectedOrderType}
                                            onChange={setOrderState}
                                    >
                                        <option value="" select= "true" disabled>Order Type...</option> 
                                        {(state.ArrForOrderType).map((singleOrderType,index) => <option key={index} value={singleOrderType}>{singleOrderType}</option>)}
                                    </select>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="inputgroupCustom" style={{paddingBottom:"0.8rem"}}>
                            <label className="reg-form-label">Comment:</label>
                            <textarea type='text' className="LabelComment" value={state.Comment} onChange={onEnteredNewComment}/>  
                        </div>

                        <div style={{textAlign: "center"}}>
                            <button type="button"
                                className="btn-md AddOrderDetailButton"
                                onClick={toggleSelectCustomerDetails}>{state.createEditOrder ? "Add Order Details" : "Add Api Order Details"}
                            </button>
                        </div>

                        <div className = "buttonErrorMessage" style={{textAlign: "center"}}>
                            {state.errors.isOrderTypeSelected.length > 0 && 
                                <p style={{color: 'var(--errorColor)', fontSize: '0.8rem', textAlign: "center"}} className='error'>{state.errors.isOrderTypeSelected}</p>}
                        </div>

                        {/* <div style={{marginTop:"1rem", border:" 1px solid rgba(0,0,0,.1)"}} hidden={state.createEditOrder && state.selectedOrderType != null && state.selectedOrderType.length > 0 ? false :
                                                                !state.createEditOrder && state.orderTypeApi != null && state.orderTypeApi.length && state.orderTypeApi == ORDER_TYPE_API ? false : true}> */}
                        <div style={{marginTop:"1rem"}} hidden={state.createEditOrder && state.selectedOrderType != null && state.selectedOrderType.length > 0 ? false :
                                                                !state.createEditOrder && state.orderTypeApi != null && state.orderTypeApi.length && state.orderTypeApi == ORDER_TYPE_API ? false : true}>
                            <div className="border border-3 ReactTableStyle">
                                <ReactTable
                                    data={state.productdata}
                                    columns={state.createEditOrder && state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType == ORDER_TYPE_RENTAL ? state.rentalOrderColumns : 
                                        state.createEditOrder && state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType != ORDER_TYPE_RENTAL ? state.productColumns : state.productColumnsForApiOrder}
                                    renderBasedOnTable = {"productdataTable"}
                                    getCellProps={(cellInfo) => {
                                        return {
                                            onClick: () => {
                                                if(cellInfo.column.id == "edit") {
                                                    onEditOrderDetails(cellInfo.row, cellInfo.column)     
                                                }         
                                            },
                                        }}
                                    }
                                    // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                    // so passing all the state variables on which react table is dependent through passedStateVariable
                                    passedStateVariable = {[state.custFulfilledDetailsTableData, props.propAction, state.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi,
                                        state.showEditAndQuantityOfUnitsScannedwhenRental, state.showEditAndQuantityOfUnitsScannedwhenApi,
                                        state.createEditOrder, state.selectedRowIndex, state.loading, state.OrderType]}
                                />
                            </div>
                        </div>

                        {state.createEditOrder 
                        ?
                        <Modal size="xl" show-close="false" isOpen={state.selectCustomerDetails} toggle={toggleSelectCustomerDetails} backdrop={state.backdrop}>
                            <ModalHeader toggle={toggleSelectCustomerDetails}>{`Add ${state.selectedOrderType} Order Details`}</ModalHeader>
                            <ModalBody>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="container col-lg-6 col-lg-offset-3
                                                                col-md-8 col-md-offset-2">
                                            <div className="modal-body box">
                                                <form>
                                                    <div>
                                                        <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>
                                                    </div>
                                                    <div>
                                                        <label className="reg-form-label" style={{width:"100%"}}>Product Line:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <div>
                                                            {state.formViewMode == "editMode" && state.SelectedCustNewOrderDetails == false
                                                                ? 
                                                                    <input className="input-form" style={{height:"2.5rem", marginBottom: "1rem"}} value={state.selectedProductLine} disabled/>
                                                                : 
                                                                    <select  className="input-form" 
                                                                                style={{height:"2.5rem", marginBottom: "1rem"}}
                                                                                value={state.selectedProductLine}
                                                                                onChange={onChangeProductLineSelection}
                                                                    >
                                                                        <option value="" disabled select="true">Select...</option>
                                                                        {(state.ProductLine).map((singleProductLine,index) => <option key={index} value={singleProductLine}>{singleProductLine}</option>)}
                                                                    </select>
                                                            }    
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label className="reg-form-label" style={{width:"100%"}}>Product Name:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <div>
                                                            {state.formViewMode == "editMode" && state.SelectedCustNewOrderDetails == false
                                                                ? 
                                                                    <input className="input-form" style={{height:"2.5rem", marginBottom: "1rem"}} value={state.selectedProductName} disabled/>
                                                                : 
                                                                    <select  className="input-form" 
                                                                                style={{height:"2.5rem", marginBottom: "1rem"}}
                                                                                value={state.selectedProductName}
                                                                                onChange={onChangeProductNameSelection}
                                                                    >
                                                                        <option value="" disabled select="true">Select...</option>
                                                                        {(state.ProductName).map((singleProductName,index) => <option key={index} value={singleProductName}>{singleProductName}</option>)}
                                                                    </select>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label className="reg-form-label">Product Code:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.ProductCode} disabled/>  
                                                    </div>

                                                    { state.formViewMode == "editMode" && state.SelectedCustNewOrderDetails == false ? 
                                                        <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}}>
                                                            <label className="reg-form-label">Quantity Of Unit Scanned:</label>
                                                            <input type='number' className="input-form" required="required" value={state.QuantityOfUnitsScanned} disabled/>  
                                                        </div>
                                                    :
                                                        <div/>
                                                    }

                                                    <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}}>
                                                        <label className="reg-form-label">Quantity Of Unit Ordered:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.newEnteredQuantityOfUnit} onChange={onEnteredQuantityOfUnitOrder}/>  
                                                    </div>

                                                    {/* there are diffrent text fields for Rental order 
                                                        here for sale and demo order, Rental related text fields are hidden
                                                        for rental related order, sale and demo order text fields are hidden */}

                                                    <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem", marginBottom: "0.5rem"}} hidden={state.selectedOrderType != null && 
                                                        state.selectedOrderType.length > 0 && state.selectedOrderType == ORDER_TYPE_RENTAL ? true : false}>
                                                        <label className="reg-form-label">Price Per Unit Order (INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.PricePerUnitINR} onChange={onEnterPricePerUnitINR}/>  
                                                    </div> 

                                                    <div style={{border:"1px solid var(--primaryColor)", paddingLeft:"0.5rem", paddingRight:"0.5rem", borderRadius:"0.5rem", background:"#f8f6f6", paddingBottom:"0.8rem", marginBottom: "1rem"}} 
                                                        hidden={state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType == ORDER_TYPE_RENTAL ? true : false}>
                                                        <label className="reg-form-label" style={{marginTop:"0.5rem", width:"100%",marginBottom:"0rem", textAlign: "center"}}>Tax on Price Per Unit Order</label>
                                                        <div className="trackFrmLblAndInputDiv">
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "0.3rem"}}>Enter Tax %:<span className="addCustRequiredMarkStar">*</span></label>
                                                                <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "3.5rem"}}>Tax Price(INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                            </div>   
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <input type='number' className="input-form" required="required" value={state.TaxPercentagePerUnit} onChange={onEnterTaxPercentagePerUnit}/>
                                                                <input type='number' className="input-form" required="required" readOnly style={{marginLeft: "1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}} value={state.TaxPerUnitINR} /> 
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}} hidden={state.selectedOrderType != null && 
                                                        state.selectedOrderType.length > 0 && (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO) ? true : false}>
                                                        <label className="reg-form-label">Security Deposit Per Unit Order (INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.securityDepositPrice} onChange={onEnterSecurityDepositPricePerUnitINR}/>  
                                                    </div>

                                                    <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem", marginBottom:"0.5rem"}} hidden={state.selectedOrderType != null && 
                                                        state.selectedOrderType.length > 0 && (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO) ? true : false}>
                                                        <label className="reg-form-label">One Time Charge Per Unit Order (INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.oneTimeChargePrice} onChange={onEnterOneTimeChargePricePerUnitOrder }/>  
                                                    </div>

                                                    <div style={{border:"1px solid var(--primaryColor)", paddingLeft:"0.5rem", paddingRight:"0.5rem", borderRadius:"0.5rem", background:"#f8f6f6", paddingBottom:"0.8rem", marginBottom: "1rem"}} 
                                                        hidden={state.selectedOrderType != null && state.selectedOrderType.length > 0 && (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO ? true : false )}>
                                                        <label className="reg-form-label" style={{marginTop:"0.5rem", width:"100%",textAlign: "center"}}>Tax on One Time Charge Per Unit Order</label>
                                                        <div className="trackFrmLblAndInputDiv">
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "0.3rem"}}>Enter Tax in %:<span className="addCustRequiredMarkStar">*</span></label>
                                                                <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "3.5rem"}}>Tax Price(INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                            </div>   
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <input type='number' className="input-form" required="required" value={state.taxPercentageForOneTimeChargePrice} onChange={onEnterTaxPercentageOnOneTimeChargePrice}/> 
                                                                <input type='number' className="input-form" required="required" readOnly style={{marginLeft: "1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}} value={state.taxPriceForOneTimeCharge}/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                
                                                    <div style = {{marginLeft:"0.5rem"}}>
                                                        <label style={{fontSize:"0.8rem"}} hidden={(state.SubscriptionPeriodPerUnit == null && state.SubscriptionPricePerUnitINR == null && state.SubcriptionTaxPercentagePerUnitINR == null && state.SubcriptionTaxPerUnitINR == null &&
                                                            state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType != ORDER_TYPE_RENTAL) ? true : false}>
                                                            {state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType == ORDER_TYPE_RENTAL ? 
                                                            "The above product requires a Rental Period as given below. Please update the period and price as applicable." :
                                                            "The above product requires a subscription as given below. Please update the period and price as applicable."}
                                                        </label>
                                                        <p style={{display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}} hidden={(state.SubscriptionPeriodPerUnit == null && state.SubscriptionPricePerUnitINR == null && state.SubcriptionTaxPerUnitINR == null && state.SubcriptionTaxPercentagePerUnitINR == null &&
                                                            state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType != ORDER_TYPE_RENTAL ) ? true : false}>
                                                            {state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType == ORDER_TYPE_RENTAL ? 
                                                            "Note: Create a separate Order Detail Line Item for all product items having the same Rental Period details (i.e. group same Rental Period product items together)." : 
                                                            "Note: Create a separate Order Detail Line Item for all product items having the same subscription details (i.e. group same subscription product items together)."}
                                                        </p>
                                                    </div>

                                                    <div style={{border:"1px solid var(--primaryColor)", paddingLeft:"0.5rem", paddingRight:"0.5rem", borderRadius:"0.5rem", background:"#f8f6f6"}} 
                                                        hidden={((state.SubscriptionPeriodPerUnit == null) &&
                                                                (state.SubscriptionPricePerUnitINR == null) &&
                                                                (state.SubcriptionTaxPercentagePerUnitINR == null) &&
                                                                (state.SubcriptionTaxPerUnitINR == null)&& 
                                                                state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                                (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO))
                                                                ? true : false }>
                                                            <div className="inputgroupCustom">
                                                                <label className="reg-form-label" style={{marginTop:"0.5rem"}}>{state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                                state.selectedOrderType == ORDER_TYPE_RENTAL? "Enter Rental Period:" : "Enter Subscription Period:"}<span className="addCustRequiredMarkStar" hidden={state.selectedOrderType != null && 
                                                                    state.selectedOrderType.length > 0 && (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO) ? true : false}>*</span></label>
                                                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                                                    <input type='number' className="input-form" required="required" value={state.SubscriptionPeriodPerUnit} onChange={onEnterSubscriptionPeriodPerUnit} disabled={state.isOrderInProcessingState}/> 
                                                                    <select  className="input-form" 
                                                                                style={{marginLeft: "1rem"}}
                                                                                value={state.selectedSubscriptionPeriod}
                                                                                onChange={onChangeSubscriptionPeriod}
                                                                                disabled={state.isOrderInProcessingState}
                                                                    >
                                                                        <option value="1" select="true">Month</option>
                                                                        <option value="12" select="true">Year</option>
                                                                    </select> 
                                                                </div>
                                                            </div>
                
                                                        <div hidden={((state.SubscriptionPricePerUnitINR == null || 
                                                                state.SubscriptionPricePerUnitINR.length <= 0 ||  
                                                                state.SubcriptionTaxPerUnitINR == null ||
                                                                state.SubcriptionTaxPerUnitINR.length <= 0 ||
                                                                state.SubcriptionTaxPercentagePerUnitINR == null ||
                                                                state.SubcriptionTaxPercentagePerUnitINR.length <= 0) &&
                                                                (state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                                (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO))) 
                                                                ? true : 
                                                                (state.SubscriptionPricePerUnitINR ==0 && 
                                                                state.SubcriptionTaxPerUnitINR ==0 && 
                                                                state.SubscriptionPeriodPerUnit == 0 && 
                                                                state.SubcriptionTaxPercentagePerUnitINR == 0 &&
                                                                state.formViewMode == "editMode" && 
                                                                state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                                (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO)) 
                                                                ? true : 
                                                                ((state.rentalPricePerUnitINR == null || 
                                                                state.rentalPricePerUnitINR.length <= 0 ||  
                                                                state.rentaltaxPercentagePerUnit == null ||
                                                                state.rentaltaxPercentagePerUnit.length <= 0 ||
                                                                state.rentaltaxPricePerUnit == null ||
                                                                state.rentaltaxPricePerUnit.length <= 0) &&
                                                                (state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                                                    state.selectedOrderType == ORDER_TYPE_RENTAL))
                                                                ? true : 
                                                                (state.rentalPricePerUnitINR ==0 && 
                                                                    state.rentaltaxPercentagePerUnit ==0 && 
                                                                    state.SubscriptionPeriodPerUnit == 0 && 
                                                                    state.rentaltaxPricePerUnit == 0 &&
                                                                    state.formViewMode == "editMode" && 
                                                                    state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                                        state.selectedOrderType == ORDER_TYPE_RENTAL)
                                                                ? true : false }
                                                                style={{marginTop:"0.6rem"}}>
                                                            <input type="checkbox"
                                                                    style={{marginRight:"0.5rem"}} 
                                                                    defaultChecked={state.isOrderWithFreeSubscription}
                                                                    disabled={state.isOrderInProcessingState}
                                                                    onClick={setOrderSubscription}/>
                                                                {state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                                state.selectedOrderType == ORDER_TYPE_RENTAL? "Provide free Rental for above period:" : "Provide free Subscription for above period:"}
                                                        </div>

                                                        <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}} hidden={state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                            state.selectedOrderType == ORDER_TYPE_RENTAL  ? true : false}>
                                                            <label className="reg-form-label">Subcription Price Per Unit Month (INR):</label>
                                                            <input type='number' className="input-form" required="required" value={state.SubscriptionPricePerUnitINR} onChange={onEnterSubcriptionPricePerUnitINR} disabled={state.isOrderWithFreeSubscription || state.isOrderInProcessingState}/>  
                                                        </div>
                                                        
                                                        <div style={{border:"1px solid var(--primaryColor)", paddingLeft:"0.5rem", paddingRight:"0.5rem", borderRadius:"0.5rem", background:"#f8f6f6", paddingBottom:"0.8rem", marginBottom: "1rem", marginTop: "0.8rem"}} 
                                                            hidden={state.selectedOrderType != null && state.selectedOrderType.length > 0 && state.selectedOrderType == ORDER_TYPE_RENTAL  ? true : false}>
                                                            <label className="reg-form-label" style={{marginTop:"0.5rem", width:"100%",marginBottom:"0rem", textAlign: "center"}}>Tax on Subcription Price Per Unit (Month)</label>
                                                            <div className="trackFrmLblAndInputDiv">
                                                                <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                    <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "0.3rem"}}>Enter Tax %:</label>
                                                                    <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "3.5rem"}}>Tax Price(INR):</label>
                                                                </div>   
                                                                <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                    <input type='number' className="input-form" required="required" value={state.SubcriptionTaxPercentagePerUnitINR} onChange={onEnterSubcriptionTaxPercentPerUnitINR} disabled={state.isOrderWithFreeSubscription || state.isOrderInProcessingState}/> 
                                                                    <input type='number' className="input-form" required="required" readOnly style={{marginLeft: "1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}} value={state.SubcriptionTaxPerUnitINR} disabled={state.isOrderWithFreeSubscription || state.isOrderInProcessingState}/> 
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}} hidden={state.selectedOrderType != null && state.selectedOrderType.length > 0 && 
                                                            (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO ) ? true : false}>
                                                            <label className="reg-form-label">Rental Price Per Unit Month (INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                            <input type='number' className="input-form" required="required" value={state.rentalPricePerUnitINR} onChange={onEnterRentalPricePerUnitINR} disabled={state.isOrderWithFreeSubscription || state.isOrderInProcessingState}/>  
                                                        </div>

                                                        <div style={{border:"1px solid var(--primaryColor)", paddingLeft:"0.5rem", paddingRight:"0.5rem", borderRadius:"0.5rem", background:"#f8f6f6", paddingBottom:"0.8rem", marginBottom: "1rem", marginTop: "0.8rem"}} 
                                                            hidden={state.selectedOrderType != null && state.selectedOrderType.length > 0 && (state.selectedOrderType == ORDER_TYPE_SALE || state.selectedOrderType == ORDER_TYPE_DEMO ) ? true : false}>
                                                            <label className="reg-form-label" style={{marginTop:"0.5rem", width:"100%", textAlign: "center"}}>Tax on Rental Price Per Unit (Month)</label>
                                                            <div className="trackFrmLblAndInputDiv">
                                                                <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                    <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "0.3rem"}}>Enter Tax %:<span className="addCustRequiredMarkStar">*</span></label>
                                                                    <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "3.5rem"}}>Tax Price(INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                                </div>   
                                                                <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                    <input type='number' className="input-form" required="required" value={state.rentaltaxPercentagePerUnit} onChange={onEnterRentalTaxPercentagePerUnit} disabled={state.isOrderWithFreeSubscription || state.isOrderInProcessingState}/> 
                                                                    <input type='number' className="input-form" required="required" readOnly style={{marginLeft: "1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}} value={state.rentaltaxPricePerUnit} disabled={state.isOrderWithFreeSubscription || state.isOrderInProcessingState}/> 
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group reg-form" style={{marginTop:"1.5rem", display:"flex", justifyContent:"space-evenly"}}>
                                                        <div className="CreateOrderButton">
                                                            <button type="button" className="btn-lg reg-btn"  onClick={onOkButtonClick}>Ok</button>
                                                        </div>
                                                        <div className="CreateOrderButton">
                                                            <button type="button" className="btn-lg reg-btn" onClick={onCancelButtonClick}>Cancel</button>
                                                        </div>
                                                    </div>

                                                    <div className = "buttonErrorMessage" style={{textAlign: "center"}}>
                                                        {state.errors.others.length > 0 && 
                                                            <p style={{color: 'var(--errorColor)', fontSize: '0.8rem', textAlign: "center"}} className='error'>{state.errors.others}</p>}
                                                    </div> 
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                        :
                        <Modal size="xl" isOpen={state.selectCustomerDetails} toggle={toggleSelectCustomerDetails} backdrop={state.backdrop}>
                            <ModalHeader toggle={toggleSelectCustomerDetails}> Add API Order Details </ModalHeader>
                            <ModalBody>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="container col-lg-6 col-lg-offset-3
                                                                col-md-8 col-md-offset-2">
                                            <div className="modal-body box">
                                                <form>
                                                    <div>
                                                        <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>
                                                    </div>
                                                    <div>
                                                        <label className="reg-form-label" style={{width:"100%"}}>Product Line:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <div>
                                                                <input className="input-form" style={{height:"2.5rem", marginBottom: "1rem"}} value={state.selectedProductLineForApiOrder} disabled/>                                                   
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label className="reg-form-label" style={{width:"100%"}}>Product Name:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <div>
                                                        {state.formViewMode == "editMode" && state.SelectedCustNewOrderDetails == false
                                                        ? 
                                                            <input className="input-form" style={{height:"2.5rem", marginBottom: "1rem"}} value={state.selectedProductNameForApiOrder} disabled/>
                                                        : 
                                                            <select  className="input-form" 
                                                                        style={{height:"2.5rem", marginBottom: "1rem"}}
                                                                        value={state.selectedProductNameForApiOrder}
                                                                        onChange={onChangeProductNameSelectionForApiOrder}
                                                            >
                                                                <option value="" disabled select="true">Select...</option>
                                                                {(state.ProductNameForApiOrder).map((singleProductNameForApiOrder,index) => <option key={index} value={singleProductNameForApiOrder}>{singleProductNameForApiOrder}</option>)}
                                                            </select>
                                                        }
                                                            </div>
                                                    </div>

                                                    <div>
                                                        <label className="reg-form-label">Product Code:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.selectedProductCodeForApiOrder} disabled/>  
                                                    </div>

                                                    { state.formViewMode == "editMode" && state.SelectedCustNewOrderDetails == false ? 
                                                        <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}}>
                                                            <label className="reg-form-label">Quantity Of Unit Scanned:</label>
                                                            <input type='number' className="input-form" required="required" value={state.QuantityOfUnitsScannedForApiOrder} disabled/>  
                                                        </div>
                                                    :
                                                        <div/>
                                                    }

                                                    <div className="inputgroupCustom" style={{paddingTop: "0.8rem", paddingBottom:"0.8rem"}}>
                                                        <label className="reg-form-label">Quantity Of Unit Ordered:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.newEnteredQuantityOfUnitForApiOrder} disabled/>  
                                                    </div>

                                                    <div className="inputgroupCustom" style={{paddingBottom:"0.8rem"}}>
                                                        <label className="reg-form-label">One Time Charge Per Order (INR):<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.oneTimeChargePerUnitINRForApiOrder} onChange={onEnterOneTimeChargePerUnitINRForApiOrder}/>  
                                                    </div> 

                                                    <div>
                                                        <label className="reg-form-label" style={{width:"100%"}}>Select Package Duration:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <div>
                    
                                                            <select  className="input-form" 
                                                                        style={{height:"2.5rem", marginBottom: "1rem"}}
                                                                        value={state.selectedDurationForApiOrder}
                                                                        onChange={onChangeDurationForApiOrder}
                                                            >
                                                                <option value="" disabled select="true">Select...</option>
                                                                {(state.convertedPkgDuration).map((singleDuration,index) => <option key={index} value={singleDuration}>{singleDuration}</option>)}
                                                            </select>
                                                        
                                                        </div>
                                                    </div>

                                                    <div className="inputgroupCustom" style={{paddingBottom:"0.8rem"}}>
                                                        <label className="reg-form-label">API Hits on Selected Package Duration:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.apiHitOnSelectedDuration} disabled/>  
                                                    </div> 

                                                    <div className="inputgroupCustom" style={{paddingBottom:"0.8rem"}}>
                                                        <label className="reg-form-label">Total Order Price without Tax:<span className="addCustRequiredMarkStar">*</span></label>
                                                        <input type='number' className="input-form" required="required" value={state.totalOrderPriceWithoutTax} disabled/>  
                                                    </div> 

                                                    <div style={{border:"1px solid var(--primaryColor)", paddingLeft:"0.5rem", paddingRight:"0.5rem", borderRadius:"0.5rem", background:"#f8f6f6", paddingBottom:"0.8rem", marginBottom: "1.5rem", marginTop: "0.8rem"}}>
                                                        <label className="reg-form-label" style={{marginTop:"0.5rem", width:"100%",marginBottom:"0rem", textAlign: "center"}}>Discount on Total Order Price</label>
                                                        <div className="trackFrmLblAndInputDiv">
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "0.3rem"}}>Enter Discount %:<span className="addCustRequiredMarkStar">*</span></label>
                                                                <label className="reg-form-label" style={{marginTop:"0.8rem", width:"40%", marginLeft: "3.5rem"}}>Discount Amount(INR):</label>
                                                            </div>   
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <input type='number' className="input-form" required="required" value={state.discountPercntForTotalOrderPrice} onChange={onChnageDiscountPercntForTotalOrderPrice}/> 
                                                                <input type='number' className="input-form" required="required" readOnly style={{marginLeft: "1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}} value={state.discountPriceForTotalOrderPrice}/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                    <div style={{border:"1px solid var(--primaryColor)", paddingLeft:"0.5rem", paddingRight:"0.5rem", borderRadius:"0.5rem", background:"#f8f6f6", paddingBottom:"0.8rem", marginBottom: "1rem", marginTop: "0.8rem"}}>
                                                        <label className="reg-form-label" style={{marginTop:"0.5rem", width:"100%",marginBottom:"0rem", textAlign: "center"}}>Applicable Tax After Discount</label>
                                                        <div className="trackFrmLblAndInputDiv">
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <label className="reg-form-label" style={{marginTop:"0.5rem", width:"40%", marginLeft: "0.3rem"}}>Enter Tax %:<span className="addCustRequiredMarkStar">*</span></label>
                                                                <label className="reg-form-label" style={{marginTop:"0.8rem", width:"40%", marginLeft: "3.5rem"}}>Tax Price(INR):</label>
                                                            </div>   
                                                            <div style={{display:"flex", justifyContent: "evenly"}}>
                                                                <input type='number' className="input-form" required="required" value={state.totalTaxPrcntForSelectedDuration} onChange={onChnageTotalTaxPrcntForSelectedPackageDuration}/> 
                                                                <input type='number' className="input-form" required="required" readOnly style={{marginLeft: "1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}} value={state.totalTaxPriceForSelectedDuration}/> 
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{display:"flex", justifyContent: "center"}}>
                                                        <label className="reg-form-label" style = {{width:"50%", justifyContent:"center", marginLeft:"1rem"}}>Total Payable Amount for Order</label>
                                                    </div>

                                                    <div style={{display:"flex", justifyContent: "center", marginBottom: "1rem"}}>
                                                        <input readOnly style = {{marginLeft: "1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", width:"50%", justifyContent:"center"}} type='number' className="input-form" required="required" value={state.finalTotalOrderPrice} disabled/> 
                                                    </div>

                                                    <div className="form-group reg-form" style={{marginTop:"1.5rem", display:"flex", justifyContent:"space-evenly"}}>
                                                        <div className="CreateOrderButton">
                                                            <button type="button" className="btn-lg reg-btn"  onClick={onOkButtonClick}>Ok</button>
                                                        </div>
                                                        <div className="CreateOrderButton">
                                                            <button type="button" className="btn-lg reg-btn" onClick={onCancelButtonClick}>Cancel</button>
                                                        </div>
                                                    </div>

                                                    <div className = "buttonErrorMessage" style={{textAlign: "center"}}>
                                                        {state.errors.errApiOrder.length > 0 && 
                                                            <p style={{color: 'var(--errorColor)', fontSize: '0.8rem', textAlign: "center"}} className='error'>{state.errors.errApiOrder}</p>}
                                                    </div> 
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                        }
                        <div className="CreateOrderTotalValue" hidden={state.createEditOrder && state.selectedOrderType != null && state.selectedOrderType.length > 0 ? false :
                                                                !state.createEditOrder && state.orderTypeApi != null && state.orderTypeApi.length && state.orderTypeApi == ORDER_TYPE_API ? false : true}>
                            <label style={{fontWeight:"bold"}}>Total Order Value(INR):</label>
                            <input className="CreateOrderTotalValueInput" type='number' required="required" value={state.TotalOrderValue} disabled/>  
                        </div>
                        <div style={{textAlign: "center"}} hidden={state.createEditOrder && state.selectedOrderType != null && state.selectedOrderType.length > 0 ? false :
                                                                !state.createEditOrder && state.orderTypeApi != null && state.orderTypeApi.length && state.orderTypeApi == ORDER_TYPE_API ? false : true}>
                            <button type="submit" className="createOrderSaveButton btn-md" onClick={onSaveButtonClick}>Save</button>
                        </div>
                        { state.createEditOrder ?
                            <div className = "buttonErrorMessage" style={{textAlign: "right"}}>
                                {state.errors.others.length > 0 && 
                                    <p style={{color: 'var(--errorColor)', fontSize: '0.8rem', textAlign: "center"}} className='error'>{state.errors.others}</p>}
                            </div> 
                        :
                            <div className = "buttonErrorMessage" style={{textAlign: "right"}}>
                                {state.errors.errApiOrder.length > 0 && 
                                    <p style={{color: 'var(--errorColor)', fontSize: '0.8rem', textAlign: "center"}} className='error'>{state.errors.errApiOrder}</p>}
                            </div> 
                        }
                    </div>    
                </ModalBody>
            </Modal>
            <Modal size="xl" isOpen={state.showFulfilledDetailsModal} toggle={toggleFulfilledDetailsModal} backdrop={state.backdrop}>
                <ModalHeader toggle={toggleFulfilledDetailsModal}> 
                    {`View Fulfilled Order Details.`}
                </ModalHeader>
                <ModalBody>
                    <div align="left" style={{border:"1px solid green", padding:"0.2rem", borderRadius:"5px"}}>
                        <label style={{fontSize: "0.8rem"}}>
                            <b>Note</b>: The DeviceID displayed in "Item ID" column is the Device ID which is originally assigned to the order. But if there is any Device ID present in the "Replaced Device ID" column then that Device ID should be considered as latest Device ID which is assigned to the order. Because device in "Item ID" column has been replaced by device in "Replaced Device ID" column.
                        </label>
                    </div>

                    {state.custFulfilledDetailsTableData.length > 0 ?
                        // <div style={{marginTop:"1rem", border: "1px solid black", textAlign:"center"}}>
                        <div className="border border-3 ReactTableStyle" style = {{marginTop:"1rem"}}>
                            <ReactTable
                                data={state.custFulfilledDetailsTableData}
                                columns={state.custFulfilledOrderDetailscolumns}
                                renderBasedOnTable = {"custFulfilledOrderDetailsTable"}
                                getCellProps = {(cellInfo) => ({})}   
                                // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                // so passing all the state variables on which react table is dependent through passedStateVariable
                                passedStateVariable = {[state.custFulfilledDetailsTableData, props.propAction, state.showEditAndQuantityOfUnitsScannedwhenNotRentalAndApi,
                                    state.showEditAndQuantityOfUnitsScannedwhenRental, state.showEditAndQuantityOfUnitsScannedwhenApi,
                                    state.createEditOrder, state.selectedRowIndex, state.loading, state.OrderType]}                      
                            />
                        </div>
                    :
                        null
                    }

                </ModalBody>
            </Modal>
        </div>
    );
}

export default VcOrder;
