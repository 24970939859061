import React, { Component } from 'react'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext} from '../../AppContext';
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa'
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
import { ITM_STATE_DEMO, ITM_STATE_RENTAL, ITM_STATE_SOLD, ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_READY_FOR_SHIPMENT, ITM_STATE_DECOMMISSIONED_DEVICEID,
         ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, ITM_STATE_RECALL_SOLD_DEVICE, ITM_STATE_UNDER_REPAIR,
         ITM_STATE_FAULTY, ITM_STATE_MAPPED_AS_REPLACEMENT } from '../../VcConstants';
import { trimStringAndRemoveTrailingComma, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

export class VcProductItemState extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            objPrivilege: this.props.objPrivilege,
            LoggedInUserID: "",
            ItemID: "", // same as deviceID.
            replacementItemIdOfFaultyDevice: "", // same as deviceID.
            orderLineNumber: "",
            orderType: "",
            selectedProductLine: "",
            selectedProductName: "",
            alreadyPresentProductLine: "",
            alreadyPresentProductName: "",
            alreadyPresentProductID: "",
            arrProductTypeAndItsRelevantInfo: [],
            arrProductLine: [],
            arrProductInfoBasedOnSelectedProductline: [],
            arrProductStates: [],
            arrProductStateSeq: [],
            enteredProductItemIdInfo: [],
            arrProductModelRelavantInfo: [],
            showAndAssignStateToProduct: false,
            hasPvgToAssignedNextState: false,
            isDeviceIDExist: false,
            currStateOfProduct: "",
            nextStateToBeAssignedToProduct: [],
            nextStateIDToBeAssignedToProduct: [],
            selectStepToMark: "",
            selectedStateCodeToMark: "",
            ItemDescription: "",
            markProductState:false,
            DevcIDQRCodeFlag: false,
            replacementItemIDOfFaultyDeviceQRCodeFlag: false,
            invokedFrom: this.props.invokedFrom,
            invokeFromInsideFormPage: "",
            dtTmWhenDvcTknBack: convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(new Date()),
            subscriptionPeriod: "",
            subscriptionEndDtTm: "",
            securityDepositToReturn: "",
            maxSecurityDepositToReturn: "",
            subscriptionAmountToReturn: "",
            maxSubscriptionAmountToReturn: "",
            totalReturnableAmount: "",
            isSubExpired: false,
            comments: "",
            passwordShown: false,
            errors: {
                others:"",
                deviceExistenceErr: "",
                email: "",
                password: "",
                loginIssue:"",
                securityDepositToReturn:"",
                comments: "",
                subscriptionAmountToReturn: "",
                totalReturnableAmount: "",
            },
            OpenForm: false,
            email:"",
            password:"",
            backdrop:"static"
        }
    }

    componentDidMount () {
        this.getProductCategoryAndItsRelevantInfo();
    }

    getProductCategoryAndItsRelevantInfo = (inModifiedState = null) => {
        let modifiedState;

        if(inModifiedState ==  null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.arrProductTypeAndItsRelevantInfo = [];
        modifiedState.arrProductLine = [];
        modifiedState.arrProductStates = [];
        modifiedState.arrProductStateSeq = [];

        axios.post(`${getAPIHostURL()}/wclient/getProductCategoryAndItsRelevantInfo`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data["retrievedProdTypeAndItsRelvantInfo"] == null ||
                    response.data["retrievedProdTypeAndItsRelvantInfo"].length <= 0 ||
                    response.data["retrievedStateSeq"] == null ||
                    response.data["retrievedStateSeq"].length <= 0 ||
                    response.data["retrievedStates"] == null ||
                    response.data["retrievedStates"].length <= 0 

                ) {
                    modifiedState.errors.others = 'Product relevant information or its States are not present on server.'
                } else {
                    // Include all Product and its relevant infrmation (MasterData)
                    modifiedState.arrProductTypeAndItsRelevantInfo = response.data["retrievedProdTypeAndItsRelvantInfo"];

                    // Include all state through product goes (MasterData)
                    modifiedState.arrProductStates = response.data["retrievedStates"];

                    // GenericProductSeq (MasterData)
                    modifiedState.arrProductStateSeq = response.data["retrievedStateSeq"];
                    
                    // filter out distinct Product Line.
                    for(let i=0; i<modifiedState.arrProductTypeAndItsRelevantInfo.length; i++) {
                        let ProductLine = modifiedState.arrProductTypeAndItsRelevantInfo[i]["ProductLine"];
                        if( !modifiedState.arrProductLine.includes(ProductLine) ) {
                            modifiedState.arrProductLine.push(ProductLine);
                        }
                    }
                }
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 

    }

    onChangeProductLine = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedProductLine = e.target.value;
        modifiedState.errors.others = "";

        // make sure you blank oit array explicitly before assigning new array.
        modifiedState.arrProductInfoBasedOnSelectedProductline = [];
        modifiedState.selectedProductName = "";

        // Filtering those elemets from array which having selected Product line.
        let filteredArrBasedOnProductLine = modifiedState.arrProductTypeAndItsRelevantInfo.filter((singleProductInfo) => singleProductInfo["ProductLine"] == modifiedState.selectedProductLine);

        modifiedState.arrProductInfoBasedOnSelectedProductline = filteredArrBasedOnProductLine;
        this.setState(modifiedState);
    } 

    onChangeProductName = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedProductName = e.target.value;

        // Filtering those elemets from array which having selected Product line.
        let filteredArrBasedOnProductLineAndName
            = modifiedState.arrProductTypeAndItsRelevantInfo.filter(
                    (singleProductInfo) => singleProductInfo["ProductName"] == modifiedState.selectedProductName
                );

        let StateSeqId = filteredArrBasedOnProductLineAndName[0]["StateSeqId"];

        // if Third party product is not yet undergo any state.
        modifiedState.currStateOfProduct = 'No state assigned yet.';

        let filteredArrStateSeqIdFromStateSeqId = modifiedState.arrProductStateSeq.filter((stateSeqInfo) => stateSeqInfo["StateSeqId"] == StateSeqId);
        
        // As this is the intial step (Happens only once when their is no any state assigned.)
        // Hence here we are assigning a initial state of a particular StateSeqId to be marked as a nextStateID.
        let ArrNextStateToBeAssignedToProduct = modifiedState.arrProductStates.filter(stateInfo => stateInfo["StateID"] == filteredArrStateSeqIdFromStateSeqId[0]["CurrentStateID"]);

        // modifiedState.nextStateIDToBeAssignedToProduct = ArrNextStateToBeAssignedToProduct[0]["StateID"];
        // modifiedState.nextStateToBeAssignedToProduct = ArrNextStateToBeAssignedToProduct[0]["State"];
        modifiedState.nextStateIDToBeAssignedToProduct.push(ArrNextStateToBeAssignedToProduct[0]["StateID"]);
        modifiedState.nextStateToBeAssignedToProduct.push(ArrNextStateToBeAssignedToProduct[0]["State"]);
        this.checkUserPvgBeforeMarkingProductState(modifiedState);
        // modifiedState.errors.others = "";
        // this.setState(modifiedState);
    }

    onChangeItemID = (e) => {
        let modifiedState = this.state;
        modifiedState.ItemID = e.target.value;

        // Explicitly Make all state value to blank in order to avoid wrong values.
        modifiedState.showAndAssignStateToProduct = false;
        modifiedState.isSubExpired = false;
        modifiedState.markProductState = false;
        modifiedState.hasPvgToAssignedNextState = false;
        modifiedState.isDeviceIDExist = false;
        modifiedState.selectedProductLine = "";
        modifiedState.alreadyPresentProductName = "";
        modifiedState.alreadyPresentProductLine = "";
        modifiedState.alreadyPresentProductID = "";
        modifiedState.selectedProductName = "";
        modifiedState.currStateOfProduct = "";
        // modifiedState.nextStateToBeAssignedToProduct = "";
        // modifiedState.nextStateIDToBeAssignedToProduct = 0;
        modifiedState.nextStateToBeAssignedToProduct = [];
        modifiedState.nextStateIDToBeAssignedToProduct = [];
        modifiedState.ItemDescription = "";
        modifiedState.DevcIDQRCodeFlag = false;
        modifiedState.selectStepToMark = "";
        modifiedState.dtTmWhenDvcTknBack = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(new Date());
        modifiedState.subscriptionPeriod = "";
        modifiedState.subscriptionEndDtTm = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(new Date());
        modifiedState.securityDepositToReturn = "";
        modifiedState.maxSecurityDepositToReturn = "";
        modifiedState.subscriptionAmountToReturn = "";
        modifiedState.maxSubscriptionAmountToReturn = "";
        modifiedState.totalReturnableAmount = "";
        modifiedState.comments = "";
        modifiedState.invokeFromInsideFormPage = "";
        modifiedState.replacementItemIdOfFaultyDevice = "";

        modifiedState.errors = {
            others:"",
            deviceExistenceErr: "",
            securityDepositToReturn: "",
            securityDepositToReturn: "",
            subscriptionAmountToReturn: "",
            totalReturnableAmount: "",
            comments: ""
        }

        this.setState(modifiedState);
    }

    onChangeReplaceItemIdOfFaultyDevice = (e) => {
        let modifiedState = this.state;
        modifiedState.replacementItemIdOfFaultyDevice = e.target.value;

        modifiedState.errors.deviceExistenceErr = "";
        modifiedState.errors.others = "";

        this.setState(modifiedState);
    }

    handleKeyDown = (e) => {
       
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    verifyEnteredDeviceID = (e = null, inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }     

        let deviceIdToVarified = "";

        // If its called from replaceFaultyOrderDevice then we have to just verify the device ID existence and current state of d
        //  evice which should be "Ready for Shipment".
        // Otherwise it will check the device id existence and previous or Next valid state of device as per needed.

        if(modifiedState.invokeFromInsideFormPage != null && modifiedState.invokeFromInsideFormPage == "replaceFaultyOrderDevice") {
            deviceIdToVarified = modifiedState.replacementItemIdOfFaultyDevice;             

        } else {
            deviceIdToVarified = modifiedState.ItemID;

        }

        if(deviceIdToVarified == null || deviceIdToVarified.length <=0 ) {
            modifiedState.errors.deviceExistenceErr = 'Please enter ItemID.';
            this.setState(modifiedState);
            return;
        }

        let StateToValidate = (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackDemoDevice") ? ITM_STATE_DEMO 
                                   : (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackRentalDevice") ? ITM_STATE_RENTAL
                                   : (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "recallSoldDevice") ? ITM_STATE_SOLD 
                                   : (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInDecommisionSt") ? ITM_STATE_DECOMMISSIONED_DEVICEID 
                                   : (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInUnderRepairSt") ? ITM_STATE_UNDER_REPAIR   
                                   : (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "readyForShipment") ? ITM_STATE_READY_FOR_SHIPMENT
                                   : (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice") ? ITM_STATE_FAULTY : null;

        let trimSpacesOfItemID = trimStringAndRemoveTrailingComma(deviceIdToVarified);

        let jsonParams = {
            EnteredDeviceID: trimSpacesOfItemID,
            StateToValidate : StateToValidate,
            invokedFrom : (modifiedState.invokedFrom == null || modifiedState.invokedFrom == "ProductItemStateHistory") || (
                (modifiedState.invokedFrom == "replaceFulfilledFaultyDevice") &&
                (modifiedState.invokeFromInsideFormPage != null && modifiedState.invokeFromInsideFormPage == "replaceFaultyOrderDevice")) ? null : modifiedState.invokedFrom                 
        }

        //Calling API to check checkDeviceIdExistence
        axios.post(`${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonParams)
        .then(response => {        
            if(response.data.code == 'SUCCESS') {
                if(response.data.isDeviceIDExist == null) {
                    modifiedState.errors.deviceExistenceErr = "Server experiencing issues.\nTry again later.";
                    this.setState(modifiedState);
                    return;

                } else {
                    if(response.data.isDeviceIDExist != 0 && modifiedState.invokedFrom != null && modifiedState.invokedFrom == "ProductItemStateHistory") {
                        // this is the case where loggedInUser scanned serial no or the product which is other than our Defined product.
                        modifiedState.showAndAssignStateToProduct = true;
                        
                        this.getProductItemState(modifiedState);

                    } else if((modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice") &&
                            (modifiedState.invokeFromInsideFormPage != null && modifiedState.invokeFromInsideFormPage == "replaceFaultyOrderDevice") ) {

                        if(response.data.isDeviceIDExist == 0) {
                            modifiedState.errors.deviceExistenceErr = `Invalid Device ID.`;
                            this.setState(modifiedState);
                            return;
                        } else {
                            modifiedState.isDeviceIDExist = true;
                            this.getProductItemState(modifiedState);   
                        }
                    } else {
                        let isPrevStateIsValid = (response.data.isPrevStateIsValid != null && response.data.isPrevStateIsValid.length > 0) ? response.data.isPrevStateIsValid : false;
                        modifiedState.errors.deviceExistenceErr = "";

                        if(response.data.isDeviceIDExist == 0 && modifiedState.invokedFrom != null && modifiedState.invokedFrom != "ProductItemStateHistory") {
                            modifiedState.selectStepToMark = "";
                            modifiedState.errors.deviceExistenceErr = `Invalid Device ID.`;
                            this.setState(modifiedState);
                            return;
                        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom != "ProductItemStateHistory" && isPrevStateIsValid == true) {
                            modifiedState.isDeviceIDExist = true;
                            modifiedState.showAndAssignStateToProduct = true;
                            this.getProductItemState(modifiedState);
                            
                        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom != "ProductItemStateHistory" &&  isPrevStateIsValid == false) {
                            modifiedState.selectStepToMark = "";
                            let CurrentAssignState = (modifiedState.invokedFrom == "takeBackDemoDevice") ? ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT : 
                                                        modifiedState.invokedFrom == "takeBackRentalDevice" ? ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT :
                                                        modifiedState.invokedFrom == "moveDevcInDecommisionSt" ? ITM_STATE_DECOMMISSIONED_DEVICEID :
                                                        modifiedState.invokedFrom == "moveDevcInUnderRepairSt" ? ITM_STATE_UNDER_REPAIR : 
                                                        (modifiedState.invokedFrom == "readyForShipment") ? ITM_STATE_READY_FOR_SHIPMENT : 
                                                        (modifiedState.invokedFrom == "replaceFulfilledFaultyDevice") ? ITM_STATE_FAULTY : 
                                                        (modifiedState.invokedFrom == "recallSoldDevice") ? ITM_STATE_RECALL_SOLD_DEVICE : "";
                            
                            modifiedState.errors.deviceExistenceErr = `You cannot assign this device to a '${CurrentAssignState}' state because existing state of the Device is not valid.`;
                            this.setState(modifiedState);
                            return;
                        } else {
                            modifiedState.isDeviceIDExist = true;
                            modifiedState.showAndAssignStateToProduct = true;
                            this.getProductItemState(modifiedState);
                        }
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = '"Server experiencing issues (required parameters not sent). Try again later."';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
           
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.deviceExistenceErr = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });   
    }

    checkIfSubscriptionIsCancelled = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        if(modifiedState.ItemID == null || modifiedState.ItemID.length <=0 ) {
            modifiedState.deviceExistenceErr = 'Please enter ItemID.';
            this.setState(modifiedState);
            return;
        }

        let jsonTocheckIfSubscriptionIsCancelled = {
            deviceID: modifiedState.ItemID,                            
        }

        // Calling API to check if subscription is already cancelled or not
        axios.post(`${getAPIHostURL()}/wclient/checkIfSubscriptionIsCancelled`, jsonTocheckIfSubscriptionIsCancelled)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["isSubscriptionCancelled"] == null || response.data["isSubscriptionCancelled"].length <= 0) {
                    modifiedState.isSubExpired = false;
                    modifiedState.errors.others = "Rental period data is not available for selected DeviceID " + "'" + modifiedState.ItemID + "'";

                } else {
                    let retrievedIsSubCancelled = response.data["isSubscriptionCancelled"];

                    if(retrievedIsSubCancelled["subExpiryDate"] != null && retrievedIsSubCancelled["subExpiryDate"] == "SubscriptionExpired"){
                        modifiedState.isSubExpired = true;                                             

                        // if subscription id expired take following rental subscription information
                        modifiedState.maxSubscriptionAmountToReturn = retrievedIsSubCancelled["maxSubscriptionAmountToReturn"] != null &&
                            retrievedIsSubCancelled["maxSubscriptionAmountToReturn"].length > 0 ? retrievedIsSubCancelled["maxSubscriptionAmountToReturn"] : "";
                    
                        modifiedState.maxSecurityDepositToReturn = retrievedIsSubCancelled["maxSecurityDepositToReturn"] != null &&
                            retrievedIsSubCancelled["maxSecurityDepositToReturn"].length > 0 ? retrievedIsSubCancelled["maxSecurityDepositToReturn"] : "";  
                            
                        modifiedState.orderLineNumber = retrievedIsSubCancelled["OrderLineNumber"] != null &&
                            retrievedIsSubCancelled["OrderLineNumber"].length > 0 ? retrievedIsSubCancelled["OrderLineNumber"] : ""; 
                        
                        modifiedState.orderType = retrievedIsSubCancelled["OrderType"] != null &&
                            retrievedIsSubCancelled["OrderType"].length > 0 ? retrievedIsSubCancelled["OrderType"] : ""; 
                                    
                        modifiedState.showAndAssignStateToProduct = true;

                        if(modifiedState.maxSubscriptionAmountToReturn != null && modifiedState.maxSubscriptionAmountToReturn > 0 && 
                            modifiedState.maxSecurityDepositToReturn != null && modifiedState.maxSecurityDepositToReturn > 0 
                            ){
                                modifiedState.subscriptionAmountToReturn = modifiedState.maxSubscriptionAmountToReturn;
                                modifiedState.securityDepositToReturn = modifiedState.maxSecurityDepositToReturn;
                                modifiedState.totalReturnableAmount = (Number(modifiedState.subscriptionAmountToReturn) + Number(modifiedState.securityDepositToReturn)).toFixed(2);
                        } else{
                            modifiedState.subscriptionAmountToReturn = "0.00";
                            modifiedState.securityDepositToReturn = "0.00";
                            modifiedState.totalReturnableAmount = (Number(modifiedState.subscriptionAmountToReturn) + Number(modifiedState.securityDepositToReturn)).toFixed(2);
                        }  
                    } else {
                        modifiedState.isSubExpired = false;
                        modifiedState.errors.others = "Rental period for selected DeviceID " + "'" + modifiedState.ItemID + "' is not expired and it is going to expire on date '" 
                                                    + convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(retrievedIsSubCancelled["subExpiryDate"]) + "'.";
                    }
                }
                this.setState(modifiedState);
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (required parameters not sent). Try again later.';
                }  else if (response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    getProductItemState = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let deviceIdToSend = "";

        if((modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice") && 
            modifiedState.invokeFromInsideFormPage != null && modifiedState.invokeFromInsideFormPage == "replaceFaultyOrderDevice") {
            
            deviceIdToSend = modifiedState.replacementItemIdOfFaultyDevice;             

        } else {
            deviceIdToSend = modifiedState.ItemID;

        }

        modifiedState.enteredProductItemIdInfo = [];
        modifiedState.arrProductModelRelavantInfo = [];

        let trimSpacesOfItemID = trimStringAndRemoveTrailingComma(deviceIdToSend);

        let jsonParams = {
            ProductItemID: trimSpacesOfItemID
        }

        axios.post(`${getAPIHostURL()}/wclient/getProductItemState`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if( (response.data["retrievedProductStateAndItsInfo"] == null || response.data["retrievedProductStateAndItsInfo"].length <=0) &&
                    (response.data["retrievedProductModelRelavantInfo"] == null || response.data["retrievedProductModelRelavantInfo"].length <=0)
                ) {
                    if( modifiedState.isDeviceIDExist == false) {

                        // No need to do anything as currentState and nextstate of product item will be based 
                        // selected productLine and productName's StateSeqId.
                        this.setState(modifiedState);

                    } else {
                        modifiedState.showAndAssignStateToProduct = false;
                        modifiedState.errors.others = 'The Product Line and Product Name for this product item is not present in our Product List.' +
                                                        '\nPlease request Product Owner to add the same in the Product List.';

                        this.setState(modifiedState);
                    }

                // If page invoke from the replaceFulfilledFaultyDevice then we check if old device and new device both have same modal
                //  and new device should have in readyForShipment than only we procedd for replaceing the faulty Device.
                } else if((modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice") &&
                    modifiedState.invokeFromInsideFormPage != null && modifiedState.invokeFromInsideFormPage == "replaceFaultyOrderDevice"
                ) {

                    if (response.data["retrievedProductStateAndItsInfo"] != null && response.data["retrievedProductStateAndItsInfo"].length > 0) {

                        let replaceFaultyOrderProductItemIdInfo = response.data["retrievedProductStateAndItsInfo"];

                        let arrCurrentReplaceFaultyOrderProductItemInfo = modifiedState.arrProductTypeAndItsRelevantInfo.filter((stateInfo) => stateInfo["ProductCode"] == replaceFaultyOrderProductItemIdInfo[0]["ProductCode"]);

                        let currentReplaceFaultyOrderProductLine = arrCurrentReplaceFaultyOrderProductItemInfo[0]["ProductLine"];
                        let currentReplaceFaultyOrderProductName = arrCurrentReplaceFaultyOrderProductItemInfo[0]["ProductName"];
                        let currentReplaceFaultyOrderProductID = arrCurrentReplaceFaultyOrderProductItemInfo[0]["ProductCode"];
                        
                        let currentReplaceFaultyOrderItemStateID = replaceFaultyOrderProductItemIdInfo[0]["StateID"];

                        // Find out the current state of the product from the array of state based on curent StateID.
                        let arrCurrentReplaceFaultyOrderItemStateInfo = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == currentReplaceFaultyOrderItemStateID);

                        modifiedState.invokeFromInsideFormPage = "";

                        if(currentReplaceFaultyOrderProductLine != modifiedState.alreadyPresentProductLine ||
                            currentReplaceFaultyOrderProductName != modifiedState.alreadyPresentProductName ||
                            currentReplaceFaultyOrderProductID != modifiedState.alreadyPresentProductID    
                        ) {
                            modifiedState.errors.others = `You cannot Replace faulty Device with this Device ID '${modifiedState.replacementItemIdOfFaultyDevice}' because Product Line/Name/ID/Code does not match with faulty device.`;

                            this.setState(modifiedState);
                            return;

                        } else if(arrCurrentReplaceFaultyOrderItemStateInfo == null || arrCurrentReplaceFaultyOrderItemStateInfo.length <= 0 
                            || arrCurrentReplaceFaultyOrderItemStateInfo[0]["State"] != ITM_STATE_READY_FOR_SHIPMENT) {

                            modifiedState.errors.others = `You cannot Replace faulty Device with this Device ID '${modifiedState.replacementItemIdOfFaultyDevice}' because existing state of the Device is not valid.`;
                            this.setState(modifiedState);
                            return;

                        } else {
                            this.setProductItemState(modifiedState)

                        }
                        
                    } else {
                        // State Not Present
                        modifiedState.errors.others = 'The Product Line and Product Name for this product item is not present in our Product List.' +
                        `\n or Product State not Present for Device ID ${modifiedState.replacementItemIdOfFaultyDevice}`;
                        this.setState(modifiedState);
                        return;
                    }

                } else if( (response.data["retrievedProductStateAndItsInfo"] == null || response.data["retrievedProductStateAndItsInfo"].length <=0) &&
                            (response.data["retrievedProductModelRelavantInfo"] != null || response.data["retrievedProductModelRelavantInfo"].length > 0)
                ) {

                    // if product is not yet undergo any state.
                    modifiedState.currStateOfProduct = 'No state assigned yet.';

                    // As DeviceId is already present in DB hence here we are retriving its model info.
                    modifiedState.arrProductModelRelavantInfo = response.data["retrievedProductModelRelavantInfo"];

                    let StateSeqId = modifiedState.arrProductModelRelavantInfo[0]["StateSeqId"];
                    let filteredArrStateSeqIdFromStateSeqId = modifiedState.arrProductStateSeq.filter((stateSeqInfo) => stateSeqInfo["StateSeqId"] == StateSeqId);

                    // Hence here we are assigning here 1st state from all Product state.
                    let ArrNextStateToBeAssignedToProduct = modifiedState.arrProductStates.filter(stateInfo => stateInfo["StateID"] == [filteredArrStateSeqIdFromStateSeqId[0]["CurrentStateID"]]);
                    
                    // Hence here we are assigning the 1st state based on its StateSeqID (GenericSeqID, ThirdPartySeqID).

                    // modifiedState.nextStateIDToBeAssignedToProduct = ArrNextStateToBeAssignedToProduct[0]["StateID"];
                    // modifiedState.nextStateToBeAssignedToProduct = ArrNextStateToBeAssignedToProduct[0]["State"];
                    
                    modifiedState.nextStateIDToBeAssignedToProduct.push(ArrNextStateToBeAssignedToProduct[0]["StateID"]);
                    modifiedState.nextStateToBeAssignedToProduct.push(ArrNextStateToBeAssignedToProduct[0]["State"]);

                    modifiedState.alreadyPresentProductLine = modifiedState.arrProductModelRelavantInfo[0]["ProductLine"];
                    modifiedState.alreadyPresentProductName = modifiedState.arrProductModelRelavantInfo[0]["ProductName"];
                    modifiedState.alreadyPresentProductID = modifiedState.arrProductModelRelavantInfo[0]["ProductCode"]

                    this.checkUserPvgBeforeMarkingProductState(modifiedState);

                } else {

                    modifiedState.enteredProductItemIdInfo = response.data["retrievedProductStateAndItsInfo"];

                    // Here we are retriving all product relevant information ["ProductLine", "ProductName"] from arrProductTypeAndItsRelevantInfo based on ProductCode.
                    let arrCurrentProductInfo = modifiedState.arrProductTypeAndItsRelevantInfo.filter((stateInfo) => stateInfo["ProductCode"] == modifiedState.enteredProductItemIdInfo[0]["ProductCode"]);
                    
                    let StateSeqId = arrCurrentProductInfo[0]["StateSeqId"];
                    modifiedState.alreadyPresentProductLine = arrCurrentProductInfo[0]["ProductLine"];
                    modifiedState.alreadyPresentProductName = arrCurrentProductInfo[0]["ProductName"];
                    modifiedState.alreadyPresentProductID = arrCurrentProductInfo[0]["ProductCode"];

                    // get curent StateID.
                    let StateIDOfCurrentProductState = modifiedState.enteredProductItemIdInfo[0]["StateID"];

                    // Find out the current state of the product from the array of state based on curent StateID.
                    let arrCurrentProductStateInfo = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == StateIDOfCurrentProductState);

                    modifiedState.currStateOfProduct = arrCurrentProductStateInfo[0]["State"];

                    let arrNextProductStateInfoBasedOnCurrentState = [];

                    if(arrCurrentProductStateInfo[0]["State"] == ITM_STATE_DECOMMISSIONED_DEVICEID) {
                        modifiedState.errors.others = `Next state for this product item can not be assigned as it is already Decommissioned Device.`;
                        this.setState(modifiedState);
                    } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice" && 
                        (arrCurrentProductStateInfo[0]["State"] == ITM_STATE_MAPPED_AS_REPLACEMENT || arrCurrentProductStateInfo[0]["State"] == ITM_STATE_MAPPED_TO_OWNER)
                    ){
                        modifiedState.errors.others = `This product can be mark as faulty only through 'Device Replacement' module.`;
                        this.setState(modifiedState);
                    } 
                    // else if(arrCurrentProductStateInfo[0]["State"] == ITM_STATE_DEMO || 
                    //           arrCurrentProductStateInfo[0]["State"] == ITM_STATE_SOLD 
                    //         //   || 
                    //         //   arrCurrentProductStateInfo[0]["State"] == ITM_STATE_MAPPED_TO_OWNER
                    // ) {
                    //     modifiedState.errors.others = `Next state for this product item can only be set from the 'Fault Report'  Or 'Device Replacement' module.`;
                    //     this.setState(modifiedState);
                    // } 
                    else {
                        // Find out the next state of the product from the array of state sequence based on its StateSeqID (GenericSeqID, ThirdPartySeqID) and current state of sequence.
                        arrNextProductStateInfoBasedOnCurrentState 
                                    = modifiedState.arrProductStateSeq.filter((stateInfo) => stateInfo["StateSeqId"] == StateSeqId && 
                                                                                             stateInfo["CurrentStateID"] == StateIDOfCurrentProductState
                                                                            );

                        let arrSplitedNextState = (arrNextProductStateInfoBasedOnCurrentState[0]["NextStateIDs"]).split(",");

                        let StateIDToRemove;

                        if(arrCurrentProductStateInfo[0]["State"] == ITM_STATE_READY_FOR_SHIPMENT) {
                            for(let i = 0; i<modifiedState.arrProductStates.length; i++){
                                if(modifiedState.arrProductStates[i]["State"] == ITM_STATE_DEMO || modifiedState.arrProductStates[i]["State"] == ITM_STATE_SOLD ||
                                    modifiedState.arrProductStates[i]["State"] == ITM_STATE_RENTAL){
                                    StateIDToRemove = modifiedState.arrProductStates[i]["StateID"];
                                    
                                    arrSplitedNextState = arrSplitedNextState.filter((stateInfo) => parseInt(stateInfo) != StateIDToRemove);
                                    
                                }
                            }
                        }
                    
                        for(let i = 0; i<arrSplitedNextState.length; i++){

                            if(arrNextProductStateInfoBasedOnCurrentState.length > 0) {
                                let arrNextStateInfo 
                                        // = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == arrNextProductStateInfoBasedOnCurrentState[0]["NextStateIDs"])
                                        = modifiedState.arrProductStates.filter((stateInfo) => stateInfo["StateID"] == arrSplitedNextState[i])
    
                                // modifiedState.nextStateToBeAssignedToProduct = arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"];
                                // modifiedState.nextStateIDToBeAssignedToProduct = arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"];
                                if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackDemoDevice") {
                                    if(arrNextStateInfo[0] != null && arrNextStateInfo[0]["State"] != null && arrNextStateInfo[0]["State"] == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT) {
                                        modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                        modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                    }

                                } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackRentalDevice") {
                                    if(arrNextStateInfo[0] != null && arrNextStateInfo[0]["State"] != null && arrNextStateInfo[0]["State"] == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT) {
                                        modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                        modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                    }

                                } 
                                else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "recallSoldDevice") {
                                    if(arrNextStateInfo[0] != null && arrNextStateInfo[0]["State"] != null && arrNextStateInfo[0]["State"] == ITM_STATE_RECALL_SOLD_DEVICE) {
                                        modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                        modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                    }

                                } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "readyForShipment"){
                                    if(arrNextStateInfo[0] != null && arrNextStateInfo[0]["State"] != null && arrNextStateInfo[0]["State"] == ITM_STATE_READY_FOR_SHIPMENT) {
                                        modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                        modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                    }
                                } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice"){
                                    if(arrNextStateInfo[0] != null && arrNextStateInfo[0]["State"] != null && arrNextStateInfo[0]["State"] == ITM_STATE_FAULTY) {
                                        modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                        modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                    }
                                } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInDecommisionSt") {
                                    if(arrNextStateInfo[0] != null && arrNextStateInfo[0]["State"] != null && arrNextStateInfo[0]["State"] == ITM_STATE_DECOMMISSIONED_DEVICEID) {
                                        modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                        modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                    }

                                } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInUnderRepairSt") {
                                    if(arrNextStateInfo[0] != null && arrNextStateInfo[0]["State"] != null && arrNextStateInfo[0]["State"] == ITM_STATE_UNDER_REPAIR) {
                                        modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                        modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                    }

                                } else {
                                    modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                                    modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                                }
                                this.checkUserPvgBeforeMarkingProductState(modifiedState);
                            } else {
                                modifiedState.errors.others = 'Something went wrong. Try again later!';
                                this.setState(modifiedState);
                            }

                        }
                    }                   
                }
             
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (required parameters not sent). Try again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    checkUserPvgBeforeMarkingProductState = (inModifiedState = null) => {
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let arrLoggedInUserAssignedPvg = [];

        // here we are getting loggedinUser privileges.
        for (let [key, value] of Object.entries(modifiedState.objPrivilege)) {
            //console.log(`${key}: ${value}`);

            if(value == true) {
                arrLoggedInUserAssignedPvg.push(key);
            }
        }

        let jsonParams = {
            nextStateID: modifiedState.nextStateIDToBeAssignedToProduct,
            arrLoggedInUserAssignedPvg: arrLoggedInUserAssignedPvg
        }

        axios.post(`${getAPIHostURL()}/wclient/checkUserPvgBeforeMarkingProductState`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["arrPvg"] == null || response.data["arrPvg"].length <=0 ) {

                    modifiedState.errors.others = 'You do not have required privileges to move the product item into next state.';
                    
                } else {
                    let arrHasLoggedInUserPvgToAssignedNxtState = response.data["arrPvg"];

                    for(let i=0; i<arrHasLoggedInUserPvgToAssignedNxtState.length; i++) {
                        if(arrHasLoggedInUserPvgToAssignedNxtState[i]["bPrivilege"] == 'true') {
                            modifiedState.hasPvgToAssignedNextState = true;
                        }
                    }
                    if(modifiedState.invokedFrom != null && modifiedState.invokedFrom != "ProductItemStateHistory"){
                        modifiedState.selectedStateCodeToMark = modifiedState.nextStateIDToBeAssignedToProduct[0];
                        modifiedState.selectStepToMark = modifiedState.nextStateToBeAssignedToProduct[0];
                    }

                    if(modifiedState.hasPvgToAssignedNextState == false) {
                        modifiedState.errors.others = 'You do not have required privileges to move the product item into next state.';
                        this.setState(modifiedState);
                        return;
                    } else {

                        if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackRentalDevice" && 
                            (modifiedState.nextStateToBeAssignedToProduct != null && modifiedState.nextStateToBeAssignedToProduct.length > 0 
                                && modifiedState.nextStateToBeAssignedToProduct == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT) 
                        ) {
                            this.checkIfSubscriptionIsCancelled(modifiedState)
                            // modifiedState.nextStateToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["State"]);
                            // modifiedState.nextStateIDToBeAssignedToProduct.push(arrNextStateInfo.length > 0 && arrNextStateInfo[0]["StateID"]);
                        } else {
                            this.setState(modifiedState);
                        }
                    }
                }            

            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.deviceExistenceErr = 'Server experiencing issues (required parameters not sent). Try again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.deviceExistenceErr = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    onChangeItemDescription = (e) => {
        let modifiedState = this.state;
        modifiedState.ItemDescription = e.target.value;
        modifiedState.errors.others = "";
        this.setState(modifiedState);
    }  

    // onClickGetProductState = (e) => {
    //     let modifiedState = this.state;
    //     // modifiedState.nextStateIDToBeAssignedToProduct = e.target.getAttribute("next-product-stateid");
    //     // modifiedState.nextStateToBeAssignedToProduct = e.target.getAttribute("next-product-state");
    //     modifiedState.markProductState = true;
    //     this.setState(modifiedState);
    // }

    openQRCameraForPrdItmStateDevcID  = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        console.log("result",result);

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
            
        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.DevcIDQRCodeFlag = !modifiedState.DevcIDQRCodeFlag;
            console.log("modifiedState.DevcIDQRCodeFlag",modifiedState.DevcIDQRCodeFlag);
            // Explicitly Make all state value to blank in order to avoid wrong values.
            modifiedState.ItemID = "";
            modifiedState.orderLineNumber = "";
            modifiedState.orderType = "";
            modifiedState.showAndAssignStateToProduct = false;
            modifiedState.isSubExpired = false;
            modifiedState.markProductState = false;
            modifiedState.hasPvgToAssignedNextState = false;
            modifiedState.isDeviceIDExist = false;
            modifiedState.selectedProductLine = "";
            modifiedState.alreadyPresentProductName = "";
            modifiedState.alreadyPresentProductLine = "";
            modifiedState.alreadyPresentProductID = "";
            modifiedState.selectedProductName = "";
            modifiedState.currStateOfProduct = "";
            // modifiedState.nextStateToBeAssignedToProduct = "";
            // modifiedState.nextStateIDToBeAssignedToProduct = 0;
            modifiedState.nextStateToBeAssignedToProduct = [];
            modifiedState.nextStateIDToBeAssignedToProduct = [];
            modifiedState.ItemDescription = "";
            modifiedState.subscriptionPeriod = "";
            modifiedState.securityDepositToReturn = "";
            modifiedState.maxSecurityDepositToReturn = "";
            modifiedState.subscriptionAmountToReturn = "";
            modifiedState.maxSubscriptionAmountToReturn = "";
            modifiedState.totalReturnableAmount = "";
            modifiedState.invokeFromInsideFormPage = "";
            modifiedState.replacementItemIdOfFaultyDevice = "";
            modifiedState.comments = "";
            modifiedState.errors = {
                others: "",
                deviceExistenceErr: "",
                securityDepositToReturn: "",
                subscriptionAmountToReturn: "",
                totalReturnableAmount: "",
                comments: ""
            }  

            return modifiedState
        })
    }

    handleScanResultOfPrdItmStateDevcID = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.ItemID = data;
            if((modifiedState.ItemID != null && modifiedState.ItemID.length > 0)) {
                modifiedState.DevcIDQRCodeFlag = false;
                // this.verifyEnteredDeviceID(modifiedState);
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            }
            this.setState(modifiedState);
        }
    }

    openQRCameraForReplacementItemIdOfFaultyDevice  = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
            
        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.replacementItemIDOfFaultyDeviceQRCodeFlag = !modifiedState.replacementItemIDOfFaultyDeviceQRCodeFlag;
            modifiedState.replacementItemIdOfFaultyDevice = "";
            modifiedState.errors.deviceExistenceErr = "";
            modifiedState.errors.others = "";

            return modifiedState
        })
    }

    handleScanResultOfReplacedItemIdOfFaultyDevcID = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.replacementItemIdOfFaultyDevice = data;
            if((modifiedState.replacementItemIdOfFaultyDevice != null && modifiedState.replacementItemIdOfFaultyDevice.length > 0)) {
                modifiedState.replacementItemIDOfFaultyDeviceQRCodeFlag = false;

            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            }
            this.setState(modifiedState);
        }
    }


    handleQRCodeError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    onTogglePassword = () => {
        let modifiedState = this.state;
        modifiedState.passwordShown = !modifiedState.passwordShown;
        this.setState(modifiedState);
    }

    onFormSubmit = (e=null, inModifiedState=null, hasPrivilege=null) => {

        e != null && e.preventDefault();
        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }     

        let appRelevantDataContextValue = this.context;

        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        if(hasPrivilege == null && modifiedState.invokedFrom == "moveDevcInDecommisionSt" && modifiedState.OpenForm == false) {
            modifiedState.OpenForm = true;
            this.setState(modifiedState);
            return;
        }

        // on form submit for case other than replace takeBackRentalDevice or replaceFulfilledFaultyDevice we change device state
        // and for replaceFulfilledFaultyDevice we first verify the new device id and its current state and than only proceed replace device.
        // for take back rental devices first we check if subcription was canceled or expired or not then only proceed for take back the rental devices.

        if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackRentalDevice" && 
            (modifiedState.nextStateToBeAssignedToProduct != null && modifiedState.nextStateToBeAssignedToProduct.length > 0 
            && modifiedState.nextStateToBeAssignedToProduct == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT) 
        ) {
            this.insertOrUpdateTknBackDvcPmntInfo(modifiedState)

        } else if (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice" && 
            (modifiedState.nextStateToBeAssignedToProduct != null && modifiedState.nextStateToBeAssignedToProduct.length > 0 
            && modifiedState.nextStateToBeAssignedToProduct == ITM_STATE_FAULTY) 
        ){

            modifiedState.invokeFromInsideFormPage = "replaceFaultyOrderDevice";

            this.verifyEnteredDeviceID(modifiedState);

        } else {
            this.setProductItemState(modifiedState)
        }

    }

    setProductItemState = (inModifiedState=null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let selectedProductCode;
        let appRelevantDataContextValue = this.context;
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        if( modifiedState.isDeviceIDExist == false && 
            modifiedState.selectedProductLine.length > 0 &&
            modifiedState.selectedProductName.length > 0
        ) {

            // A case where entered serialNo is not in our db or so here user is going to select productline and productName for the very firstTime.
            let arrSelectedPrductInfo 
                    = modifiedState.arrProductTypeAndItsRelevantInfo.filter((productInfo) => 
                                                                                productInfo["ProductLine"] == modifiedState.selectedProductLine && 
                                                                                productInfo["ProductName"] == modifiedState.selectedProductName
                                                                    );

            selectedProductCode = arrSelectedPrductInfo[0]["ProductCode"];
        } else {
            selectedProductCode = modifiedState.alreadyPresentProductID;
        } 

        let trimSpacesOfItemID = trimStringAndRemoveTrailingComma(modifiedState.ItemID);

        let ItemDescription = trimStringAndRemoveTrailingComma(modifiedState.ItemDescription);

        if(ItemDescription == null || ItemDescription.length <= 0 ) {
            modifiedState.errors.others = "Please Enter Descrption for state change."
            this.setState(modifiedState);
            return;
        }

        if(modifiedState.invokedFrom == null || modifiedState.nextStateToBeAssignedToProduct == null || 
            (modifiedState.invokedFrom == "replaceFulfilledFaultyDevice" && modifiedState.nextStateToBeAssignedToProduct == ITM_STATE_FAULTY && 
                (modifiedState.replacementItemIdOfFaultyDevice == null || modifiedState.replacementItemIdOfFaultyDevice.length <= 0)
            )
        ) {
            modifiedState.errors.others = "Please Enter Device ID for Replacement of Faulty Device ID";
            this.setState(modifiedState)
            return;
        } 

        let deviceIdWhichReplacedFaultyOrderItemID = modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice" && 
            (modifiedState.nextStateToBeAssignedToProduct != null && modifiedState.nextStateToBeAssignedToProduct == ITM_STATE_FAULTY) 
            ? trimStringAndRemoveTrailingComma(modifiedState.replacementItemIdOfFaultyDevice) : null;

        let jsonParams = {
            ProductCode: selectedProductCode,
            StateIDToBeAssigned: modifiedState.selectedStateCodeToMark,
            LoggedInUserID: modifiedState.LoggedInUserID,
            ProductItemID: trimSpacesOfItemID,
            NextStateDescription: ItemDescription,
            nextStateToBeAssignedToProduct: modifiedState.nextStateIDToBeAssignedToProduct,
            deviceIdWhichReplacedFaultyItemID: deviceIdWhichReplacedFaultyOrderItemID
        }
        
        // return;

        axios.post(`${getAPIHostURL()}/wclient/setProductItemState`, jsonParams)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {

                if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackRentalDevice" && 
                    (modifiedState.nextStateToBeAssignedToProduct != null && modifiedState.nextStateToBeAssignedToProduct.length > 0 
                        && modifiedState.nextStateToBeAssignedToProduct == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT) 
                ) {
                    alert(`Successfully taken back rental device and updated the state of device to 'Device taken back for Replenishment'.`);

                } else {
                    alert(`Successfully updated the state of Product.`);
                }

                modifiedState.selectedProductLine = "";
                modifiedState.selectedProductName = "";
                modifiedState.alreadyPresentProductLine = "";
                modifiedState.alreadyPresentProductName = "";
                modifiedState.alreadyPresentProductID = "";
                modifiedState.arrProductInfoBasedOnSelectedProductline = [];
                modifiedState.arrProductLine = [];
                modifiedState.arrProductStates = [];
                modifiedState.showAndAssignStateToProduct = false;
                modifiedState.isSubExpired = false;
                modifiedState.markProductState = false;
                modifiedState.isDeviceIDExist = false;
                modifiedState.ItemDescription = "";
                modifiedState.enteredProductItemIdInfo = [];
                modifiedState.ItemID = "";
                modifiedState.currStateOfProduct = "";
                // modifiedState.nextStateToBeAssignedToProduct = "";
                // modifiedState.nextStateIDToBeAssignedToProduct = 0;
                modifiedState.nextStateToBeAssignedToProduct = [];
                modifiedState.nextStateIDToBeAssignedToProduct = [];
                modifiedState.selectStepToMark = "";
                modifiedState.dtTmWhenDvcTknBack = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(new Date());
                modifiedState.securityDepositToReturn = "";
                modifiedState.maxSecurityDepositToReturn = "";
                modifiedState.subscriptionAmountToReturn = "";
                modifiedState.maxSubscriptionAmountToReturn = "";
                modifiedState.totalReturnableAmount = "";
                modifiedState.comments = "";
                modifiedState.email = "";
                modifiedState.password = "";
                modifiedState.replacementItemIdOfFaultyDevice = "";
                modifiedState.invokeFromInsideFormPage = "";
                
                modifiedState.errors = {
                    others:"",
                    deviceExistenceErr: "",
                    email: "",
                    password: "",
                    loginIssue: "",
                    securityDepositToReturn: "",
                    subscriptionAmountToReturn: "",
                    totalReturnableAmount: "",
                    comments: ""

                }

                this.getProductCategoryAndItsRelevantInfo(modifiedState);
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues (required parameters not sent). Try again later.';
                }  else if (response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);

            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    insertOrUpdateTknBackDvcPmntInfo = (inModifiedState=null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let appRelevantDataContextValue = this.context;

        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        //check if data is entered or not for returnable security deposit, subscription amount and total amount for take back rental devicces
        modifiedState.errors.securityDepositToReturn = (modifiedState.securityDepositToReturn == null || modifiedState.securityDepositToReturn.length <= 0)
            ? "Please Enter Security Deposit To Return" 
            : "";

        modifiedState.errors.subscriptionAmountToReturn = (modifiedState.subscriptionAmountToReturn == null || modifiedState.subscriptionAmountToReturn.length <= 0)
            ? "Please Enter Subscription Amount To Return" 
            : "";
        
        modifiedState.errors.totalReturnableAmount = (modifiedState.totalReturnableAmount == null || modifiedState.totalReturnableAmount.length <= 0)
            ? "Please Enter Total Returnable Amount" 
            : "";

        // if any one of the required fields is not entered by user then return the function and show error message.
        if(modifiedState.errors.securityDepositToReturn.length > 0 || modifiedState.errors.subscriptionAmountToReturn.length > 0 ||
            modifiedState.errors.totalReturnableAmount.length > 0){     
            this.setState(modifiedState);
            return;
        }

        // replace all the single quotes to double quotes and escape character with double escape character while storing into database.
        let comments = modifiedState.comments.replace(/'/g, "''").replace(/\\/g, "\\\\");

        let jsonToInsertOrUpdateTknBackDvcPmntInfo = {
            itemID: modifiedState.ItemID,
            orderLineNumber: modifiedState.orderLineNumber,
            orderType: modifiedState.orderType,
            dtTmWhenDvcTknBack: convertLocalDateToStrYYYYMMDDHH24MMSS(new Date(modifiedState.dtTmWhenDvcTknBack)),
            securityDepositToReturn: modifiedState.securityDepositToReturn,
            subscriptionAmountToReturn: modifiedState.subscriptionAmountToReturn,
            comments: trimStringAndRemoveTrailingComma(comments),
            UserID: LoggedInUserID
        }

        //Calling API to insert payment info of taken back device.
        axios.post(`${getAPIHostURL()}/wclient/insertOrUpdateTknBackDvcPmntInfo`, jsonToInsertOrUpdateTknBackDvcPmntInfo)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                // alert('Successfully stored payment info of taken back device.')
                this.setProductItemState(modifiedState)
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues (required parameters not sent). Try again later.';
                }  else if (response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });     
    }

    onSelectProductStateToMark = (e) => {

        let modifiedState = this.state;
        modifiedState.selectStepToMark = e.target.value;

        let filteredSelectedStateInfo = modifiedState.arrProductStates.filter((singleProductStates) => singleProductStates["State"] == modifiedState.selectStepToMark);
        modifiedState.selectedStateCodeToMark = filteredSelectedStateInfo[0]['StateID'];
    
        this.setState(modifiedState);
    }

    onLoginSumbit = (e) => {
        let modifiedState = this.state;
        if(modifiedState.email == null || modifiedState.email.length <=0) {
            modifiedState.errors.email = "Please enter Email ID."
            this.setState(modifiedState); 
            return;
        } else if(modifiedState.password == null || modifiedState.password.length < 8) {
            modifiedState.errors.password = "Please enter password."
            this.setState(modifiedState); 
            return;
        } else {
            this.validateUserIdAndPassword();
        }
    }

    validateUserIdAndPassword = () => {
        let modifiedState = this.state;

        // Convert UserID to lowercase and remove all whiteSpaces before sending to Server as it makes comparison easy and valid.
        let UserID =  modifiedState.email.trim();
        UserID = UserID.toLowerCase();

        let jsonBody = {
            UserEmailID: UserID,
            UserPassword: modifiedState.password
        }

        axios.post( `${getAPIHostURL()}/wclient/validateUserIdAndPassword`, jsonBody)
        .then(response => {

            if(response.data.code == 'SUCCESS') { 

                if(response.data.hasPrivilege != null && response.data.hasPrivilege.length > 0) {
                    if(response.data.hasPrivilege > 0) {
                        modifiedState.OpenForm = false;
                        this.onFormSubmit(null, modifiedState, response.data.hasPrivilege);

                    } else {
                        alert(`The Login Credentials are Invalid or the User does not have required Privileges for marking Device into ${ITM_STATE_DECOMMISSIONED_DEVICEID} state`);
                    }
                }

            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = '"Server experiencing issues (required parameters not sent). Try again later."';
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.loginIssue = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'USER_PASSWORD_INVALID'){
                    modifiedState.errors.loginIssue = 'Password Invalid';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.loginIssue = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.loginIssue = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });
    }


    handleChange = (e) => {
        let modifiedState = this.state;    
        const { name, value } = e.target;
        modifiedState.errors.loginIssue = "";
      
        switch (name) {
          case 'email': 
            modifiedState.errors.email = value.length > 0 ? ((validEmailRegex.test(value)) ? '' : "EmailID is not valid!") : "";
            modifiedState.email = value;
            break;
          case 'password': 
            modifiedState.errors.password = value.length < 8 ? "password must be 8 characters long!" : '';
            modifiedState.password = value;
            break;
          case 'subscriptionAmountToReturn': 
            modifiedState.subscriptionAmountToReturn  = e.target.value;
            modifiedState.totalReturnableAmount = (Number(modifiedState.subscriptionAmountToReturn) + Number(modifiedState.securityDepositToReturn)).toFixed(2);
            modifiedState.errors.others= "";
            modifiedState.errors.subscriptionAmountToReturn = e.target.value > modifiedState.maxSubscriptionAmountToReturn 
                                        ? "This Amount Should Not Be Greater Than Maximum Returnable Rental Period Amount" 
                                        : e.target.value < 0 ? "This Amount Should Be Greater Than 0" : "";
            break;
          case 'securityDepositToReturn': 
            modifiedState.securityDepositToReturn  = e.target.value;
            modifiedState.totalReturnableAmount = (Number(modifiedState.subscriptionAmountToReturn) + Number(modifiedState.securityDepositToReturn)).toFixed(2);
            modifiedState.errors.others= "";
            modifiedState.errors.securityDepositToReturn = e.target.value > modifiedState.maxSecurityDepositToReturn 
                                        ? "This Amount Should Not Be Greater Than Maximum Returnable Rental Period Amount" 
                                        : e.target.value < 0 ? "This Amount Should Be Greater Than 0" : "";
            break;
        case 'comments': 
            modifiedState.comments  = e.target.value;
            modifiedState.errors.others= "";
            modifiedState.errors.comments = "";
            break;
          default:
            break;
        }  
        this.setState(modifiedState);
    }

    onCloseLoginPopup = (e) => {
        let modifiedState = this.state;
        modifiedState.OpenForm = false;
        modifiedState.email = '';
        modifiedState.password = '';
        this.setState(modifiedState)
    }

    // decideNoteAccordingToPageInvokedFrom = (e) => {
    //     let modifiedState = this.state;
    //     let whoPerformThisAction = null;
    //     let shouldCurrentStateOfDevc = null;
    //     let whatShouldBeDoneWithSameDevc = null;

    //     if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "readyForShipment") {
    //         whatShouldBeDoneWithSameDevc = "If the Device is Functional Tested and found OK or sometimes a Demo Device is brought back for Replenishment within a few days. In that case, we can directly use for another customer without making any changes to the Device, then it is packed in a Box and can be shipped as per requirement. For this case, the 'Functional Tested'/'Device Taken Back For Replenishment' Device can be marked as 'Ready for Shipment' using this UI."
    //         whoPerformThisAction = "A CRM User having 'ProductionWorker' or 'ProductionSupervisor' Privilege."
    //         shouldCurrentStateOfDevc = "The Device should be in 'Functional Tested' or 'Device Taken Back For Replenishment' State."

    //     } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice"){
    //         whatShouldBeDoneWithSameDevc = "Even before an Owner can start using the Device, sometimes the Device turns out to be Faulty. (For example - The Device might not be Powering ON or the Device is broken/damaged in transit). For this case, the 'Demo' / 'Sold' / 'Rental' Device can be marked as 'Faulty' using this UI."
    //         whoPerformThisAction = "A CRM User having 'FieldEngineer', 'ProductionWorker' or 'ProductionSupervisor' Privilege."
    //         shouldCurrentStateOfDevc = "The Device should be in 'Demo' or 'Sold' State."

    //     } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInDecommisionSt") {
    //         whatShouldBeDoneWithSameDevc = "When a Device cannot be made to work as expected by making any kind of Hardware Corrections or Calibration and the core component of the Device (ESP32) is no longer useful, then we will have to ensure that the DeviceID should not be used by the overall system. For this case, the 'Manufactured and Hardware Tested'/'Functional Tested'/'Ready for Shipment'/'Under Repair'/'Device Taken Back For Replenishment' Device can be marked as 'Decommissioned DeviceID' using this UI.";
    //         whoPerformThisAction = "A CRM User having 'ProductionWorker' or 'ProductionSupervisor' Privilege."
    //         shouldCurrentStateOfDevc = "The Device should be in 'Manufactured and Hardware Tested', 'Functional Tested', 'Ready for Shipment', 'Under Repair' or 'Demo Taken Back For Replenishment' State."

    //     } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInUnderRepairSt") {
    //         whatShouldBeDoneWithSameDevc = "If the device is 'Faulty' or if the device state is 'Upgrade' which means that it is an older model or if the device state is 'Recall Sold Device', it means that after being 'Sold' to the Customer it had developed a possible fault in the field. The repair work can be started on it by assigning it to 'Under Repair' State. For this case, the 'Faulty'/ 'Recall Sold Device'/'Upgrade' Device can be marked as 'Under Repair' using this UI.";
    //         whoPerformThisAction = "A CRM User having 'ProductionWorker' or 'ProductionSupervisor' Privilege."
    //         shouldCurrentStateOfDevc = "The Device should be in 'Faulty', 'Upgrade' or 'Recall Sold Device' State."

    //     } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "recallSoldDevice") {
    //         whatShouldBeDoneWithSameDevc = "If a Device on the field (which had been 'Sold' to the customer) has developed some fault, then it can be brought back for 'Recall Sold Device'. Note that the Root Device corresponding to this 'Mapped As Replacement' Device should have been in 'Sold' State. For this case, the 'Mapped to Owner'/'Mapped as Replacement' Device can be marked as 'Recall Sold Device' using this UI.";
    //         whoPerformThisAction = "A CRM User having 'FieldEngineer' Privilege."
    //         shouldCurrentStateOfDevc = "The Device should be in 'Mapped to Owner' or 'Mapped as Replacement' State."

    //     } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackDemoDevice") {
    //         whatShouldBeDoneWithSameDevc = "If a Device on the field (which had been given to the customer for 'Demo') is done with its task, then it can be taken back for Replenishment. Note that the Root Device corresponding to this 'Mapped As Replacement' Device should have been in 'Demo' State. For this case, the 'Mapped to Owner'/'Mapped as Replacement' Device can be marked as 'Device Taken Back For Replenishment' using this UI.";
    //         whoPerformThisAction = "A CRM User having 'FieldEngineer' Privilege."
    //         shouldCurrentStateOfDevc = "The Device should be in 'Mapped to Owner' or 'Mapped as Replacement' State."
    //     } else {
    //         whatShouldBeDoneWithSameDevc = null;
    //         whoPerformThisAction = null;
    //         shouldCurrentStateOfDevc = null;
    //     }
    //     return {whoPerformThisAction: whoPerformThisAction, shouldCurrentStateOfDevc:shouldCurrentStateOfDevc, whatShouldBeDoneWithSameDevc:whatShouldBeDoneWithSameDevc}
    // }

    renderInfoRelatedToPutDevcInSelectedState = () => {
        let valueDependsOnPageInvokedFrom = this.decideNoteAccordingToPageInvokedFrom();

        let whoPerformThisAction = valueDependsOnPageInvokedFrom.whoPerformThisAction;
        let shouldCurrentStateOfDevc = valueDependsOnPageInvokedFrom.shouldCurrentStateOfDevc;
        let whatShouldBeDoneWithSameDevc = valueDependsOnPageInvokedFrom.whatShouldBeDoneWithSameDevc;
        return(
            <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} 
                 hidden={(whoPerformThisAction == null || whoPerformThisAction.length <=0) &&
                         (shouldCurrentStateOfDevc == null || shouldCurrentStateOfDevc.length <=0) &&
                         (whatShouldBeDoneWithSameDevc == null || whatShouldBeDoneWithSameDevc.length <=0)}
            >
                <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>{whatShouldBeDoneWithSameDevc}</div>
                <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>Who can Perform this Action ?</div>
                <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>{whoPerformThisAction}</div>
                <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>What should be the Current State of the Device ?</div>
                <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>{shouldCurrentStateOfDevc}</div>
            </div>
        )
    }

    decideNoteAccordingToPageInvokedFrom = (e) => {
        let modifiedState = this.state;

        if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "readyForShipment") {

            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A Device is suitable for Shipment in the following scenarios: 
                        <br/>- The Device is Functional Tested and has Passed. 
                        <br/>- The Device is Under Repair but has some minor hardware related issue which doesnt need Manufactured and hardware test or functional test, in that case, it can be directly put for Ready for Shipment. 
                        <br/>- Sometimes a Demo Device is brought back for Replenishment within a few days. In that case, it can be directly used for another customer without making any changes to the Device.
                        <br/><br/>For the above scenarios, the device can be packed in a Box and can be shipped as per requirement.
                        Here, the 'Functional Tested'/'Device Taken Back For Replenishment'/'Under Repair' Device can be marked as 'Ready for Shipment' using this UI.</div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        Who can Perform this Action ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A CRM User having 'ProductionWorker' or 'ProductionSupervisor' Privilege.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Under Repair', 'Functional Tested' or 'Device Taken Back For Replenishment' State.
                    </div>
                </div>
            )

        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "replaceFulfilledFaultyDevice"){
            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        Sometimes even before an Owner can start using the Device, the Device turns out to be Faulty. 
                        (For example - The Device might not be Powering ON or the Device is Broken/Damaged in transit). 
                        For this case, the 'Demo' / 'Sold' / 'Rental' Device can be replaced and marked as 'Faulty' with 
                        new Device having state 'Ready For Shippment'. The new Device will be marked as respectice 
                        state of the Faulty Device such as 'Demo' / 'Sold' / 'Rental'.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        Who can Perform this Action ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A CRM User having 'FieldEngineer', 'ProductionWorker' or 'ProductionSupervisor' Privilege.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Faulty Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Demo' / 'Sold' / 'Rental' State.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Replacement Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Ready For Shippment' State.
                    </div>
                </div>
            )

        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInDecommisionSt") {
            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        Sometimes a Device cannot be made to work as expected by making any kind of Hardware Corrections or Calibration and the core component of the Device (ESP32) is no longer usable.
                        <br/>
                        This can happen in the following scenarios:
                        <br/>
                        - A 'Manufactured and Hardware Tested'/'Functional Tested' Device cannot be moved to any other useful state because of an issue in the Device.
                        <br/>
                        - A 'Ready for Shipment' Device went into an un-repairable damage.
                        <br/>
                        - A 'Device Taken Back For Replenishment' was found to be damaged and no kind of repair (hardware, firmware or calibration) can make it usable.
                        <br/>
                        - An 'Under Repair' Device cannot be made usable with any kind of repair work.
                        <br/>
                        <br/>
                        In the above scenarios, we will have to ensure that the DeviceID should not be used by the overall system. For this case, the 'Manufactured and Hardware Tested'/'Functional Tested'/'Ready for Shipment'/'Under Repair'/'Device Taken Back For Replenishment' Device can be marked as 'Decommissioned DeviceID' using this UI.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        Who can Perform this Action ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A CRM User having 'ProductionWorker' or 'ProductionSupervisor' Privilege.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Manufactured and Hardware Tested', 'Functional Tested', 'Ready for Shipment', 'Under Repair' or 'Device Taken Back For Replenishment' State.
                    </div>
                </div>
            )

        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "moveDevcInUnderRepairSt") {
            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A Device might need Repairs/Modifications in the following scenarios:
                        <br/>
                        - The device became 'Faulty' after the owner started using it or even before he could actually use it.
                        <br/> 
                        - The device state is 'Upgrade' which means that it is an older model.
                        <br/> 
                        - The device state is 'Recall Sold Device', i.e after being 'Sold' to the Customer it had developed a possible fault in the field. 
                        <br/>
                        <br/>
                        For the above scenarios, the repair work can be started on it by assigning it to 'Under Repair' State. For this case, the 'Faulty'/'Recall Sold Device'/'Upgrade' Device can be marked as 'Under Repair' using this UI.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        Who can Perform this Action ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A CRM User having 'ProductionWorker' or 'ProductionSupervisor' Privilege.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Faulty', 'Upgrade' or 'Recall Sold Device' State.
                    </div>
                </div>
            )

        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "recallSoldDevice") {

            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        If a Device on the field (which had been 'Sold' to the customer) has a possible fault after being put into use, then it can be 'Recalled' to prevent further issues.
                        <br/>
                        There are following kinds of 'Sold' Devices on the field:
                        <br/> 
                        - A 'Mapped to Owner' Device (which was a 'Sold' device assigned to Owner through Setup screen).
                        <br/> 
                        - A 'Mapped as Replacement' Device which was assigned through the 'Replacement/Upgrade' UI. (Note that the Root Device for this 'Mapped' Device should originally have been in 'Sold' State).
                        <br/>
                        <br/>
                        For the above Scenarios, the 'Mapped to Owner'/'Mapped as Replacement' Device can be marked as 'Recall Sold Device' using this UI.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        Who can Perform this Action ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A CRM User having 'FieldEngineer' Privilege.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Mapped to Owner' or 'Mapped as Replacement' State.
                    </div>
                </div>
            )

        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackDemoDevice") {
            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        If a Device on the field (which had been given to the customer for 'Demo') is done with its task, then it can be taken back for Replenishment.                        
                        <br/>
                        There are following kinds of 'Demo' Devices on the field:
                        <br/> 
                        - A 'Mapped to Owner' Device (which was a 'Demo' device assigned to Owner through Setup screen).
                        <br/> 
                        - A 'Mapped as Replacement' Device which was assigned through the 'Replacement/Upgrade' UI. (Note that the Root Device for this 'Mapped' Device should originally have been in 'Demo' State).                        
                        <br/>
                        <br/>
                        For the above Scenarios, the 'Mapped to Owner'/'Mapped as Replacement' Device can be marked as 'Device Taken Back For Replenishment' using this UI.                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        Who can Perform this Action ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A CRM User having 'FieldEngineer' Privilege.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Mapped to Owner' or 'Mapped as Replacement' State.
                    </div>
                </div>
            )
        } else if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == "takeBackRentalDevice") {
            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        If a Device on the field (which had been given to the customer on 'Rent Basis') is completed with its rental period or customer wants to cancel the rental subscription before rental perios ends,
                        then it can be taken back for Replenishment.                        
                        <br/>
                        There are following kinds of 'Rental' Devices on the field:
                        <br/> 
                        - A 'Mapped to Owner' Device (which was a 'Rental' device assigned to Owner through Setup screen).
                        <br/> 
                        - A 'Mapped as Replacement' Device which was assigned through the 'Replacement/Upgrade' UI. (Note that the Root Device for this 'Mapped' Device should originally have been in 'Rental' State).                        
                        <br/>
                        <br/>
                        For the above Scenarios, the 'Mapped to Owner'/'Mapped as Replacement' Device can be marked as 'Device Taken Back For Replenishment' using this UI.                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        Who can Perform this Action ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        A CRM User having 'FieldEngineer' Privilege.
                    </div>
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                        What should be the Current State of the Device ?
                    </div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                        The Device should be in 'Mapped to Owner' or 'Mapped as Replacement' State.
                    </div>
                </div>
            )
        } else {
            return(
                <div/>
            )
        }
    }
    
    render() {
        const color = {
            color : "var(--errorColor)",
            fontSize:"13px",
            marginLeft: "2rem",
            paddingBottom:"1rem"
          }
  
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12">
                        <div className="modal-body p-4 box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>
                                {this.state.invokedFrom == "ProductItemStateHistory" ? "Set Product Item State" 
                                 : this.state.invokedFrom == "takeBackDemoDevice" ? "Take Back Demo Device" 
                                 : this.state.invokedFrom == "takeBackRentalDevice" ? "Take Back Rental Device" 
                                 : this.state.invokedFrom == "recallSoldDevice" ? "Recall Sold Device"
                                 : this.state.invokedFrom == "moveDevcInDecommisionSt" ? "Mark DeviceID as Decommissioned" 
                                 : this.state.invokedFrom == "moveDevcInUnderRepairSt" ? "Mark Device as Under Repair" 
                                 : this.state.invokedFrom == "readyForShipment" ? "Mark Device as Ready for Shipment"
                                 : this.state.invokedFrom == "replaceFulfilledFaultyDevice" ? "Replace Fulfilled Order Faulty Device" : "ProductItemStateHistory"}
                            </div> 
                            {/* {this.renderInfoRelatedToPutDevcInSelectedState()} */}
                            {this.decideNoteAccordingToPageInvokedFrom()}
                            <form onSubmit={this.onFormSubmit}>
                                <div>
                                    <div>
                                        <label className="reg-form-label" 
                                            style={{width:"100%"}}
                                        >
                                            Scan QR Code for Serial No / Device ID of the Product Item or Enter the same:
                                        </label>
                                        <input style={{width:"100%"}}
                                            className="input-form"
                                            value= {this.state.ItemID}
                                            onChange = {this.onChangeItemID}
                                            onKeyDown={this.handleKeyDown}
                                            required
                                        />
                                        <label onClick= {this.openQRCameraForPrdItmStateDevcID} className = "qrcodeTextBtn">
                                            <FaQrcode className = "qrcodeTextBtnIcon"/>
                                        </label>
                                        <div>
                                            {(this.state.DevcIDQRCodeFlag == true)
                                            ?
                                                <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>
                                                    {/* <QrReader
                                                        scanDelay={300}
                                                        onResult={(result, error) => {
                                                            if (!!result) {
                                                                this.handleScanResultOfPrdItmStateDevcID(result?.text);
                                                            }
                                                        }}
                                                        className = "QRCodeCamBoxForModalAndContainer"
                                                    /> */}
                                                    <QrScanner
                                                        scanDelay={300}
                                                        onResult={(result, error) => {
                                                            if (!!result) {
                                                                this.handleScanResultOfPrdItmStateDevcID(result?.text);
                                                            }
                                                        }}
                                                        className = "QRCodeCamBoxForModalAndContainer"
                                                    />
                                                </div>
                                            :
                                                <div/>
                                            }
                                        </div>
                                        {this.state.showAndAssignStateToProduct == false && 
                                            <div style={{display:"flex", justifyContent: "center", flexDirection:"column"}}>
                                                <button type= {"button"}
                                                        style={{borderRadius: "5px", 
                                                                width:'40%', 
                                                                marginLeft:'30%',
                                                                marginTop:"0.5rem",
                                                                backgroundColor: "var(--primaryColor)", color:"white",
                                                                fontSize: "1.2rem", padding: "0.4rem"}}
                                                        className="btn-lg"
                                                        onClick= {() => this.verifyEnteredDeviceID()} 
                                                >
                                                    Proceed
                                                </button>
                                                {this.state.errors.deviceExistenceErr.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", paddingTop: "0.3rem"}} className='errors'>{this.state.errors.deviceExistenceErr}</p>} 
                                            </div>
                                        }  
                                    </div>
                                    {((this.state.invokedFrom != null && this.state.invokedFrom == "takeBackRentalDevice") 
                                        ?
                                        this.state.showAndAssignStateToProduct == true && this.state.isSubExpired == true && (this.state.nextStateToBeAssignedToProduct != null && 
                                        this.state.nextStateToBeAssignedToProduct.length > 0 && this.state.nextStateToBeAssignedToProduct == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT) 
                                        :
                                        this.state.showAndAssignStateToProduct == true) &&
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <div>
                                                <label className="reg-form-label" 
                                                        style={{width:"100%"}}
                                                >
                                                    Select Product Line:
                                                </label>
                                                {( (this.state.isDeviceIDExist == false) &&
                                                    (this.state.arrProductModelRelavantInfo == null || this.state.arrProductModelRelavantInfo.length <=0) &&
                                                    (this.state.enteredProductItemIdInfo == null || this.state.enteredProductItemIdInfo.length <=0)
                                                 ) ?
                                                    <select className="input-form" 
                                                            style={{width:"100%", background:"white"}} 
                                                            required
                                                            value={this.state.selectedProductLine}
                                                            onChange={this.onChangeProductLine}
                                                    >
                                                        <option value="" select= "true" disabled>Select Product Line...</option> 
                                                        {(this.state.arrProductLine).map((singleProductCategory, index) => <option key={index} value={singleProductCategory}>{singleProductCategory}</option>)}

                                                    </select>
                                                : 
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", background:"#F5F5F5"}}
                                                            className="input-form"
                                                            value= {this.state.alreadyPresentProductLine}
                                                            readOnly
                                                    />
                                                }

                                                <label className="reg-form-label" 
                                                        style={{width:"100%"}}
                                                >
                                                    Select Product Name:
                                                </label>
                                                {( (this.state.isDeviceIDExist == false) &&
                                                    (this.state.arrProductModelRelavantInfo == null || this.state.arrProductModelRelavantInfo.length <=0) &&
                                                    (this.state.enteredProductItemIdInfo == null || this.state.enteredProductItemIdInfo.length <=0)
                                                 ) ?
                                                    <select className="input-form" 
                                                            style={{width:"100%", background:"white"}} 
                                                            required
                                                            value={this.state.selectedProductName}
                                                            onChange={this.onChangeProductName}
                                                    >
                                                        <option value="" select= "true" disabled>Select Product Name...</option> 
                                                        {(this.state.arrProductInfoBasedOnSelectedProductline).map(
                                                                (singleProductInfo, index) => <option key={index} 
                                                                                                    value={singleProductInfo["ProductName"]}
                                                                                            > 
                                                                                                {singleProductInfo["ProductName"]}
                                                                                            </option>
                                                        )}

                                                    </select>
                                                :
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", background:"#F5F5F5"}}
                                                            className="input-form"
                                                            value= {this.state.alreadyPresentProductName}
                                                            readOnly
                                                    />
                                                }
                                            </div>
                                            <div>
                                                <label className="reg-form-label" 
                                                        style={{width:"100%"}}
                                                >
                                                    Current state of the Product Item:
                                                </label>
                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", background:"#F5F5F5"}}
                                                    className="input-form"
                                                    value= {this.state.currStateOfProduct}
                                                    readOnly
                                                />
                                                
                                                {this.state.hasPvgToAssignedNextState == true &&
                                                    <div>
                                                        <label className="reg-form-label" 
                                                                style={{width:"100%"}}
                                                        >
                                                            Reason to Change:
                                                        </label>
                                                        <input style={{width:"100%"}}
                                                                className="input-form"
                                                                value= {this.state.ItemDescription}
                                                                onChange = {this.onChangeItemDescription}
                                                                required
                                                        />

                                                        <div>
                                                            <label className="reg-form-label">{this.state.invokedFrom != null 
                                                                                            && this.state.invokedFrom != "ProductItemStateHistory" 
                                                                                            ? "Next State To Mark : " : "Select State To Mark : "
                                                                                            }
                                                            </label>
                                                            <select className="input-form" style={{height:"2.5rem"}}
                                                                                        value={this.state.selectStepToMark}
                                                                                        onChange={this.onSelectProductStateToMark}
                                                            >
                                                                <option value="" disabled select= "true">Select State To Mark...</option>
                                                                {(this.state.nextStateToBeAssignedToProduct).map((singleStateToMark,index) => <option key={index} value={singleStateToMark}>{singleStateToMark}</option>)}
                                                                
                                                            </select>
                                                        </div>

                                                        {(this.state.invokedFrom != null && this.state.invokedFrom == "replaceFulfilledFaultyDevice" && 
                                                            (this.state.nextStateToBeAssignedToProduct != null && this.state.nextStateToBeAssignedToProduct == ITM_STATE_FAULTY) 
                                                            ) && this.state.showAndAssignStateToProduct == true &&    

                                                            <div>
                                                                <label className="reg-form-label" 
                                                                    style={{width:"100%"}}
                                                                >
                                                                    Scan QR Code for Serial No / Device ID for <b> Replacement of Faulty Device </b> or Enter the same:
                                                                </label>
                                                                <input style={{width:"100%"}}
                                                                    className="input-form"
                                                                    value= {this.state.replacementItemIdOfFaultyDevice}
                                                                    onChange = {this.onChangeReplaceItemIdOfFaultyDevice}
                                                                    onKeyDown={this.handleKeyDown}
                                                                    required
                                                                />
                                                                <label onClick= {this.openQRCameraForReplacementItemIdOfFaultyDevice} className = "qrcodeTextBtn">
                                                                    <FaQrcode className = "qrcodeTextBtnIcon"/>
                                                                </label>
                                                                <div>
                                                                    {(this.state.replacementItemIDOfFaultyDeviceQRCodeFlag == true)
                                                                    ?
                                                                        <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                                                marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                                                marginRight: "auto"}}>
                                                                            <QrScanner
                                                                                scanDelay={300}
                                                                                onResult={(result, error) => {
                                                                                    if (!!result) {
                                                                                        this.handleScanResultOfReplacedItemIdOfFaultyDevcID(result?.text);
                                                                                    }
                                                                                }}
                                                                                className = "QRCodeCamBoxForModalAndContainer"
                                                                            />
                                                                        </div>
                                                                    :
                                                                        <div/>
                                                                    }
                                                                </div>
                                                            
                                                                {this.state.errors.deviceExistenceErr != null && this.state.errors.deviceExistenceErr.length > 0 && 
                                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", paddingTop: "0.3rem"}} className='errors'>{this.state.errors.deviceExistenceErr}</p>} 

                                                            </div>
                                                        }

                                                    {/* <div>

                                                        <button type= {"button"}
                                                                style={{borderRadius: "5px", 
                                                                        width:'80%', 
                                                                        marginLeft:'10%',
                                                                        marginTop:"0.5rem",
                                                                        backgroundColor: this.state.markProductState ? "#F5F5F5" : "var(--primaryColor)", 
                                                                        color: this.state.markProductState ? "gray" : "white"
                                                                }}
                                                                className="btn-sm"
                                                                // next-product-stateid = {this.state.nextStateIDToBeAssignedToProduct}
                                                                // next-product-state = {this.state.nextStateToBeAssignedToProduct}
                                                                onClick={this.onClickGetProductState} 
                                                                disabled = {this.state.markProductState}
                                                        >
                                                            {this.state.markProductState == false ? 
                                                                <div>Mark Product Item As:  &nbsp;
                                                                    {/* <b>{this.state.nextStateToBeAssignedToProduct}</b>
                                                                    <b>{this.state.selectStepToMark}</b>  
                                                                </div>

                                                            :
                                                                <div>Marked Product Item As:  &nbsp;
                                                                {/* <b>{this.state.nextStateToBeAssignedToProduct}</b> 
                                                                <b>{this.state.selectStepToMark}</b>  
                                                                </div>
                                                            }    
                                                            Mark Product As:  &nbsp;
                                                            <b>{this.state.nextStateToBeAssignedToProduct}</b> 
                                                        </button> 
                                                    </div> */}

                                                    </div>
                                                }   
                                            </div>
                                        </div>
                                    }  
                                    {(this.state.invokedFrom != null && this.state.invokedFrom == "takeBackRentalDevice" && 
                                        (this.state.nextStateToBeAssignedToProduct != null && this.state.nextStateToBeAssignedToProduct.length > 0 
                                        && this.state.nextStateToBeAssignedToProduct == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT) 
                                        ) && this.state.showAndAssignStateToProduct == true && this.state.isSubExpired == true &&        
                                            <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                                <div>
                                                    <label className="reg-form-label">{this.state.invokedFrom != null 
                                                                                    && this.state.invokedFrom != "ProductItemStateHistory" 
                                                                                    ? "Date when device taken back : " : null
                                                                                    }
                                                    </label>
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", background:"#F5F5F5"}}
                                                        className="input-form"
                                                        value= {this.state.dtTmWhenDvcTknBack}
                                                        readOnly
                                                    />
                                                </div>  
                                                <div className = "startAndEnddate" style={{ marginTop: "0.5rem"}}>
                                                    <div style={{flex: "1"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                            {this.state.invokedFrom != null 
                                                            && this.state.invokedFrom != "ProductItemStateHistory" 
                                                            && this.state.invokedFrom == "takeBackRentalDevice"
                                                            ? "Returnable Rental Period Amount (INR): " : null
                                                            }
                                                        </label>
                                                        <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                            className="input-form"
                                                            type= "number"
                                                            step= "any"
                                                            min = "0"
                                                            max = {this.state.maxSubscriptionAmountToReturn}
                                                            value= {this.state.subscriptionAmountToReturn}
                                                            name = "subscriptionAmountToReturn"
                                                            onChange = {this.handleChange}
                                                        />
                                                        {this.state.errors.subscriptionAmountToReturn.length > 0 &&
                                                            <span  className='validationErrorLabel'>{this.state.errors.subscriptionAmountToReturn}</span>}
                                                    </div>
                                            
                                                    <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                            {this.state.invokedFrom != null 
                                                                && this.state.invokedFrom != "ProductItemStateHistory" 
                                                                && this.state.invokedFrom == "takeBackRentalDevice"
                                                                ? "Returnable Rental Security Deposit (INR): " : null
                                                                }
                                                        </label>
                                                        <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                            className="input-form"
                                                            type= "number"
                                                            min = "0"
                                                            step= "any"
                                                            max = {this.state.maxSecurityDepositToReturn}
                                                            value= {this.state.securityDepositToReturn}
                                                            name = "securityDepositToReturn"
                                                            onChange = {this.handleChange}
                                                        />
                                                        {this.state.errors.securityDepositToReturn.length > 0 &&
                                                            <span  className='validationErrorLabel'>{this.state.errors.securityDepositToReturn}</span>}
                                                    </div>     
                                                </div >
                                                <div style={{flex:"1", marginTop: "0.5rem"}}>
                                                    <label className="reg-form-label">{this.state.invokedFrom != null 
                                                                                    && this.state.invokedFrom != "ProductItemStateHistory" 
                                                                                    && this.state.invokedFrom == "takeBackRentalDevice"
                                                                                    ? "Total Returnable Rental Amount (INR): " : null
                                                                                    }
                                                    </label>
                                                    <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", marginRight:"2rem", background:"#F5F5F5"}}
                                                        className="input-form"
                                                        readOnly
                                                        value= {this.state.totalReturnableAmount}
                                                    />
                                                    {this.state.errors.totalReturnableAmount.length > 0 &&
                                                        <span  className='validationErrorLabel'>{this.state.errors.totalReturnableAmount}</span>}
                                                </div>
                                                <div>
                                                    <label className="reg-form-label" style={{width:"100%", marginTop:"0.5rem"}}>
                                                        Comment:
                                                    </label>
                                                    <input style={{width:"100%"}}
                                                            className="input-form"
                                                            value= {this.state.comments}
                                                            name = "comments"
                                                            onChange = {this.handleChange}
                                                    />
                                                </div>        
                                            </div>   
                                            }
                                </div>
                                {/* {this.state.markProductState == true && */}
                                {this.state.selectStepToMark != null && this.state.selectStepToMark.length > 0 && this.state.errors.others.length <= 0 && 
                                    <div>
                                    
                                        <button type= {"submit"}
                                                style={{borderRadius: "5px", 
                                                        width:'40%', 
                                                        marginTop:"0.5rem",
                                                        backgroundColor: "var(--primaryColor)", color:"white"}}
                                                className="btn-lg"
                                        >
                                            Save
                                        </button>                                       
                                    </div>
                                }
                                <div>
                                    {this.state.errors.others.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p> }   
                                </div>
                                    
                            </form>
                        </div>
                        <div className="adduser-blackfilm" hidden={this.state.OpenForm == false}>    
                            <div className="container">
                                <div className="row">
                                    <div className="container col-lg-4 col-lg-offset-4
                                                            col-md-6 col-md-offset-3">
                                        <div className="mvAddScroll">
                                        <div className="adduser-popup">
                                            <form>
                                                <div className="popup-scroll">
                                                    <h6 className="deviceDetail">Login</h6>
                                                    <div className="form-group update-form">
                                                        <div className="inputgroupCustom">
                                                            <label className="adduser-form-label">Email ID:</label>
                                                            <input type='email' name='email' 
                                                                    className="input-form" 
                                                                    required="required" 
                                                                    value = {this.state.email}
                                                                    onChange={this.handleChange}
                                                                    noValidate 
                                                            />
                                                                {this.state.errors.email != null && this.state.errors.email.length > 0 && 
                                                                <span style={color} className='error'>{this.state.errors.email}</span>} 
                                                        </div>
                                                    </div>

                                                    <div className="form-group update-form">
                                                        <div className="inputgroupCustom">
                                                            <label className="adduser-form-label">Password:</label>
                                                            <input autoComplete="off" type= {this.state.passwordShown == true ? "text" : "password"} name='password'  className="input-form" style={{marginBottom: "0.2rem"}} required="required" onChange={this.handleChange} noValidate />
                                                                {this.state.passwordShown == true ? 
                                                                <MdVisibilityOff style={{marginTop: "0.6rem", color: "#043e73", marginLeft: "-2.2rem"}} size="1.5rem" className="viewAndEditIcon qrcodeTextBtnIcon" onClick= {this.onTogglePassword}/>
                                                                :
                                                                <MdVisibility style={{marginTop: "0.6rem", color: "#043e73",marginLeft: "-2.2rem"}} size="1.5rem" className="viewAndEditIcon qrcodeTextBtnIcon" onClick= {this.onTogglePassword}/>
                                                                }                                                            {this.state.errors.password != null && this.state.errors.password.length > 0 && 
                                                            <p style={color} className='error'>{this.state.errors.password}</p>}
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                                                        <button type="button" 
                                                                className="adduserButton btn-lg"
                                                                style={{fontSize:"1rem", fontWeight: "bold"}}
                                                                onClick={this.onLoginSumbit}> 
                                                                PROCEED
                                                        </button>
                                                        <button type="button" 
                                                                className="adduserButton btn-lg"
                                                                style={{fontSize:"1rem", fontWeight: "bold"}}
                                                                onClick={this.onCloseLoginPopup}> 
                                                                CANCEL
                                                        </button>
                                                    </div>
                                                    {this.state.errors.loginIssue != null && this.state.errors.loginIssue.length > 0 && 
                                                        <p style={color} className='error'>{this.state.errors.loginIssue}</p>}

                                                </div>
                                            </form>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

VcProductItemState.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods
export default VcProductItemState;
